// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { ReactNode, Suspense, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";

import { useStore } from "../../store";
import { usePlatforms } from "../../hook/usePlatforms";
import { getUrlParam } from "../../util";
import SuspenseLoading from "../suspense-loading/SuspenseLoading";
interface Props {
  children?: ReactNode;
  path: string;
  component: React.FC;
  exact: boolean;
}

const RoutePage: React.FC<Props> = ({ children, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const hasCreatedKey = useStore(store => store.wallet.keys !== undefined);
  const hasVerifiedEmail = useStore(
    store => store.verifyEmail.hasVerifiedEmail
  );

  const { mustInstallPwaAndroid, mustInstallIosApp } = usePlatforms();

  const isCreateWalletPath = (path: string): boolean => {
    return (
      path == "/signin" ||
      path == "/login" ||
      path == "/signup" ||
      path == "/signin/existing-seed-phrase" ||
      path == "/signin/existing-jwt" ||
      path == "/signin/reset-account"
    );
  };

  const isMustInstallPwaPath = (path: string): boolean => {
    return path === "/install";
  };

  const isVerifyEmailPath = (path: string): boolean => {
    return path == "/verify-email";
  };

  const isViewPagePath = (path: string): boolean => {
    return path == "/event/detail" || path == "/terms-and-conditions";
  };

  useEffect(() => {
    const eventCode = getUrlParam("event", location);
    const currentParams = new URLSearchParams(window.location.search);
    if (eventCode) {
      const eventDetailUrl = new URL(`${window.location.origin}/event/detail`);
      currentParams.forEach((value, key) => {
        eventDetailUrl.searchParams.set(key, value);
      });
      eventDetailUrl.searchParams.set("code", eventCode);
      eventDetailUrl.searchParams.delete("event");
      history.replace(eventDetailUrl.pathname + eventDetailUrl.search);
      return;
    }
    if (isViewPagePath(location.pathname)) {
      return;
    }
    if (mustInstallPwaAndroid || mustInstallIosApp) {
      if (!isMustInstallPwaPath(location.pathname)) {
        history.replace("/install" + window.location.search);
      }
      return;
    }
    if (!hasCreatedKey) {
      if (!isCreateWalletPath(location.pathname)) {
        history.replace("/signin" + window.location.search);
      }
      return;
    }
    if (hasCreatedKey) {
      if (isCreateWalletPath(location.pathname)) {
        history.replace("/home" + window.location.search);
      }
      return;
    }
    if (!hasVerifiedEmail) {
      if (!isVerifyEmailPath(location.pathname)) {
        history.replace("/verify-email");
      }
      return;
    }
    if (
      isCreateWalletPath(location.pathname) ||
      isVerifyEmailPath(location.pathname)
    ) {
      history.replace("/home" + window.location.search);
      return;
    }
  }, [location.pathname]);

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Route {...rest}>{children}</Route>
    </Suspense>
  );
};

export default RoutePage;
