// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { envVar } from "../util";

export const P2S_URL = envVar("REACT_APP_P2S_URL");
export const P2S_WS_URL = envVar("REACT_APP_P2S_WS_URL");
export const P2S_APP_URL = envVar("REACT_APP_URL");
export const P2S_APP_STORE_URL =
  "https://apps.apple.com/gb/app/pay2stay/id1664086830";
