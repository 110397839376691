// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useEffect } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
/* global style*/
import "./theme/global.scss";
/* animation style*/
import "animate.css";

import { useStore } from "./store";
import { balanceListenToWs } from "./wallet/balance";
import Loading from "./components/loading/Loading";
import { BrowserSession } from "./components/browser-session/BrowserSession";
import { PinLockOverlay } from "./components/pin-lock-overlay/PinLockOverlay";
import { UpdateAppVersion } from "./components/update-app-version/UpdateAppVersion";
import { AppUrlOpener } from "./components/app-url-opener/AppUrlOpener";
import { BottomNavigation } from "./components/bottom-navigation/BottomNavigation";
import LoadingOverlay from "./components/loading-overlay/LoadingOverlay";
import { InviteCodeModal } from "./components/invite-code-modal/InviteCodeModal";

setupIonicReact();

const App: React.FC = () => {
  const isReady = useStore(store => store.wallet.isReady);
  const walletLoad = useStore(store => store.walletLoad);
  const pinLoad = useStore(store => store.pinLoad);
  const walletFetchBalance = useStore(store => store.walletFetchBalance);
  const wsInit = useStore(store => store.wsInit);
  const walletKeys = useStore(store => store.wallet.keys);
  const loadInstallPromptEvent = useStore(
    store => store.loadInstallPromptEvent
  );
  const loadStoragePwaInstallable = useStore(
    store => store.loadStoragePwaInstallable
  );
  const hasVerifiedEmail = useStore(
    store => store.verifyEmail.hasVerifiedEmail
  );
  const paymentHistorySync = useStore(store => store.paymentHistorySync);

  useEffect(() => {
    const initApp = async () => {
      await pinLoad();
      await walletLoad();
      loadStoragePwaInstallable();
    };
    initApp();
  }, []);

  useEffect(() => {
    if (isReady && walletKeys !== undefined) {
      walletFetchBalance();
      balanceListenToWs(walletFetchBalance, walletKeys.pkh);
      wsInit();
      if (hasVerifiedEmail && !walletKeys.isProvisionalPk) {
        paymentHistorySync();
      }
    }
  }, [isReady, walletKeys]);

  useEffect(loadInstallPromptEvent, []);

  if (!isReady) {
    return (
      <IonApp className="app-wrapper">
        <Loading progress={70} />
      </IonApp>
    );
  }

  return (
    <IonApp>
      <LoadingOverlay />
      <IonReactRouter>
        <AppUrlOpener />
        <InviteCodeModal />
        <BrowserSession />
        <PinLockOverlay />
        <UpdateAppVersion />
        <BottomNavigation />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
