// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import * as history from "history";

import { P2S_APP_URL } from "./rpc/settings";
import { INSTALLED_PWA_PARAM } from "./pages/install/install-pwa-android-buttons/InstallAndroidPwaButtons";

export const numberRange = (start: number, end: number) => {
  const numbers: number[] = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }
  return numbers;
};

export const getUrlParam = (
  key: string,
  location: history.Location
): string | undefined => {
  const searchParams = new URLSearchParams(location.search);
  const valueOfKeys = searchParams.getAll(key);
  if (!valueOfKeys.length) {
    return undefined;
  }
  return valueOfKeys[0];
};
export interface DataParams {
  [key: string]: string | string[] | number | undefined;
}

export const buildUrlParams = (pathName: string, data: DataParams) => {
  const param = [];
  for (let d in data) {
    const value = data[d];
    if (value !== undefined) {
      const valUri = Array.isArray(value)
        ? value.map(encodeURIComponent).join(",")
        : encodeURIComponent(value);
      param.push(encodeURIComponent(d) + "=" + valUri);
    }
  }
  if (!param.length) {
    return pathName;
  }
  return `${pathName}?${param.join("&")}`;
};

export function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== "string") {
    throw new TypeError(
      `Expected \`${value}\` to be \`string\`, received \`${typeof value}\``
    );
  }
}

export const clamp = (value: number, min: number, max: number) =>
  Math.max(Math.min(max, value), min);

export const groupBy = <Value, Key>(
  array: Value[],
  callback: (value: Value) => Key
) =>
  array.reduce((result, value) => {
    const key = callback(value);
    if (isPropertyKey(key)) {
      if (result[key]) {
        result[key].push(value);
      } else {
        result[key] = [value];
      }
    }
    return result;
  }, {} as Record<Extract<Key, PropertyKey>, Value[]>);

/** Checks whether a value can be used as a key of an object.  */
export const isPropertyKey = <Value>(
  value: Value
): value is Extract<Value, PropertyKey> =>
  typeof value === "number" ||
  typeof value === "string" ||
  typeof value === "symbol";

export const months = () => {
  const arbitraryYear = 2000;
  const numberOfMonths = 12;
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", { month: "long" });
  return Array.from({ length: numberOfMonths }, (_, index) =>
    dateTimeFormat.format(new Date(arbitraryYear, index))
  );
};

export const sumBy = <Value>(
  array: Value[],
  callback: (value: Value) => number
) => array.reduce((result, value) => result + callback(value), 0);

export const toInteger = (value: unknown) => Math.trunc(Number(value));

export const getLinkPwaAndroid = (eventCode: string | undefined) => {
  const linkApp = P2S_APP_URL;
  return eventCode
    ? `${linkApp}?event=${eventCode}&${INSTALLED_PWA_PARAM}=true`
    : `${linkApp}?${INSTALLED_PWA_PARAM}=true`;
};

export function envVar(name: string): string {
  if (!process.env[name]) {
    throw `Environment variable ${name} is unset, make sure to use one of the env-cmd commands`;
  } else {
    return process.env[name] as string;
  }
}
