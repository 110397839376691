// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect } from "react";

import Loading from "../loading/Loading";
import { useStore } from "../../store";

import "./SuspenseLoading.scss";

const SuspenseLoading: React.FC = () => {
  const setSuspenseLoading = useStore(state => state.setSuspenseLoading);

  useEffect(() => {
    setSuspenseLoading(true);
    return () => {
      setSuspenseLoading(false);
    };
  }, []);

  const outlet = document.getElementsByTagName("ion-router-outlet");
  const page = outlet[0].getElementsByClassName("ion-page");
  if (page.length === 0) {
    // for init app (load first component)
    return <Loading progress={90} />;
  } else {
    return <></>;
  }
};

export default SuspenseLoading;
