// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonButton, IonFooter, IonIcon, IonModal, IonRow } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import React, { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import "./ConfirmModal.scss";

export interface ActionButton {
  fill?: "clear" | "outline" | "solid" | "default";
  text?: string;
  icon?: string;
  function: () => void;
}

interface Props {
  children?: ReactNode;
  title: string;
  isOpen: boolean;
  buttons: ActionButton[];
  isFullWith?: boolean;
  onCloseClicked?: () => void;
}

const ConfirmModal: React.FC<Props> = props => {
  const modal = useRef<HTMLIonModalElement>(null);
  const location = useLocation();

  useEffect(() => {
    return () => {
      modal.current?.dismiss();
    };
  }, [location]);

  useEffect(() => {
    if (props.isOpen) {
      modal.current?.present();
    } else {
      modal.current?.dismiss();
    }
  }, [props.isOpen]);

  return (
    <IonModal
      ref={modal}
      backdropDismiss={false}
      id="confirm-modal"
      className={props.isFullWith ? "full-with" : ""}
    >
      <IonFooter mode="ios">
        <div className="wrap-close-btn">
          <IonButton
            size="small"
            onClick={props.onCloseClicked}
            mode="ios"
            fill="clear"
            className="close-button"
          >
            <IonIcon slot="start" icon={closeCircleOutline}></IonIcon>
          </IonButton>
        </div>

        <h2 className="title">{props.title}</h2>
        {props.children}
        <IonRow className="buttons-confirm">
          {props.buttons.map((button, index) => {
            return (
              <IonButton
                key={index}
                onClick={button.function}
                fill={button.fill || "solid"}
                mode="ios"
                color="primary"
              >
                {button.icon && (
                  <IonIcon slot="start" icon={button.icon}></IonIcon>
                )}
                <span>{button.text}</span>
              </IonButton>
            );
          })}
        </IonRow>
      </IonFooter>
    </IonModal>
  );
};

export default ConfirmModal;
