// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect, useState } from "react";
import { App } from "@capacitor/app";
import localforage from "localforage";
import { isPlatform } from "@ionic/core";

import ConfirmModal from "../confirm-modal/ConfirmModal";
import EnterEventCode from "../enter-event-code/EnterEventCode";
import { usePlatforms } from "../../hook/usePlatforms";
import { useStore } from "../../store";

export const InviteCodeModal: React.FC<any> = () => {
  const hasCreatedKey = useStore(store => store.wallet.keys !== undefined);
  const [showInviteBox, setShowInviteBox] = useState(false);
  const { isApp, isIos } = usePlatforms();
  const isOsApp = isApp && (isIos || isPlatform("ipad"));

  const checkAppState = async () => {
    const appState = await localforage.getItem<string>("appState");
    if (!appState || appState === "paused") {
      // App was killed or this is the first launch
      await localforage.setItem("appState", "started");
      setShowInviteBox(true);
    }
  };

  useEffect(() => {
    checkAppState();
    App.addListener("appStateChange", async ({ isActive }) => {
      if (!isActive) {
        await localforage.setItem("appState", "paused");
      } else {
        const appState = await localforage.getItem<string>("appState");
        if (appState === "paused") {
          // App resumed from background
          await localforage.setItem("appState", "resumed");
        } else {
          // App was killed or this is the first launch
          await localforage.setItem("appState", "started");
        }
      }
    });
    return () => {
      App.removeAllListeners();
    };
  }, []);

  return (
    <ConfirmModal
      title="Event code"
      onCloseClicked={() => setShowInviteBox(false)}
      isOpen={!hasCreatedKey && isOsApp && showInviteBox}
      buttons={[
        {
          text: "Close",
          fill: "outline",
          function: () => {
            setShowInviteBox(false);
          },
        },
      ]}
    >
      <div
        className="confirm-wrap"
        style={{ textAlign: "center", padding: "10px" }}
      >
        <p>Paste the copied event code here:</p>
        <EnterEventCode />
      </div>
    </ConfirmModal>
  );
};
