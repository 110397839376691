// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import { requestUtxos } from "./request";
import * as tx from "./tx";
import * as proto from "../rpc/p2s";
import {
  Ecc,
  send_opreturn_vec,
  sign_tx,
  script_p2pkh,
  slpv2_opreturn_section1,
  slpv2_send_section_vec,
} from "./ffi";
import { WalletKeys } from "../store/wallet";
import { SlpUtxo } from "./utxo";
import { fromHex, fromHexRev } from "./hex";
import { DUST_AMOUNT } from "./settings";

export type TokenProtocol = "Slp" | "Slpv2";

export function tokenProtocolProto(
  tokenProtocol: TokenProtocol
): proto.TokenProtocol {
  switch (tokenProtocol) {
    case "Slp":
      return proto.TokenProtocol.Slp;
    case "Slpv2":
      return proto.TokenProtocol.Slpv2;
  }
}

export function selectUtxos(
  utxos: SlpUtxo[],
  requiredAmount: Long
): [SlpUtxo[], Long] {
  if (requiredAmount.isZero()) {
    return [[], Long.ZERO];
  }
  const exactUtxo = utxos.find(utxo => utxo.slpAmount.eq(requiredAmount));
  if (exactUtxo !== undefined) {
    return [[exactUtxo], requiredAmount];
  }
  const selectedUtxos: SlpUtxo[] = [];
  let selectedAmount = Long.ZERO;
  for (const utxo of utxos) {
    selectedUtxos.push(utxo);
    selectedAmount = selectedAmount.add(utxo.slpAmount);
    if (selectedAmount.gte(requiredAmount)) {
      break;
    }
  }
  return [selectedUtxos, selectedAmount];
}

export interface SendToParams {
  ecc: Ecc;
  keys: WalletKeys;
  tokenId: string;
  tokenProtocol: TokenProtocol;
  amount: Long;
  recipientScript: Uint8Array;
  leftoverScript: Uint8Array;
  feePerKb: number;
  postage: PostageData;
}

export interface SendToOneParams {
  ecc: Ecc;
  keys: WalletKeys;
  tokenId: string;
  tokenProtocol: TokenProtocol;
  outputs: SlpOutput[];
  leftoverScript: Uint8Array;
  feePerKb: number;
}

export interface PostageData {
  totalFee: Long;
  script: Uint8Array;
}

export interface SlpOutput {
  amount: Long;
  script: Uint8Array;
  sats?: Long;
}

export async function buildSendToTxs(
  params: SendToParams
): Promise<Uint8Array[]> {
  const { ecc, keys, tokenId } = params;
  const walletUtxos = await requestUtxos(tokenId, keys.pkh);
  let deductedAmount = params.amount;
  if (params.postage !== undefined) {
    deductedAmount = deductedAmount.add(params.postage.totalFee);
  }
  const [utxos, selectedAmount] = selectUtxos(walletUtxos, deductedAmount);
  if (selectedAmount.lt(deductedAmount)) {
    throw new Error("Insufficient balance");
  }
  let leftoverAmount = selectedAmount.sub(deductedAmount);
  let feeAmount =
    params.postage === undefined ? Long.ZERO : params.postage.totalFee;
  if (
    utxos.length == walletUtxos.length &&
    leftoverAmount.lt(100) &&
    params.postage
  ) {
    // If sending all UTXOs and leftover < $0.01, send the remainder to postage
    leftoverAmount = Long.ZERO;
    feeAmount = feeAmount.add(leftoverAmount);
  }

  // Sort ascendingly so the last leftover amount is sufficient
  utxos.sort((a, b) => a.slpAmount.comp(b.slpAmount));

  const outputScript = script_p2pkh(keys.pkh);
  const txs: Uint8Array[] = [];
  for (let idx = 0; idx < utxos.length; ++idx) {
    const utxo = utxos[idx];
    const slpOutputs: SlpOutput[] = [];

    if (idx == utxos.length - 1) {
      if (!leftoverAmount.isZero()) {
        slpOutputs.push({
          amount: utxo.slpAmount.sub(leftoverAmount).sub(feeAmount),
          script: params.recipientScript,
        });
        slpOutputs.push({
          amount: leftoverAmount,
          script: params.leftoverScript,
        });
      } else {
        slpOutputs.push({
          amount: utxo.slpAmount.sub(feeAmount),
          script: params.recipientScript,
        });
      }
      if (params.postage !== undefined) {
        slpOutputs.push({
          amount: params.postage.totalFee,
          script: params.postage.script,
        });
      }
    } else {
      slpOutputs.push({
        amount: utxo.slpAmount,
        script: params.recipientScript,
      });
    }

    const outputs = buildOutputs(
      params.tokenId,
      params.tokenProtocol,
      slpOutputs
    );

    const txRequest: tx.Tx = {
      version: 1,
      inputs: [
        {
          prevOut: utxo.outpoint,
          script: new Uint8Array(),
          sequence: 0xffff_ffff,
          signData: <tx.SignData>{
            fields: [
              <tx.SignField>{
                outputScript,
              },
              <tx.SignField>{
                value: utxo.value,
              },
            ],
            p2pkh: {
              seckey: keys.seckey,
              pubkey: keys.pubkey,
              sigHashType: 0xc1,
            },
          },
        },
      ],
      outputs,
      lockTime: 0,
    };
    const txRequestEncoded = tx.Tx.encode(txRequest).finish();
    const signedTx = sign_tx(
      txRequestEncoded,
      ecc,
      params.feePerKb,
      DUST_AMOUNT
    );
    txs.push(signedTx);
  }
  return txs;
}

export async function buildSendToOneTx(
  params: SendToOneParams
): Promise<Uint8Array> {
  const { ecc, keys, tokenId } = params;
  const walletUtxos = await requestUtxos(tokenId, keys.pkh);
  const totalAmount = params.outputs.reduce(
    (a, b) => a.add(b.amount),
    Long.ZERO
  );
  const [utxos, selectedAmount] = selectUtxos(walletUtxos, totalAmount);
  if (selectedAmount.lt(totalAmount)) {
    throw new Error("Insufficient balance");
  }
  const leftoverAmount = selectedAmount.sub(totalAmount);
  let slpOutputs = params.outputs;
  if (!totalAmount.isZero()) {
    slpOutputs = [
      ...slpOutputs,
      { amount: leftoverAmount, script: params.leftoverScript },
    ];
  }
  const outputs = buildOutputs(tokenId, params.tokenProtocol, slpOutputs);
  const outputScript = script_p2pkh(keys.pkh);
  const txRequest: tx.Tx = {
    version: 1,
    inputs: utxos.map(utxo => ({
      prevOut: utxo.outpoint,
      script: new Uint8Array(),
      sequence: 0xffff_ffff,
      signData: <tx.SignData>{
        fields: [
          <tx.SignField>{
            outputScript,
          },
          <tx.SignField>{
            value: utxo.value,
          },
        ],
        p2pkh: {
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          sigHashType: 0xc1,
        },
      },
    })),
    outputs,
    lockTime: 0,
  };
  const txRequestEncoded = tx.Tx.encode(txRequest).finish();
  const signedTx = sign_tx(txRequestEncoded, ecc, params.feePerKb, DUST_AMOUNT);
  return signedTx;
}

function buildOutputs(
  tokenId: string,
  tokenProtocol: TokenProtocol,
  slpOutputs: SlpOutput[]
): tx.TxOutput[] {
  const outputs: tx.TxOutput[] = [
    {
      value: Long.ZERO,
      script: sendOpreturn(
        tokenId,
        tokenProtocol,
        slpOutputs.map(output => output.amount)
      ),
    },
  ];
  for (const output of slpOutputs) {
    outputs.push({
      value: output.sats ?? Long.fromNumber(DUST_AMOUNT),
      script: output.script,
    });
  }
  return outputs;
}

export function sendOpreturn(
  tokenId: string,
  tokenProtocol: TokenProtocol,
  amounts: Long[]
): Uint8Array {
  switch (tokenProtocol) {
    case "Slp":
      return sendSlpOpreturn(tokenId, amounts);
    case "Slpv2":
      return sendSlpv2Opreturn(tokenId, amounts);
  }
}

function sendSlpOpreturn(tokenId: string, amounts: Long[]): Uint8Array {
  if (amounts.length > 19) {
    throw new Error(`Too many SLP SEND amounts, got ${amounts.length} amounts`);
  }
  return send_opreturn_vec(fromHex(tokenId), amountsToHighLow(amounts));
}

function sendSlpv2Opreturn(tokenId: string, amounts: Long[]): Uint8Array {
  if (amounts.length > 29) {
    throw new Error(
      `Too many SLPv2 SEND amounts, got ${amounts.length} amounts`
    );
  }
  const section = slpv2_send_section_vec(
    fromHexRev(tokenId),
    amountsToHighLow(amounts)
  );
  return slpv2_opreturn_section1(section);
}

export function amountsToHighLow(amounts: Long[]): Int32Array {
  const amountsVec = new Int32Array(amounts.length * 2);
  for (let i = 0; i < amounts.length; ++i) {
    amountsVec[i * 2] = amounts[i].low;
    amountsVec[i * 2 + 1] = amounts[i].high;
  }
  return amountsVec;
}
