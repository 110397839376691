// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";
import { WalletKeys } from "../store/wallet";
import { HDKey, hash160, Ecc } from "../wallet/ffi";
import { encodeECashAddress, encodeEtokenAddress } from "./address";

export function deriveWalletKeys(
  seed: Uint8Array,
  seedPhrase: string,
  ecc: Ecc
): WalletKeys {
  const masterKey = HDKey.master(seed);
  const key = masterKey.derive(ecc, "m/44'/1899'/0'/0/0");
  masterKey.free();
  const seckey = key.private_key();
  const pubkey = ecc.derive_pubkey(seckey);
  const pkh = hash160(pubkey);
  const address = encodeEtokenAddress("P2PKH", pkh);
  const eCashAddress = encodeECashAddress("P2PKH", pkh);
  return {
    seedPhrase,
    seckey,
    pubkey,
    pkh,
    address,
    eCashAddress,
  };
}

export async function storeWalletKeys(walletKeys: WalletKeys) {
  await Promise.all([
    localforage.setItem("p2s:seedPhrase", walletKeys.seedPhrase),
    localforage.setItem("p2s:seckey", walletKeys.seckey),
    localforage.setItem("p2s:pubkey", walletKeys.pubkey),
    localforage.setItem("p2s:pkh", walletKeys.pkh),
    localforage.setItem("p2s:address", walletKeys.address),
    localforage.setItem("p2s:eCashAddress", walletKeys.eCashAddress),
  ]);
}

export async function deleteWalletKeys() {
  await Promise.all([
    localforage.removeItem("p2s:seedPhrase"),
    localforage.removeItem("p2s:seckey"),
    localforage.removeItem("p2s:pubkey"),
    localforage.removeItem("p2s:pkh"),
    localforage.removeItem("p2s:address"),
    localforage.removeItem("p2s:eCashAddress"),
    localforage.removeItem("p2s:hasVerifiedEmail"),
    localforage.removeItem("p2s:hasDecidedAboutNewsletter"),
    localforage.removeItem("p2s:isProvisionalPk"),
  ]);
}
