// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect, useState } from "react";
import { IonButton } from "@ionic/react";
import { useLocation } from "react-router";
import { IonProgressBar } from "@ionic/react";

import InstallAppPopup from "../install-app-popup/InstallAppPopup";
import { useStore } from "../../../store";
import { getLinkPwaAndroid, getUrlParam } from "../../../util";

import "./InstallAndroidPwaButtons.scss";

export const INSTALLED_PWA_PARAM = "installpwa";
// We don't know when the PWA installation is finished, so we simply wait 10s.
const INSTALL_TIME_MS = 500;

interface InstallText {
  hasInstallButton: boolean;
  text?: React.ReactNode;
}

type InstallUserChoice = BeforeInstallPromptEventUserChoiceOutcome | "default";

const installTexts: Record<InstallUserChoice, InstallText> = {
  accepted: {
    hasInstallButton: false,
  },
  default: {
    hasInstallButton: true,
  },
  dismissed: {
    hasInstallButton: true,
  },
};

const ProgressBarInstall = ({ time }: { time: number }) => {
  const [progress, setProgress] = useState(0);

  const pctIncrement = 1 / (time / 50);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => prevProgress + pctIncrement);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  if (progress > 1) {
    setTimeout(() => {
      setProgress(0);
    }, time);
  }

  return <IonProgressBar value={progress} />;
};

interface Props {
  eventCode?: string | undefined;
}

const InstallAndroidPwaButtons: React.FC<Props> = props => {
  const { eventCode } = props;
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [outcome, setOutcome] = useState<InstallUserChoice>("default");
  const promptInstallation = useStore(store => store.promptInstallation);
  const location = useLocation();

  const installText = installTexts[outcome];

  const hasInstalledPwa = getUrlParam(INSTALLED_PWA_PARAM, location) == "true";

  const [installingApp, setInstallingApp] = useState(false);

  useEffect(() => {
    setIsPopupVisible(true);
  }, []);

  const handledInstallApp = async () => {
    if (installText.hasInstallButton && !hasInstalledPwa) {
      const userChoice = await promptInstallation();
      const outcome = userChoice?.outcome;
      if (outcome == "accepted") {
        setInstallingApp(true);
        setOutcome("accepted");
        // Manually wait for the installation process for about 10 seconds
        setTimeout(() => {
          setInstallingApp(false);
        }, INSTALL_TIME_MS);
      }
    } else if (installingApp) {
      console.log("waiting");
    } else {
      const link: string = getLinkPwaAndroid(eventCode);
      window.open(link, "_blank");
    }
  };

  return (
    <div className="install-android-app-btns">
      <InstallAppPopup
        eventCode={props.eventCode}
        isOpen={isPopupVisible}
        onBackdropDismiss={() => setIsPopupVisible(false)}
        onClickInstall={handledInstallApp}
        installCustom={
          <>
            {installText.hasInstallButton && !hasInstalledPwa ? (
              <div className="btn solid">Install</div>
            ) : installingApp ? (
              <div className="wait-install">
                <div className="btn progress-bar">
                  <ProgressBarInstall time={INSTALL_TIME_MS} />
                  <div className="text">Installing</div>
                </div>
              </div>
            ) : (
              <div className="btn solid">Open</div>
            )}
          </>
        }
      />

      <IonButton
        size="large"
        onClick={() => setIsPopupVisible(true)}
        mode="md"
        className="button-event-detail primary-btn"
      >
        {eventCode ? "RSVP Now" : "Open Pay2Stay"}
      </IonButton>
    </div>
  );
};

export default InstallAndroidPwaButtons;
