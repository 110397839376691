// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreSet } from "../store";
import { catchError } from "./error";
import { request } from "../rpc/request";
import * as proto from "../rpc/p2s";

export interface AppInfoState {
  versionFromApi: number | undefined;
  currentVersion: number | undefined;
}

export interface AppInfoActions {
  appVersionFetch: (isInit: boolean) => Promise<void>;
}

export const initialAppInfo: AppInfoState = {
  versionFromApi: undefined,
  currentVersion: undefined,
};

export function appInfoActions(set: StoreSet): AppInfoActions {
  return {
    appVersionFetch: catchError(
      "App version",
      set,
      async (isInit: boolean) => {
        const response = await request({
          proto: proto.VersionResponse,
          path: "/version",
          method: "GET",
          data: new Uint8Array(),
        });
        set(state => {
          state.appInfo.versionFromApi = response.versionNumber;
          if (isInit) {
            // We just (re)loaded the app, store our version
            state.appInfo.currentVersion = response.versionNumber;
          }
        });
      },
      undefined,
      false
    ),
  };
}
