// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { registerPlugin } from "@capacitor/core";

export interface Pay2StayPlugin {
  openApp(): Promise<void>;
}

export const Pay2Stay = registerPlugin<Pay2StayPlugin>("Pay2Stay");
