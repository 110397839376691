// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import * as proto from "../rpc/p2s";
import { StoreGet, StoreSet } from "../store";

export interface CartState {
  cart: proto.EventPurchaseCart | undefined;
}

export interface CartActions {
  setCart: (cart: proto.EventPurchaseCart) => void;
}
export const initialCart: CartState = {
  cart: undefined,
};

export function cartActions(set: StoreSet, get: StoreGet): CartActions {
  return {
    setCart: (cart: proto.EventPurchaseCart) => {
      set(state => {
        state.cart.cart = cart;
      });
    },
  };
}
