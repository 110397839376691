// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { Area } from "react-easy-crop/types";

import { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";

export interface ImagesState {
  isLoadingImage: boolean;
}

export interface ImagesActions {
  uploadAvatar: (imageUpload: Uint8Array) => Promise<void>;
  uploadEventBanner: (
    imageUpload: Uint8Array,
    eventId: number
  ) => Promise<void>;
  cropImage: (imageSrc: string, pixelCrop: Area) => Promise<Uint8Array>;
}

export const initialImages: ImagesState = {
  isLoadingImage: false,
};

export function imagesActions(set: StoreSet, get: StoreGet): ImagesActions {
  return {
    cropImage: catchError(
      "Crop Image",
      set,
      async (imageSrc: string, pixelCrop: Area) => {
        set(state => {
          state.images.isLoadingImage = true;
        });
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext("2d");
        if (ctx == null) {
          throw new Error("cropImage: cannot getContext canvas");
        }
        return new Promise((resolve, reject) => {
          ctx.drawImage(image, 0, 0);
          const dataImage = ctx.getImageData(0, 0, image.width, image.height);
          canvas.width = pixelCrop.width;
          canvas.height = pixelCrop.height;

          ctx.putImageData(
            dataImage,
            Math.round(0 - pixelCrop.x),
            Math.round(0 - pixelCrop.y)
          );

          canvas.toBlob(async blob => {
            try {
              const imageArray = new Uint8Array(
                await new Response(blob).arrayBuffer()
              );
              resolve(imageArray);
            } catch (error) {
              reject(error);
            }
          }, "image/jpeg");
        });
      },
      state => {
        state.images.isLoadingImage = false;
      }
    ),
    uploadAvatar: catchError(
      "Upload Avatar",
      set,
      async (imageJpegBytes: Uint8Array) => {
        set(state => {
          state.images.isLoadingImage = true;
        });
        const response = await signedRequest({
          proto: proto.FileUploadResponse,
          path: "/file/upload",
          method: "POST",
          ecc: get().wallet.ecc!,
          seckey: get().wallet.keys!.seckey,
          pubkey: get().wallet.keys!.pubkey,
          payload: proto.FileUploadRequest.encode({
            fileUsage: "UserAvatar",
            eventId: 0,
          }).finish(),
        });

        await fetch(response.signedUploadUrl, {
          method: "PUT",
          body: imageJpegBytes,
        });

        await signedRequest({
          proto: proto.FileUploadResponse,
          path: "/file/notify-uploaded",
          method: "POST",
          ecc: get().wallet.ecc!,
          seckey: get().wallet.keys!.seckey,
          pubkey: get().wallet.keys!.pubkey,
          payload: proto.FileNotifyUploadedRequest.encode({
            fileId: response.fileId,
          }).finish(),
        });
      },
      state => {
        state.images.isLoadingImage = false;
      }
    ),
    uploadEventBanner: catchError(
      "Upload Event Banner",
      set,
      async (imageJpegBytes: Uint8Array, eventId: number) => {
        set(state => {
          state.images.isLoadingImage = true;
        });
        const response = await signedRequest({
          proto: proto.FileUploadResponse,
          path: "/file/upload",
          method: "POST",
          ecc: get().wallet.ecc!,
          seckey: get().wallet.keys!.seckey,
          pubkey: get().wallet.keys!.pubkey,
          payload: proto.FileUploadRequest.encode({
            fileUsage: "EventBanner",
            eventId: eventId,
          }).finish(),
        });

        await fetch(response.signedUploadUrl, {
          method: "PUT",
          body: imageJpegBytes,
        });

        await signedRequest({
          proto: proto.FileUploadResponse,
          path: "/file/notify-uploaded",
          method: "POST",
          ecc: get().wallet.ecc!,
          seckey: get().wallet.keys!.seckey,
          pubkey: get().wallet.keys!.pubkey,
          payload: proto.FileNotifyUploadedRequest.encode({
            fileId: response.fileId,
          }).finish(),
        });
      },
      state => {
        state.images.isLoadingImage = false;
      }
    ),
  };
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });
