// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import * as ffi from "./ffi";

export interface Mnemonic {
  phrase: string;
  seed: Uint8Array;
}

export function generateMnemonic(): Mnemonic {
  const entropy = new Uint8Array(16);
  window.crypto.getRandomValues(entropy);
  return mnemonicFromEntropy(entropy);
}

export function mnemonicFromEntropy(entropy: Uint8Array): Mnemonic {
  const mnemonic = ffi.Mnemonic.from_entropy(entropy);
  const phrase = mnemonic.phrase();
  const seed = mnemonic.seed();
  mnemonic.free();
  return { phrase, seed };
}

export function parseMnemonic(phrase: string): Mnemonic {
  const mnemonic = ffi.Mnemonic.parse_phrase(phrase);
  const seed = mnemonic.seed();
  mnemonic.free();
  return { phrase, seed };
}

export function sanitizeSeedPhrase(phrase: string): string {
  // Sanitize seed phrase: normalize whitespace, lower case
  phrase = phrase.replace(/\s\s+/g, " ");
  return phrase.trim().toLowerCase();
}
