// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";
import { CountryData } from "react-phone-input-2";
import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import * as proto from "../rpc/p2s";
import { signedRequest } from "../rpc/request";
import { nowSeconds } from "../wallet/format";
import { generateMnemonic, parseMnemonic } from "../wallet/mnemonic";
import { deriveWalletKeys, storeWalletKeys } from "../wallet/key";
import { WalletKeys } from "./wallet";
import { browserStorage } from "../storage/browser";

const PHONE_NUMBER_REGEX = /^\+[1-9]\d{5,20}$/;

export interface SignInPhoneInfo {
  phoneNumber: string;
  consentTypes: string[];
  country: CountryData;
  resendTimestamp: number;
  phrase: string;
}

export interface VerifyPhoneNumberStore {
  phoneNumber: string;
  consentTypes: string[];
  country: CountryData | undefined;
  resendTimestamp: Long | undefined;
  provisionalWalletKeys: WalletKeys | undefined;
}
export interface VerifyPhoneNumberActions {
  sendVerificationSms: (
    phoneNumber: string,
    country: CountryData,
    consentTypes: string[]
  ) => Promise<void>;
  checkVerificationOtp: (otp: string) => Promise<void>;
  sendSignInVerificationSms: (
    phoneNumber: string,
    country: CountryData,
    consentTypes: string[],
    shouldStoreInfo?: boolean
  ) => Promise<void>;
  checkSignInVerificationOTP: (otp: string) => Promise<void>;
  initSignInPhoneInfo: () => Promise<boolean>;
}

export const initialVerifyPhoneNumber: VerifyPhoneNumberStore = {
  phoneNumber: "",
  consentTypes: [],
  resendTimestamp: undefined,
  country: undefined,
  provisionalWalletKeys: undefined,
};

export function verifyPhoneNumberActions(
  set: StoreSet,
  get: StoreGet
): VerifyPhoneNumberActions {
  return {
    initSignInPhoneInfo: catchError(
      "init SignIn Phone Info",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const signInPhoneInfo = await browserStorage.signInPhoneInfo.get();
        if (!signInPhoneInfo) {
          return false;
        }
        const mnemonic = parseMnemonic(signInPhoneInfo.phrase);
        const ecc = get().wallet.ecc!;
        const provisionalWalletKeys = deriveWalletKeys(
          mnemonic.seed,
          mnemonic.phrase,
          ecc
        );
        set(state => {
          state.verifyPhoneNumber.resendTimestamp = Long.fromNumber(
            signInPhoneInfo.resendTimestamp
          );
          state.verifyPhoneNumber.provisionalWalletKeys = provisionalWalletKeys;
          state.verifyPhoneNumber.phoneNumber = signInPhoneInfo.phoneNumber;
          state.verifyPhoneNumber.consentTypes = signInPhoneInfo.consentTypes;
          state.verifyPhoneNumber.country = signInPhoneInfo.country;
        });
        return true;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    sendVerificationSms: catchError(
      "Send Verification SMS",
      set,
      async (
        phoneNumber: string,
        country: CountryData,
        consentTypes: string[]
      ) => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const resendTimestamp = get().verifyPhoneNumber.resendTimestamp;
        const errorFontNumber = validatePhoneNumber(phoneNumber, country);
        if (errorFontNumber !== undefined) {
          throw errorFontNumber;
        }
        if (
          resendTimestamp !== undefined &&
          nowSeconds() - resendTimestamp.toNumber() < 0
        ) {
          throw "Wait few seconds and try again";
        }
        const response = await signedRequest({
          proto: proto.SendOtpResponse,
          path: "/otp/send",
          method: "POST",
          ecc: ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.SendOtpRequest.encode({
            isSignin: false,
            phoneNumber: "+" + phoneNumber,
          }).finish(),
        });
        set(state => {
          state.verifyPhoneNumber.resendTimestamp = response.resendTimestamp;
        });
        if (!response.isSuccess) {
          throw "Wait few seconds and try again";
        }
        set(state => {
          state.verifyPhoneNumber.phoneNumber = phoneNumber;
          state.verifyPhoneNumber.consentTypes = consentTypes;
          state.verifyPhoneNumber.country = country;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    checkVerificationOtp: catchError(
      "OTP Verification",
      set,
      async (otp: string) => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const phoneNumber = get().verifyPhoneNumber.phoneNumber;
        const consentTypes = get().verifyPhoneNumber.consentTypes;
        if (keys === undefined || phoneNumber === undefined) {
          return;
        }
        await signedRequest({
          proto: proto.VerifyOtpResponse,
          path: "/otp/verify",
          method: "POST",
          ecc: ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.VerifyOtpRequest.encode({
            isSignin: false,
            phoneNumber: "+" + phoneNumber,
            otpCode: otp,
            consentTypes: consentTypes,
          }).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    sendSignInVerificationSms: catchError(
      "Sign In Verifiaction SMS",
      set,
      async (
        phoneNumber: string,
        country: CountryData,
        consentTypes: string[],
        shouldStoreInfo?: boolean
      ) => {
        set(state => {
          state.loading.isLoading = true;
        });

        const mnemonic = generateMnemonic();
        const seed = mnemonic.seed;
        const phrase = mnemonic.phrase;
        const ecc = get().wallet.ecc!;
        const provisionalWalletKeys = deriveWalletKeys(seed, phrase, ecc);
        const errorPhoneNumber = validatePhoneNumber(phoneNumber, country);
        if (errorPhoneNumber !== undefined) {
          throw errorPhoneNumber;
        }
        const response = await signedRequest({
          proto: proto.SendOtpResponse,
          path: "/otp/send",
          method: "POST",
          ecc: ecc,
          seckey: provisionalWalletKeys.seckey,
          pubkey: provisionalWalletKeys.pubkey,
          payload: proto.SendOtpRequest.encode({
            isSignin: true,
            phoneNumber: "+" + phoneNumber,
          }).finish(),
        });
        set(state => {
          state.verifyPhoneNumber.resendTimestamp = response.resendTimestamp;
        });
        if (!response.isSuccess) {
          throw "Wait few seconds and try again";
        }
        if (shouldStoreInfo) {
          const signInPhoneInfo: SignInPhoneInfo = {
            phoneNumber,
            consentTypes,
            country,
            phrase,
            resendTimestamp: response.resendTimestamp.toNumber(),
          };
          await browserStorage.signInPhoneInfo.set(signInPhoneInfo);
        }
        set(state => {
          state.verifyPhoneNumber.phoneNumber = phoneNumber;
          state.verifyPhoneNumber.consentTypes = consentTypes;
          state.verifyPhoneNumber.country = country;
          state.verifyPhoneNumber.provisionalWalletKeys = provisionalWalletKeys;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    checkSignInVerificationOTP: catchError(
      "Verify Sign In Code",
      set,
      async (otp: string) => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const provisionalWalletKeys =
          get().verifyPhoneNumber.provisionalWalletKeys!;
        const phoneNumber = get().verifyPhoneNumber.phoneNumber;
        const consentTypes = get().verifyPhoneNumber.consentTypes;
        if (provisionalWalletKeys === undefined || phoneNumber === undefined) {
          return;
        }
        await signedRequest({
          proto: proto.VerifyOtpResponse,
          path: "/otp/verify",
          method: "POST",
          ecc: ecc,
          seckey: provisionalWalletKeys.seckey,
          pubkey: provisionalWalletKeys.pubkey,
          payload: proto.VerifyOtpRequest.encode({
            isSignin: true,
            phoneNumber: "+" + phoneNumber,
            otpCode: otp,
            consentTypes: consentTypes,
          }).finish(),
        });
        await storeWalletKeys(provisionalWalletKeys);
        await localforage.setItem("p2s:hasVerifiedEmail", true);
        await localforage.setItem("p2s:isProvisionalPk", true);
        await browserStorage.signInPhoneInfo.clear();
        set(state => {
          state.wallet.keys = {
            ...provisionalWalletKeys,
            isProvisionalPk: true,
          };
          state.verifyEmail.hasVerifiedEmail = true;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}

const validatePhoneNumber = (
  phoneNumber: string,
  country: CountryData
): string | undefined => {
  if (phoneNumber == "") {
    return "Please enter your phone number";
  } else if (
    !phoneNumber.startsWith(country.dialCode) &&
    // Demo prefix handled by the backend
    !phoneNumber.startsWith("991")
  ) {
    return "Invalid number prefix";
  } else {
    const isValid = PHONE_NUMBER_REGEX.test("+" + phoneNumber);
    if (!isValid) {
      return "Invalid phone number";
    }
  }
  return undefined;
};
