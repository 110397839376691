let wasm;

const cachedTextDecoder =
  typeof TextDecoder !== "undefined"
    ? new TextDecoder("utf-8", { ignoreBOM: true, fatal: true })
    : {
        decode: () => {
          throw Error("TextDecoder not available");
        },
      };

if (typeof TextDecoder !== "undefined") {
  cachedTextDecoder.decode();
}

let cachedUint8Memory0 = null;

function getUint8Memory0() {
  if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
    cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
  }
  return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
  if (heap_next === heap.length) heap.push(heap.length + 1);
  const idx = heap_next;
  heap_next = heap[idx];

  heap[idx] = obj;
  return idx;
}

let WASM_VECTOR_LEN = 0;

function passArray8ToWasm0(arg, malloc) {
  const ptr = malloc(arg.length * 1, 1) >>> 0;
  getUint8Memory0().set(arg, ptr / 1);
  WASM_VECTOR_LEN = arg.length;
  return ptr;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
  if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
    cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
  }
  return cachedInt32Memory0;
}
/**
 * Encode data in base58 and return as a string.
 * @param {Uint8Array} data
 * @returns {string}
 */
export function encode_base58(data) {
  let deferred2_0;
  let deferred2_1;
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(data, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.encode_base58(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    deferred2_0 = r0;
    deferred2_1 = r1;
    return getStringFromWasm0(r0, r1);
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
    wasm.__wbindgen_export_1(deferred2_0, deferred2_1, 1);
  }
}

const cachedTextEncoder =
  typeof TextEncoder !== "undefined"
    ? new TextEncoder("utf-8")
    : {
        encode: () => {
          throw Error("TextEncoder not available");
        },
      };

const encodeString =
  typeof cachedTextEncoder.encodeInto === "function"
    ? function (arg, view) {
        return cachedTextEncoder.encodeInto(arg, view);
      }
    : function (arg, view) {
        const buf = cachedTextEncoder.encode(arg);
        view.set(buf);
        return {
          read: arg.length,
          written: buf.length,
        };
      };

function passStringToWasm0(arg, malloc, realloc) {
  if (realloc === undefined) {
    const buf = cachedTextEncoder.encode(arg);
    const ptr = malloc(buf.length, 1) >>> 0;
    getUint8Memory0()
      .subarray(ptr, ptr + buf.length)
      .set(buf);
    WASM_VECTOR_LEN = buf.length;
    return ptr;
  }

  let len = arg.length;
  let ptr = malloc(len, 1) >>> 0;

  const mem = getUint8Memory0();

  let offset = 0;

  for (; offset < len; offset++) {
    const code = arg.charCodeAt(offset);
    if (code > 0x7f) break;
    mem[ptr + offset] = code;
  }

  if (offset !== len) {
    if (offset !== 0) {
      arg = arg.slice(offset);
    }
    ptr = realloc(ptr, len, (len = offset + arg.length * 3), 1) >>> 0;
    const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
    const ret = encodeString(arg, view);

    offset += ret.written;
    ptr = realloc(ptr, len, offset, 1) >>> 0;
  }

  WASM_VECTOR_LEN = offset;
  return ptr;
}

function getObject(idx) {
  return heap[idx];
}

function dropObject(idx) {
  if (idx < 132) return;
  heap[idx] = heap_next;
  heap_next = idx;
}

function takeObject(idx) {
  const ret = getObject(idx);
  dropObject(idx);
  return ret;
}

function getArrayU8FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}
/**
 * Decode base58 encoded string and return as byte array.
 * Throws an error when decoding failed.
 * @param {string} data
 * @returns {Uint8Array}
 */
export function decode_base58(data) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passStringToWasm0(
      data,
      wasm.__wbindgen_export_0,
      wasm.__wbindgen_export_2
    );
    const len0 = WASM_VECTOR_LEN;
    wasm.decode_base58(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

function _assertClass(instance, klass) {
  if (!(instance instanceof klass)) {
    throw new Error(`expected instance of ${klass.name}`);
  }
  return instance.ptr;
}
/**
 * Build a P2PKH script from the given public key hash.
 * @param {Uint8Array} pkh
 * @returns {Uint8Array}
 */
export function script_p2pkh(pkh) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(pkh, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.script_p2pkh(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a P2SH script from the given script hash.
 * @param {Uint8Array} script_hash
 * @returns {Uint8Array}
 */
export function script_p2sh(script_hash) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(script_hash, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.script_p2sh(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Sign the tx encoded in `tx_proto`.
 * @param {Uint8Array} tx_proto
 * @param {Ecc} ecc
 * @param {number} fee_per_kb
 * @param {number} dust_limit
 * @returns {Uint8Array}
 */
export function sign_tx(tx_proto, ecc, fee_per_kb, dust_limit) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(tx_proto, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    _assertClass(ecc, Ecc);
    wasm.sign_tx(retptr, ptr0, len0, ecc.__wbg_ptr, fee_per_kb, dust_limit);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a SLPv2 OP_RETURN script
 * @param {Uint8Array} section
 * @returns {Uint8Array}
 */
export function slpv2_opreturn_section1(section) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(section, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.slpv2_opreturn_section1(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a SLPv2 OP_RETURN script with 2 sections
 * @param {Uint8Array} section1
 * @param {Uint8Array} section2
 * @returns {Uint8Array}
 */
export function slpv2_opreturn_section2(section1, section2) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(section1, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passArray8ToWasm0(section2, wasm.__wbindgen_export_0);
    const len1 = WASM_VECTOR_LEN;
    wasm.slpv2_opreturn_section2(retptr, ptr0, len0, ptr1, len1);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v3 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v3;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a SLPv2 OP_RETURN script with 3 sections
 * @param {Uint8Array} section1
 * @param {Uint8Array} section2
 * @param {Uint8Array} section3
 * @returns {Uint8Array}
 */
export function slpv2_opreturn_section3(section1, section2, section3) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(section1, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passArray8ToWasm0(section2, wasm.__wbindgen_export_0);
    const len1 = WASM_VECTOR_LEN;
    const ptr2 = passArray8ToWasm0(section3, wasm.__wbindgen_export_0);
    const len2 = WASM_VECTOR_LEN;
    wasm.slpv2_opreturn_section3(retptr, ptr0, len0, ptr1, len1, ptr2, len2);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v4 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v4;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
  if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
    cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
  }
  return cachedUint32Memory0;
}

function passArray32ToWasm0(arg, malloc) {
  const ptr = malloc(arg.length * 4, 4) >>> 0;
  getUint32Memory0().set(arg, ptr / 4);
  WASM_VECTOR_LEN = arg.length;
  return ptr;
}
/**
 * Build a SLPv2 SEND section with amounts.
 * Each SLPv2 48-bit amount is encoded using 2 32-bit integers.
 * @param {Uint8Array} token_id
 * @param {Int32Array} amounts_low_high
 * @returns {Uint8Array}
 */
export function slpv2_send_section_vec(token_id, amounts_low_high) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(token_id, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passArray32ToWasm0(amounts_low_high, wasm.__wbindgen_export_0);
    const len1 = WASM_VECTOR_LEN;
    wasm.slpv2_send_section_vec(retptr, ptr0, len0, ptr1, len1);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v3 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v3;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a SLP SEND section with amounts.
 * Each SLP 48-bit amount is encoded using 2 32-bit integers.
 * @param {Uint8Array} token_id
 * @param {Int32Array} amounts_low_high
 * @param {number} num_batons
 * @returns {Uint8Array}
 */
export function slpv2_mint_section(token_id, amounts_low_high, num_batons) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(token_id, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passArray32ToWasm0(amounts_low_high, wasm.__wbindgen_export_0);
    const len1 = WASM_VECTOR_LEN;
    wasm.slpv2_mint_section(retptr, ptr0, len0, ptr1, len1, num_batons);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v3 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v3;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build an SLPv2 BURN section with the given amount.
 * @param {Uint8Array} token_id
 * @param {number} burn_amount_low
 * @param {number} burn_amount_high
 * @returns {Uint8Array}
 */
export function slpv2_burn_section(
  token_id,
  burn_amount_low,
  burn_amount_high
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(token_id, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.slpv2_burn_section(
      retptr,
      ptr0,
      len0,
      burn_amount_low,
      burn_amount_high
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a SLP SEND OP_RETURN script with amounts.
 * Each SLP 64-bit amount is encoded using 2 32-bit integers.
 * @param {Uint8Array} token_id
 * @param {Int32Array} amounts_low_high
 * @returns {Uint8Array}
 */
export function send_opreturn_vec(token_id, amounts_low_high) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(token_id, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passArray32ToWasm0(amounts_low_high, wasm.__wbindgen_export_0);
    const len1 = WASM_VECTOR_LEN;
    wasm.send_opreturn_vec(retptr, ptr0, len0, ptr1, len1);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v3 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v3;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build a SLP SEND OP_RETURN script with 1 amount.
 * Each SLP 64-bit amount is encoded using 2 32-bit integers.
 * @param {Uint8Array} token_id
 * @param {number} mint_amount_low
 * @param {number} mint_amount_high
 * @param {number} mint_out_idx
 * @returns {Uint8Array}
 */
export function mint_opreturn(
  token_id,
  mint_amount_low,
  mint_amount_high,
  mint_out_idx
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(token_id, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.mint_opreturn(
      retptr,
      ptr0,
      len0,
      mint_amount_low,
      mint_amount_high,
      mint_out_idx
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build self-mint script, used by BUX token
 * @param {Uint8Array} auth_pubkey
 * @param {boolean} check_is_first_input
 * @returns {Uint8Array}
 */
export function self_mint_script(auth_pubkey, check_is_first_input) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(auth_pubkey, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.self_mint_script(retptr, ptr0, len0, check_is_first_input);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Build the redeem_script for an SLP escrow [`SlpEscrow`].
 * `recipient_outputs_proto` encodes a [`proto::TxOutputs`].
 * @param {Uint8Array} escrow_pk
 * @param {Uint8Array} alice_pk
 * @param {Uint8Array} bob_pk
 * @param {string} token_id
 * @param {string} token_protocol
 * @param {Uint8Array} recipient_outputs_proto
 * @param {number} sig_hash_type
 * @returns {Uint8Array}
 */
export function slp_escrow_script(
  escrow_pk,
  alice_pk,
  bob_pk,
  token_id,
  token_protocol,
  recipient_outputs_proto,
  sig_hash_type
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(escrow_pk, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passArray8ToWasm0(alice_pk, wasm.__wbindgen_export_0);
    const len1 = WASM_VECTOR_LEN;
    const ptr2 = passArray8ToWasm0(bob_pk, wasm.__wbindgen_export_0);
    const len2 = WASM_VECTOR_LEN;
    const ptr3 = passStringToWasm0(
      token_id,
      wasm.__wbindgen_export_0,
      wasm.__wbindgen_export_2
    );
    const len3 = WASM_VECTOR_LEN;
    const ptr4 = passStringToWasm0(
      token_protocol,
      wasm.__wbindgen_export_0,
      wasm.__wbindgen_export_2
    );
    const len4 = WASM_VECTOR_LEN;
    const ptr5 = passArray8ToWasm0(
      recipient_outputs_proto,
      wasm.__wbindgen_export_0
    );
    const len5 = WASM_VECTOR_LEN;
    wasm.slp_escrow_script(
      retptr,
      ptr0,
      len0,
      ptr1,
      len1,
      ptr2,
      len2,
      ptr3,
      len3,
      ptr4,
      len4,
      ptr5,
      len5,
      sig_hash_type
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v7 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v7;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Estimate the size of the tx required to spend the escrow.
 * @param {Uint8Array} recipient_outputs_proto
 * @param {number} fee_per_kb
 * @param {string} token_protocol
 * @returns {number}
 */
export function slp_escrow_estimate_spend_size(
  recipient_outputs_proto,
  fee_per_kb,
  token_protocol
) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(
      recipient_outputs_proto,
      wasm.__wbindgen_export_0
    );
    const len0 = WASM_VECTOR_LEN;
    const ptr1 = passStringToWasm0(
      token_protocol,
      wasm.__wbindgen_export_0,
      wasm.__wbindgen_export_2
    );
    const len1 = WASM_VECTOR_LEN;
    wasm.slp_escrow_estimate_spend_size(
      retptr,
      ptr0,
      len0,
      fee_per_kb,
      ptr1,
      len1
    );
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    if (r2) {
      throw takeObject(r1);
    }
    return r0;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Calculate RIPEMD160(SHA256(data)), commonly used as address hash.
 * @param {Uint8Array} data
 * @returns {Uint8Array}
 */
export function hash160(data) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(data, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.hash160(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Calculate SHA256(data).
 * @param {Uint8Array} data
 * @returns {Uint8Array}
 */
export function sha256(data) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(data, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.sha256(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

/**
 * Self mint V2 redeem script for the given auth pubkey
 * @param {Uint8Array} auth_pubkey
 * @returns {Uint8Array}
 */
export function self_mint_v2_script(auth_pubkey) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
    const ptr0 = passArray8ToWasm0(auth_pubkey, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    wasm.self_mint_v2_script(retptr, ptr0, len0);
    var r0 = getInt32Memory0()[retptr / 4 + 0];
    var r1 = getInt32Memory0()[retptr / 4 + 1];
    var r2 = getInt32Memory0()[retptr / 4 + 2];
    var r3 = getInt32Memory0()[retptr / 4 + 3];
    if (r3) {
      throw takeObject(r2);
    }
    var v2 = getArrayU8FromWasm0(r0, r1).slice();
    wasm.__wbindgen_export_1(r0, r1 * 1, 1);
    return v2;
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16);
  }
}

const CashAddressFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_cashaddress_free(ptr >>> 0));
/**
 * Address format for BCH/XEC, see the spec:
 * [https://github.com/bitcoincashorg/bitcoincash.org/blob/master/spec/cashaddr.md]
 */
export class CashAddress {
  static __wrap(ptr) {
    ptr = ptr >>> 0;
    const obj = Object.create(CashAddress.prototype);
    obj.__wbg_ptr = ptr;
    CashAddressFinalization.register(obj, obj.__wbg_ptr, obj);
    return obj;
  }

  __destroy_into_raw() {
    const ptr = this.__wbg_ptr;
    this.__wbg_ptr = 0;
    CashAddressFinalization.unregister(this);
    return ptr;
  }

  free() {
    const ptr = this.__destroy_into_raw();
    wasm.__wbg_cashaddress_free(ptr);
  }
  /**
   * Build CashAddress from prefix, address type and 20-byte hash.
   * @param {string} prefix
   * @param {string} addr_type
   * @param {Uint8Array} hash
   * @returns {CashAddress}
   */
  static from_hash(prefix, addr_type, hash) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passStringToWasm0(
        prefix,
        wasm.__wbindgen_export_0,
        wasm.__wbindgen_export_2
      );
      const len0 = WASM_VECTOR_LEN;
      const ptr1 = passStringToWasm0(
        addr_type,
        wasm.__wbindgen_export_0,
        wasm.__wbindgen_export_2
      );
      const len1 = WASM_VECTOR_LEN;
      const ptr2 = passArray8ToWasm0(hash, wasm.__wbindgen_export_0);
      const len2 = WASM_VECTOR_LEN;
      wasm.cashaddress_from_hash(retptr, ptr0, len0, ptr1, len1, ptr2, len2);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      if (r2) {
        throw takeObject(r1);
      }
      return CashAddress.__wrap(r0);
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Decode the cash address in the string.
   * @param {string} s
   * @returns {CashAddress}
   */
  static decode(s) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passStringToWasm0(
        s,
        wasm.__wbindgen_export_0,
        wasm.__wbindgen_export_2
      );
      const len0 = WASM_VECTOR_LEN;
      wasm.cashaddress_decode(retptr, ptr0, len0);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      if (r2) {
        throw takeObject(r1);
      }
      return CashAddress.__wrap(r0);
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Encode the address as a cash address string.
   * @returns {string}
   */
  encode() {
    let deferred1_0;
    let deferred1_1;
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      wasm.cashaddress_encode(retptr, this.__wbg_ptr);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      deferred1_0 = r0;
      deferred1_1 = r1;
      return getStringFromWasm0(r0, r1);
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
      wasm.__wbindgen_export_1(deferred1_0, deferred1_1, 1);
    }
  }
  /**
   * Get the scriptPubKey that this address encodes.
   * @returns {Uint8Array}
   */
  script() {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      wasm.cashaddress_script(retptr, this.__wbg_ptr);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var v1 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v1;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
}

const EccFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_ecc_free(ptr >>> 0));
/**
 * ECC signatures with libsecp256k1.
 */
export class Ecc {
  __destroy_into_raw() {
    const ptr = this.__wbg_ptr;
    this.__wbg_ptr = 0;
    EccFinalization.unregister(this);
    return ptr;
  }

  free() {
    const ptr = this.__destroy_into_raw();
    wasm.__wbg_ecc_free(ptr);
  }
  /**
   * Create a new Ecc instance.
   */
  constructor() {
    const ret = wasm.ecc_new();
    this.__wbg_ptr = ret >>> 0;
    return this;
  }
  /**
   * Derive a public key from secret key.
   * @param {Uint8Array} seckey
   * @returns {Uint8Array}
   */
  derive_pubkey(seckey) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passArray8ToWasm0(seckey, wasm.__wbindgen_export_0);
      const len0 = WASM_VECTOR_LEN;
      wasm.ecc_derive_pubkey(retptr, this.__wbg_ptr, ptr0, len0);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      var r3 = getInt32Memory0()[retptr / 4 + 3];
      if (r3) {
        throw takeObject(r2);
      }
      var v2 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v2;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Sign an ECDSA signature.
   * @param {Uint8Array} seckey
   * @param {Uint8Array} msg
   * @returns {Uint8Array}
   */
  ecdsa_sign(seckey, msg) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passArray8ToWasm0(seckey, wasm.__wbindgen_export_0);
      const len0 = WASM_VECTOR_LEN;
      const ptr1 = passArray8ToWasm0(msg, wasm.__wbindgen_export_0);
      const len1 = WASM_VECTOR_LEN;
      wasm.ecc_ecdsa_sign(retptr, this.__wbg_ptr, ptr0, len0, ptr1, len1);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      var r3 = getInt32Memory0()[retptr / 4 + 3];
      if (r3) {
        throw takeObject(r2);
      }
      var v3 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v3;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Sign a Schnorr signature.
   * @param {Uint8Array} seckey
   * @param {Uint8Array} msg
   * @returns {Uint8Array}
   */
  schnorr_sign(seckey, msg) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passArray8ToWasm0(seckey, wasm.__wbindgen_export_0);
      const len0 = WASM_VECTOR_LEN;
      const ptr1 = passArray8ToWasm0(msg, wasm.__wbindgen_export_0);
      const len1 = WASM_VECTOR_LEN;
      wasm.ecc_schnorr_sign(retptr, this.__wbg_ptr, ptr0, len0, ptr1, len1);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      var r3 = getInt32Memory0()[retptr / 4 + 3];
      if (r3) {
        throw takeObject(r2);
      }
      var v3 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v3;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Create a compact ECDSA signature (65 bytes), which allows reconstructing
   * the used public key.
   * The format is one header byte, followed by two times 32 bytes for the
   * serialized r and s values.
   * The header byte: 0x1B = first key with even y, 0x1C = first key with odd
   * y,
   *                  0x1D = second key with even y, 0x1E = second key with
   * odd y,
   *                  add 0x04 for compressed keys.
   * @param {Uint8Array} seckey
   * @param {Uint8Array} msg
   * @returns {Uint8Array}
   */
  sign_recoverable(seckey, msg) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passArray8ToWasm0(seckey, wasm.__wbindgen_export_0);
      const len0 = WASM_VECTOR_LEN;
      const ptr1 = passArray8ToWasm0(msg, wasm.__wbindgen_export_0);
      const len1 = WASM_VECTOR_LEN;
      wasm.ecc_sign_recoverable(retptr, this.__wbg_ptr, ptr0, len0, ptr1, len1);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      var r3 = getInt32Memory0()[retptr / 4 + 3];
      if (r3) {
        throw takeObject(r2);
      }
      var v3 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v3;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
}

const HDKeyFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_hdkey_free(ptr >>> 0));
/**
 * Key of a hierarchical deterministic wallet
 */
export class HDKey {
  static __wrap(ptr) {
    ptr = ptr >>> 0;
    const obj = Object.create(HDKey.prototype);
    obj.__wbg_ptr = ptr;
    HDKeyFinalization.register(obj, obj.__wbg_ptr, obj);
    return obj;
  }

  __destroy_into_raw() {
    const ptr = this.__wbg_ptr;
    this.__wbg_ptr = 0;
    HDKeyFinalization.unregister(this);
    return ptr;
  }

  free() {
    const ptr = this.__destroy_into_raw();
    wasm.__wbg_hdkey_free(ptr);
  }
  /**
   * Master key derived from a seed.
   * @param {Uint8Array} seed
   * @returns {HDKey}
   */
  static master(seed) {
    const ptr0 = passArray8ToWasm0(seed, wasm.__wbindgen_export_0);
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.hdkey_master(ptr0, len0);
    return HDKey.__wrap(ret);
  }
  /**
   * Derive the [`HDKey`] at the given path (e.g. "m/44'/1899'/0'/0/0").
   * @param {Ecc} ecc
   * @param {string} chain_path
   * @returns {HDKey}
   */
  derive(ecc, chain_path) {
    _assertClass(ecc, Ecc);
    const ptr0 = passStringToWasm0(
      chain_path,
      wasm.__wbindgen_export_0,
      wasm.__wbindgen_export_2
    );
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.hdkey_derive(this.__wbg_ptr, ecc.__wbg_ptr, ptr0, len0);
    return HDKey.__wrap(ret);
  }
  /**
   * Derive a normal [`HDKey`] at the given index.
   * @param {Ecc} ecc
   * @param {number} idx
   * @returns {HDKey}
   */
  derive_idx(ecc, idx) {
    _assertClass(ecc, Ecc);
    const ret = wasm.hdkey_derive_idx(this.__wbg_ptr, ecc.__wbg_ptr, idx);
    return HDKey.__wrap(ret);
  }
  /**
   * Derive a hardened [`HDKey`] at the given index, normalizing into valid range if necessary.
   * @param {number} idx
   * @returns {HDKey}
   */
  derive_idx_hardened(idx) {
    const ret = wasm.hdkey_derive_idx_hardened(this.__wbg_ptr, idx);
    return HDKey.__wrap(ret);
  }
  /**
   * Private key of this [`HDKey`].
   * @returns {Uint8Array}
   */
  private_key() {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      wasm.hdkey_private_key(retptr, this.__wbg_ptr);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var v1 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v1;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
}

const MnemonicFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_mnemonic_free(ptr >>> 0));
/**
 * BIP39-style mnemonic (English only).
 */
export class Mnemonic {
  static __wrap(ptr) {
    ptr = ptr >>> 0;
    const obj = Object.create(Mnemonic.prototype);
    obj.__wbg_ptr = ptr;
    MnemonicFinalization.register(obj, obj.__wbg_ptr, obj);
    return obj;
  }

  __destroy_into_raw() {
    const ptr = this.__wbg_ptr;
    this.__wbg_ptr = 0;
    MnemonicFinalization.unregister(this);
    return ptr;
  }

  free() {
    const ptr = this.__destroy_into_raw();
    wasm.__wbg_mnemonic_free(ptr);
  }
  /**
   * Derive mnemonic from entropy.
   * @param {Uint8Array} entropy
   * @returns {Mnemonic}
   */
  static from_entropy(entropy) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passArray8ToWasm0(entropy, wasm.__wbindgen_export_0);
      const len0 = WASM_VECTOR_LEN;
      wasm.mnemonic_from_entropy(retptr, ptr0, len0);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      if (r2) {
        throw takeObject(r1);
      }
      return Mnemonic.__wrap(r0);
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Parse mnemonic phrase.
   * @param {string} phrase
   * @returns {Mnemonic}
   */
  static parse_phrase(phrase) {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      const ptr0 = passStringToWasm0(
        phrase,
        wasm.__wbindgen_export_0,
        wasm.__wbindgen_export_2
      );
      const len0 = WASM_VECTOR_LEN;
      wasm.mnemonic_parse_phrase(retptr, ptr0, len0);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var r2 = getInt32Memory0()[retptr / 4 + 2];
      if (r2) {
        throw takeObject(r1);
      }
      return Mnemonic.__wrap(r0);
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
  /**
   * Phrase for this mnemonic
   * @returns {string}
   */
  phrase() {
    let deferred1_0;
    let deferred1_1;
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      wasm.mnemonic_phrase(retptr, this.__wbg_ptr);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      deferred1_0 = r0;
      deferred1_1 = r1;
      return getStringFromWasm0(r0, r1);
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
      wasm.__wbindgen_export_1(deferred1_0, deferred1_1, 1);
    }
  }
  /**
   * Seed bytes for this mnemonic
   * @returns {Uint8Array}
   */
  seed() {
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
      wasm.mnemonic_seed(retptr, this.__wbg_ptr);
      var r0 = getInt32Memory0()[retptr / 4 + 0];
      var r1 = getInt32Memory0()[retptr / 4 + 1];
      var v1 = getArrayU8FromWasm0(r0, r1).slice();
      wasm.__wbindgen_export_1(r0, r1 * 1, 1);
      return v1;
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16);
    }
  }
}

async function __wbg_load(module, imports) {
  if (typeof Response === "function" && module instanceof Response) {
    if (typeof WebAssembly.instantiateStreaming === "function") {
      try {
        return await WebAssembly.instantiateStreaming(module, imports);
      } catch (e) {
        if (module.headers.get("Content-Type") != "application/wasm") {
          console.warn(
            "`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n",
            e
          );
        } else {
          throw e;
        }
      }
    }

    const bytes = await module.arrayBuffer();
    return await WebAssembly.instantiate(bytes, imports);
  } else {
    const instance = await WebAssembly.instantiate(module, imports);

    if (instance instanceof WebAssembly.Instance) {
      return { instance, module };
    } else {
      return instance;
    }
  }
}

function __wbg_get_imports() {
  const imports = {};
  imports.wbg = {};
  imports.wbg.__wbindgen_string_new = function (arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
  };
  imports.wbg.__wbindgen_throw = function (arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
  };

  return imports;
}

function __wbg_init_memory(imports, maybe_memory) {}

function __wbg_finalize_init(instance, module) {
  wasm = instance.exports;
  __wbg_init.__wbindgen_wasm_module = module;
  cachedInt32Memory0 = null;
  cachedUint32Memory0 = null;
  cachedUint8Memory0 = null;

  return wasm;
}

function initSync(module) {
  if (wasm !== undefined) return wasm;

  const imports = __wbg_get_imports();

  __wbg_init_memory(imports);

  if (!(module instanceof WebAssembly.Module)) {
    module = new WebAssembly.Module(module);
  }

  const instance = new WebAssembly.Instance(module, imports);

  return __wbg_finalize_init(instance, module);
}

async function __wbg_init(input) {
  if (wasm !== undefined) return wasm;

  if (typeof input === "undefined") {
    input = new URL("p2s_wallet_bg.wasm", import.meta.url);
  }
  const imports = __wbg_get_imports();

  if (
    typeof input === "string" ||
    (typeof Request === "function" && input instanceof Request) ||
    (typeof URL === "function" && input instanceof URL)
  ) {
    input = fetch(input);
  }

  __wbg_init_memory(imports);

  const { instance, module } = await __wbg_load(await input, imports);

  return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
