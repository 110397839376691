// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";
import { ApplePayEventsEnum, Stripe } from "@capacitor-community/stripe";

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { chronik, signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { buildSelfMintTx, processAuthCode } from "../wallet/selfMint";
import { Ecc } from "../wallet/ffi";
import { WalletKeys } from "./wallet";

export const STRIPE_PAY_TYPE_PARAM = "pay_type";

export interface StripeState {
  publishableKey: string | undefined;
  clientSecret: string | undefined;
  paymentId: string | undefined;
  isInitialized: boolean;
}

export const initialStripe: StripeState = {
  publishableKey: undefined,
  clientSecret: undefined,
  paymentId: undefined,
  isInitialized: false,
};

export interface StripeActions {
  loadPkStripe: () => Promise<void>;
  createPaymentIntentRequest: (
    amountCent: Long,
    eventCode?: string
  ) => Promise<{ paymentId: string } | undefined>;
  createCartPaymentIntentRequest: (
    burnTokenAmount: Long,
    isPreauth: boolean
  ) => Promise<{ paymentId: string }>;
  getAuthCodeRequest: (paymentIntentId: string) => Promise<void>;
  autoSelfMint: (paymentIntentId: string) => Promise<void>;
  payApplePay: (
    amountCent: Long,
    eventTitle: string,
    eventCode: string
  ) => Promise<ApplePayEventsEnum>;
  resetStripe: () => Promise<void>;
}

export function stripeActions(set: StoreSet, get: StoreGet): StripeActions {
  return {
    autoSelfMint: catchError(
      "Auto Self Mint",
      set,
      async (paymentIntentId: string) => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;

        const response = await signedRequest({
          proto: proto.StripeMintCodeResponse,
          path: "/stripe/mint-code",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.StripeMintCodeRequest.encode({
            paymentId: paymentIntentId,
          }).finish(),
        });
        const authCode = response.mintCode;
        const authCodeData = await processAuthCode(authCode);
        const tx = await buildSelfMintTx({
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          pkh: keys.pkh,
          authCodeData,
        });
        await chronik().broadcastTx(tx);
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    getAuthCodeRequest: catchError(
      "Get AuthCode",
      set,
      async (paymentIntentId: string) => {
        set(state => {
          state.loading.isLoading = true;
          state.selfMint.authCode = undefined;
        });

        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const response = await signedRequest({
          proto: proto.StripeMintCodeResponse,
          path: "/stripe/mint-code",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.StripeMintCodeRequest.encode({
            paymentId: paymentIntentId,
          }).finish(),
        });
        console.log("/stripe/mint-code", response);
        set(state => {
          state.selfMint.authCode = response.mintCode;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    loadPkStripe: catchError(
      "Load Stripe",
      set,
      async () => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.StripeLoadPkResponse,
          path: "/stripe/load-pk",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.Empty.encode({}).finish(),
        });
        set(state => {
          state.stripe.publishableKey = response.publishableKey;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    createPaymentIntentRequest: catchError(
      "Create Payment",
      set,
      async (tokenBaseAmount: Long, eventCode?: string) => {
        if (tokenBaseAmount.lessThanOrEqual(Long.ZERO)) {
          throw "Already sufficient CRD balance";
        }
        if (get().stripe.isInitialized) {
          return { paymentId: get().stripe.paymentId! };
        }
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        if (keys.isProvisionalPk) {
          // Can't purchase a mint code without a wallet
          return undefined;
        }
        set(state => {
          state.stripe.isInitialized = true;
          state.loading.isLoading = true;
        });
        try {
          const response = await createStripePayment({
            ecc,
            keys,
            tokenBaseAmount,
            eventCode,
          });
          console.log(
            "created payment intent",
            tokenBaseAmount.toNumber(),
            response
          );
          set(state => {
            state.stripe.clientSecret = response.clientSecret;
            state.stripe.paymentId = response.paymentId;
          });
          return { paymentId: response.paymentId };
        } catch (ex) {
          set(state => {
            state.stripe.isInitialized = false;
          });
          throw ex;
        }
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    createCartPaymentIntentRequest: catchError(
      "Create Payment",
      set,
      async (burnTokenAmount: Long, isPreauth: boolean) => {
        if (get().stripe.isInitialized) {
          return { paymentId: get().stripe.paymentId! };
        }
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.stripe.isInitialized = true;
          state.loading.isLoading = true;
        });
        try {
          const response = await createEventStripePayment({
            ecc,
            keys,
            cart: get().cart.cart!,
            burnTokenAmount,
            isPreauth,
          });
          console.log(
            "Initialized stripe: ",
            response.paymentId,
            response.clientSecret
          );
          set(state => {
            state.stripe.clientSecret = response.clientSecret;
            state.stripe.paymentId = response.paymentId;
          });
          return { paymentId: response.paymentId };
        } catch (ex) {
          set(state => {
            state.stripe.isInitialized = false;
          });
          throw ex;
        }
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    payApplePay: catchError(
      "Create Payment",
      set,
      async (amountCent, eventTitle, eventCode) => {
        if (!get().stripe.isInitialized) {
          throw "Stripe is not initialized";
        }
        const clientSecret = get().stripe.clientSecret;
        const paymentId = get().stripe.paymentId;
        if (!clientSecret || !paymentId) {
          throw "No clientSecret or paymentId";
        }
        await Stripe.createApplePay({
          paymentIntentClientSecret: clientSecret,
          paymentSummaryItems: [
            {
              label: `Pay2Stay: "${eventTitle}" Ticket (code ${eventCode})`,
              amount: amountCent.toNumber() / 100,
            },
          ],
          merchantIdentifier: "merchant.com.pay2stay",
          countryCode: "US",
          currency: "USD",
        });
        const result = await Stripe.presentApplePay();
        console.log("Apple Pay result", result);
        return result.paymentResult;
      }
    ),
    resetStripe: async () => {
      set(state => {
        state.stripe = initialStripe;
      });
    },
  };
}

async function createStripePayment(params: {
  ecc: Ecc;
  keys: WalletKeys;
  tokenBaseAmount: Long;
  eventCode: string | undefined;
}): Promise<proto.CreateMintCodePaymentIntentResponse> {
  return await signedRequest({
    proto: proto.CreateMintCodePaymentIntentResponse,
    path: "/stripe/payment-intent/create",
    method: "POST",
    ecc: params.ecc,
    seckey: params.keys.seckey,
    pubkey: params.keys.pubkey,
    payload: proto.CreateMintCodePaymentIntentRequest.encode({
      tokenBaseAmount: params.tokenBaseAmount,
      eventCode: params.eventCode ?? "",
      hideDetails: false,
    }).finish(),
  });
}

async function createEventStripePayment(params: {
  ecc: Ecc;
  keys: WalletKeys;
  cart: proto.EventPurchaseCart;
  burnTokenAmount: Long;
  isPreauth: boolean;
}): Promise<proto.CreatEventPaymentIntentResponse> {
  return await signedRequest({
    proto: proto.CreatEventPaymentIntentResponse,
    path: "/event/payment-intent/create",
    method: "POST",
    ecc: params.ecc,
    seckey: params.keys.seckey,
    pubkey: params.keys.pubkey,
    payload: proto.CreatEventPaymentIntentRequest.encode({
      cart: params.cart,
      burnTokenAmount: params.burnTokenAmount,
      isPreauth: params.isPreauth,
    }).finish(),
  });
}
