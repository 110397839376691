// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect, useState } from "react";

import { useUpdateRegularly } from "../../hook/useUpdateRegularly";
import { useStore } from "../../store";
import ConfirmModal from "../confirm-modal/ConfirmModal";

export const UpdateAppVersion: React.FC = () => {
  const appVersionFetch = useStore(store => store.appVersionFetch);
  const versionFromApi = useStore(store => store.appInfo.versionFromApi);
  const currentVersion = useStore(store => store.appInfo.currentVersion);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Set version when (re)loading the app
    appVersionFetch(true);
  }, []);

  // update every 10 minutes, or when the user switches to the tab
  useUpdateRegularly(() => appVersionFetch(false), [], 10 * 60 * 1000);

  useEffect(() => {
    if (currentVersion !== undefined && versionFromApi != currentVersion) {
      setShowModal(true);
    }
  }, [versionFromApi, currentVersion]);

  return (
    <ConfirmModal
      title="New App Version Available"
      onCloseClicked={() => setShowModal(false)}
      isOpen={showModal}
      buttons={[
        {
          text: "Reload",
          function: () => {
            setShowModal(false);
            window.location.reload();
          },
        },
        {
          text: "Cancel",
          function: () => setShowModal(false),
          fill: "outline",
        },
      ]}
    >
      <div className="confirm-wrap" style={{ textAlign: "center" }}>
        <p>
          There&apos;s a new version available, do you want to reload the app?
        </p>
      </div>
    </ConfirmModal>
  );
};
