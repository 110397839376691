// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React from "react";
import { IonButton, IonCol, IonRow } from "@ionic/react";

import "./PadButtons.scss";

export interface PadButton {
  value: string;
  noBox?: boolean;
}

interface Props {
  handleClick: (value: string) => void;
  disabled: boolean;
  hasCancelBtn?: boolean;
}

const PadButtons: React.FC<Props> = ({
  handleClick,
  disabled,
  hasCancelBtn,
}) => {
  const buttons: PadButton[][] = [
    [{ value: "1" }, { value: "2" }, { value: "3" }],
    [{ value: "4" }, { value: "5" }, { value: "6" }],
    [{ value: "7" }, { value: "8" }, { value: "9" }],
    [
      { value: hasCancelBtn ? "Cancel" : "", noBox: true },
      { value: "0" },
      { value: "Del", noBox: true },
    ],
  ];
  return (
    <div className="pad-buttons">
      {buttons.map((buttonRow, index) => {
        return (
          <IonRow key={index}>
            {buttonRow.map(button => {
              return (
                <IonCol className="button-wrap" key={button.value}>
                  {button.value && (
                    <IonButton
                      className={
                        button.noBox ? "button-noBox" : "button-number"
                      }
                      onClick={() => handleClick(button.value)}
                      disabled={disabled}
                    >
                      {button.value == "Del" ? "\u232b" : button.value}
                    </IonButton>
                  )}
                </IonCol>
              );
            })}
          </IonRow>
        );
      })}
    </div>
  );
};

export default PadButtons;
