// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { StoreGet, StoreSet } from "../store";

export interface LoadingState {
  isLoading: boolean;
  isSuspenseLoading: boolean;
  isFullPageLoading: boolean;
}

export interface LoadingActions {
  setSuspenseLoading: (suspenseLoading: boolean) => void;
  setFullPageLoading: (fullPageLoading: boolean) => void;
}

export const initialLoading: LoadingState = {
  isLoading: false,
  isSuspenseLoading: false,
  isFullPageLoading: false,
};

export function loadingActions(set: StoreSet, get: StoreGet): LoadingActions {
  return {
    setSuspenseLoading: async (suspenseLoading: boolean) => {
      set(state => {
        state.loading.isSuspenseLoading = suspenseLoading;
      });
    },
    setFullPageLoading: async (fullPageLoading: boolean) => {
      set(state => {
        state.loading.isFullPageLoading = fullPageLoading;
      });
    },
  };
}
