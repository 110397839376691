// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";
import isEqual from "lodash/isEqual";

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { parseMnemonic } from "../wallet/mnemonic";
import { deriveWalletKeys, storeWalletKeys } from "../wallet/key";
import { signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";

export interface LoginState {
  isNotExisting: boolean;
}

export interface LoginActions {
  loginRequest: (phrase: string, existingPubkey?: Uint8Array) => Promise<void>;
}

export const initialLogin: LoginState = {
  isNotExisting: false,
};

export function loginActions(set: StoreSet, get: StoreGet): LoginActions {
  return {
    loginRequest: catchError(
      "Login",
      set,
      async (phrase: string, existingPubkey?: Uint8Array) => {
        const state = get();
        set(state => {
          state.loading.isLoading = true;
          state.login.isNotExisting = false;
        });
        const mnemonic = parseMnemonic(phrase);
        const ecc = state.wallet.ecc!;
        const walletKeys = deriveWalletKeys(
          mnemonic.seed,
          mnemonic.phrase,
          ecc
        );
        if (
          existingPubkey !== undefined &&
          !isEqual(walletKeys.pubkey, existingPubkey)
        ) {
          throw "Seed phrase does not match";
        }
        // Check if the user exists and is verified
        const response = await signedRequest({
          proto: proto.LoginResponse,
          path: "/login",
          method: "POST",
          ecc: ecc,
          seckey: walletKeys.seckey,
          pubkey: walletKeys.pubkey,
          payload: proto.LoginRequest.encode({
            sessionId: get().browserSession.sessionId ?? "",
          }).finish(),
        });
        if (!response.exists) {
          set(state => {
            state.signUp.mnemonic = mnemonic;
            state.login.isNotExisting = true;
          });
          return;
        }
        await storeWalletKeys(walletKeys);
        await localforage.setItem<boolean>(
          "p2s:hasVerifiedEmail",
          response.hasVerifiedEmail
        );
        set(state => {
          state.wallet.keys = walletKeys;
          state.verifyEmail.hasVerifiedEmail = response.hasVerifiedEmail;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
