// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import axios, { AxiosResponse } from "axios";
import { ChronikClientNode } from "chronik-client";

import * as ffi from "../wallet/ffi";
import { CHRONIK_URL } from "../wallet/settings";
import * as proto from "./p2s";
import { P2S_URL } from "./settings";
import { protoDecode, ProtoDecoder } from "./util";

export function chronik(): ChronikClientNode {
  return new ChronikClientNode([CHRONIK_URL]);
}

export function signRequest(params: {
  ecc: ffi.Ecc;
  seckey: Uint8Array | undefined;
  pubkey: Uint8Array | undefined;
  payload: Uint8Array;
}): Uint8Array {
  if (!params.seckey || !params.pubkey) {
    return proto.SignedPayload.encode({
      pubkey: new Uint8Array(33),
      payload: params.payload,
      signature: new Uint8Array(),
    }).finish();
  }
  const msg = ffi.sha256(params.payload);
  const signature = params.ecc.schnorr_sign(params.seckey, msg);
  const signedPayload = proto.SignedPayload.encode({
    pubkey: params.pubkey,
    payload: params.payload,
    signature,
  }).finish();
  return signedPayload;
}

export async function signedRequestRaw(params: {
  path: string;
  method: string;
  ecc: ffi.Ecc;
  seckey: Uint8Array | undefined;
  pubkey: Uint8Array | undefined;
  payload: Uint8Array;
}): Promise<Uint8Array> {
  const signedPayload = signRequest(params);
  return await requestRaw({
    path: params.path,
    method: params.method,
    data: signedPayload,
  });
}

export async function signedRequest<T>(params: {
  proto: ProtoDecoder<T>;
  path: string;
  method: string;
  ecc: ffi.Ecc;
  seckey: Uint8Array | undefined;
  pubkey: Uint8Array | undefined;
  payload: Uint8Array;
}): Promise<T> {
  const responseBytes = await signedRequestRaw(params);
  return protoDecode(params.proto, responseBytes);
}

export async function requestRaw(params: {
  path: string;
  method: string;
  data: Uint8Array;
}): Promise<Uint8Array> {
  const response = await axios.request({
    url: `${P2S_URL}${params.path}`,
    method: params.method,
    data: params.data,
    responseType: "arraybuffer",
    validateStatus: undefined,
    // Prevents Axios encoding the Uint8Array as JSON or something
    transformRequest: x => x,
    headers: {
      "Content-Type": "application/x-protobuf",
    },
  });
  ensureResponseErrorThrown(response, params.path);
  return new Uint8Array(response.data);
}

export async function request<T>(params: {
  proto: ProtoDecoder<T>;
  path: string;
  method: string;
  data: Uint8Array;
}): Promise<T> {
  const responseBytes = await requestRaw(params);
  return protoDecode(params.proto, responseBytes);
}

function ensureResponseErrorThrown(response: AxiosResponse, path: string) {
  if (response.status != 200) {
    const error = protoDecode(proto.Error, new Uint8Array(response.data));
    throw new Error(error.msg);
  }
}
