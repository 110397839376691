// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonGrid, IonRow, useIonViewDidLeave } from "@ionic/react";
import React, { ReactNode } from "react";
import { Clipboard } from "@capacitor/clipboard";

import "./PasteClipboard.scss";
import { pasteIcon } from "../../icon/icon";

interface Props {
  labelInput?: string;
  children?: ReactNode;
  onPasted: (value: string) => void;
  setValue: (value: string) => void;
  sanitizeValue?: (value: string) => string;
}

const convertHtmlToPlain = (html: string) => {
  let tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

const PasteClipboard: React.FC<Props> = props => {
  const handlePaste = async () => {
    try {
      let { value, type } = await Clipboard.read();

      if (type == "text/html") {
        value = convertHtmlToPlain(value);
      }

      if (props.sanitizeValue) {
        value = props.sanitizeValue(value);
      }
      props.setValue(value);
      props.onPasted(value);
    } catch (error) {
      console.log(error);
    }
  };

  useIonViewDidLeave(() => {
    props.setValue("");
  });

  return (
    <IonGrid className="wrap-paste-clipboard">
      <IonRow>
        <div className="wrap-input">
          {props.labelInput && (
            <div className="label-input">{props.labelInput}</div>
          )}
          {props.children}
          <div className="paste-btn">
            <button onClick={handlePaste}>
              <img src={pasteIcon} />
              <span>Paste</span>
            </button>
          </div>
        </div>
      </IonRow>
    </IonGrid>
  );
};

export default PasteClipboard;
