// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { ReactNode } from "react";
import { IonHeader, IonProgressBar } from "@ionic/react";

import { useStore } from "../../store";

import "./LoadingHeader.scss";

interface Props {
  suspenseLoadingPosition: "top" | "bottom";
  hasSafeArea?: boolean;
  children?: ReactNode;
  refHeader?: React.RefObject<HTMLIonHeaderElement>;
  className?: string;
  mode?: "ios" | "md";
  colorProgressBar?:
    | "danger"
    | "dark"
    | "light"
    | "medium"
    | "primary"
    | "secindary"
    | "success"
    | "tertiary"
    | "wraning";
}

const LoadingHeader: React.FC<Props> = ({
  suspenseLoadingPosition,
  hasSafeArea,
  children,
  refHeader,
  className,
  mode,
  colorProgressBar,
}) => {
  const isSuspenseLoading = useStore(store => store.loading.isSuspenseLoading);

  return (
    <IonHeader
      className={className ? `${className} loading-header` : "loading-header"}
      mode={mode || "ios"}
      ref={refHeader}
    >
      {hasSafeArea && <div className="safe-area" />}
      {suspenseLoadingPosition == "top" && isSuspenseLoading && (
        <IonProgressBar type="indeterminate" color={colorProgressBar} />
      )}
      {children}
      {suspenseLoadingPosition == "bottom" && isSuspenseLoading && (
        <IonProgressBar type="indeterminate" color="" />
      )}
    </IonHeader>
  );
};

export default LoadingHeader;
