// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import uniqBy from "lodash/uniqBy";

import * as proto from "../rpc/p2s";
import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { signedRequest } from "../rpc/request";
import { WalletStore } from "./wallet";

export interface PaymentHistoryState {
  payments: proto.Payment[] | undefined;
  isSyncing: boolean;
  paymentCurrentHistoryPage: number;
  shouldStopSyncing: boolean;
}

export interface PaymentHistoryActions {
  paymentHistoryInit: () => Promise<void>;
  paymentHistorySync: () => Promise<void>;
  paymentHistoryList: (page: number) => Promise<void>;
  paymentHistoryStopSync: () => void;
}

export const initialPaymentHistory: PaymentHistoryState = {
  payments: undefined,
  isSyncing: false,
  paymentCurrentHistoryPage: 0,
  shouldStopSyncing: false,
};

export function paymentHistoryActions(
  set: StoreSet,
  get: StoreGet
): PaymentHistoryActions {
  return {
    paymentHistoryStopSync: () => {
      set(state => {
        state.paymentHistory.shouldStopSyncing = true;
      });
    },
    paymentHistoryInit: catchError(
      "Payment History Init",
      set,
      async () => {
        const wallet = get().wallet;
        if (wallet.keys?.isProvisionalPk) {
          return;
        }
        const waitUntilSynced = async (): Promise<boolean | undefined> => {
          const isSyncing = (await paymentHistoryStatusRequest(wallet))
            .isSyncing;
          if (!isSyncing || get().paymentHistory.shouldStopSyncing) {
            return;
          }
          await new Promise(res => setTimeout(res, 500));
          await waitUntilSynced();
        };
        set(state => {
          state.paymentHistory.paymentCurrentHistoryPage = 0;
          state.paymentHistory.isSyncing = true;
          state.paymentHistory.shouldStopSyncing = false;
        });
        paymentHistoryListRequest(wallet, 0).then(response => {
          set(state => {
            state.paymentHistory.payments = response.payments;
          });
        });
        const request = await paymentHistorySyncRequest(wallet);
        // Another request is already syncing the payment history, wait for that one
        if (request.isSyncing) {
          await waitUntilSynced();
        }
        const paymentList = (await paymentHistoryListRequest(wallet, 0))
          .payments;
        set(state => {
          state.paymentHistory.payments = paymentList;
          state.paymentHistory.isSyncing = false;
        });
      },
      undefined
    ),
    paymentHistorySync: catchError(
      "Payment History sync",
      set,
      async () => {
        if (get().wallet.keys?.isProvisionalPk) {
          return;
        }
        await paymentHistorySyncRequest(get().wallet);
      },
      undefined
    ),
    paymentHistoryList: catchError(
      "Payment History List",
      set,
      async (pageNumber: number) => {
        const wallet = get().wallet;
        if (wallet.keys?.isProvisionalPk) {
          return;
        }
        const response = await paymentHistoryListRequest(wallet, pageNumber);
        set(state => {
          state.paymentHistory.payments = uniqBy(
            [...(state.paymentHistory.payments || []), ...response.payments],
            item => item.paymentId
          );
          state.paymentHistory.paymentCurrentHistoryPage = pageNumber;
        });
      },
      undefined
    ),
  };
}

async function paymentHistorySyncRequest(wallet: WalletStore) {
  return await signedRequest({
    proto: proto.PaymentHistoryStatusResponse,
    path: "/payment-history/sync",
    method: "POST",
    ecc: wallet.ecc!,
    seckey: wallet.keys!.seckey,
    pubkey: wallet.keys!.pubkey,
    payload: proto.Empty.encode({}).finish(),
  });
}

async function paymentHistoryStatusRequest(wallet: WalletStore) {
  return await signedRequest({
    proto: proto.PaymentHistoryStatusResponse,
    path: "/payment-history/status",
    method: "POST",
    ecc: wallet.ecc!,
    seckey: wallet.keys!.seckey,
    pubkey: wallet.keys!.pubkey,
    payload: proto.Empty.encode({}).finish(),
  });
}

export async function paymentHistoryListRequest(
  wallet: WalletStore,
  pageNumber: number
) {
  return await signedRequest({
    proto: proto.PaymentHistoryResponse,
    path: "/payment-history/list",
    method: "POST",
    ecc: wallet.ecc!,
    seckey: wallet.keys!.seckey,
    pubkey: wallet.keys!.pubkey,
    payload: proto.PaymentHistoryRequest.encode({
      page: pageNumber,
    }).finish(),
  });
}
