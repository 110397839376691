// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { browserStorage } from "../storage/browser";
import type { StoreGet, StoreSet } from "../store";

export interface InstallPromptState {
  event?: BeforeInstallPromptEvent;
  canInstallPwa: boolean | undefined;
}

export interface InstallPromptActions {
  loadStoragePwaInstallable: () => Promise<void>;
  loadInstallPromptEvent: () => () => void;
  promptInstallation: () => Promise<
    BeforeInstallPromptEventUserChoice | undefined
  >;
  setInstallPromptEvent: (event: BeforeInstallPromptEvent) => void;
}

export const initialInstallPrompt: InstallPromptState = {
  event: undefined,
  canInstallPwa: undefined,
};

export function installPromptActions(
  set: StoreSet,
  get: StoreGet
): InstallPromptActions {
  return {
    loadStoragePwaInstallable: async () => {
      const canInstallPwa = await browserStorage.canInstallPwa.get();
      set(state => {
        state.installPrompt.canInstallPwa = canInstallPwa;
      });
    },
    loadInstallPromptEvent: () => {
      const handleBeforeInstallPrompt = get().setInstallPromptEvent;
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    },
    promptInstallation: async () => {
      const outcome = await get().installPrompt.event?.prompt();
      if (outcome?.outcome === "accepted") {
        browserStorage.canInstallPwa.set(true);
        set(state => {
          state.installPrompt.canInstallPwa = true;
        });
      }
      return outcome;
    },
    setInstallPromptEvent: event => {
      set(state => {
        state.installPrompt.event = event;
      });
    },
  };
}
