// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import type { StoreGet, StoreSet } from "../store";
import * as proto from "../rpc/p2s";
import { signedRequest } from "../rpc/request";
import { catchError } from "./error";
import { browserStorage } from "../storage/browser";

export interface ProfileState {
  hasLoadedFromStorage: boolean;
  storageLoadingPromise: Promise<void> | undefined;
  user: UserProfile | undefined;
  privileges: UserPrivileges | undefined;
}

export interface UserProfile {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  birthdayDay: number;
  birthdayMonth: number;
  birthdayYear: number;
  timestampCreated: Long;
  avatarUrl: string;
  phoneNumber: string;
  hasCreatedWallet: boolean;
}

export interface UpdatedUserProfile {
  firstName: string;
  lastName: string;
  birthdayDay?: number;
  birthdayMonth?: number;
  birthdayYear?: number;
}

export interface UserPrivileges {
  isHost: boolean;
  isAdmin: boolean;
  isPayout: boolean;
}

export interface ProfileActions {
  profileLoad: () => Promise<void>;
  profileUnload: () => Promise<void>;
  profileFetch: () => Promise<UserProfile | undefined>;
  privilegesFetch: () => Promise<void>;
  profileUpdate: (updatedUser: UpdatedUserProfile) => Promise<void>;
  grantPrivilegeRequests: (
    privilegesList: string[],
    granteeId: number
  ) => Promise<void>;
}

export const initialProfile: ProfileState = {
  hasLoadedFromStorage: false,
  storageLoadingPromise: undefined,
  user: undefined,
  privileges: undefined,
};

export function profileActions(set: StoreSet, get: StoreGet): ProfileActions {
  return {
    /**
     * Waits for the profile and privileges to load from storage if they haven't been loaded yet.
     */
    profileLoad: catchError("Load Profile From Storage", set, async () => {
      const profileState = get().profile;

      if (profileState.hasLoadedFromStorage) {
        return;
      }

      if (profileState.storageLoadingPromise) {
        return profileState.storageLoadingPromise;
      }

      set(state => {
        state.profile.storageLoadingPromise = (async () => {
          const [profile, privileges] = await Promise.allSettled([
            browserStorage.profile.get(),
            browserStorage.privileges.get(),
          ]);
          set(state => {
            if (profile.status === "fulfilled") {
              state.profile.user = profile.value;
            }
            if (privileges.status === "fulfilled") {
              state.profile.privileges = privileges.value;
            }
            state.profile.hasLoadedFromStorage = true;
          });
        })();
      });

      await get().profile.storageLoadingPromise;
    }),
    profileUnload: catchError("Delete Profile From Storage", set, async () => {
      browserStorage.profile.clear();
    }),
    profileFetch: catchError("Fetch Profile", set, async () => {
      const wallet = get().wallet;
      if (wallet.keys === undefined) {
        return;
      }

      const request = proto.Empty.encode({}).finish();
      const userPromise = signedRequest({
        proto: proto.User,
        path: "/me",
        method: "POST",
        ecc: wallet.ecc!,
        seckey: wallet.keys!.seckey,
        pubkey: wallet.keys!.pubkey,
        payload: request,
      });
      const userMetadataPromise = signedRequest({
        proto: proto.UserMetadata,
        path: "/me/metadata",
        method: "POST",
        ecc: wallet.ecc!,
        seckey: wallet.keys!.seckey,
        pubkey: wallet.keys!.pubkey,
        payload: request,
      });

      try {
        await get().profileLoad();
      } catch (_) {} // Error is already handled

      const [user, userMetadata] = await Promise.all([
        userPromise,
        userMetadataPromise,
      ]);
      const profile: UserProfile = {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        birthdayDay: user.birthdayDay,
        birthdayMonth: user.birthdayMonth,
        birthdayYear: user.birthdayYear,
        timestampCreated: user.timestampCreated,
        phoneNumber: user.phoneNumber,
        avatarUrl: userMetadata.avatarUrl,
        hasCreatedWallet: user.hasCreatedWallet,
      };

      set(state => {
        state.profile.user = profile;
      });

      browserStorage.profile.set(profile);

      return profile;
    }),
    grantPrivilegeRequests: catchError(
      "Grant Privileges",
      set,
      async (privilegesList: string[], granteeId: number) => {
        const wallet = get().wallet;
        if (wallet.keys === undefined) {
          return;
        }
        await Promise.all(
          privilegesList.map(privilege => {
            signedRequest({
              proto: proto.User,
              path: "/privilege/grant",
              method: "POST",
              ecc: wallet.ecc!,
              seckey: wallet.keys!.seckey,
              pubkey: wallet.keys!.pubkey,
              payload: proto.GrantPrivilegeRequest.encode({
                grantee: granteeId,
                privilege: privilege,
              }).finish(),
            });
          })
        );
      },
      undefined,
      false
    ),
    privilegesFetch: catchError(
      "Privileges",
      set,
      async () => {
        if (get().wallet.keys === undefined) {
          return;
        }

        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const requestPromise = signedRequest({
          proto: proto.PrivilegesResponse,
          path: "/user/privileges",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });

        try {
          await get().profileLoad();
        } catch (_) {} // Error is already handled

        const response = await requestPromise;
        const privileges = response.privileges;
        const isHost = privileges.some(item => item.privilege === "Host");
        const isAdmin = privileges.some(item => item.privilege === "Admin");
        const isPayout = privileges.some(item => item.privilege === "Payout");

        set(state => {
          state.profile.privileges = { isHost, isAdmin, isPayout };
        });

        browserStorage.privileges.set({ isHost, isAdmin, isPayout });
      },
      undefined,
      false
    ),
    profileUpdate: catchError(
      "Update Profile",
      set,
      async (updatedUser: UpdatedUserProfile) => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        if (
          updatedUser.birthdayDay === undefined ||
          updatedUser.birthdayMonth === undefined ||
          updatedUser.birthdayYear === undefined
        ) {
          updatedUser.birthdayDay = 0;
          updatedUser.birthdayMonth = 0;
          updatedUser.birthdayYear = 0;
        }
        const user = proto.User.fromPartial(updatedUser);
        await signedRequest({
          proto: proto.Empty,
          path: "/user/update",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.UpdateProfileRequest.encode({ user }).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;

        if (state.profile.user) {
          browserStorage.profile.set(state.profile.user);
        }
      },
      false
    ),
  };
}
