// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { format, fromUnixTime, parse } from "date-fns";
import get from "lodash/get";
import Long from "long";

import { EventTable } from "../components/event-info-table/EventInfoTable";
import { GuestCardData } from "../components/event-card-guest/EventCardGuest";
import * as proto from "../rpc/p2s";
import { P2S_APP_URL } from "../rpc/settings";
import { UserProfile } from "../store/profile";
import { priceToUsd, totalPriceEvent } from "./price";
import {
  TOKEN_FACTOR_CENT,
  TOKEN_FACTOR_USD,
  TOKEN_DECIMALS,
  CURRENCY,
  PROOF_OF_BURN_FEE_PER1000,
} from "./settings";
import { HostCardData } from "../components/event-card-host/EventCardHost";
import { AffiliateCardData } from "../components/event-card-affiliate/EventCardAffiliate";
import { EndedHostedEventData } from "../pages/ended-events/EndedHostedEventsPage";
import { EndedJoinedEventData } from "../pages/ended-events/EndedJoinedEventsPage";
import { toHexRev } from "./hex";
import { PayoutDataToShow } from "../pages/payout-history/PayoutHistoryPage";
import { GuestInfo } from "../pages/verify-guest/VerifyUserGuest";

export function nowSeconds(): number {
  return new Date().getTime() / 1000;
}

export function formatPrice(price: Long): string {
  const priceUsd = priceToUsd(price);
  return priceUsd.toFixed(2);
}

export function formatBalance(balance: proto.Balance | undefined) {
  return balance === undefined ? "-.--" : balance.usd.toFixed(2);
}

export function formatEventPrice(
  priceData: proto.EventPrice | undefined,
  guestState: proto.GuestState | undefined,
  minHoursUpfront: number
): string {
  if (
    !priceData ||
    totalPriceEvent(priceData, guestState, minHoursUpfront).isZero()
  ) {
    return `Free`;
  }
  if (priceData.priceFlat.isZero() || canJoinAgain(guestState)) {
    return `${formatUsd(formatPrice(priceData.pricePerHour))} per hour`;
  }
  if (priceData.pricePerHour.isZero() || minHoursUpfront === 0) {
    return `${formatUsd(formatPrice(priceData.priceFlat))} upfront`;
  }
  return (
    `${formatUsd(formatPrice(priceData.priceFlat))} upfront + ` +
    `${formatUsd(formatPrice(priceData.pricePerHour))} per hour`
  );
}

const minPriceStr = (
  priceData: proto.EventPrice | undefined,
  guestState: proto.GuestState | undefined,
  minHoursUpfront: number
) => {
  return `${minHoursUpfront} hours = ${formatUsd(
    minPriceToken(priceData, guestState, minHoursUpfront)
  )}`;
};

export function formatMinimumSmall(params: {
  priceData: proto.EventPrice | undefined;
  guestState: proto.GuestState | undefined;
  minHoursUpfront: number;
}): EventTable[] {
  if (
    params.priceData === undefined ||
    params.priceData.pricePerHour.isZero() ||
    params.minHoursUpfront == 0
  ) {
    return [];
  }
  return [
    {
      title: "Minimum",
      info: {
        main: minPriceStr(
          params.priceData,
          params.guestState,
          params.minHoursUpfront
        ),
      },
    },
  ];
}

export function formatMinBookedHours(
  priceData: proto.EventPrice | undefined,
  numBookedHours: number | undefined
): EventTable[] {
  if (
    priceData === undefined ||
    priceData.pricePerHour.isZero() ||
    numBookedHours! <= 0
  ) {
    return [];
  }
  return [
    {
      title: "Hours",
      info: {
        extra:
          numBookedHours !== undefined
            ? `${numBookedHours} ${numBookedHours == 1 ? "hour" : "hours"}`
            : "unspecified, please review",
      },
    },
  ];
}

export function formatDateTime(dateTime: Long, textDivision: string = "") {
  return `${formatDateEvent(dateTime)} ${textDivision} ${formatTimeEvent(
    dateTime
  )}`;
}

export function formatDateEvent(dateTime: Long): string {
  const timestamp = fromUnixTime(dateTime.toNumber());
  const timestampStr =
    new Date().getFullYear() === timestamp.getFullYear()
      ? format(timestamp, "EEE, MMM dd")
      : format(timestamp, "EEE, MMM dd, yyyy");
  return timestampStr;
}

export function formatTimeEvent(dateTime: Long, customFormat?: string): string {
  const timestamp = fromUnixTime(dateTime.toNumber());
  return format(timestamp, customFormat || "hh:mm aaa");
}

export function formatDateTimeCustomEvent(
  dateTime: Long,
  custom: string
): string {
  const timestamp = fromUnixTime(dateTime.toNumber());
  return format(timestamp, custom);
}

export function formatDateTimePaymentHistory(dateTime: Long): string {
  const timestamp = fromUnixTime(dateTime.toNumber());
  const timestampStr =
    new Date().toDateString() === timestamp.toDateString()
      ? format(timestamp, "hh:mm aaa")
      : new Date().getFullYear() === timestamp.getFullYear()
      ? format(timestamp, "EEE, do MMMM")
      : format(timestamp, "do MMMM yyyy");

  return timestampStr;
}

export const minPriceToken = (
  priceData: proto.EventPrice | undefined,
  guestState: proto.GuestState | undefined,
  minHoursUpfront: number
) => {
  return formatPrice(totalPriceEvent(priceData, guestState, minHoursUpfront));
};

export function formatSubcent(baseTokens: Long): [Long, string] {
  const subcentBalance = baseTokens.mod(TOKEN_FACTOR_CENT);
  const subcentBalanceStr = subcentBalance
    .toString()
    .padStart(TOKEN_DECIMALS - 2, "0");
  return [subcentBalance, subcentBalanceStr];
}

export function userBirthday(user: {
  birthdayDay: number;
  birthdayMonth: number;
  birthdayYear: number;
}): Date | undefined {
  if (
    user.birthdayYear == 0 &&
    user.birthdayMonth == 0 &&
    user.birthdayDay == 0
  ) {
    return undefined;
  }
  const birthdayStr = `${user.birthdayDay} ${user.birthdayMonth} ${user.birthdayYear}`;
  return parse(birthdayStr, "d M yyyy", new Date());
}

export function guestUsedSeconds(
  guestState: proto.GuestState,
  nowSeconds: number
): number {
  const usedSecondsLive =
    guestState.status === "CheckIn"
      ? nowSeconds - guestState.timestampLastCheckin.toNumber()
      : 0;
  return guestState.timeCheckedIn.toNumber() + usedSecondsLive;
}

export function formatTimeRemaining(
  guestState: proto.GuestState,
  nowSeconds: number,
  durationFormat: boolean = false
): string {
  const bookedSeconds = guestState.totalTimeAvailable.toNumber();
  const usedSeconds = guestUsedSeconds(guestState, nowSeconds);
  const remainingSeconds = Math.max(bookedSeconds - usedSeconds, 0);
  return formatDuration(remainingSeconds, durationFormat);
}

export function usedFundsCent(
  guestState: proto.GuestState,
  nowSeconds: number
): number {
  const fundsAvailable = guestState.totalFundsAvailable.toNumber();
  const timeAvailable = guestState.totalTimeAvailable.toNumber();
  const usedTime = guestUsedSeconds(guestState, nowSeconds);
  if (usedTime >= timeAvailable) {
    return fundsAvailable;
  }
  const dt = nowSeconds - guestState.timestampLastFundsUpdated.toNumber();
  const totalTokensUsed = guestState.tokensUsed.add(
    guestState.tokensUsedPerHour.mul(dt).div(3600)
  );
  return totalTokensUsed.div(TOKEN_FACTOR_CENT).toNumber();
}

export function remainingFundsUsd(
  guestState: proto.GuestState,
  nowSeconds: number
): number {
  const fundsAvailable = guestState.totalFundsAvailable.toNumber();
  const usedFunds = usedFundsCent(guestState, nowSeconds);
  const remainingFunds = (fundsAvailable - usedFunds) / 100;
  if (remainingFunds < 0 || isNaN(remainingFunds)) {
    return 0;
  }
  return remainingFunds;
}

export const calcEventHours = (
  event: (proto.Event | proto.EventDetails) & {
    guestState: proto.GuestState | undefined;
  },
  nowSeconds: number
) => {
  const guestState = event.guestState;
  if (guestState && guestState.status != "PaidOut") {
    const bookedSeconds = guestState.totalTimeAvailable.toNumber();
    return Math.floor(bookedSeconds / 3600);
  }
  return minRequiredHours(event, nowSeconds);
};

export function minRequiredHours(
  event: (proto.Event | proto.EventDetails) & {
    guestState: proto.GuestState | undefined;
  },
  nowSeconds: number
): number {
  const guestState = event.guestState;
  const numSecsIntoEvent = Math.max(nowSeconds - event.dateTime.toNumber(), 0);
  // Rounded down to only capture full hours
  const numHoursIntoEvent = Math.floor(numSecsIntoEvent / 3600);
  const usedTime = guestState ? guestUsedSeconds(guestState, nowSeconds) : 0;
  const remainingTime = guestState
    ? Math.max(guestState.totalTimeAvailable.toNumber() - usedTime, 0)
    : 0;
  const remainingHours = Math.floor(remainingTime / 3600);
  return Math.max(
    event.minHoursUpfront - numHoursIntoEvent - remainingHours,
    0
  );
}

export function formatFunds(fundsUsd: number): string {
  return `CRD ${fundsUsd.toFixed(2)}`;
}

export function formatDuration(
  totalSeconds: number,
  durationFormat = false
): string {
  const totalMinutes = totalSeconds / 60;
  const totalHours = totalSeconds / 3600;
  const seconds = Math.floor(totalSeconds % 60);
  const hours = Math.floor(totalHours);
  const minutes = Math.floor(totalMinutes) % 60;

  if (durationFormat) {
    let duration = [];

    if (totalHours >= 1) {
      duration.push(hours + "h");
    }

    if (totalMinutes >= 1 || totalHours === 1) {
      duration.push(minutes + "m");
    }

    duration.push(seconds + "s");

    return duration.join(" ");
  }

  const secondsStr = seconds.toFixed(0).padStart(2, "0");
  const minutesStr = minutes.toFixed(0).padStart(2, "0");
  const hoursStr = hours.toFixed(0);

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function formatCheckinTitle(guestState: proto.GuestState): string {
  switch (guestState.status) {
    case "Going":
      return "Check-In";
    case "CheckIn":
      return "Check-Out";
    case "CheckOut":
      return "Check-In Again";
    case "PaidOut":
      return "Paid-Out";
    default:
      return `Invalid: ${guestState.status}`;
  }
}

export function formatEventStatus(
  guestState: proto.GuestState | undefined,
  params: { isForDetails: boolean }
): string {
  if (guestState == undefined) {
    return "Invalid";
  }
  switch (guestState.status) {
    case "Going":
      return params.isForDetails ? "GOING" : "";
    case "CheckIn":
      return "CHECKED-IN";
    case "CheckOut":
      return "CHECKED-OUT";
    case "PaidOut":
      return "PAID-OUT";
    default:
      return guestState.status.toUpperCase();
  }
}

export function formatEventStatusHost(eventStatus: string): string {
  switch (eventStatus) {
    case "Planned":
      return "UPCOMING";
    case "Live":
      return "LIVE";
    case "Ended":
      return "ENDED";
    default:
      return eventStatus.toUpperCase();
  }
}

export interface ShareData {
  url: string;
  text: string;
}

export interface ShareEventInfo {
  eventTitle: string;
  dateTime: string;
  eventCode: string;
}

export function formatShareEventInfo(event: proto.Event): ShareEventInfo {
  return {
    eventTitle: event.title,
    dateTime: formatDateTime(event.dateTime, "at"),
    eventCode: event.eventCodes[0],
  };
}

export function eventHostShareInviteText(
  shareEventInfo: ShareEventInfo
): ShareData {
  const eventCode = shareEventInfo.eventCode;
  const url = `${P2S_APP_URL}?event=${eventCode}`;
  return {
    url,
    text:
      `I'm hosting "${shareEventInfo.eventTitle}" on Pay2Stay on ${shareEventInfo.dateTime}. ` +
      `Click this link to RSVP now (or enter the event code ${eventCode}): `,
  };
}

export function eventGuestShareInviteText(event: proto.Event): ShareData {
  const titleEvent = event.title;
  const dateTime = formatDateTime(event.dateTime, "at");
  const eventCode = event.eventCodes[0];
  const guestStatus = event.guestState && event.guestState.status;
  const url = `${P2S_APP_URL}?event=${eventCode}`;
  if (event.guestState === undefined) {
    return {
      url,
      text:
        `Join "${titleEvent}" using Pay2Stay on ${dateTime}. ` +
        `Click this link to RSVP now (or enter the event code ${eventCode}): `,
    };
  }
  switch (guestStatus) {
    case "Going":
      return {
        url,
        text:
          `I'm going to "${titleEvent}" using Pay2Stay on ${dateTime}. ` +
          `Click this link to join me (or enter the event code ${eventCode}): `,
      };
    case "CheckOut":
    case "CheckIn":
      return {
        url,
        text:
          `I'm at "${titleEvent}" using Pay2Stay. ` +
          `Click this link and join me (or enter the event code ${eventCode}): `,
      };
    case "PaidOut":
      return {
        url: P2S_APP_URL,
        text:
          `I went to "${titleEvent}" using Pay2Stay. ` +
          `You can install it via this link, and join me next time: `,
      };
    default:
      return { url: P2S_APP_URL, text: "Install Pay2Stay at: " };
  }
}

export function eventAffiliateShareInviteText(
  event: proto.Event,
  eventCode?: string
): ShareData {
  const titleEvent = event.title;
  const dateTime = formatDateTime(event.dateTime, "at");
  if (!eventCode) {
    eventCode = event.eventCodes[0];
  }
  const url = `${P2S_APP_URL}?event=${eventCode}`;
  return {
    url,
    text:
      `Want to go to ${titleEvent} on Pay2Stay on ${dateTime}?\n` +
      `Click this link and join me (or enter the event code ${eventCode}) `,
  };
}

export function formatCheckinTable(
  user: UserProfile,
  event: proto.Event,
  guestState: proto.GuestState
): EventTable[] {
  const nowSeconds = new Date().getTime() / 1000;
  let table: EventTable[] = [
    {
      title: "Guest",
      info: {
        main: `${user.firstName} ${user.lastName}`,
      },
    },
    {
      title: "Event",
      info: {
        extra: event.title,
      },
    },
  ];
  if (!event.priceData?.pricePerHour.isZero()) {
    table = table.concat([
      {
        title: "Booked",
        info: {
          main: formatDuration(guestState.totalTimeAvailable.toNumber()),
        },
      },
      {
        title: "Remaining",
        info: {
          main: formatTimeRemaining(guestState, nowSeconds),
        },
      },
    ]);
  }
  return table;
}

export function formatCardData(event: proto.Event): GuestCardData {
  const guestState = event.guestState!; // have to be guest for this
  const nowSeconds = new Date().getTime() / 1000;
  return {
    backgroundImage: event.bannerUrl,
    titleEvent: event.title,
    dateTime: formatDateTime(event.dateTime), // Tue, 25th August 9:00 pm
    timeRemaining: formatTimeRemaining(guestState, nowSeconds),
    fundsRemaining: remainingFundsUsd(guestState, nowSeconds),
    fundsUsed: usedFundsCent(guestState, nowSeconds) / 100,
    hostFullName: `${event.hostFirstName} ${event.hostLastName}`,
    hostAvatarUrl: event.hostAvatarUrl,
    event,
  };
}

export function formatEndedJoinedEvent(
  event: proto.Event
): EndedJoinedEventData {
  const guestState = event.guestState!; // have to be joined events ended for this
  const nowSeconds = new Date().getTime() / 1000;
  return {
    eventId: event.eventId,
    titleEvent: event.title,
    dateTime: format(
      fromUnixTime(event.dateTime.toNumber()),
      "MM/dd/yyyy hh:mm aaa"
    ),
    timeSpent: formatDuration(guestUsedSeconds(guestState, nowSeconds)),
    fundsSpent: formatUsd(
      (usedFundsCent(guestState, nowSeconds) / 100).toFixed(2)
    ),
  };
}

export function formatEndedHostedEvent(
  event: proto.Event
): EndedHostedEventData {
  const hostState = event.eventStats!; // have to be hosted evens ended for this
  const nowSeconds = new Date().getTime() / 1000;
  return {
    eventId: event.eventId,
    titleEvent: event.title,
    dateTime: format(
      fromUnixTime(event.dateTime.toNumber()),
      "MM/dd/yyyy hh:mm aaa"
    ),
    attended: hostState.numGuestsJoined,
    collected: formatFunds(totalUsdCollectedHost(hostState, nowSeconds)),
  };
}

export function formatEventCardHost(event: proto.Event): HostCardData {
  const hostState = event.eventStats!; // have to be host for this
  const nowSeconds = new Date().getTime() / 1000;
  return {
    backgroundImage: event.bannerUrl,
    titleEvent: event.title,
    dateTime: formatDateTime(event.dateTime), // Tue, 25th August 9:00 pm
    numGuestsJoined: hostState.numGuestsJoined,
    numGuestsCheckedIn: hostState.numGuestsCheckedIn,
    fundsCollectedPerHour:
      hostState.tokensCollectedPerHour.toNumber() / TOKEN_FACTOR_USD,
    totalFundsCollected: totalUsdCollectedHost(hostState, nowSeconds),
    isPastInvalidation: isPastInvalidation(
      hostState.timestampNextInvalidation,
      nowSeconds
    ),
    event,
    hostFullName: `${event.hostFirstName} ${event.hostLastName}`,
    hostAvatarUrl: event.hostAvatarUrl,
  };
}

export function formatEventCardAffiliate(
  event: proto.Event
): AffiliateCardData {
  const affiliateStats = event.affiliateStats;
  const nowSeconds = new Date().getTime() / 1000;
  const tokensPerHour = affiliateStats.reduce(
    (a, b) => a.add(b.tokensPerHour),
    Long.ZERO
  );
  return {
    backgroundImage: event.bannerUrl,
    titleEvent: event.title,
    dateTime: formatDateTime(event.dateTime), // Tue, 25th August 9:00 pm
    numPayments: affiliateStats.reduce((a, b) => a + b.numPayments, 0),
    numGuestsCheckedIn: affiliateStats.reduce((a, b) => a + b.numCheckedIn, 0),
    fundsCollectedPerHour: tokensPerHour.toNumber() / TOKEN_FACTOR_USD,
    totalFundsCollected: totalUsdCollectedAffiliate(affiliateStats, nowSeconds),
    isPastInvalidation: isAffiliatePastInvalidation(affiliateStats, nowSeconds),
    event,
    hostFullName: `${event.hostFirstName} ${event.hostLastName}`,
    hostAvatarUrl: event.hostAvatarUrl,
  };
}

export function isAffiliatePastInvalidation(
  affiliateStats: proto.AffiliateStats[],
  nowSeconds: number
): boolean {
  return affiliateStats.reduce(
    (a, b) => a || isPastInvalidation(b.timestampNextInvalidation, nowSeconds),
    false
  );
}

export function isPastInvalidation(
  timestampNextInvalidation: Long,
  nowSeconds: number
): boolean {
  if (timestampNextInvalidation.eq(Long.NEG_ONE)) {
    return false;
  }
  return timestampNextInvalidation.lte(nowSeconds);
}

export function totalUsdCollectedHost(
  hostState: proto.EventStats,
  nowSeconds: number
) {
  return totalFundsCollected(
    hostState.totalTokensCollected.toNumber() / TOKEN_FACTOR_USD,
    hostState.tokensCollectedPerHour.toNumber() / TOKEN_FACTOR_USD,
    hostState.timestampLastFundsUpdated.toNumber(),
    nowSeconds
  );
}

export function totalUsdCollectedAffiliate(
  affiliateStats: proto.AffiliateStats[],
  nowSeconds: number
) {
  return affiliateStats.reduce(
    (a, b) =>
      a +
      totalFundsCollected(
        b.tokensCollected.toNumber() / TOKEN_FACTOR_USD,
        b.tokensPerHour.toNumber() / TOKEN_FACTOR_USD,
        b.timestampLastFundsUpdated.toNumber(),
        nowSeconds
      ),
    0
  );
}

export function totalFundsCollected(
  totalFundsCollected: number,
  fundsCollectedPerHour: number,
  timestampLastFundsUpdated: number,
  nowSeconds: number
) {
  const timeSeconds = nowSeconds - timestampLastFundsUpdated;
  return totalFundsCollected + fundsCollectedPerHour * (timeSeconds / 3600);
}

export function formatBurnDataToShow(
  proofOfBurnPayout: proto.ProofOfBurnPayout
): PayoutDataToShow {
  return {
    amountUsd: proofOfBurnPayout.amountCent.toNumber() / 100,
    date: proofOfBurnPayout.timestampSubmitted.toNumber() * 1000,
    txid: toHexRev(proofOfBurnPayout.txid),
    payoutType: proofOfBurnPayout.payoutType,
    status: proofOfBurnPayout.status,
    payoutMethodId: proofOfBurnPayout.payoutMethodId,
    payoutRecipient: proofOfBurnPayout.payoutRecipient,
  };
}

export function formatAmountSep(amountCent: Long): [string, string] {
  const wholeUsd = amountCent.div(100).toNumber();
  return [wholeUsd.toLocaleString("en-US"), formatSubcent(amountCent)[1]];
}

export function formatUsd(value: string): string {
  if (CURRENCY.POSITION === "Front") {
    return CURRENCY.SYMBOL + value;
  } else {
    return `${value} ${CURRENCY.SYMBOL}`;
  }
}

export function canJoinAgain(
  guestState: proto.GuestState | undefined
): boolean {
  return guestState?.status === "PaidOut";
}

export function formatTokenAmountToString(deltaTokenAmount: Long): string {
  const amountString = (deltaTokenAmount.toNumber() / TOKEN_FACTOR_USD).toFixed(
    2
  );
  return `${
    deltaTokenAmount.toNumber() >= 0 ? "+" + amountString : amountString
  } CRD`;
}

export function payoutAmountAfterFees(amountUsd: number | undefined): string {
  return amountUsd
    ? (amountUsd * (1 - PROOF_OF_BURN_FEE_PER1000 / 1000)).toFixed(2)
    : "-.--";
}

export function formatGuestInfo(
  guests: proto.User | proto.CheckinGuest
): GuestInfo {
  const birthday = userBirthday(guests);
  return {
    name: `${guests.firstName} ${guests.lastName}`,
    dateOfBirth: birthday && format(birthday, "dd MMMM yyyy"),
    avatarUrl: get(guests, "avatarUrl", ""),
  };
}

export function calculateEndTime(startTime: Long, hoursToAdd: number): Long {
  return startTime.add(hoursToAdd * 3600);
}

export function doesEndSameDay(startTime: Long, endTime: Long): boolean {
  const startDate = new Date(startTime.toNumber() * 1000);
  const endDate = new Date(endTime.toNumber() * 1000);

  return (
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate()
  );
}

export function formatTimeRange(
  startTime: Long,
  endTime: Long
): {
  startTimeFormat: string;
  endTimeFormat: string;
} {
  const start = fromUnixTime(startTime.toNumber());
  const end = fromUnixTime(endTime.toNumber());

  const bothMinutesZero =
    format(start, "mm") === "00" && format(end, "mm") === "00";
  const timeFormat = bothMinutesZero ? "h" : "h:mm";

  return {
    startTimeFormat: formatTimeEvent(startTime, timeFormat + "aaa"),
    endTimeFormat: startTime.equals(endTime)
      ? "TBD"
      : formatTimeEvent(endTime, timeFormat + "aaa"),
  };
}
