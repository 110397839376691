// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import { signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { catchError } from "./error";

export interface SearchUsersState {
  response: undefined | proto.SearchUsersResponse;
}

export interface SearchUsersActions {
  searchUsersRequest: (query: string) => Promise<proto.SearchUsersResponse>;
  searchUsersReset: () => void;
}

export const initialSearchUsers: SearchUsersState = {
  response: undefined,
};

export function searchUsersActions(
  set: StoreSet,
  get: StoreGet
): SearchUsersActions {
  return {
    searchUsersReset: () => {
      set(state => {
        state.searchUsers = initialSearchUsers;
      });
    },
    searchUsersRequest: catchError(
      "Search Users",
      set,
      async (query: string) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.SearchUsersRequest = {
          query,
        };
        const response = await signedRequest({
          proto: proto.SearchUsersResponse,
          path: "/user/search",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.SearchUsersRequest.encode(request).finish(),
        });
        set(store => {
          store.searchUsers.response = response;
        });
        return response;
      }
    ),
  };
}
