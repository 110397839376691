// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { useIonToast, ToastOptions } from "@ionic/react";
import { AxiosError } from "axios";

const TOAST_OPTIONS_NETWORK_ERROR: ToastOptions = {
  header: "Failed to connect to server",
  message: "Check your internet connection and try again.",
  mode: "ios",
  duration: 1000,
  color: "danger",
};

const TOAST_OPTIONS_UNKNOWN_ERROR: ToastOptions = {
  header: "Unknown error",
  message: "Please try again later.",
  mode: "ios",
  duration: 1000,
  color: "danger",
};

export const useExceptionToast = () => {
  const [showToast] = useIonToast();

  const showExceptionToast = (exception: unknown): Promise<void> => {
    console.log("Exception:", exception, exception instanceof Error);

    if (!(exception instanceof Error)) {
      return showToast(TOAST_OPTIONS_UNKNOWN_ERROR);
    }

    if (exception instanceof AxiosError) {
      return showToast(TOAST_OPTIONS_NETWORK_ERROR);
    }

    return showToast(TOAST_OPTIONS_UNKNOWN_ERROR);
  };

  return showExceptionToast;
};
