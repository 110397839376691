// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonLoading } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { useStore } from "../../store";

const LoadingOverlay: React.FC = () => {
  const isLoading = useStore(store => store.loading.isLoading);

  // For interlaced API calls, it sometimes happens that isLoading stays `true`;
  // By adding a small delay of 200ms when an API stopped loading we can prevent the loader to stay on indefinitely.
  const [isLoadingVisibles, setIsLoadingVisible] = useState(false);
  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (isLoading) {
      setIsLoadingVisible(true);
    } else {
      timeOut = setTimeout(() => {
        setIsLoadingVisible(false);
      }, 200);
    }
    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, [isLoading]);

  return (
    <IonLoading
      mode="ios"
      id="loading-overlay-modal"
      isOpen={isLoadingVisibles}
      duration={20000} // set a time out to avoid loading forever
    />
  );
};

export default LoadingOverlay;
