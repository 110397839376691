// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";
import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";
import { generateMnemonic, Mnemonic } from "../wallet/mnemonic";
import { request, signedRequest, signedRequestRaw } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { catchError } from "./error";
import { deriveWalletKeys, storeWalletKeys } from "../wallet/key";
import { months } from "../util";

export interface SignUpStore {
  // Generated keys
  mnemonic: Mnemonic | undefined;

  // User data
  firstName: string;
  lastName: string;
  email: string;
  birthdayDay: number | undefined;
  birthdayMonth: string | undefined;
  birthdayYear: number | undefined;

  // Captcha
  captchaSiteKey: string | undefined;
}

export interface SignUpActions {
  signUpReset: () => void;
  signUpInit: () => void;
  signUpSetFirstName: (firstName: string) => void;
  signUpSetLastName: (lastName: string) => void;
  signUpSetEmail: (email: string) => void;
  signUpSetBirthdayDay: (day: number) => void;
  signUpSetBirthdayMonth: (month: string) => void;
  signUpSetBirthdayYear: (year: number) => void;
  signUpRequest: () => Promise<void>;
  captchaLoadSiteKey: () => Promise<void>;
  signUpCaptcha: (token: string) => Promise<void>;
}

export const initialSignUp: SignUpStore = {
  mnemonic: undefined,
  firstName: "",
  lastName: "",
  email: "",
  birthdayDay: undefined,
  birthdayMonth: undefined,
  birthdayYear: undefined,
  captchaSiteKey: undefined,
};

export function signUpActions(set: StoreSet, get: StoreGet): SignUpActions {
  return {
    signUpReset: () => {
      set(state => {
        state.signUp = initialSignUp;
      });
    },
    signUpInit: () => {
      const signUp = get().signUp;
      const mnemonic = signUp.mnemonic || generateMnemonic();
      set(state => {
        const signUp = state.signUp;
        signUp.mnemonic = mnemonic;
      });
    },
    signUpSetFirstName: (firstName: string) =>
      set(state => {
        state.signUp.firstName = firstName;
      }),
    signUpSetLastName: (lastName: string) =>
      set(state => {
        state.signUp.lastName = lastName;
      }),
    signUpSetEmail: (email: string) =>
      set(state => {
        state.signUp.email = email;
      }),
    signUpSetBirthdayDay: (day: number) =>
      set(state => {
        state.signUp.birthdayDay = day;
      }),
    signUpSetBirthdayMonth: (month: string) =>
      set(state => {
        state.signUp.birthdayMonth = month;
      }),
    signUpSetBirthdayYear: (year: number) =>
      set(state => {
        state.signUp.birthdayYear = year;
      }),
    signUpRequest: catchError(
      "Sign Up",
      set,
      async () => {
        const state = get();
        const signUp = state.signUp;
        const mnemonic = signUp.mnemonic;
        if (mnemonic === undefined) {
          return;
        }
        const seed = mnemonic.seed;
        const phrase = mnemonic.phrase;
        set(state => {
          state.loading.isLoading = true;
        });
        // 0/0/0 == unspecified DOB
        let birthdayYear = 0,
          birthdayMonth = 0,
          birthdayDay = 0;
        if (
          signUp.birthdayYear !== undefined &&
          signUp.birthdayMonth !== undefined &&
          signUp.birthdayDay !== undefined
        ) {
          birthdayYear = signUp.birthdayYear;
          birthdayMonth =
            1 + months().findIndex(month => month === signUp.birthdayMonth);
          birthdayDay = signUp.birthdayDay;
        }
        const ecc = state.wallet.ecc!;
        const walletKeys = deriveWalletKeys(seed, phrase, ecc);
        const request = proto.SignUpRequest.encode({
          user: {
            userId: 0,
            userPk: walletKeys.pubkey,
            firstName: signUp.firstName,
            lastName: signUp.lastName,
            email: signUp.email,
            phoneNumber: "",
            birthdayYear,
            birthdayMonth,
            birthdayDay,
            timestampCreated: Long.ZERO,
            hasCreatedWallet: false,
          },
          sessionId: get().browserSession.sessionId ?? "",
        }).finish();
        await signedRequestRaw({
          path: "/signup",
          method: "PUT",
          ecc: ecc,
          seckey: walletKeys.seckey,
          pubkey: walletKeys.pubkey,
          payload: request,
        });
        await storeWalletKeys(walletKeys);
        set(state => {
          state.wallet.keys = walletKeys;
          state.signUp = initialSignUp;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    captchaLoadSiteKey: catchError(
      "Load Captcha",
      set,
      async () => {
        const response = await request({
          proto: proto.CaptchaSiteKeyResponse,
          path: "/captcha/site-key",
          method: "POST",
          data: proto.Empty.encode({}).finish(),
        });
        set(state => {
          state.signUp.captchaSiteKey = response.siteKey;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    signUpCaptcha: catchError(
      "Sign Up",
      set,
      async token => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const mnemonic = generateMnemonic();
        const seed = mnemonic.seed;
        const phrase = mnemonic.phrase;
        const keys = deriveWalletKeys(seed, phrase, ecc);
        await signedRequestRaw({
          path: "/captcha/signup",
          method: "PUT",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.SignUpCaptchaRequest.encode({
            captchaToken: token,
          }).finish(),
        });
        await storeWalletKeys(keys);
        await localforage.setItem("p2s:hasVerifiedEmail", true);
        await localforage.setItem("p2s:isProvisionalPk", true);
        set(state => {
          state.wallet.keys = {
            ...keys,
            isProvisionalPk: true,
          };
          state.verifyEmail.hasVerifiedEmail = true;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
