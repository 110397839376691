// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Cookies from "js-cookie";

export const USE_BROWSER_COOKIE = "useBrowerCookie";

export const setCookie = (name: string, value: string, seconds: number) => {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  Cookies.set(name, value, { expires: date });
};

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

export const isCookieExpired = (name: string): boolean => {
  const cookie = getCookie(name);
  return cookie === undefined;
};
