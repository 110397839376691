// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import debounce from "lodash/debounce";
import { DependencyList, useEffect } from "react";

const EVENT_TYPES = ["focus", "pageshow", "visibilitychange"];

export const useUpdateRegularly = (
  update: () => void,
  deps: DependencyList,
  timeMs: number = 60 * 1000 // default 60s
) => {
  useEffect(() => {
    // fetch immediately
    update();

    // update every {timeMs}
    const interval = setInterval(update, timeMs);
    return () => clearInterval(interval);
  }, deps);

  useEffect(() => {
    const debouncedUpdate = debounce(update, 100);

    // update when switching back to the page
    for (const eventType of EVENT_TYPES) {
      window.addEventListener(eventType, debouncedUpdate);
    }
    return () => {
      for (const eventType of EVENT_TYPES) {
        window.removeEventListener(eventType, debouncedUpdate);
      }
    };
  }, deps);
};
