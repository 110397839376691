// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useState, useEffect } from "react";
import { useLocation, Redirect, Route } from "react-router-dom";
import {
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonTabs,
} from "@ionic/react";

import { homeIcon, scanIcon, userIcon } from "../../icon/icon";
import { appRoutes } from "../router/routes";
import RoutePage from "../router/RoutePage";
import { useStore } from "../../store";

import "./BottomNavigation.scss";

const VISIBLE_ROUTES = ["/home", "/scan", "/profile", "/wallet"];

export const BottomNavigation: React.FC = () => {
  const isFullPageLoading = useStore(store => store.loading.isFullPageLoading);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isFullPageLoading) {
      setIsVisible(false);
    } else if (VISIBLE_ROUTES.includes(location.pathname)) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [location, isFullPageLoading]);

  return (
    <IonTabs className="app-wrapper">
      <IonTabBar
        id="main-tab"
        className="custom-tab"
        slot="bottom"
        style={isVisible ? { display: "flex" } : { display: "none" }}
      >
        <IonTabButton tab="home" href="/home">
          <img src={homeIcon} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="scan" href="/scan">
          <img src={scanIcon} />
          <IonLabel>Scan</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href="/profile">
          <img src={userIcon} />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
      <IonRouterOutlet animated={false}>
        {appRoutes.map((tab, index) => {
          return (
            <RoutePage
              key={index}
              path={tab.path}
              component={tab.component}
              exact
            />
          );
        })}
        <Route exact path="/">
          <Redirect to={"/home" + window.location.search} />
        </Route>
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </IonRouterOutlet>
    </IonTabs>
  );
};
