// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import * as PayPal from "@paypal/paypal-js";
import { requestAuthCode } from "../paypal/request";
import {
  buildSelfMintTx,
  AuthCodeData,
  processAuthCode,
} from "../wallet/selfMint";
import { chronik } from "../rpc/request";

export const PURCHASE_AMOUNT_PARAM = "amount";
export const AUTH_CODE_PARAM = "mintauth";

export interface SelfMintState {
  authCode: string | undefined;
  authCodeData: AuthCodeData | undefined;
}

export const initialSelfMint: SelfMintState = {
  authCode: undefined,
  authCodeData: undefined,
};

export interface SelfMintActions {
  selfMintReset: () => void;
  processAuthCode: (authCode: string) => Promise<void>;
  processOrderDetails: (
    orderDetails: PayPal.OrderResponseBody
  ) => Promise<void>;
  sendSelfMint: () => Promise<void>;
}

export function selfMintActions(set: StoreSet, get: StoreGet): SelfMintActions {
  return {
    selfMintReset: () => {
      set(state => {
        state.selfMint = initialSelfMint;
      });
    },
    processAuthCode: catchError(
      "Process AuthCode",
      set,
      async (authCode: string) => {
        if (!authCode) {
          throw new Error("Authorization Code is required");
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const authCodeData = processAuthCode(authCode);
        set(state => {
          state.selfMint.authCode = authCode;
          state.selfMint.authCodeData = authCodeData;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    processOrderDetails: catchError(
      "Process PayPal Order",
      set,
      async (orderDetails: PayPal.OrderResponseBody) => {
        set(state => {
          state.loading.isLoading = true;
          state.selfMint.authCode = undefined;
        });
        const authCodeResponse = await requestAuthCode(orderDetails.id, "get");
        console.log(authCodeResponse.authcode);
        set(state => {
          state.selfMint.authCode = authCodeResponse.authcode;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    sendSelfMint: catchError(
      "Send Self-Mint",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const authCodeData = get().selfMint.authCodeData;
        if (authCodeData === undefined) {
          throw "Invalid AuthCode";
        }
        const tx = await buildSelfMintTx({
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          pkh: keys.pkh,
          authCodeData,
        });
        await chronik().broadcastTx(tx);
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
