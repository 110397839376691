// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { Draft } from "immer";

import {
  initialAppInfo,
  appInfoActions,
  AppInfoActions,
  AppInfoState,
} from "./store/appInfo";
import {
  initialBrowserSession,
  browserSessionActions,
  BrowserSessionActions,
  BrowserSessionState,
} from "./store/browserSession";
import {
  initialProfile,
  profileActions,
  ProfileActions,
  ProfileState,
} from "./store/profile";
import {
  initialCheckin,
  checkinActions,
  CheckinActions,
  CheckinState,
} from "./store/checkin";
import {
  initialEventDetail,
  eventDetailActions,
  EventDetailActions,
  EventDetailState,
} from "./store/eventDetail";
import {
  initialEvents,
  eventsActions,
  EventsActions,
  EventsState,
} from "./store/events";
import {
  initialInstallPrompt,
  installPromptActions,
  InstallPromptActions,
  InstallPromptState,
} from "./store/installPrompt";
import {
  initialManageEvent,
  manageEventActions,
  ManageEventActions,
  ManageEventState,
} from "./store/manageEvent";
import {
  initialManageEventCode,
  manageEventCodeActions,
  ManageEventCodeActions,
  ManageEventCodeState,
} from "./store/manageEventCode";
import {
  EventPayoutActions,
  eventPayoutActions,
  initialEventPayout,
  EventPayoutState,
} from "./store/eventPayout";
import {
  PaymentHistoryActions,
  paymentHistoryActions,
  initialPaymentHistory,
  PaymentHistoryState,
} from "./store/paymentHistory";
import {
  initialPayoutMethod,
  payoutMethodActions,
  PayoutMethodActions,
  PayoutMethodState,
} from "./store/payoutMethod";
import {
  ProofOfBurnActions,
  proofOfBurnActions,
  initialProofOfBurn,
  ProofOfBurnState,
} from "./store/proofOfBurn";
import {
  initialSearchUsers,
  SearchUsersActions,
  searchUsersActions,
  SearchUsersState,
} from "./store/searchUsers";
import {
  SelfMintActions,
  SelfMintState,
  initialSelfMint,
  selfMintActions,
} from "./store/selfMint";
import {
  StripeActions,
  StripeState,
  initialStripe,
  stripeActions,
} from "./store/stripe";
import {
  initialSignUp,
  signUpActions,
  SignUpActions,
  SignUpStore,
} from "./store/signup";
import {
  initialWallet,
  walletActions,
  WalletActions,
  WalletStore,
} from "./store/wallet";
import { initialWs, wsActions, WsActions, WsState } from "./store/ws";
import {
  ErrorActions,
  ErrorState,
  initialError,
  errorActions,
} from "./store/error";
import {
  LoadingState,
  initialLoading,
  LoadingActions,
  loadingActions,
} from "./store/loading";
import {
  LoginActions,
  LoginState,
  initialLogin,
  loginActions,
} from "./store/login";
import { initialSend, SendActions, sendActions, SendStore } from "./store/send";
import {
  initialVerifyEmail,
  verifyEmailActions,
  VerifyEmailActions,
  VerifyEmailState,
} from "./store/verifyEmail";
import {
  initialVerifyPhoneNumber,
  verifyPhoneNumberActions,
  VerifyPhoneNumberActions,
  VerifyPhoneNumberStore,
} from "./store/verifyPhoneNumber";
import {
  initialNewsletter,
  newsletterActions,
  NewsletterState,
  NewsletterActions,
} from "./store/newsletter";
import { initialPin, PinActions, pinActions, PinState } from "./store/pin";
import {
  initialWeb3Auth,
  web3authActions,
  Web3AuthActions,
  Web3AuthState,
} from "./store/web3auth";
import {
  ImagesState,
  ImagesActions,
  initialImages,
  imagesActions,
} from "./store/images";
import { CartState, CartActions, initialCart, cartActions } from "./store/cart";

export interface State {
  appInfo: AppInfoState;
  browserSession: BrowserSessionState;
  wallet: WalletStore;
  profile: ProfileState;
  eventPayout: EventPayoutState;
  paymentHistory: PaymentHistoryState;
  proofOfBurn: ProofOfBurnState;
  paymentMethod: PayoutMethodState;
  checkin: CheckinState;
  eventDetail: EventDetailState;
  events: EventsState;
  installPrompt: InstallPromptState;
  manageEvent: ManageEventState;
  manageEventCode: ManageEventCodeState;
  searchUsers: SearchUsersState;
  selfMint: SelfMintState;
  stripe: StripeState;
  signUp: SignUpStore;
  error: ErrorState;
  loading: LoadingState;
  login: LoginState;
  send: SendStore;
  verifyEmail: VerifyEmailState;
  verifyPhoneNumber: VerifyPhoneNumberStore;
  newsletter: NewsletterState;
  pin: PinState;
  web3auth: Web3AuthState;
  images: ImagesState;
  ws: WsState;
  cart: CartState;
}

export type Actions = AppInfoActions &
  BrowserSessionActions &
  WalletActions &
  ProfileActions &
  CheckinActions &
  EventDetailActions &
  EventsActions &
  InstallPromptActions &
  ManageEventActions &
  ManageEventCodeActions &
  EventPayoutActions &
  PaymentHistoryActions &
  PayoutMethodActions &
  ProofOfBurnActions &
  SearchUsersActions &
  SendActions &
  SelfMintActions &
  StripeActions &
  SignUpActions &
  ErrorActions &
  LoginActions &
  LoadingActions &
  VerifyEmailActions &
  VerifyPhoneNumberActions &
  NewsletterActions &
  PinActions &
  Web3AuthActions &
  ImagesActions &
  WsActions &
  CartActions;

export type Store = State & Actions;
export type StoreGet = () => Store;
export type StoreSet = (f: (state: Draft<State>) => void) => void;

export const useStore = create<Store, [["zustand/immer", never]]>(
  immer((set, get) => ({
    appInfo: initialAppInfo,
    ...appInfoActions(set),
    browserSession: initialBrowserSession,
    ...browserSessionActions(set, get),
    wallet: initialWallet,
    ...walletActions(set, get),
    profile: initialProfile,
    ...profileActions(set, get),
    paymentHistory: initialPaymentHistory,
    ...paymentHistoryActions(set, get),
    paymentMethod: initialPayoutMethod,
    ...payoutMethodActions(set, get),
    proofOfBurn: initialProofOfBurn,
    ...proofOfBurnActions(set, get),
    checkin: initialCheckin,
    ...checkinActions(set, get),
    eventDetail: initialEventDetail,
    eventPayout: initialEventPayout,
    ...eventPayoutActions(set, get),
    ...eventDetailActions(set, get),
    events: initialEvents,
    ...eventsActions(set, get),
    installPrompt: initialInstallPrompt,
    ...installPromptActions(set, get),
    manageEvent: initialManageEvent,
    ...manageEventActions(set, get),
    manageEventCode: initialManageEventCode,
    ...manageEventCodeActions(set, get),
    selfMint: initialSelfMint,
    ...selfMintActions(set, get),
    stripe: initialStripe,
    ...stripeActions(set, get),
    signUp: initialSignUp,
    ...signUpActions(set, get),
    send: initialSend,
    ...sendActions(set, get),
    error: initialError,
    ...errorActions(set),
    loading: initialLoading,
    ...loadingActions(set, get),
    login: initialLogin,
    ...loginActions(set, get),
    verifyEmail: initialVerifyEmail,
    ...verifyEmailActions(set, get),
    verifyPhoneNumber: initialVerifyPhoneNumber,
    ...verifyPhoneNumberActions(set, get),
    newsletter: initialNewsletter,
    ...newsletterActions(set, get),
    pin: initialPin,
    ...pinActions(set, get),
    searchUsers: initialSearchUsers,
    ...searchUsersActions(set, get),
    web3auth: initialWeb3Auth,
    ...web3authActions(set, get),
    images: initialImages,
    ...imagesActions(set, get),
    ws: initialWs,
    ...wsActions(set, get),
    cart: initialCart,
    ...cartActions(set, get),
  }))
);
