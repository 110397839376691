// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { CashAddress, hash160 } from "./ffi";
import { TOKEN_PREFIX } from "./settings";

export type AddressType = "P2PKH" | "P2SH";

export function encodeEtokenAddress(
  addr_type: AddressType,
  hash: Uint8Array
): string {
  const address = CashAddress.from_hash(TOKEN_PREFIX, addr_type, hash);
  const encoded = address.encode();
  address.free();
  return encoded;
}

export function encodeECashAddress(
  addr_type: AddressType,
  hash: Uint8Array
): string {
  const address = CashAddress.from_hash("ecash", addr_type, hash);
  const encoded = address.encode();
  address.free();
  return encoded;
}

export function decodeEtokenAddress(str: string): Uint8Array {
  const address = CashAddress.decode(str);
  const script = address.script();
  address.free();
  return script;
}

export function isValidEtokenAddress(str: string): boolean {
  if (!str.startsWith(TOKEN_PREFIX)) {
    return false;
  } else {
    try {
      decodeEtokenAddress(str);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export function etokenAddressFromPk(pubkey: Uint8Array): string {
  const pkh = hash160(pubkey);
  return encodeEtokenAddress("P2PKH", pkh);
}
