// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import * as proto from "../rpc/p2s";
import { signedRequest } from "../rpc/request";

export interface NewsletterState {
  // has decided to subscribe to the newsletter or not
  hasDecided: boolean;
}

export interface NewsletterActions {
  subscribeNewsletter: () => Promise<void>;
  checkNewsletterStatus: () => Promise<void>;
  setHasDecidedAboutNewsletter: (hasDecided: boolean) => Promise<void>;
}

export const initialNewsletter: NewsletterState = {
  hasDecided: false,
};

export function newsletterActions(
  set: StoreSet,
  get: StoreGet
): NewsletterActions {
  return {
    setHasDecidedAboutNewsletter: catchError(
      "Save Email Newsletter",
      set,
      async (hasDecided: boolean) => {
        set(state => {
          state.newsletter.hasDecided = hasDecided;
        });
        await localforage.setItem("p2s:hasDecidedAboutNewsletter", hasDecided);
      }
    ),
    subscribeNewsletter: catchError(
      "Subscribe to Email Newsletter",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        await signedRequest({
          proto: proto.Empty,
          path: "/newsletter/subscribe",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.Empty.encode({}).finish(),
        });
        set(state => {
          state.newsletter.hasDecided = true;
        });
        await localforage.setItem("p2s:hasDecidedAboutNewsletter", true);
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    checkNewsletterStatus: catchError(
      "Email newsletter",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const response = await signedRequest({
          proto: proto.NewsletterSubscriptionStatusResponse,
          path: "/newsletter/status",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.Empty.encode({}).finish(),
        });
        // skip false because we don't know if user has decided yet
        if (!response.isSubscribed) {
          return;
        }
        set(state => {
          state.newsletter.hasDecided = true;
        });
        await localforage.setItem("p2s:hasDecidedAboutNewsletter", true);
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
