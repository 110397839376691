// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { Draft } from "immer";

import get from "lodash/get";
import type { State, StoreSet } from "../store";

export interface ErrorState {
  message: string | undefined;
  code: string | undefined;
  title: string | undefined;
  isNetworkError: boolean;
}

export const initialError: ErrorState = {
  message: undefined,
  code: undefined,
  title: undefined,
  isNetworkError: false,
};

export interface ErrorActions {
  errorReset: () => void;
}

export function errorActions(set: StoreSet): ErrorActions {
  return {
    errorReset: () => {
      set(state => {
        state.error = initialError;
      });
    },
  };
}

export function catchError<T extends unknown[], R>(
  title: string,
  set: StoreSet,
  f: (...args: T) => R | Promise<R>,
  fFinally: ((state: Draft<State>) => void) | undefined = undefined,
  resetError: boolean = true
): (...args: T) => Promise<R> {
  return async (...args: T) => {
    if (resetError) {
      set(state => {
        state.error.isNetworkError = false;
        state.error.title = undefined;
        state.error.message = undefined;
        state.error.code = undefined;
      });
    }
    try {
      const result = f(...args);
      if (result instanceof Promise) {
        await result;
      }
      fFinally && set(fFinally);
      return result;
    } catch (error) {
      const errorMessage = errorToString(error);
      set(state => {
        state.error.isNetworkError = errorMessage === "Network Error";
        state.error.code = get(error, "cause.code", undefined);
        state.error.title = title;
        state.error.message = errorMessage;
      });
      fFinally && set(fFinally);
      throw error;
    }
  };
}

export function errorToString(error: unknown): string {
  if (typeof error === "string") {
    return error;
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return JSON.stringify(error);
  }
}
