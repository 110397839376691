// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import * as proto from "../rpc/p2s";
import * as tx from "./tx";
import { toHex } from "./hex";
import { DUST_AMOUNT, TOKEN_DATA, TOKEN_FACTOR_USD } from "./settings";
import { WalletKeys } from "../store/wallet";
import { Ecc, sign_tx, slp_escrow_script } from "./ffi";
import { sendOpreturn } from "./send";

export interface EventPayoutSummary {
  totalHostPayoutUsd: number;
  totalUnredeemedToken: Long;
  entries: EventPayoutSummaryEntry[];
}

export interface EventPayoutSummaryEntry {
  user: proto.EventPayoutUser;
  unredeemedToken: Long;
  unredeemedUsd: number;
  redeemedToken: Long;
  redeemedUsd: number;
}

export function calcEventPayoutSummary(
  payouts: proto.EventPayout[],
  payoutsWalletless: proto.EventPayoutWalletless[],
  payoutUsers: proto.EventPayoutUser[],
  hostPkHex: string,
  hostUserId: number
): EventPayoutSummary {
  const scriptToUserMap = new Map(
    payoutUsers.map(user => [toHex(user.recipientScript), user])
  );
  const redeemedPayout = new Map<number, Long>();
  const unredeemedPayout = new Map<number, Long>();
  let totalHostPayoutToken = Long.ZERO;
  let totalUnredeemedToken = Long.ZERO;
  for (const payout of payouts) {
    for (let idx = 0; idx < payout.recipientScripts.length; ++idx) {
      const recipientScript = toHex(payout.recipientScripts[idx]);
      const user = scriptToUserMap.get(recipientScript);
      const tokenAmount = payout.escrowTokenAmounts[idx];

      if (user === undefined) {
        console.warn("Ignored unknown recipient script:", recipientScript);
        continue;
      }

      if (payout.isRedeemed) {
        const currentRedeemed = redeemedPayout.get(user.userId) ?? Long.ZERO;
        redeemedPayout.set(user.userId, currentRedeemed.add(tokenAmount));
      } else {
        const currentUnredeemed =
          unredeemedPayout.get(user.userId) ?? Long.ZERO;
        unredeemedPayout.set(user.userId, currentUnredeemed.add(tokenAmount));
        totalUnredeemedToken = totalUnredeemedToken.add(tokenAmount);
      }

      if (user.userId == hostUserId) {
        totalHostPayoutToken = totalHostPayoutToken.add(tokenAmount);
      }
    }
  }
  for (const payout of payoutsWalletless) {
    const currentUnredeemed = unredeemedPayout.get(payout.userId) ?? Long.ZERO;
    unredeemedPayout.set(
      payout.userId,
      currentUnredeemed.add(payout.tokenAmount)
    );
    totalUnredeemedToken = totalUnredeemedToken.add(payout.tokenAmount);
    if (payout.userId == hostUserId) {
      totalHostPayoutToken = totalHostPayoutToken.add(payout.tokenAmount);
    }
  }
  const sortFn = (a: EventPayoutSummaryEntry, b: EventPayoutSummaryEntry) => {
    // always sort host first
    if (toHex(a.user.userPk) == hostPkHex) {
      return -1;
    }
    if (toHex(b.user.userPk) == hostPkHex) {
      return 1;
    }
    // then sort by unredeemed amount, descendingly
    if (a.unredeemedToken.notEquals(b.unredeemedToken)) {
      return b.unredeemedToken.sub(a.unredeemedToken).toNumber();
    }
    // then sort by redeemed amount, descendingly
    if (a.redeemedToken.notEquals(b.redeemedToken)) {
      return b.redeemedToken.sub(a.redeemedToken).toNumber();
    }
    // then sort by user ID, ascendingly
    return a.user.userId - b.user.userId;
  };
  const toUsd = (tokenAmount: Long) => {
    return tokenAmount.toNumber() / TOKEN_FACTOR_USD;
  };
  return {
    totalHostPayoutUsd: toUsd(totalHostPayoutToken),
    totalUnredeemedToken,
    entries: payoutUsers
      .filter(
        user =>
          (redeemedPayout.get(user.userId) ??
            unredeemedPayout.get(user.userId)) !== undefined
      )
      .map<EventPayoutSummaryEntry>(user => {
        const redeemedToken = redeemedPayout.get(user.userId) ?? Long.ZERO;
        const unredeemedToken = unredeemedPayout.get(user.userId) ?? Long.ZERO;
        return {
          user,
          redeemedToken,
          redeemedUsd: toUsd(redeemedToken),
          unredeemedToken,
          unredeemedUsd: toUsd(unredeemedToken),
        };
      })
      .sort(sortFn),
  };
}

export function buildSlpPayoutTx(params: {
  ecc: Ecc;
  keys: WalletKeys;
  tokenId: string;
  guestPk: Uint8Array;
  hostPk: Uint8Array;
  isGuestRedeeming: boolean;
  payout: proto.EventPayout;
}) {
  const tokenData = TOKEN_DATA.get(params.tokenId)!;
  const recipientOutputs: tx.TxOutput[] = params.payout.recipientScripts.map(
    script => ({
      value: Long.fromNumber(DUST_AMOUNT),
      script,
    })
  );
  const recipientOutputsProto = tx.TxOutputs.encode({
    outputs: recipientOutputs,
  }).finish();
  const escrowRedeemScript = slp_escrow_script(
    params.payout.escrowPk,
    params.guestPk,
    params.hostPk,
    params.tokenId,
    tokenData.tokenProtocol,
    recipientOutputsProto,
    0x41
  );
  const txRequest: tx.Tx = {
    version: 1,
    inputs: [
      {
        prevOut: {
          txid: params.payout.escrowTxid,
          outIdx: params.payout.escrowOutIdx,
        },
        script: new Uint8Array(),
        sequence: 0xffff_ffff,
        signData: <tx.SignData>{
          fields: [
            <tx.SignField>{
              redeemScript: escrowRedeemScript,
            },
            <tx.SignField>{
              value: params.payout.escrowOutputSats,
            },
          ],
          slpEscrow: {
            sigHashType: 0x41,
            escrowMsg: params.payout.escrowMsg,
            escrowSig: params.payout.escrowSig,
            isAlice: params.isGuestRedeeming,
            covenantSk: params.keys.seckey,
          },
        },
      },
    ],
    outputs: [
      {
        value: Long.ZERO,
        script: sendOpreturn(
          params.tokenId,
          tokenData.tokenProtocol,
          params.payout.escrowTokenAmounts
        ),
      },
      ...recipientOutputs,
    ],
    lockTime: 0,
  };
  return sign_tx(tx.Tx.encode(txRequest).finish(), params.ecc, 0, 0);
}
