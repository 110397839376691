// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import * as proto from "../rpc/p2s";
import { signedRequest } from "../rpc/request";

export const PAYMENT_PAYOUT_METHOD_ID_PARAM = "payout_method";
export const PAYMENT_PAYOUT_METHOD_TYPE_PARAM = "payout_type";
export const PAYMENT_AMOUNT_PARAM = "amount";

export interface PayoutMethodState {
  payoutMethods: proto.PayoutMethod[] | undefined;
}

export interface PayoutMethodActions {
  payoutMethodsFetch: () => Promise<void>;
  createPaymentMethod: (
    paymentMethodUSBank: proto.PayoutDataUsBank
  ) => Promise<void>;
  updatePaymentMethod: (
    payoutMethodId: number,
    payoutData: proto.PayoutData
  ) => Promise<void>;
  deletePayoutMethod: (payoutMethodId: number) => Promise<void>;
}

export const initialPayoutMethod: PayoutMethodState = {
  payoutMethods: undefined,
};

export function payoutMethodActions(
  set: StoreSet,
  get: StoreGet
): PayoutMethodActions {
  return {
    payoutMethodsFetch: catchError(
      "Payment Methods",
      set,
      async () => {
        set(store => {
          store.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const response = await signedRequest({
          proto: proto.PayoutMethodsResponse,
          path: "/payout-method/read",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });
        set(state => {
          state.paymentMethod.payoutMethods = response.payoutMethods.sort(
            (a, z) => z.timestampCreated.subtract(a.timestampCreated).toNumber()
          );
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    createPaymentMethod: catchError(
      "Create Payment Method",
      set,
      async (payoutDataUsBank: proto.PayoutDataUsBank) => {
        set(store => {
          store.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.CreatePayoutMethodRequest.encode({
          payoutData: {
            recipientId: "",
            paypal: undefined,
            usBank: payoutDataUsBank,
          },
        }).finish();

        await signedRequest({
          proto: proto.Empty,
          path: "/payout-method/create",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    updatePaymentMethod: catchError(
      "Update Payment Method",
      set,
      async (payoutMethodId: number, payoutData: proto.PayoutData) => {
        set(store => {
          store.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.UpdatePayoutMethodRequest.encode({
          payoutMethodId: payoutMethodId,
          payoutData,
        }).finish();

        await signedRequest({
          proto: proto.Empty,
          path: "/payout-method/update",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    deletePayoutMethod: catchError(
      "Delete Payment Method",
      set,
      async (payoutMethodId: number) => {
        set(store => {
          store.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        await signedRequest({
          proto: proto.Empty,
          path: "/payout-method/delete",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.DeletePayoutMethodRequest.encode({
            payoutMethodId: payoutMethodId,
          }).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
