// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";
import { getUnixTime } from "date-fns";

import type { StoreGet, StoreSet } from "../store";
import * as proto from "../rpc/p2s";
import { signedRequest, signedRequestRaw } from "../rpc/request";
import { catchError } from "./error";
import { CreateEventForm } from "../pages/manage-event/ManageEventPage";

export type Role =
  | "CoHost"
  | "Accountant"
  | "Partner"
  | "SecurityPersonnel"
  | "Promoter";

export interface ManageEventState {
  details: proto.EventDetails | undefined;
  codes: proto.CodeDetails[];
  teamMembers: proto.TeamMember[];
}

export interface ManageEventActions {
  createEventRequest: (templateEventId: number | undefined) => Promise<number>;
  generateCodeRequest: (
    dataGenerateCode: proto.GenerateCodeRequest
  ) => Promise<string>;
  deleteCodeRequest: (eventId: number, code: string) => Promise<void>;
  updateEventDetailsRequest: (
    eventId: number,
    eventForm: CreateEventForm
  ) => Promise<void>;
  manageEventReset: () => void;
  eventDataRequest: (eventId: number) => Promise<void>;
  addTeamMemberRequest: (
    eventId: number,
    memberId: number,
    role: Role
  ) => Promise<void>;
  removeTeamMemberRequest: (eventId: number, memberId: number) => Promise<void>;
}

export const initialManageEvent: ManageEventState = {
  details: undefined,
  codes: [],
  teamMembers: [],
};

export function manageEventActions(
  set: StoreSet,
  get: StoreGet
): ManageEventActions {
  return {
    manageEventReset: () => {
      set(state => {
        state.manageEvent = initialManageEvent;
      });
    },
    createEventRequest: async (templateEventId: number | undefined) => {
      const ecc = get().wallet.ecc!;
      const keys = get().wallet.keys!;
      const request: proto.CreateEventRequest = {
        templateEventId: templateEventId ?? 0,
      };
      set(state => {
        state.manageEvent = initialManageEvent;
      });
      const response = await signedRequest({
        proto: proto.CreateEventResponse,
        path: "/event/manage/create",
        method: "POST",
        ecc,
        seckey: keys.seckey,
        pubkey: keys.pubkey,
        payload: proto.CreateEventRequest.encode(request).finish(),
      });
      return response.eventId;
    },
    generateCodeRequest: catchError(
      "Generate Code Request",
      set,
      async (dataGenerateCode: proto.GenerateCodeRequest) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(store => {
          store.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.GenerateCodeResponse,
          path: "/event/manage/code/generate",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.GenerateCodeRequest.encode(dataGenerateCode).finish(),
        });
        return response.code;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    deleteCodeRequest: catchError(
      "Delete Code Request",
      set,
      async (eventId: number, code: string) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.DeleteCodeRequest = {
          eventId,
          code,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        await signedRequestRaw({
          path: "/event/manage/code/delete",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.DeleteCodeRequest.encode(request).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDataRequest: catchError(
      "Fetch Event",
      set,
      async (eventId: number) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.EventDataRequest = {
          eventId,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventDataResponse,
          path: "/event/manage/read",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventDataRequest.encode(request).finish(),
        });
        set(store => {
          store.manageEvent.details = response.eventData!.details;
          store.manageEvent.teamMembers = response.eventData!.teamMembers;
          store.manageEvent.codes = response.eventData!.codes;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    updateEventDetailsRequest: catchError(
      "Update Event",
      set,
      async (eventId: number, eventForm: CreateEventForm) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const eventDetails: proto.EventDetails = {
          title: eventForm.eventName,
          bannerUrl: "",
          locationAddress: eventForm.eventLocation,
          dateTime: Long.fromNumber(
            getUnixTime(new Date(eventForm.dateTimeEvent))
          ),
          duration: 24 * 3600, // --> default 24h
          description: eventForm.description,
          priceData: {
            priceFlat: eventForm.priceFlatUsd
              ? Long.fromNumber(eventForm.priceFlatUsd * 100)
              : Long.ZERO,
            pricePerHour: eventForm.pricePerHourUsd
              ? Long.fromNumber(eventForm.pricePerHourUsd * 100)
              : Long.ZERO,
            priceUpchargeCash: Long.ZERO,
          },
          isCashAllowed: eventForm.isCashAllowed,
          isPreauthAllowed: eventForm.isPreauthAllowed,
          isAppRequired: eventForm.isAppRequired,
          minHoursUpfront: eventForm.minHoursUpfront || 0,
          currency: "USD",
          autoGuestAffiliatePer1000: -1,
          tags: [],
        };
        const request: proto.UpdateEventDetailsRequest = {
          eventId,
          eventDetails,
        };
        await signedRequestRaw({
          path: "/event/manage/update",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.UpdateEventDetailsRequest.encode(request).finish(),
        });
      }
    ),
    addTeamMemberRequest: catchError(
      "Add Doorman",
      set,
      async (eventId: number, memberId: number, role: Role) => {
        if (role !== "SecurityPersonnel") {
          throw "Not implemented";
        }
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.AddDoormanRequest = {
          eventId,
          doormanId: memberId,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        await signedRequestRaw({
          path: "/event/manage/doorman/add",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.AddDoormanRequest.encode(request).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    removeTeamMemberRequest: catchError(
      "Remove Team Member",
      set,
      async (eventId: number, memberId: number) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.RemoveDoormanRequest = {
          eventId,
          doormanId: memberId,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        await signedRequestRaw({
          path: "/event/manage/doorman/remove",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.RemoveDoormanRequest.encode(request).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
