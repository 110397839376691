// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonBackButton, IonButton, IonButtons, IonIcon } from "@ionic/react";
import React, { ReactNode } from "react";
import { chevronBack } from "ionicons/icons";

import { primaryLogo } from "../../icon/icon";
import { useHistory } from "react-router";
import { useStore } from "../../store";
import LoadingHeader from "../loading-header/LoadingHeader";

import "./Header.scss";

interface Props {
  children?: ReactNode;
  backButton?: {
    text?: string;
    defaultHref: string;
    onClicked?: React.MouseEventHandler<HTMLIonButtonElement>;
  };
  hideLogo?: boolean;
}

const Header: React.FC<Props> = props => {
  const history = useHistory();
  const hasCreatedKey = useStore(store => store.wallet.keys !== undefined);
  const hasVerifiedEmail = useStore(
    store => store.verifyEmail.hasVerifiedEmail
  );

  const goHome = () => {
    if (hasCreatedKey && hasVerifiedEmail) {
      history.replace("/home");
    } else {
      window.location.reload();
    }
  };
  return (
    <LoadingHeader
      mode="ios"
      className="header-component no-border"
      hasSafeArea={true}
      suspenseLoadingPosition="bottom"
    >
      <div className="no-border header-wrap">
        {props.backButton && (
          <IonButtons className="button-back">
            {props.backButton.onClicked ? (
              <IonButton
                mode="ios"
                className="btn-back-custom"
                onClick={props.backButton.onClicked}
                color="primary"
                fill="clear"
              >
                <IonIcon
                  slot="start"
                  className="icon-back"
                  icon={chevronBack}
                />
                {props.backButton.text}
              </IonButton>
            ) : (
              <IonBackButton
                defaultHref={props.backButton?.defaultHref}
                text={props.backButton?.text}
              />
            )}
          </IonButtons>
        )}
        <div>{props.children}</div>
        {!props.hideLogo && (
          <div className="logo-inner" onClick={goHome}>
            <img src={primaryLogo} />
          </div>
        )}
      </div>
    </LoadingHeader>
  );
};

export default Header;
