// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";
import uniqBy from "lodash/uniqBy";
import { Base64 } from "js-base64";

import type { StoreGet, StoreSet } from "../store";
import { signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { catchError } from "./error";
import { buildBurnTx } from "../wallet/burn";
import {
  FEE_PER_KB,
  PROOF_OF_BURN_FEE_PER1000,
  TOKEN_FACTOR_CENT,
  TOKEN_DATA,
} from "../wallet/settings";
import { script_p2pkh, sha256 } from "../wallet/ffi";
import { toHex, toHexRev } from "../wallet/hex";
import { signMessage } from "../wallet/signMessage";

export const BURN_AMOUNT_PARAM = "amount";

export interface RefundablePurchases {
  purchases: proto.RefundablePurchase[];
  maxAmountCent: Long;
}

export interface ProofOfBurnState {
  refundable: RefundablePurchases | undefined;
  successRefundIds: string[] | undefined;
  successPayoutIds: string[] | undefined;
  isProcessing: boolean;
  payoutCurrentHistoryPage: number;
  payoutHistory: proto.ProofOfBurnPayout[] | undefined;
}

export interface ProofOfBurnActions {
  refundReset: () => void;
  refundablePurchasesFetch: () => Promise<void>;
  refundPurchases: (tokenAmount: Long) => Promise<void>;
  proofOfBurnPayoutRequest: (
    tokenAmount: Long,
    payoutMethodId: number
  ) => Promise<void>;
  proofOfBurnPayoutHistoryRequest: (
    isInit: boolean,
    page: number
  ) => Promise<void>;
}

export const initialProofOfBurn: ProofOfBurnState = {
  refundable: undefined,
  successRefundIds: undefined,
  successPayoutIds: undefined,
  isProcessing: false,
  payoutCurrentHistoryPage: 0,
  payoutHistory: undefined,
};

export function proofOfBurnActions(
  set: StoreSet,
  get: StoreGet
): ProofOfBurnActions {
  return {
    refundReset: () => {
      set(state => {
        state.proofOfBurn = initialProofOfBurn;
      });
    },
    refundablePurchasesFetch: catchError(
      "Process Payment",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const refundablePurchases = await signedRequest({
          proto: proto.RefundablePurchasesResponse,
          path: "/proof-of-burn/refundable-purchases",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });
        let sumCent = Long.ZERO;
        for (const purchase of refundablePurchases.refundablePurchases) {
          sumCent = sumCent.add(purchase.refundableAmountCent);
        }
        const maxAmountCent = sumCent
          .mul(1000)
          .div(1000 - PROOF_OF_BURN_FEE_PER1000);
        console.log(
          "refundablePurchases",
          refundablePurchases,
          "max refundable:",
          maxAmountCent.toNumber()
        );
        set(state => {
          state.proofOfBurn.refundable = {
            purchases: refundablePurchases.refundablePurchases,
            maxAmountCent,
          };
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    refundPurchases: catchError(
      "Process Payment",
      set,
      async (burnTokenAmount: Long) => {
        set(state => {
          state.loading.isLoading = true;
          state.proofOfBurn.isProcessing = true;
        });
        const refundable = get().proofOfBurn.refundable;
        if (refundable === undefined) {
          return;
        }
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const tokenId = get().wallet.tokenId;
        const tokenData = TOKEN_DATA.get(tokenId)!;
        const burnAmountCent = burnTokenAmount.div(TOKEN_FACTOR_CENT);
        const feePer1000 = 10;
        const burnFeeCent = burnAmountCent.mul(feePer1000).div(1000);
        const burnPayoutCent = burnAmountCent.sub(burnFeeCent);

        let refundAmountCentSum = Long.ZERO;
        const refundPurchaseIds = [];
        for (const purchase of refundable.purchases) {
          refundAmountCentSum = refundAmountCentSum.add(
            purchase.refundableAmountCent
          );
          refundPurchaseIds.push(purchase.purchaseId);
          if (refundAmountCentSum.gte(burnPayoutCent)) {
            break;
          }
        }

        const burnTx = await buildBurnTx({
          ecc,
          keys,
          tokenId,
          tokenProtocol: tokenData.tokenProtocol,
          amount: burnTokenAmount,
          leftoverScript: script_p2pkh(keys.pkh),
          feePerKb: FEE_PER_KB,
          postageAllowed: false,
        });
        const txidBytes = sha256(sha256(burnTx));
        const txid = toHexRev(txidBytes);
        const signature = Base64.fromUint8Array(
          signMessage(ecc, keys.seckey, txid)
        );

        console.log("burnTx", toHex(burnTx));
        console.log("signature", signature);
        console.log("refundPurchaseIds", refundPurchaseIds);

        const burnRequest = proto.SubmitProofOfBurnRequest.encode({
          payoutMethodId: 0,
          burnRawTx: burnTx,
          signature,
          refundPurchaseIds: refundPurchaseIds,
          isSimulation: false,
        }).finish();

        const burnResponse = await signedRequest({
          proto: proto.SubmitProofOfBurnResponse,
          path: "/proof-of-burn/submit",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: burnRequest,
        });
        console.log("burnResponse", burnResponse);
        set(state => {
          state.proofOfBurn.successRefundIds = burnResponse.refundIds;
          state.proofOfBurn.isProcessing = false;
        });
      },
      state => {
        state.loading.isLoading = false;
        state.proofOfBurn.isProcessing = false;
      }
    ),
    proofOfBurnPayoutRequest: catchError(
      "Process Payment",
      set,
      async (burnTokenAmount: Long, payoutMethodId: number) => {
        set(state => {
          state.loading.isLoading = true;
          state.proofOfBurn.isProcessing = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const tokenId = get().wallet.tokenId;
        const tokenData = TOKEN_DATA.get(tokenId)!;

        const burnTx = await buildBurnTx({
          ecc,
          keys,
          tokenId,
          tokenProtocol: tokenData.tokenProtocol,
          amount: burnTokenAmount,
          leftoverScript: script_p2pkh(keys.pkh),
          feePerKb: FEE_PER_KB,
          postageAllowed: false,
        });
        const txidBytes = sha256(sha256(burnTx));
        const txid = toHexRev(txidBytes);
        const signature = Base64.fromUint8Array(
          signMessage(ecc, keys.seckey, txid)
        );

        console.log("burnTx", toHex(burnTx));
        console.log("signature", signature);
        console.log("payoutMethodId", payoutMethodId);

        const burnRequest = proto.SubmitProofOfBurnRequest.encode({
          payoutMethodId: payoutMethodId,
          burnRawTx: burnTx,
          signature,
          refundPurchaseIds: [],
          isSimulation: false,
        }).finish();

        const burnResponse = await signedRequest({
          proto: proto.SubmitProofOfBurnResponse,
          path: "/proof-of-burn/submit",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: burnRequest,
        });
        console.log("burnResponse", burnResponse);
        set(state => {
          state.proofOfBurn.successPayoutIds = burnResponse.payoutIds;
          state.proofOfBurn.isProcessing = false;
        });
      },
      state => {
        state.loading.isLoading = false;
        state.proofOfBurn.isProcessing = false;
      }
    ),
    proofOfBurnPayoutHistoryRequest: catchError(
      "Proof Of Burn Payout History Request",
      set,
      async (isInit: boolean, page: number) => {
        const wallet = get().wallet;
        const request: proto.ProofOfBurnPayoutHistoryRequest = {
          page: Long.fromNumber(isInit ? 0 : page),
        };

        const response = await signedRequest({
          proto: proto.ProofOfBurnPayoutHistoryResponse,
          path: "/proof-of-burn/payout-history",
          method: "POST",
          ecc: wallet.ecc!,
          seckey: wallet.keys!.seckey,
          pubkey: wallet.keys!.pubkey,
          payload:
            proto.ProofOfBurnPayoutHistoryRequest.encode(request).finish(),
        });

        set(state => {
          state.proofOfBurn.payoutHistory = isInit
            ? response.payouts
            : uniqBy(
                [
                  ...(state.proofOfBurn.payoutHistory || []),
                  ...response.payouts,
                ],
                item => toHexRev(item.txid)
              );
          state.proofOfBurn.payoutCurrentHistoryPage = page;
        });
      },
      undefined
    ),
  };
}
