// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";
import { chronik } from "../rpc/request";
import { toHex } from "./hex";
import * as proto from "../rpc/p2s";
import { TOKEN_FACTOR_CENT } from "./settings";
import { SlpUtxo } from "./utxo";

export function calculateBalance(utxos: SlpUtxo[]): proto.Balance {
  let balance = Long.ZERO;
  for (const utxo of utxos) {
    balance = balance.add(utxo.slpAmount);
  }
  const balanceCent = balance.div(TOKEN_FACTOR_CENT);
  const balanceUsd = balanceCent.toNumber() / 100;
  return {
    usd: balanceUsd,
    cent: balanceCent,
    baseToken: balance,
  };
}

export const balanceListenToWs = async (
  walletFetchBalance: () => void,
  pkh: Uint8Array
) => {
  const ws = chronik().ws({
    onMessage: msg => {
      if (msg.type == "Tx") {
        walletFetchBalance();
      }
    },
  });
  await ws.waitForOpen();
  ws.subscribeToScript("p2pkh", toHex(pkh));
};
