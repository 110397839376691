// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonPage,
  IonToolbar,
} from "@ionic/react";

import { useStore } from "../../store";
import PadButtons from "../pad-buttons/PadButtons";
import PinDot from "../pin-dots/PinDots";
import Header from "../header/Header";
import {
  PIN_MAX_ATTEMPTS,
  PIN_BLOCK_TIME,
  PIN_CODE_LENGTH,
} from "../../store/pin";

import "./PinModal.scss";

export type ActionPinType = "CheckPin" | "SetupPin";

interface Props {
  action: ActionPinType;
  dismiss: () => void;
}

type PinModalStatus =
  | "Unlock"
  | "Incorrect"
  | "Setup"
  | "Confirm"
  | "Mismatch"
  | "Blocked";

const PinModal: React.FC<Props> = props => {
  const setWalletPinCode = useStore(store => store.setPinCode);
  const setTimestampPinBlocked = useStore(
    store => store.setTimestampPinBlocked
  );
  const unblockPin = useStore(store => store.unblockPin);
  const timestampPinBlocked = useStore(store => store.pin.timestampPinBlocked);
  const [status, setStatus] = useState<PinModalStatus>(
    props.action == "SetupPin"
      ? "Setup"
      : timestampPinBlocked === undefined
      ? "Unlock"
      : "Blocked"
  );
  const walletPinCode = useStore(store => store.pin.pinCode);
  const [enteredPin, setEnteredPin] = useState("");
  const [firstPinEntered, setFirstPinEntered] = useState("");
  const [numFailedAttempts, setNumFailedAttempts] = useState(0);
  const [blockedTimeRemaining, setBlockedTimeRemaining] = useState(0);

  const closePinModal = () => {
    setTimestampPinBlocked(undefined);
    props.dismiss();
  };

  const checkEnteredPin = (enteredPin: string) => {
    if (walletPinCode === enteredPin) {
      // valid PIN -> close this modal
      closePinModal();
      return;
    }
    // Invalid PIN -> advance attempts, or block
    setEnteredPin("");
    const newNumFailedAttempts = numFailedAttempts + 1;
    if (newNumFailedAttempts < PIN_MAX_ATTEMPTS) {
      setNumFailedAttempts(newNumFailedAttempts);
      setStatus("Incorrect");
    } else {
      const blockedUntil = Math.floor(Date.now() / 1000) + PIN_BLOCK_TIME;
      setTimestampPinBlocked(blockedUntil);
      setStatus("Blocked");
    }
  };

  const setupEnteredPin = (enteredPin: string) => {
    switch (status) {
      case "Setup":
      case "Mismatch": {
        setFirstPinEntered(enteredPin);
        setStatus("Confirm");
        break;
      }
      case "Confirm": {
        if (firstPinEntered === enteredPin) {
          setWalletPinCode(enteredPin);
          closePinModal();
        } else {
          setStatus("Mismatch");
          setFirstPinEntered("");
        }
        break;
      }
    }
    setEnteredPin("");
  };

  useEffect(() => {
    if (timestampPinBlocked !== undefined) {
      setStatus("Blocked");
      const updateBlockedStatus = () => {
        const now = Math.floor(Date.now() / 1000);
        const timeRemaining = timestampPinBlocked - now;
        if (timeRemaining <= 0) {
          setStatus("Unlock");
          setEnteredPin("");
          setFirstPinEntered("");
          setNumFailedAttempts(0);
          setTimestampPinBlocked(undefined);
          unblockPin();
          return;
        }
        setBlockedTimeRemaining(timeRemaining);
      };
      updateBlockedStatus();
      const blockedTimer = setInterval(() => {
        updateBlockedStatus();
      }, 1000);
      return () => clearInterval(blockedTimer);
    }
  }, [timestampPinBlocked]);

  const handlePadButton = (value: string) => {
    if (value == "Cancel") {
      return closePinModal();
    }
    if (value === "Del") {
      const newPin = enteredPin.substring(0, enteredPin.length - 1);
      return setEnteredPin(newPin);
    }
    const newPin = enteredPin + value;
    setEnteredPin(newPin);
    if (newPin.length == PIN_CODE_LENGTH) {
      // Timeout to let dot animation play
      const timeout = setTimeout(() => {
        switch (props.action) {
          case "CheckPin": {
            return checkEnteredPin(newPin);
          }
          case "SetupPin": {
            return setupEnteredPin(newPin);
          }
        }
      }, 200);
      return () => clearTimeout(timeout);
    }
  };

  const title = (() => {
    switch (status) {
      case "Unlock":
        return "Please enter your PIN";
      case "Incorrect":
        return "Incorrect PIN, try again";
      case "Setup":
        return "Create your PIN";
      case "Confirm":
        return "Re-type your PIN to confirm";
      case "Mismatch":
        return "PINs don't match, restart";
      case "Blocked":
        return "";
    }
  })();

  return (
    <IonPage className="pin-modal">
      <Header
        backButton={
          props.action == "SetupPin"
            ? {
                text: "Profile Detail",
                defaultHref: "",
                onClicked: closePinModal,
              }
            : undefined
        }
      />

      <IonContent fullscreen>
        <div className="pin-header">
          <div className="title">
            {status != "Blocked" ? (
              <>
                <div className="message">
                  <span>{title}</span>
                </div>
                <PinDot pinLength={enteredPin.length}></PinDot>
                {status === "Incorrect" && (
                  <p className="attempts-remaing">
                    Attempts remaning: {PIN_MAX_ATTEMPTS - numFailedAttempts}
                  </p>
                )}
              </>
            ) : (
              <div>
                <span className="message">
                  Try again in {blockedTimeRemaining}s
                </span>
              </div>
            )}
          </div>
        </div>
      </IonContent>

      <IonFooter mode="ios" className="calculator-container">
        <IonGrid className="ion-text-center ion-justify-content-center wrap-button">
          <PadButtons
            handleClick={handlePadButton}
            disabled={status == "Blocked"}
            hasCancelBtn={props.action == "SetupPin"}
          />
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default PinModal;
