// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { getPlatforms, isPlatform } from "@ionic/react";

import { useSupportsInstallPrompt } from "./useSupportsInstallPrompt";
import { useUserAgent } from "./useUserAgent";
import { isCookieExpired, USE_BROWSER_COOKIE } from "../cookieUtil";
import { useStore } from "../store";

export const usePlatforms = () => {
  const { userAgent, inAppType } = useUserAgent();
  const platforms = getPlatforms();
  const isSafariBrowser = inAppType.browser == "safari";
  const isPwa = platforms.includes("pwa");
  const isApp =
    platforms.includes("capacitor") ||
    platforms.includes("cordova") ||
    isPlatform("hybrid");
  const isIos = platforms.includes("ios");
  const isAndroid = platforms.includes("android");
  const isSupportsInstallPromptAndroid = useSupportsInstallPrompt();
  const canInstallPwa = useStore(store => store.installPrompt.canInstallPwa);

  // Blink is the rendering engine used by Chromium, and `beforeinstallprompt`
  // is only supported in Chromium-based browsers.
  const blinkEngineChromium = userAgent.engine.name === "Blink";

  const isBrowserAndroid =
    !isPlatform("hybrid") && !isPlatform("pwa") && isPlatform("android");

  const isBrowserIos =
    !isPlatform("hybrid") &&
    !isPlatform("pwa") &&
    (isPlatform("ios") || isPlatform("ipad"));

  return {
    isAndroid,
    isApp,
    isIos,
    isPwa,
    isSafariBrowser,
    isSupportsInstallPromptAndroid,
    isBrowserIos,
    isBrowserAndroid,
    hasInstalledPwa: isBrowserAndroid && blinkEngineChromium && canInstallPwa,
    mustInstallPwaAndroid:
      isBrowserAndroid &&
      isSupportsInstallPromptAndroid &&
      isCookieExpired(USE_BROWSER_COOKIE),
    mustInstallIosApp: isCookieExpired(USE_BROWSER_COOKIE) && isBrowserIos,
  };
};
