// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import { signedRequest } from "../rpc/request";
import { StoreGet, StoreSet } from "../store";
import { buildCheckinUri, ParsedCheckinUri } from "../wallet/checkin";
import { catchError } from "./error";
import { decode_base58, sha256 } from "../wallet/ffi";
import * as proto from "../rpc/p2s";

export interface CheckinState {
  guestCheckinUri: string | undefined;
  verifyType: string | undefined;
  userBeingVerified: proto.User | undefined;
  guests: proto.CheckinGuest[] | undefined;
  eventDetails: proto.EventDetails | undefined;
  checkinSuccessful: boolean;
  isCheckin: boolean;
  mustPay: boolean;
}

export const initialCheckin: CheckinState = {
  guestCheckinUri: undefined,
  verifyType: undefined,
  userBeingVerified: undefined,
  guests: undefined,
  eventDetails: undefined,
  checkinSuccessful: false,
  isCheckin: false,
  mustPay: false,
};

export interface CheckinActions {
  checkinBuildUri: (event: proto.Event) => void;
  checkinVerifyGuest: (
    parsedUri: ParsedCheckinUri,
    performCheckin: boolean,
    markAsPaid: boolean
  ) => Promise<void>;
  checkinReset: () => void;
  checkoutManually: (eventId: number, guestId: number) => Promise<void>;
}

export function checkinActions(set: StoreSet, get: StoreGet): CheckinActions {
  return {
    checkinReset: () => {
      set(state => {
        state.checkin = initialCheckin;
      });
    },
    checkinBuildUri: (event: proto.Event) => {
      const user = get().profile.user;
      const ecc = get().wallet.ecc!;
      const keys = get().wallet.keys!;
      if (event === undefined || user === undefined) {
        return;
      }
      const uri = buildCheckinUri({
        ecc: ecc,
        guestSk: keys.seckey,
        guestId: user.userId,
        eventId: event.eventId,
        isCheckin: event.guestState?.status != "CheckIn",
        isManualByDoorman: false,
      });
      console.log(uri);
      set(state => {
        state.checkin.guestCheckinUri = uri;
      });
    },
    checkinVerifyGuest: catchError(
      "Verify Guest",
      set,
      async (
        parsedUri: ParsedCheckinUri,
        performCheckin: boolean,
        markAsPaid: boolean
      ) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.checkin.checkinSuccessful = false;
          state.loading.isLoading = true;
        });
        let guestCheckinMsg: Uint8Array;
        try {
          guestCheckinMsg = decode_base58(parsedUri.msgBase58);
        } catch (ex) {
          throw "invalid checkin message base58: " + ex;
        }
        let parsedCheckinMsg: proto.GuestCheckinMsg;
        try {
          parsedCheckinMsg = proto.GuestCheckinMsg.decode(guestCheckinMsg);
        } catch (ex) {
          throw "invalid guest checkin message: " + ex;
        }
        let guestCheckinSig: Uint8Array;
        try {
          guestCheckinSig = decode_base58(parsedUri.sigBase58);
        } catch (ex) {
          throw "invalid checkin signature base58: " + ex;
        }
        const response = await signedRequest({
          proto: proto.CheckinResponse,
          path: "/event/checkin",
          method: "POST",
          ecc: ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.CheckinRequest.encode({
            guestCheckinMsg,
            guestCheckinSig,
            performCheckin,
            markAsPaid,
          }).finish(),
        });
        set(state => {
          state.checkin.checkinSuccessful =
            response.checkinPerformedSuccessfully;
          state.checkin.verifyType = response.verifyType;
          state.checkin.userBeingVerified = response.user;
          state.checkin.guests = response.guests;
          state.checkin.eventDetails = response.eventDetails;
          state.checkin.isCheckin = parsedCheckinMsg.isCheckin;
          state.checkin.mustPay = response.mustPay;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    checkoutManually: catchError(
      "Manual Checkout",
      set,
      async (eventId: number, guestId: number) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.loading.isLoading = true;
        });
        const timestampNumber = new Date().getTime() / 1000;
        const guestCheckinMsg = proto.GuestCheckinMsg.encode({
          guestId,
          eventId,
          timestamp: Long.fromInt(Math.round(timestampNumber)),
          isCheckin: false,
          isManualByDoorman: true,
        }).finish();
        const msgHash = sha256(guestCheckinMsg);
        const guestCheckinSig = ecc.schnorr_sign(keys.seckey, msgHash);
        const response = await signedRequest({
          proto: proto.CheckinResponse,
          path: "/event/checkin",
          method: "POST",
          ecc: ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.CheckinRequest.encode({
            guestCheckinMsg,
            guestCheckinSig,
            performCheckin: true,
            markAsPaid: false,
          }).finish(),
        });
        if (!response.checkinPerformedSuccessfully) {
          throw `Check out failed: ${response.verifyType}`;
        }
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
