// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";

export const PIN_MAX_ATTEMPTS = 3;
export const PIN_CODE_LENGTH = 6;
export const PIN_BLOCK_TIME = 60; // 60 seconds
export const PIN_IDLE_TIME_UNTIL_LOCK = 5 * 60; // 5 minutes

export interface PinState {
  pinCode: string | undefined;
  timestampPinBlocked: number | undefined;
}

export interface PinActions {
  pinLoad: () => Promise<void>;
  setPinCode: (pin: string) => Promise<void>;
  removePinCode: () => Promise<void>;
  setTimestampPinBlocked: (timestamp: number | undefined) => Promise<void>;
  unblockPin: () => Promise<void>;
}

export const initialPin: PinState = {
  pinCode: undefined,
  timestampPinBlocked: undefined,
};

export function pinActions(set: StoreSet, get: StoreGet): PinActions {
  return {
    pinLoad: async () => {
      let [pinCode, timestampPinBlocked] = await Promise.all([
        localforage.getItem<string>("p2s:pinCode"),
        localforage.getItem<number>("p2s:timestampPinBlocked"),
      ]);
      set(store => {
        store.pin.pinCode = pinCode || undefined;
        store.pin.timestampPinBlocked = timestampPinBlocked || undefined;
      });
    },
    setPinCode: async (pinCode: string) => {
      await localforage.setItem("p2s:pinCode", pinCode);
      set(state => {
        state.pin.pinCode = pinCode;
      });
    },
    removePinCode: async () => {
      await localforage.removeItem("p2s:pinCode");
      set(state => {
        state.pin.pinCode = undefined;
      });
    },
    setTimestampPinBlocked: async (timestamp: number | undefined) => {
      await localforage.setItem("p2s:timestampPinBlocked", timestamp);
      set(state => {
        state.pin.timestampPinBlocked = timestamp;
      });
    },
    unblockPin: async () => {
      await localforage.removeItem("p2s:timestampPinBlocked");
      set(state => {
        state.pin.timestampPinBlocked = undefined;
      });
    },
  };
}
