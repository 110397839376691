// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useState } from "react";
import { IonButton } from "@ionic/react";
import { useHistory } from "react-router";

import PasteClipboard from "../paste-cipboard/PasteClipboard";
import { useStore } from "../../store";
import { buildUrlParams } from "../../util";
import { EVENT_CODE_PARAM } from "../../store/eventDetail";
import { useExceptionToast } from "../../hook/useExceptionToast";

import "./EnterEventCode.scss";

const EnterEventCode: React.FC = () => {
  const history = useHistory();

  const [inviteCode, setInviteCode] = useState<string>("");
  const eventDetailFetchByCode = useStore(
    store => store.eventDetailFetchByCode
  );

  const sanitizeEventCode = (value: string): string => {
    return value
      .replace(/[^A-Za-z0-9]/g, "") // valid alphabet for invite codes is alphanumeric
      .replace(/1/g, "I") // Replace all 1 -> I
      .replace(/0/g, "O") // Replace all 0 -> O
      .toUpperCase();
  };

  const showExceptionToast = useExceptionToast();

  const gotoDetailPage = async (inviteCode: string) => {
    try {
      await eventDetailFetchByCode(inviteCode);
      history.push(
        buildUrlParams("/event/detail", { [EVENT_CODE_PARAM]: inviteCode })
      );
    } catch (error) {
      showExceptionToast(error);
    }
  };

  return (
    <div className="enter-event-code">
      <PasteClipboard
        labelInput="Invite code"
        onPasted={gotoDetailPage}
        setValue={setInviteCode}
        sanitizeValue={sanitizeEventCode}
      >
        <input
          className="invite-input"
          type="text"
          onChange={e => {
            setInviteCode(sanitizeEventCode(e.target.value));
          }}
          value={inviteCode}
          placeholder="e.g. ABC"
        />
      </PasteClipboard>
      <IonButton
        mode="md"
        onClick={() => gotoDetailPage(inviteCode)}
        className="primary-btn join-event-btn"
      >
        Join
      </IonButton>
    </div>
  );
};

export default EnterEventCode;
