// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { envVar } from "../util";
import { fromHex } from "./hex";
import { TokenProtocol } from "./send";

export interface TokenData {
  tokenProtocol: TokenProtocol;
}

export const CHRONIK_URL = envVar("REACT_APP_CHRONIK_URL");

export const BUX_TOKEN_ID =
  "7e7dacd72dcdb14e00a03dd3aff47f019ed51a6f1f4e4f532ae50692f62bc4e5";
export const CRD_TOKEN_ID = envVar("REACT_APP_TOKEN_ID");
export const MAIN_TOKEN_ID = CRD_TOKEN_ID;

export const TOKEN_PREFIX = "etokencrd";

export const TOKEN_DATA = new Map<string, TokenData>([
  [BUX_TOKEN_ID, { tokenProtocol: "Slp" }],
  [CRD_TOKEN_ID, { tokenProtocol: "Slpv2" }],
]);

export const TOKEN_DECIMALS = 4;
export const TOKEN_FACTOR_USD = Math.pow(10, TOKEN_DECIMALS);
export const TOKEN_FACTOR_CENT = Math.pow(10, TOKEN_DECIMALS - 2);

export const MINT_AUTH_V2_PUBKEY: Uint8Array = fromHex(
  envVar("REACT_APP_MINT_AUTH_V2_PUBKEY")
);

export const POSTAGE_FEE = 100;

export const DUST_AMOUNT = 546;

export const FEE_PER_KB = 1000;

export const PROOF_OF_BURN_FEE_PER1000 = 20;

export const P2S_ADDRESS = "etoken:qr0w2r6hvd3rwlwj7qc520qtkzgqnt90sy0grcj2wt";

// 5% fee for booking events with P2S
export const P2S_FEE_PER1000 = 50;

export const CURRENCY_SYMBOL = "CRD";

export const CURRENCY = {
  SYMBOL: "$",
  POSITION: "Front",
};
