/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "p2s";

export enum TokenProtocol {
  Slp = 0,
  Slpv2 = 1,
  UNRECOGNIZED = -1,
}

export function tokenProtocolFromJSON(object: any): TokenProtocol {
  switch (object) {
    case 0:
    case "Slp":
      return TokenProtocol.Slp;
    case 1:
    case "Slpv2":
      return TokenProtocol.Slpv2;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TokenProtocol.UNRECOGNIZED;
  }
}

export function tokenProtocolToJSON(object: TokenProtocol): string {
  switch (object) {
    case TokenProtocol.Slp:
      return "Slp";
    case TokenProtocol.Slpv2:
      return "Slpv2";
    case TokenProtocol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Empty {}

export interface SignedPayload {
  /** Pubkey this payload is signed for */
  pubkey: Uint8Array;
  /** Signature signing payload for pubkey */
  signature: Uint8Array;
  /** Payload that has been signed */
  payload: Uint8Array;
}

export interface SendTxsRequest {
  txs: Uint8Array[];
  tokenProtocol: TokenProtocol;
}

export interface SendTxsResponse {
  txids: Uint8Array[];
}

export interface NotifyBurnRequest {
  tx: Uint8Array;
}

export interface NotifyBurnResponse {
  txid: Uint8Array;
}

export interface GetBurnsResponse {
  burns: Burn[];
}

export interface SignUpRequest {
  user: User | undefined;
  sessionId: string;
}

export interface SignUpJwtRequest {
  jwt: string;
  sessionId: string;
  /** Pubkey of the user to retire in favor of creating a new user */
  retireUserPk: Uint8Array;
  /** Whether the user is loggin in from the old legacy network or the new one */
  loginMethod: string;
  /** Provisional public key of an existing user that should be upgraded to a full wallet */
  provisionalPk: Uint8Array;
  /** Byte string "LOGIN_PROVISIONAL_PK<JWT PK>" signed by the provisional secret key */
  provisionalSig: Uint8Array;
}

export interface SignUpJwtResponse {
  /**
   * true if the signup completed successfully, false if there's an
   * existing user with that email with a different public key
   */
  isSuccess: boolean;
  /** Pubkey of the existing conflicting user */
  existingPubkey: Uint8Array;
  /** Login method of the existing user ("SeedPhrase", "JwtTestnet") */
  existingLoginMethod: string;
}

export interface LoginRequest {
  sessionId: string;
}

export interface LoginResponse {
  exists: boolean;
  hasVerifiedEmail: boolean;
}

export interface SignUpResponse {}

export interface SignUpCaptchaRequest {
  /** Captcha token */
  captchaToken: string;
}

export interface CaptchaSiteKeyResponse {
  /** Captcha site key */
  siteKey: string;
}

export interface UpdateProfileRequest {
  user: User | undefined;
}

export interface SearchUsersRequest {
  /**
   * Query user by:
   * - etoken address
   * - email address
   * - first name & last name prefix
   */
  query: string;
}

export interface SearchUsersResponse {
  /**
   * Users that match the query, with only user_id, user_pk, first_name, last_name set.
   * If the query is an email, the email address is also added.
   */
  users: User[];
  /** Human readable message why there are no results. */
  message: string;
}

export interface FileUploadRequest {
  /**
   * "UserAvatar" for the user avatar (ignores event_id)
   * "EventBanner" for the event avatar
   */
  fileUsage: string;
  /** event_id if the file_usage is for an event, otherwise ignored. */
  eventId: number;
}

export interface FileUploadResponse {
  /** File ID */
  fileId: Uint8Array;
  /** Signed GCP URL allowing clients to upload files directly to the cloud */
  signedUploadUrl: string;
  /** File path in the GCP bucket */
  filePath: string;
}

export interface FileNotifyUploadedRequest {
  /** File ID */
  fileId: Uint8Array;
}

export interface FileNotifyUploadedResponse {}

export interface CreateEventRequest {
  /**
   * Which event to base this event on, to copy details over
   * Must be host of this event.
   * Set to 0 to create an empty event.
   */
  templateEventId: number;
}

export interface CreateEventResponse {
  /** ID of the created event */
  eventId: number;
}

export interface UpdateEventDetailsRequest {
  /** Id of the event to be updated */
  eventId: number;
  /** Event details to be updated */
  eventDetails: EventDetails | undefined;
}

export interface GenerateCodeRequest {
  /** ID of the event to create a code for */
  eventId: number;
  /**
   * Template code to derive from, this code will derive its properties from the template.
   * Empty if no template.
   */
  templateCode: string;
  /** Whether this code is a template to derive other codes from */
  isTemplate: boolean;
  /** Discount % of this code. */
  discountPer1000: number;
  /** How often this code can be used at most. 0 to set to unlimited. */
  maxUses: number;
}

export interface GenerateCodeResponse {
  /** Code generated by the backend */
  code: string;
}

export interface EventDataRequest {
  /** ID of the event to query */
  eventId: number;
}

export interface EventDataResponse {
  /** Data of the event */
  eventData: EventData | undefined;
}

export interface UpdateCodeRequest {
  /** ID of the event to update */
  eventId: number;
  /** Event code whose affiliates should be updated. */
  code: string;
  /**
   * List of affiliates to set for the code.
   * first_name and last_name are ignored.
   * Either affiliate_id or pk has to be set.
   */
  affiliates: AffiliateDetails[];
  /** Whether to update the code data */
  shouldUpdateCode: boolean;
  /** Replace the current code with this new one */
  newCode: string;
  /** Whether this code is a template to derive other codes from */
  isTemplate: boolean;
  /** Discount % of this code. */
  discountPer1000: number;
  /** How often this code can be used at most. 0 to set to unlimited. */
  maxUses: number;
}

export interface DeleteCodeRequest {
  /** ID of the event whose code should be deleted */
  eventId: number;
  /** Event code that should be deleted */
  code: string;
}

export interface AddDoormanRequest {
  /** ID of the event that should have a doorman added */
  eventId: number;
  /** User ID of the doorman to be added */
  doormanId: number;
}

export interface RemoveDoormanRequest {
  /** ID of the event that should have a doorman removed */
  eventId: number;
  /** User ID of the doorman to be removed */
  doormanId: number;
}

/** Create a PaymentIntent for a purchase for an event */
export interface CreatEventPaymentIntentRequest {
  /** Event cart to purchase */
  cart: EventPurchaseCart | undefined;
  /** How many tokens the user is going to burn to reduce the amount of the PaymentIntent */
  burnTokenAmount: Long;
  /** `true` if this payment should only reserve the funds and actually capture the funds later */
  isPreauth: boolean;
}

/** Response of creating a PaymentIntent for an event */
export interface CreatEventPaymentIntentResponse {
  clientSecret: string;
  paymentId: string;
}

/** User joins an event */
export interface JoinEventRequest {
  /** Cart of the purchase, if set takes priority over the legacy fields */
  cart: EventPurchaseCart | undefined;
  /**
   * Like `cart` but serialized as EventPurchaseCart; can be used interchangably
   * Required when using BURN tx to "pay" for tickets, the sha256 of this has to be committed to
   */
  cartProto: Uint8Array;
  /** Txs that pay for the event */
  txs: Uint8Array[];
  /** Which type of P2SH escrow this join uses, or "Walletless" */
  escrowType: string;
  /** Whether the guest is paying later at entry */
  isPayingLater: boolean;
  /** Payment processor to use (e.g. "Stripe"), or empty if not using a processor */
  processor: string;
  /** Payment ID from the payment processor, created with CreatEventPaymentIntentRequest */
  processorPaymentId: string;
  /** Whether to create an auto-affiliate for this guest */
  createAutoAffiliate: boolean;
  /**
   * List of protocols to send a event confirmation with
   * - "Email": Send via email to the user's email address
   */
  sendConfirmationVia: string[];
  /** Whether the guest is sharing their contact info with the host of the event */
  isSharingInfoWithHost: boolean;
  /**
   * Legacy fields, should use EventPurchaseCart
   * Event code for the event
   */
  eventCodeLegacy: string;
  /**
   * How many hours the user wants to book
   * (only applied to "PerHour" events)
   */
  numBookedHoursLegacy: number;
}

/** User successfully joined event */
export interface JoinEventResponse {
  txids: Uint8Array[];
  /** Affiliate code created automatically after joining, or empty if none. */
  autoAffiliateCode: string;
  /** ID of the payment in our DB */
  paymentId: number;
}

export interface EventPurchaseCart {
  /** Event code for the event */
  eventCode: string;
  /**
   * How many hours the user wants to book
   * (only applied to "PerHour" events)
   */
  numBookedHours: number;
  /** Items that are being purchased for this event */
  items: EventCartItem[];
}

/** A checkout item for an event to be purchased */
export interface EventCartItem {
  /** Which ticket type this payment is for, or -1 if no variant selected */
  ticketVariantId: Long;
  /** How many of this item have been bought */
  quantity: number;
  /** First name of the person on the ticket */
  firstName: string;
  /** Last name of the person on the ticket */
  lastName: string;
  /** Phone number of the person on the ticket */
  phoneNumber: string;
  /** Total price (in cents) paid for this item (blank if joining an event) */
  totalPrice: Long;
  /** / Price (in cents) of this item without discounts applied */
  totalPriceUndiscounted: Long;
  /** Flat price (in cents) paid for this item (blank if joining an event) */
  priceFlat: Long;
  /** Price per hour (in cents) paid for this item (blank if joining an event) */
  pricePerHour: Long;
}

/** Send a confirmation email for having joined an event */
export interface SendEventConfirmationEmailRequest {
  /** ID of the payment in our DB (must be by the user) */
  paymentId: number;
  /**
   * List of protocols to send a event confirmation with
   * - "Email": Send via email to the user's email address
   */
  sendConfirmationVia: string[];
  /** The email address to send to, or will default to the user's email address */
  email: string;
  /** Whether the email address should be stored for future use */
  shouldSaveEmail: boolean;
  /** Signed check-in URI to include in the email as QR code */
  checkinUri: string;
}

/** Request overview of the guests of an event */
export interface EventOverviewRequest {
  /** Event ID of the event */
  eventId: number;
}

/** Overview of the guests of an event */
export interface EventOverviewResponse {
  /** Overview of the guests of an event */
  guests: OverviewGuest[];
}

/** One guest for the event overview */
export interface OverviewGuest {
  /** User ID of the guest */
  userId: number;
  /** First name of the guest */
  firstName: string;
  /** Last name of the guest */
  lastName: string;
  /** Current state of the guest */
  guestState: GuestState | undefined;
  /** Event codes used by the guest */
  eventCodes: string[];
  /** Items the guest booked */
  items: OverviewGuestItem[];
}

export interface OverviewGuestItem {
  /** First name of the guest */
  firstName: string;
  /** Last name of the guest */
  lastName: string;
}

/**
 * Request an event to be paid out by signing the escrow.
 * After this, the user will no longer be able to check-in.
 */
export interface EventPayoutRequest {
  /** Event ID to be paid out */
  eventId: number;
  /** Whether we're actually performing the payout or just simulating it */
  isSimulation: boolean;
}

/** / Grant a privilege to a user */
export interface GrantPrivilegeRequest {
  /** / User that is being granted the privilege */
  grantee: number;
  /** / Privilege that is being granted to the user */
  privilege: string;
}

export interface PrivilegesResponse {
  privileges: Privilege[];
}

/** One escrow UTXO that is now paid out */
export interface EventPayout {
  /** txid of the escrow UTXO */
  escrowTxid: Uint8Array;
  /** out_idx of the escrow UTXO */
  escrowOutIdx: number;
  /** Network this UTXO is on */
  network: string;
  /** Token protocol of the UTXO */
  tokenProtocol: TokenProtocol;
  /** Token ID of the UTXO */
  tokenId: string;
  /** Token type of the UTXO */
  tokenType: number;
  /** Number of satoshis in the UTXO */
  escrowOutputSats: Long;
  /** Which escrow type has been used to lock up the funds */
  escrowType: string;
  /**
   * Signature by the escrow releasing the funds
   * Empty when simulating the payout
   */
  escrowSig: Uint8Array;
  /**
   * Message signed by the escrow to release the funds
   * Empty when simulating the payout
   */
  escrowMsg: Uint8Array;
  /** Token amounts paid out to guest and affiliates */
  escrowTokenAmounts: Long[];
  /** Scripts being paid out, equals guest + affiliates */
  recipientScripts: Uint8Array[];
  /** Pubkey used to sign the escrow */
  escrowPk: Uint8Array;
  /** Whether the tokens are already redeemed on-chain */
  isRedeemed: boolean;
}

export interface EventPayoutWalletless {
  userId: number;
  tokenAmount: Long;
  payoutId: Long;
}

/** Details about a user that got paid out */
export interface EventPayoutUser {
  /** Recipient script of the paid out user */
  recipientScript: Uint8Array;
  /** User ID of the paid out user */
  userId: number;
  /** Pubkey of the paid-out user */
  userPk: Uint8Array;
  /** First name of the paid out user */
  firstName: string;
  /** Last name of the paid out user */
  lastName: string;
}

/** Successfully paid out an event, or we successfully simulated it */
export interface EventPayoutResponse {
  /** Escrow UTXOs that are now paid-out */
  payouts: EventPayout[];
  /** Payouts that aren't using a token escrow */
  payoutsWalletless: EventPayoutWalletless[];
  /** For host payouts: Details about paid-out users. */
  payoutUsers: EventPayoutUser[];
}

/** Events joined by a user */
export interface JoinedEventsResponse {
  /** Events joined by the user */
  events: Event[];
}

/** Events hosted by a user */
export interface HostedEventsResponse {
  /** Events hosted by the user */
  events: Event[];
}

/** Events a user is affiliated with */
export interface AffiliatedEventsResponse {
  /** Events a user is affiliated with */
  events: Event[];
}

export interface PaymentHistoryStatusResponse {
  isSyncing: boolean;
}

export interface PaymentHistoryRequest {
  page: number;
}

export interface PaymentHistoryResponse {
  payments: Payment[];
}

export interface Payment {
  paymentType: string;
  deltaTokenAmount: Long;
  timestamp: Long;
  paymentId: number;
  mint?: PaymentVariantMint | undefined;
  event?: PaymentVariantEvent | undefined;
  burnRefund?: PaymentVariantBurnRefund | undefined;
  burnPayout?: PaymentVariantBurnPayout | undefined;
  userTransfer?: PaymentVariantUserTransfer | undefined;
}

export interface PaymentVariantMint {
  mintCode: string;
  priceCent: Long;
  refundedAmountCent: Long;
}

export interface PaymentVariantBurnRefund {
  amountCent: Long;
}

export interface PaymentVariantBurnPayout {
  amountCent: Long;
  status: string;
  payoutMethodId: number;
  payoutType: string;
  payoutRecipient: string;
}

export interface PaymentVariantEvent {
  eventTitle: string;
  /** First name of the host */
  hostFirstName: string;
  /** Last name of the host */
  hostLastName: string;
  isHost: boolean;
  isGuest: boolean;
  eventCode: string;
  eventDateTime: Long;
  /** Event price data for the event */
  priceData: EventPrice | undefined;
}

export interface PaymentVariantUserTransfer {
  participants: UserTransferParticipant[];
}

export interface UserTransferParticipant {
  userId: number;
  firstName: string;
  lastName: string;
  deltaTokenAmount: Long;
}

/** Message signed by the guest */
export interface GuestCheckinMsg {
  /** user_id of the guest signing this message */
  guestId: number;
  /** event_id the guest is trying to checkin to */
  eventId: number;
  /** UNIX timestamp this msg has been signed at */
  timestamp: Long;
  /** Whether the user is checking in (true) or out (false) */
  isCheckin: boolean;
  /**
   * Whether the check-in/out is done manually by a doorman (i.e. signed by doorman).
   * This is intended for the case where a guest forgot to check out at the door.
   */
  isManualByDoorman: boolean;
}

export interface CheckinGuest {
  /** / First name of the user */
  firstName: string;
  /** / Last name of the user */
  lastName: string;
  /** / Y/m/d of the birthday */
  birthdayYear: number;
  birthdayMonth: number;
  birthdayDay: number;
  /** / Avatar URL of the user, or empty if none */
  avatarUrl: string;
  quantity: number;
}

/** Perform checkin or verify the validity of a guest's checkin request */
export interface CheckinRequest {
  /** Serialized GuestCheckinMsg */
  guestCheckinMsg: Uint8Array;
  /** Signature by the guest */
  guestCheckinSig: Uint8Array;
  /** Whether to actually perform the checkin or just verify the guest */
  performCheckin: boolean;
  /** Whether to mark the payment still due for the event as paid */
  markAsPaid: boolean;
}

export interface CheckinResponse {
  /** Result of the verification */
  verifyType: string;
  /**
   * Whether the checkin has been performed successfully.
   * false if checkin invalid or doorman not performing checkin.
   */
  checkinPerformedSuccessfully: boolean;
  /** / User data */
  user: User | undefined;
  /** / All the guests that are checking in */
  guests: CheckinGuest[];
  /** Event details */
  eventDetails: EventDetails | undefined;
  /** Whether the guest still needs to pay during check-in */
  mustPay: boolean;
}

export interface EventRequest {
  /** Code of the event */
  eventCode: string;
}

export interface StartEventRequest {
  /** ID of the event */
  eventId: number;
}

export interface TrashGuestRequest {
  /** ID of the event where the user is trashing their attendance */
  eventId: number;
}

/** / Create an email token to verify a user's email address */
export interface CreateEmailTokenResponse {
  /** / Generated ID to identify the token */
  emailTokenId: string;
  /**
   * / Public key to allow the user to send over an encrypted seed phrase
   * / and send it along in the email for storage.
   */
  seedPhrasePk: Uint8Array;
}

export interface SendOtpRequest {
  /** Phone number to verify */
  phoneNumber: string;
  /** Whether this is a sign-in attempt (as opposed to just verifying a number) */
  isSignin: boolean;
}

export interface SendOtpResponse {
  isSuccess: boolean;
  resendTimestamp: Long;
}

export interface VerifyOtpRequest {
  phoneNumber: string;
  otpCode: string;
  consentTypes: string[];
  /** Whether this is a sign-in attempt (as opposed to just verifying a number) */
  isSignin: boolean;
}

export interface VerifyOtpResponse {}

export interface NewsletterSubscriptionStatusResponse {
  /** Whether the user is subscribed */
  isSubscribed: boolean;
}

export interface GetBrowserSessionRequest {
  /** Session ID of the app */
  sessionId: string;
}

export interface GetBrowserSessionResponse {
  /** Event code that should be opened once the user with this session opens the app */
  nextEventCode: string;
}

export interface ResetBrowserSessionRequest {
  /** Session ID of the app */
  sessionId: string;
}

/** / Send the verification email to the user's address. */
export interface SendVerificationEmailRequest {
  /** / ID from `CreateEmailTokenResponse` */
  emailTokenId: string;
  /** / Optional: Encrypted seed phrase to be sent over in the verification email. */
  encryptedSeedPhrase: Uint8Array;
}

/** Current version number of the app */
export interface VersionResponse {
  /** Version number */
  versionNumber: number;
}

export interface CreateMintCodePaymentIntentRequest {
  /** Amount (in base tokens), ignored when event_code is set */
  tokenBaseAmount: Long;
  /** Event this mint code is intended for */
  eventCode: string;
  /** Whether to include event details in the payment title */
  hideDetails: boolean;
}

export interface CreateMintCodePaymentIntentResponse {
  clientSecret: string;
  paymentId: string;
  tokenBaseAmount: Long;
}

export interface StripeLoadPkResponse {
  publishableKey: string;
}

export interface StripeMintCodeRequest {
  paymentId: string;
}

export interface StripeMintCodeResponse {
  mintCode: string;
}

export interface EventPayoutMintCodeRequest {
  payoutId: Long;
}

export interface EventPayoutMintCodeResponse {
  mintCode: string;
}

export interface RefundablePurchasesResponse {
  refundablePurchases: RefundablePurchase[];
}

export interface RefundablePurchase {
  purchaseId: number;
  refundableAmountCent: Long;
  timestampCreated: Long;
  processor: string;
}

export interface PayoutMethod {
  /** Unique ID for the payout method */
  payoutMethodId: number;
  /** Payout data */
  payoutData: PayoutData | undefined;
  /** Timestamp the payout method has been created */
  timestampCreated: Long;
}

export interface PayoutData {
  recipientId: string;
  paypal?: PayoutDataPayPal | undefined;
  usBank?: PayoutDataUsBank | undefined;
}

export interface PayoutDataPayPal {
  paypalEmail: string;
}

export interface PayoutDataUsBank {
  /** Account holder type of the recipient */
  accountHolderType: string;
  /** Account type of the recipient bank account */
  accountType: string;
  /** Full legal name of the recipient */
  accountHolderName: string;
  /** ACH routing number */
  routingNumber: string;
  /** Account number */
  accountNumber: string;
  /** Beneficiary address of the account */
  address: AccountAddress | undefined;
  /** Bank name; derived from the routing info */
  bankName: string;
}

export interface AccountAddress {
  /** First address line */
  address1: string;
  /** Second address line */
  address2: string;
  /** City of the address */
  city: string;
  /** Region; in case of the US, the abbreviation of the US state, e.g. CA, TX */
  region: string;
  /** Postal code of the address */
  postalCode: string;
  /** Country of the address, the abbreviation, e.g. US */
  country: string;
}

export interface CreatePayoutMethodRequest {
  payoutData: PayoutData | undefined;
}

export interface PayoutMethodsResponse {
  payoutMethods: PayoutMethod[];
}

export interface UpdatePayoutMethodRequest {
  payoutMethodId: number;
  /** Payout data */
  payoutData: PayoutData | undefined;
}

export interface DeletePayoutMethodRequest {
  payoutMethodId: number;
}

export interface SubmitProofOfBurnRequest {
  burnRawTx: Uint8Array;
  signature: string;
  refundPurchaseIds: number[];
  payoutMethodId: number;
  isSimulation: boolean;
}

export interface SubmitProofOfBurnResponse {
  failedRefunds: string[];
  refundIds: string[];
  payoutIds: string[];
  failedPayouts: string[];
}

export interface ProofOfBurnPayoutHistoryRequest {
  page: Long;
}

export interface ProofOfBurnPayoutHistoryResponse {
  payouts: ProofOfBurnPayout[];
  numPages: Long;
}

export interface ProofOfBurnPayout {
  txid: Uint8Array;
  tokenAmount: Long;
  amountCent: Long;
  timestampSubmitted: Long;
  status: string;
  payoutMethodId: number;
  payoutType: string;
  payoutRecipient: string;
}

/** Parsed mint code */
export interface ParsedMintCode {
  /** Amount this code can mint (in base tokens) */
  tokenBaseAmount: Long;
  /** Signature by the mint authority */
  authSig: Uint8Array;
  /** TxId of the mint baton UTXO */
  mintBatonTxid: Uint8Array;
  /** output index of the mint baton UTXO */
  mintBatonOutIdx: number;
}

export interface User {
  /** / ID of the user; 0 for signup */
  userId: number;
  /** / Public key of the user, which uniquely defines them */
  userPk: Uint8Array;
  /** / First name of the user */
  firstName: string;
  /** / Last name of the user */
  lastName: string;
  /** / Email address of the user */
  email: string;
  /** / Phone number of the user */
  phoneNumber: string;
  /** / Y/m/d of the birthday */
  birthdayYear: number;
  birthdayMonth: number;
  birthdayDay: number;
  /** / Timestamp the user has been created */
  timestampCreated: Long;
  /** / Whether the user has created a wallet or if they're only having provisional pubkeys */
  hasCreatedWallet: boolean;
}

export interface UserMetadata {
  avatarUrl: string;
}

export interface Privilege {
  /** e.g. "Admin" or "Host" */
  privilege: string;
}

/** Details about an event */
export interface Event {
  /** ID of the event */
  eventId: number;
  /** Public key of the host */
  hostPk: Uint8Array;
  /** User ID of the host */
  hostId: number;
  /** First name of the host */
  hostFirstName: string;
  /** Last name of the host */
  hostLastName: string;
  /** URL of the host's avatar */
  hostAvatarUrl: string;
  /** URL of the event banner */
  bannerUrl: string;
  /** Title of the event */
  title: string;
  /** Human-readable address of the location */
  locationAddress: string;
  /** UNIX timestamp of the date & time */
  dateTime: Long;
  /** Length of the event in seconds (-1 if undefined/open end) */
  duration: number;
  /** Description of the event */
  description: string;
  /** Event price data for the event, with discounts applied */
  priceData: EventPrice | undefined;
  /** Event price data for the event w/o any discounts */
  basePriceData: EventPrice | undefined;
  /** Discount % the event */
  discountPer1000: number;
  /** for "PerHour" billing mode: How many hours to book at minimum */
  minHoursUpfront: number;
  /** Whether paying cash at entry is allowed */
  isCashAllowed: boolean;
  /** `true` if guests can only reserve funds in their bank and funds are actually captured later */
  isPreauthAllowed: boolean;
  /** Whether the user can only RSVP + check-in through the app if available */
  isAppRequired: boolean;
  /**
   * When guests RSVP, they can become an affiliate with the given %
   * (-1 if no auto affiliate enabled).
   */
  autoGuestAffiliatePer1000: number;
  /** Current state of guest for this event */
  guestState: GuestState | undefined;
  /** Which fiat currency the price is in (e.g. USD) */
  currency: string;
  /** Affiliates receiving tokens after escrow */
  affiliates: Affiliate[];
  /** Pubkey that should be used to sign escrow */
  escrowPk: Uint8Array;
  /** Stats about this event (only visible for the host) */
  eventStats: EventStats | undefined;
  /** Event codes to sign up for this event */
  eventCodes: string[];
  /** Stats for affiliates (only visible for non-host affiliates) */
  affiliateStats: AffiliateStats[];
  /**
   * Current status of the event:
   * "Planned": Users can join the event but not check-in yet
   * "Live": Users can join and check-in
   * "Ended": All users have been checked-out automatically and cannot check-in again
   */
  eventStatus: string;
  /** Payments made for this event (currently only for guests) */
  payments: EventPayment[];
  /** Pending token payout amount for this event (guests only) */
  pendingPayoutTokens: Long;
  /** Comma separated list of tags this event has */
  tags: string[];
}

export interface EventData {
  /** ID of the event */
  eventId: number;
  /** Details about this event */
  details: EventDetails | undefined;
  /** All the event codes of this event */
  codes: CodeDetails[];
  /** All the team_members of this event */
  teamMembers: TeamMember[];
}

export interface EventDetails {
  /** Title of the event */
  title: string;
  /** Human-readable address of the location */
  locationAddress: string;
  /** UNIX timestamp of the date & time */
  dateTime: Long;
  /** Length of the event in seconds (-1 if undefined/open end) */
  duration: number;
  /** Description of the event */
  description: string;
  /** URL of the event banner */
  bannerUrl: string;
  /** Event price data for the event */
  priceData: EventPrice | undefined;
  /** for "PerHour" billing mode: How many hours to book at minimum */
  minHoursUpfront: number;
  /** Whether paying cash at entry is allowed */
  isCashAllowed: boolean;
  /** `true` if guests can only reserve funds in their bank and funds are actually captured later */
  isPreauthAllowed: boolean;
  /** Whether the user can only RSVP + check-in through the app if available */
  isAppRequired: boolean;
  /**
   * When guests RSVP, they can become an affiliate with the given %
   * (-1 if no auto affiliate enabled).
   */
  autoGuestAffiliatePer1000: number;
  /** Which fiat currency the price is in (e.g. USD) */
  currency: string;
  /** Comma separated list of tags this event has */
  tags: string[];
}

export interface EventPrice {
  /** Per hour price for staying at the event (in cents/h) */
  pricePerHour: Long;
  /** Flat price of entry to the event (in cents) */
  priceFlat: Long;
  /** Upcharge if the guest wants to pay later in cash at entry (in cents) */
  priceUpchargeCash: Long;
}

export interface EventPayment {
  /** Token amount of this payment */
  tokenAmount: Long;
}

export interface CodeDetails {
  /** Code to access the event */
  code: string;
  /** All the affiliates of this event */
  affiliates: AffiliateDetails[];
  /** How many payments have been made using this code */
  numPayments: Long;
  /** When the code was created */
  timestampCreated: Long;
  /** Whether the code is meant to be a template to generate others */
  isTemplate: boolean;
  /** Template used by this code */
  template: string;
  /** How often this code can be used at most, or 0 if unlimited */
  maxUses: number;
  /** Discount % of this code. */
  discountPer1000: number;
}

export interface AffiliateDetails {
  /** User ID of the affiliate */
  affiliateId: number;
  /** Public key of the affiliate */
  pk: Uint8Array;
  /** Whether this affiliate is "Per1000" or "Leftover" */
  mode: string;
  /** How much this affiliate is getting per 1000 */
  per1000: number;
  /** First name of the affiliate. Ignored when updating */
  firstName: string;
  /** Last name of the affiliate. Ignored when updating */
  lastName: string;
}

export interface TeamMember {
  /** User ID of the member */
  memberId: number;
  /** Public key of the member */
  pk: Uint8Array;
  /** First name of the member */
  firstName: string;
  /** Last name of the member */
  lastName: string;
  /** Timestamp this member has been added to the team */
  timestampAdded: Long;
  /** Roles of the team member */
  roles: TeamMemberRole[];
}

export interface TeamMemberRole {
  /** Role of the member */
  role: string;
  /** Timestamp this role has been granted to the team member */
  timestampGranted: Long;
}

export interface Affiliate {
  outputScript: Uint8Array;
}

export interface GuestState {
  /** "CheckIn" or "CheckOut", or empty for never checked-in */
  status: string;
  /** How many seconds the guest has available for this event */
  totalTimeAvailable: Long;
  /** Total funds available for this guest, in cents */
  totalFundsAvailable: Long;
  /**
   * How many seconds the guest has spent checked-in and then checked out.
   * Every time a guest checks out, the amount of time spent checked-in
   * is added to this number.
   */
  timeCheckedIn: Long;
  /** Timestamp when the guest last checked in or out */
  timestampLastCheckin: Long;
  /** How many tokens have been paid by the user so far */
  tokensUsed: Long;
  /** How many tokens are currently paid by the user per hour */
  tokensUsedPerHour: Long;
  /** When `tokens_used` has last been updated */
  timestampLastFundsUpdated: Long;
}

export interface EventStats {
  /** Number of guests that joined the event */
  numGuestsJoined: number;
  /** Number of guests currently checked-in */
  numGuestsCheckedIn: number;
  /** How many tokens (in cents) have been collected from checked-in guests up to the last update */
  totalTokensCollected: Long;
  /** How many tokens (in cents) are collected per hour from checked-in guests */
  tokensCollectedPerHour: Long;
  /** When `total_tokens_collected` has last been updated */
  timestampLastFundsUpdated: Long;
  /** When `tokens_collected_per_hour` is no longer up-to date (-1 if indefinitely) */
  timestampNextInvalidation: Long;
}

export interface AffiliateStats {
  /** Affiliate code */
  eventCode: string;
  /** Number of payments made with this affiliate's code. */
  numPayments: number;
  /** Number of guests currently checked-in with the affiliate's code. */
  numCheckedIn: number;
  /**
   * How many funds (in base tokens) have been collected from checked-in guests from this
   * affiliate's code up to the last update.
   */
  tokensCollected: Long;
  /**
   * How many funds (in base tokens) are collected per hour from checked-in guests from this
   * affiliate's code.
   */
  tokensPerHour: Long;
  /** When the event stats have last been updated */
  timestampLastFundsUpdated: Long;
  /** When the event stats will be invalid */
  timestampNextInvalidation: Long;
}

export interface Burn {
  paymentId: number;
  txid: Uint8Array;
  burnTokenAmount: Long;
  timestamp: Long;
}

export interface TokenAmounts {
  amounts: Long[];
}

/** Wallet Balance */
export interface Balance {
  usd: number;
  cent: Long;
  baseToken: Long;
}

/** Subscription to WebSocket updates. */
export interface WsSub {
  /** Set this to `true` to unsubscribe from the event. */
  isUnsub: boolean;
  /** Subscribe as user */
  user?: WsSubUser | undefined;
}

/** Subscribe to user updates */
export interface WsSubUser {}

/** Message coming from the WebSocket */
export interface WsMsg {
  /** Error, e.g. when a bad message has been sent into the WebSocket. */
  error?: Error | undefined;
  /** Guest had an update (fired for host and guest) */
  guest?: MsgGuest | undefined;
}

/** Guest had an update (fired for host and guest) */
export interface MsgGuest {
  /** Event ID that the guest checked in/out */
  eventId: number;
  /** User ID of the guest that checked in/out */
  guestId: number;
  /** New status of the guest */
  checkinStatus: string;
}

export interface Error {
  msg: string;
}

function createBaseEmpty(): Empty {
  return {};
}

export const Empty = {
  encode(_: Empty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Empty {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Empty {
    return {};
  },

  toJSON(_: Empty): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Empty>, I>>(base?: I): Empty {
    return Empty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Empty>, I>>(_: I): Empty {
    const message = createBaseEmpty();
    return message;
  },
};

function createBaseSignedPayload(): SignedPayload {
  return {
    pubkey: new Uint8Array(0),
    signature: new Uint8Array(0),
    payload: new Uint8Array(0),
  };
}

export const SignedPayload = {
  encode(
    message: SignedPayload,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.pubkey.length !== 0) {
      writer.uint32(10).bytes(message.pubkey);
    }
    if (message.signature.length !== 0) {
      writer.uint32(18).bytes(message.signature);
    }
    if (message.payload.length !== 0) {
      writer.uint32(26).bytes(message.payload);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignedPayload {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignedPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pubkey = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signature = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.payload = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignedPayload {
    return {
      pubkey: isSet(object.pubkey)
        ? bytesFromBase64(object.pubkey)
        : new Uint8Array(0),
      signature: isSet(object.signature)
        ? bytesFromBase64(object.signature)
        : new Uint8Array(0),
      payload: isSet(object.payload)
        ? bytesFromBase64(object.payload)
        : new Uint8Array(0),
    };
  },

  toJSON(message: SignedPayload): unknown {
    const obj: any = {};
    if (message.pubkey.length !== 0) {
      obj.pubkey = base64FromBytes(message.pubkey);
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    if (message.payload.length !== 0) {
      obj.payload = base64FromBytes(message.payload);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignedPayload>, I>>(
    base?: I
  ): SignedPayload {
    return SignedPayload.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignedPayload>, I>>(
    object: I
  ): SignedPayload {
    const message = createBaseSignedPayload();
    message.pubkey = object.pubkey ?? new Uint8Array(0);
    message.signature = object.signature ?? new Uint8Array(0);
    message.payload = object.payload ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSendTxsRequest(): SendTxsRequest {
  return { txs: [], tokenProtocol: 0 };
}

export const SendTxsRequest = {
  encode(
    message: SendTxsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.txs) {
      writer.uint32(10).bytes(v!);
    }
    if (message.tokenProtocol !== 0) {
      writer.uint32(16).int32(message.tokenProtocol);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendTxsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendTxsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.txs.push(reader.bytes());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tokenProtocol = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendTxsRequest {
    return {
      txs: globalThis.Array.isArray(object?.txs)
        ? object.txs.map((e: any) => bytesFromBase64(e))
        : [],
      tokenProtocol: isSet(object.tokenProtocol)
        ? tokenProtocolFromJSON(object.tokenProtocol)
        : 0,
    };
  },

  toJSON(message: SendTxsRequest): unknown {
    const obj: any = {};
    if (message.txs?.length) {
      obj.txs = message.txs.map(e => base64FromBytes(e));
    }
    if (message.tokenProtocol !== 0) {
      obj.tokenProtocol = tokenProtocolToJSON(message.tokenProtocol);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendTxsRequest>, I>>(
    base?: I
  ): SendTxsRequest {
    return SendTxsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendTxsRequest>, I>>(
    object: I
  ): SendTxsRequest {
    const message = createBaseSendTxsRequest();
    message.txs = object.txs?.map(e => e) || [];
    message.tokenProtocol = object.tokenProtocol ?? 0;
    return message;
  },
};

function createBaseSendTxsResponse(): SendTxsResponse {
  return { txids: [] };
}

export const SendTxsResponse = {
  encode(
    message: SendTxsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.txids) {
      writer.uint32(10).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendTxsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendTxsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.txids.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendTxsResponse {
    return {
      txids: globalThis.Array.isArray(object?.txids)
        ? object.txids.map((e: any) => bytesFromBase64(e))
        : [],
    };
  },

  toJSON(message: SendTxsResponse): unknown {
    const obj: any = {};
    if (message.txids?.length) {
      obj.txids = message.txids.map(e => base64FromBytes(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendTxsResponse>, I>>(
    base?: I
  ): SendTxsResponse {
    return SendTxsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendTxsResponse>, I>>(
    object: I
  ): SendTxsResponse {
    const message = createBaseSendTxsResponse();
    message.txids = object.txids?.map(e => e) || [];
    return message;
  },
};

function createBaseNotifyBurnRequest(): NotifyBurnRequest {
  return { tx: new Uint8Array(0) };
}

export const NotifyBurnRequest = {
  encode(
    message: NotifyBurnRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.tx.length !== 0) {
      writer.uint32(10).bytes(message.tx);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotifyBurnRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyBurnRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tx = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotifyBurnRequest {
    return {
      tx: isSet(object.tx) ? bytesFromBase64(object.tx) : new Uint8Array(0),
    };
  },

  toJSON(message: NotifyBurnRequest): unknown {
    const obj: any = {};
    if (message.tx.length !== 0) {
      obj.tx = base64FromBytes(message.tx);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotifyBurnRequest>, I>>(
    base?: I
  ): NotifyBurnRequest {
    return NotifyBurnRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotifyBurnRequest>, I>>(
    object: I
  ): NotifyBurnRequest {
    const message = createBaseNotifyBurnRequest();
    message.tx = object.tx ?? new Uint8Array(0);
    return message;
  },
};

function createBaseNotifyBurnResponse(): NotifyBurnResponse {
  return { txid: new Uint8Array(0) };
}

export const NotifyBurnResponse = {
  encode(
    message: NotifyBurnResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.txid.length !== 0) {
      writer.uint32(10).bytes(message.txid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotifyBurnResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyBurnResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.txid = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotifyBurnResponse {
    return {
      txid: isSet(object.txid)
        ? bytesFromBase64(object.txid)
        : new Uint8Array(0),
    };
  },

  toJSON(message: NotifyBurnResponse): unknown {
    const obj: any = {};
    if (message.txid.length !== 0) {
      obj.txid = base64FromBytes(message.txid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotifyBurnResponse>, I>>(
    base?: I
  ): NotifyBurnResponse {
    return NotifyBurnResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotifyBurnResponse>, I>>(
    object: I
  ): NotifyBurnResponse {
    const message = createBaseNotifyBurnResponse();
    message.txid = object.txid ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetBurnsResponse(): GetBurnsResponse {
  return { burns: [] };
}

export const GetBurnsResponse = {
  encode(
    message: GetBurnsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.burns) {
      Burn.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBurnsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBurnsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.burns.push(Burn.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBurnsResponse {
    return {
      burns: globalThis.Array.isArray(object?.burns)
        ? object.burns.map((e: any) => Burn.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetBurnsResponse): unknown {
    const obj: any = {};
    if (message.burns?.length) {
      obj.burns = message.burns.map(e => Burn.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBurnsResponse>, I>>(
    base?: I
  ): GetBurnsResponse {
    return GetBurnsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBurnsResponse>, I>>(
    object: I
  ): GetBurnsResponse {
    const message = createBaseGetBurnsResponse();
    message.burns = object.burns?.map(e => Burn.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSignUpRequest(): SignUpRequest {
  return { user: undefined, sessionId: "" };
}

export const SignUpRequest = {
  encode(
    message: SignUpRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.sessionId !== "") {
      writer.uint32(18).string(message.sessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpRequest {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      sessionId: isSet(object.sessionId)
        ? globalThis.String(object.sessionId)
        : "",
    };
  },

  toJSON(message: SignUpRequest): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUpRequest>, I>>(
    base?: I
  ): SignUpRequest {
    return SignUpRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignUpRequest>, I>>(
    object: I
  ): SignUpRequest {
    const message = createBaseSignUpRequest();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.sessionId = object.sessionId ?? "";
    return message;
  },
};

function createBaseSignUpJwtRequest(): SignUpJwtRequest {
  return {
    jwt: "",
    sessionId: "",
    retireUserPk: new Uint8Array(0),
    loginMethod: "",
    provisionalPk: new Uint8Array(0),
    provisionalSig: new Uint8Array(0),
  };
}

export const SignUpJwtRequest = {
  encode(
    message: SignUpJwtRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.jwt !== "") {
      writer.uint32(10).string(message.jwt);
    }
    if (message.sessionId !== "") {
      writer.uint32(18).string(message.sessionId);
    }
    if (message.retireUserPk.length !== 0) {
      writer.uint32(26).bytes(message.retireUserPk);
    }
    if (message.loginMethod !== "") {
      writer.uint32(34).string(message.loginMethod);
    }
    if (message.provisionalPk.length !== 0) {
      writer.uint32(42).bytes(message.provisionalPk);
    }
    if (message.provisionalSig.length !== 0) {
      writer.uint32(50).bytes(message.provisionalSig);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpJwtRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpJwtRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jwt = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sessionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.retireUserPk = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.loginMethod = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.provisionalPk = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.provisionalSig = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpJwtRequest {
    return {
      jwt: isSet(object.jwt) ? globalThis.String(object.jwt) : "",
      sessionId: isSet(object.sessionId)
        ? globalThis.String(object.sessionId)
        : "",
      retireUserPk: isSet(object.retireUserPk)
        ? bytesFromBase64(object.retireUserPk)
        : new Uint8Array(0),
      loginMethod: isSet(object.loginMethod)
        ? globalThis.String(object.loginMethod)
        : "",
      provisionalPk: isSet(object.provisionalPk)
        ? bytesFromBase64(object.provisionalPk)
        : new Uint8Array(0),
      provisionalSig: isSet(object.provisionalSig)
        ? bytesFromBase64(object.provisionalSig)
        : new Uint8Array(0),
    };
  },

  toJSON(message: SignUpJwtRequest): unknown {
    const obj: any = {};
    if (message.jwt !== "") {
      obj.jwt = message.jwt;
    }
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    if (message.retireUserPk.length !== 0) {
      obj.retireUserPk = base64FromBytes(message.retireUserPk);
    }
    if (message.loginMethod !== "") {
      obj.loginMethod = message.loginMethod;
    }
    if (message.provisionalPk.length !== 0) {
      obj.provisionalPk = base64FromBytes(message.provisionalPk);
    }
    if (message.provisionalSig.length !== 0) {
      obj.provisionalSig = base64FromBytes(message.provisionalSig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUpJwtRequest>, I>>(
    base?: I
  ): SignUpJwtRequest {
    return SignUpJwtRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignUpJwtRequest>, I>>(
    object: I
  ): SignUpJwtRequest {
    const message = createBaseSignUpJwtRequest();
    message.jwt = object.jwt ?? "";
    message.sessionId = object.sessionId ?? "";
    message.retireUserPk = object.retireUserPk ?? new Uint8Array(0);
    message.loginMethod = object.loginMethod ?? "";
    message.provisionalPk = object.provisionalPk ?? new Uint8Array(0);
    message.provisionalSig = object.provisionalSig ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSignUpJwtResponse(): SignUpJwtResponse {
  return {
    isSuccess: false,
    existingPubkey: new Uint8Array(0),
    existingLoginMethod: "",
  };
}

export const SignUpJwtResponse = {
  encode(
    message: SignUpJwtResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.isSuccess === true) {
      writer.uint32(8).bool(message.isSuccess);
    }
    if (message.existingPubkey.length !== 0) {
      writer.uint32(18).bytes(message.existingPubkey);
    }
    if (message.existingLoginMethod !== "") {
      writer.uint32(26).string(message.existingLoginMethod);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpJwtResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpJwtResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isSuccess = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.existingPubkey = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.existingLoginMethod = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpJwtResponse {
    return {
      isSuccess: isSet(object.isSuccess)
        ? globalThis.Boolean(object.isSuccess)
        : false,
      existingPubkey: isSet(object.existingPubkey)
        ? bytesFromBase64(object.existingPubkey)
        : new Uint8Array(0),
      existingLoginMethod: isSet(object.existingLoginMethod)
        ? globalThis.String(object.existingLoginMethod)
        : "",
    };
  },

  toJSON(message: SignUpJwtResponse): unknown {
    const obj: any = {};
    if (message.isSuccess === true) {
      obj.isSuccess = message.isSuccess;
    }
    if (message.existingPubkey.length !== 0) {
      obj.existingPubkey = base64FromBytes(message.existingPubkey);
    }
    if (message.existingLoginMethod !== "") {
      obj.existingLoginMethod = message.existingLoginMethod;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUpJwtResponse>, I>>(
    base?: I
  ): SignUpJwtResponse {
    return SignUpJwtResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignUpJwtResponse>, I>>(
    object: I
  ): SignUpJwtResponse {
    const message = createBaseSignUpJwtResponse();
    message.isSuccess = object.isSuccess ?? false;
    message.existingPubkey = object.existingPubkey ?? new Uint8Array(0);
    message.existingLoginMethod = object.existingLoginMethod ?? "";
    return message;
  },
};

function createBaseLoginRequest(): LoginRequest {
  return { sessionId: "" };
}

export const LoginRequest = {
  encode(
    message: LoginRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.sessionId !== "") {
      writer.uint32(10).string(message.sessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginRequest {
    return {
      sessionId: isSet(object.sessionId)
        ? globalThis.String(object.sessionId)
        : "",
    };
  },

  toJSON(message: LoginRequest): unknown {
    const obj: any = {};
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginRequest>, I>>(
    base?: I
  ): LoginRequest {
    return LoginRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginRequest>, I>>(
    object: I
  ): LoginRequest {
    const message = createBaseLoginRequest();
    message.sessionId = object.sessionId ?? "";
    return message;
  },
};

function createBaseLoginResponse(): LoginResponse {
  return { exists: false, hasVerifiedEmail: false };
}

export const LoginResponse = {
  encode(
    message: LoginResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.exists === true) {
      writer.uint32(8).bool(message.exists);
    }
    if (message.hasVerifiedEmail === true) {
      writer.uint32(16).bool(message.hasVerifiedEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.exists = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasVerifiedEmail = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginResponse {
    return {
      exists: isSet(object.exists) ? globalThis.Boolean(object.exists) : false,
      hasVerifiedEmail: isSet(object.hasVerifiedEmail)
        ? globalThis.Boolean(object.hasVerifiedEmail)
        : false,
    };
  },

  toJSON(message: LoginResponse): unknown {
    const obj: any = {};
    if (message.exists === true) {
      obj.exists = message.exists;
    }
    if (message.hasVerifiedEmail === true) {
      obj.hasVerifiedEmail = message.hasVerifiedEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginResponse>, I>>(
    base?: I
  ): LoginResponse {
    return LoginResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginResponse>, I>>(
    object: I
  ): LoginResponse {
    const message = createBaseLoginResponse();
    message.exists = object.exists ?? false;
    message.hasVerifiedEmail = object.hasVerifiedEmail ?? false;
    return message;
  },
};

function createBaseSignUpResponse(): SignUpResponse {
  return {};
}

export const SignUpResponse = {
  encode(
    _: SignUpResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SignUpResponse {
    return {};
  },

  toJSON(_: SignUpResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUpResponse>, I>>(
    base?: I
  ): SignUpResponse {
    return SignUpResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignUpResponse>, I>>(
    _: I
  ): SignUpResponse {
    const message = createBaseSignUpResponse();
    return message;
  },
};

function createBaseSignUpCaptchaRequest(): SignUpCaptchaRequest {
  return { captchaToken: "" };
}

export const SignUpCaptchaRequest = {
  encode(
    message: SignUpCaptchaRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.captchaToken !== "") {
      writer.uint32(10).string(message.captchaToken);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SignUpCaptchaRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpCaptchaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.captchaToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpCaptchaRequest {
    return {
      captchaToken: isSet(object.captchaToken)
        ? globalThis.String(object.captchaToken)
        : "",
    };
  },

  toJSON(message: SignUpCaptchaRequest): unknown {
    const obj: any = {};
    if (message.captchaToken !== "") {
      obj.captchaToken = message.captchaToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUpCaptchaRequest>, I>>(
    base?: I
  ): SignUpCaptchaRequest {
    return SignUpCaptchaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignUpCaptchaRequest>, I>>(
    object: I
  ): SignUpCaptchaRequest {
    const message = createBaseSignUpCaptchaRequest();
    message.captchaToken = object.captchaToken ?? "";
    return message;
  },
};

function createBaseCaptchaSiteKeyResponse(): CaptchaSiteKeyResponse {
  return { siteKey: "" };
}

export const CaptchaSiteKeyResponse = {
  encode(
    message: CaptchaSiteKeyResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.siteKey !== "") {
      writer.uint32(10).string(message.siteKey);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CaptchaSiteKeyResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCaptchaSiteKeyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.siteKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CaptchaSiteKeyResponse {
    return {
      siteKey: isSet(object.siteKey) ? globalThis.String(object.siteKey) : "",
    };
  },

  toJSON(message: CaptchaSiteKeyResponse): unknown {
    const obj: any = {};
    if (message.siteKey !== "") {
      obj.siteKey = message.siteKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CaptchaSiteKeyResponse>, I>>(
    base?: I
  ): CaptchaSiteKeyResponse {
    return CaptchaSiteKeyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CaptchaSiteKeyResponse>, I>>(
    object: I
  ): CaptchaSiteKeyResponse {
    const message = createBaseCaptchaSiteKeyResponse();
    message.siteKey = object.siteKey ?? "";
    return message;
  },
};

function createBaseUpdateProfileRequest(): UpdateProfileRequest {
  return { user: undefined };
}

export const UpdateProfileRequest = {
  encode(
    message: UpdateProfileRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateProfileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateProfileRequest {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateProfileRequest): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateProfileRequest>, I>>(
    base?: I
  ): UpdateProfileRequest {
    return UpdateProfileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProfileRequest>, I>>(
    object: I
  ): UpdateProfileRequest {
    const message = createBaseUpdateProfileRequest();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

function createBaseSearchUsersRequest(): SearchUsersRequest {
  return { query: "" };
}

export const SearchUsersRequest = {
  encode(
    message: SearchUsersRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.query !== "") {
      writer.uint32(10).string(message.query);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchUsersRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchUsersRequest {
    return {
      query: isSet(object.query) ? globalThis.String(object.query) : "",
    };
  },

  toJSON(message: SearchUsersRequest): unknown {
    const obj: any = {};
    if (message.query !== "") {
      obj.query = message.query;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchUsersRequest>, I>>(
    base?: I
  ): SearchUsersRequest {
    return SearchUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchUsersRequest>, I>>(
    object: I
  ): SearchUsersRequest {
    const message = createBaseSearchUsersRequest();
    message.query = object.query ?? "";
    return message;
  },
};

function createBaseSearchUsersResponse(): SearchUsersResponse {
  return { users: [], message: "" };
}

export const SearchUsersResponse = {
  encode(
    message: SearchUsersResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchUsersResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchUsersResponse {
    return {
      users: globalThis.Array.isArray(object?.users)
        ? object.users.map((e: any) => User.fromJSON(e))
        : [],
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: SearchUsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map(e => User.toJSON(e));
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchUsersResponse>, I>>(
    base?: I
  ): SearchUsersResponse {
    return SearchUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchUsersResponse>, I>>(
    object: I
  ): SearchUsersResponse {
    const message = createBaseSearchUsersResponse();
    message.users = object.users?.map(e => User.fromPartial(e)) || [];
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseFileUploadRequest(): FileUploadRequest {
  return { fileUsage: "", eventId: 0 };
}

export const FileUploadRequest = {
  encode(
    message: FileUploadRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.fileUsage !== "") {
      writer.uint32(10).string(message.fileUsage);
    }
    if (message.eventId !== 0) {
      writer.uint32(16).int32(message.eventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FileUploadRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileUploadRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fileUsage = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.eventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileUploadRequest {
    return {
      fileUsage: isSet(object.fileUsage)
        ? globalThis.String(object.fileUsage)
        : "",
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
    };
  },

  toJSON(message: FileUploadRequest): unknown {
    const obj: any = {};
    if (message.fileUsage !== "") {
      obj.fileUsage = message.fileUsage;
    }
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileUploadRequest>, I>>(
    base?: I
  ): FileUploadRequest {
    return FileUploadRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileUploadRequest>, I>>(
    object: I
  ): FileUploadRequest {
    const message = createBaseFileUploadRequest();
    message.fileUsage = object.fileUsage ?? "";
    message.eventId = object.eventId ?? 0;
    return message;
  },
};

function createBaseFileUploadResponse(): FileUploadResponse {
  return { fileId: new Uint8Array(0), signedUploadUrl: "", filePath: "" };
}

export const FileUploadResponse = {
  encode(
    message: FileUploadResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.fileId.length !== 0) {
      writer.uint32(10).bytes(message.fileId);
    }
    if (message.signedUploadUrl !== "") {
      writer.uint32(18).string(message.signedUploadUrl);
    }
    if (message.filePath !== "") {
      writer.uint32(26).string(message.filePath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FileUploadResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileUploadResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fileId = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signedUploadUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filePath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileUploadResponse {
    return {
      fileId: isSet(object.fileId)
        ? bytesFromBase64(object.fileId)
        : new Uint8Array(0),
      signedUploadUrl: isSet(object.signedUploadUrl)
        ? globalThis.String(object.signedUploadUrl)
        : "",
      filePath: isSet(object.filePath)
        ? globalThis.String(object.filePath)
        : "",
    };
  },

  toJSON(message: FileUploadResponse): unknown {
    const obj: any = {};
    if (message.fileId.length !== 0) {
      obj.fileId = base64FromBytes(message.fileId);
    }
    if (message.signedUploadUrl !== "") {
      obj.signedUploadUrl = message.signedUploadUrl;
    }
    if (message.filePath !== "") {
      obj.filePath = message.filePath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileUploadResponse>, I>>(
    base?: I
  ): FileUploadResponse {
    return FileUploadResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileUploadResponse>, I>>(
    object: I
  ): FileUploadResponse {
    const message = createBaseFileUploadResponse();
    message.fileId = object.fileId ?? new Uint8Array(0);
    message.signedUploadUrl = object.signedUploadUrl ?? "";
    message.filePath = object.filePath ?? "";
    return message;
  },
};

function createBaseFileNotifyUploadedRequest(): FileNotifyUploadedRequest {
  return { fileId: new Uint8Array(0) };
}

export const FileNotifyUploadedRequest = {
  encode(
    message: FileNotifyUploadedRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.fileId.length !== 0) {
      writer.uint32(10).bytes(message.fileId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): FileNotifyUploadedRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileNotifyUploadedRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fileId = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileNotifyUploadedRequest {
    return {
      fileId: isSet(object.fileId)
        ? bytesFromBase64(object.fileId)
        : new Uint8Array(0),
    };
  },

  toJSON(message: FileNotifyUploadedRequest): unknown {
    const obj: any = {};
    if (message.fileId.length !== 0) {
      obj.fileId = base64FromBytes(message.fileId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileNotifyUploadedRequest>, I>>(
    base?: I
  ): FileNotifyUploadedRequest {
    return FileNotifyUploadedRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileNotifyUploadedRequest>, I>>(
    object: I
  ): FileNotifyUploadedRequest {
    const message = createBaseFileNotifyUploadedRequest();
    message.fileId = object.fileId ?? new Uint8Array(0);
    return message;
  },
};

function createBaseFileNotifyUploadedResponse(): FileNotifyUploadedResponse {
  return {};
}

export const FileNotifyUploadedResponse = {
  encode(
    _: FileNotifyUploadedResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): FileNotifyUploadedResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileNotifyUploadedResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FileNotifyUploadedResponse {
    return {};
  },

  toJSON(_: FileNotifyUploadedResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FileNotifyUploadedResponse>, I>>(
    base?: I
  ): FileNotifyUploadedResponse {
    return FileNotifyUploadedResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileNotifyUploadedResponse>, I>>(
    _: I
  ): FileNotifyUploadedResponse {
    const message = createBaseFileNotifyUploadedResponse();
    return message;
  },
};

function createBaseCreateEventRequest(): CreateEventRequest {
  return { templateEventId: 0 };
}

export const CreateEventRequest = {
  encode(
    message: CreateEventRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.templateEventId !== 0) {
      writer.uint32(8).int32(message.templateEventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateEventRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.templateEventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateEventRequest {
    return {
      templateEventId: isSet(object.templateEventId)
        ? globalThis.Number(object.templateEventId)
        : 0,
    };
  },

  toJSON(message: CreateEventRequest): unknown {
    const obj: any = {};
    if (message.templateEventId !== 0) {
      obj.templateEventId = Math.round(message.templateEventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateEventRequest>, I>>(
    base?: I
  ): CreateEventRequest {
    return CreateEventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateEventRequest>, I>>(
    object: I
  ): CreateEventRequest {
    const message = createBaseCreateEventRequest();
    message.templateEventId = object.templateEventId ?? 0;
    return message;
  },
};

function createBaseCreateEventResponse(): CreateEventResponse {
  return { eventId: 0 };
}

export const CreateEventResponse = {
  encode(
    message: CreateEventResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateEventResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateEventResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateEventResponse {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
    };
  },

  toJSON(message: CreateEventResponse): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateEventResponse>, I>>(
    base?: I
  ): CreateEventResponse {
    return CreateEventResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateEventResponse>, I>>(
    object: I
  ): CreateEventResponse {
    const message = createBaseCreateEventResponse();
    message.eventId = object.eventId ?? 0;
    return message;
  },
};

function createBaseUpdateEventDetailsRequest(): UpdateEventDetailsRequest {
  return { eventId: 0, eventDetails: undefined };
}

export const UpdateEventDetailsRequest = {
  encode(
    message: UpdateEventDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.eventDetails !== undefined) {
      EventDetails.encode(
        message.eventDetails,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateEventDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEventDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.eventDetails = EventDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEventDetailsRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      eventDetails: isSet(object.eventDetails)
        ? EventDetails.fromJSON(object.eventDetails)
        : undefined,
    };
  },

  toJSON(message: UpdateEventDetailsRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.eventDetails !== undefined) {
      obj.eventDetails = EventDetails.toJSON(message.eventDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEventDetailsRequest>, I>>(
    base?: I
  ): UpdateEventDetailsRequest {
    return UpdateEventDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEventDetailsRequest>, I>>(
    object: I
  ): UpdateEventDetailsRequest {
    const message = createBaseUpdateEventDetailsRequest();
    message.eventId = object.eventId ?? 0;
    message.eventDetails =
      object.eventDetails !== undefined && object.eventDetails !== null
        ? EventDetails.fromPartial(object.eventDetails)
        : undefined;
    return message;
  },
};

function createBaseGenerateCodeRequest(): GenerateCodeRequest {
  return {
    eventId: 0,
    templateCode: "",
    isTemplate: false,
    discountPer1000: 0,
    maxUses: 0,
  };
}

export const GenerateCodeRequest = {
  encode(
    message: GenerateCodeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.templateCode !== "") {
      writer.uint32(18).string(message.templateCode);
    }
    if (message.isTemplate === true) {
      writer.uint32(24).bool(message.isTemplate);
    }
    if (message.discountPer1000 !== 0) {
      writer.uint32(32).int32(message.discountPer1000);
    }
    if (message.maxUses !== 0) {
      writer.uint32(40).int32(message.maxUses);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.templateCode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isTemplate = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.discountPer1000 = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.maxUses = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateCodeRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      templateCode: isSet(object.templateCode)
        ? globalThis.String(object.templateCode)
        : "",
      isTemplate: isSet(object.isTemplate)
        ? globalThis.Boolean(object.isTemplate)
        : false,
      discountPer1000: isSet(object.discountPer1000)
        ? globalThis.Number(object.discountPer1000)
        : 0,
      maxUses: isSet(object.maxUses) ? globalThis.Number(object.maxUses) : 0,
    };
  },

  toJSON(message: GenerateCodeRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.templateCode !== "") {
      obj.templateCode = message.templateCode;
    }
    if (message.isTemplate === true) {
      obj.isTemplate = message.isTemplate;
    }
    if (message.discountPer1000 !== 0) {
      obj.discountPer1000 = Math.round(message.discountPer1000);
    }
    if (message.maxUses !== 0) {
      obj.maxUses = Math.round(message.maxUses);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateCodeRequest>, I>>(
    base?: I
  ): GenerateCodeRequest {
    return GenerateCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateCodeRequest>, I>>(
    object: I
  ): GenerateCodeRequest {
    const message = createBaseGenerateCodeRequest();
    message.eventId = object.eventId ?? 0;
    message.templateCode = object.templateCode ?? "";
    message.isTemplate = object.isTemplate ?? false;
    message.discountPer1000 = object.discountPer1000 ?? 0;
    message.maxUses = object.maxUses ?? 0;
    return message;
  },
};

function createBaseGenerateCodeResponse(): GenerateCodeResponse {
  return { code: "" };
}

export const GenerateCodeResponse = {
  encode(
    message: GenerateCodeResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GenerateCodeResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateCodeResponse {
    return { code: isSet(object.code) ? globalThis.String(object.code) : "" };
  },

  toJSON(message: GenerateCodeResponse): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateCodeResponse>, I>>(
    base?: I
  ): GenerateCodeResponse {
    return GenerateCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateCodeResponse>, I>>(
    object: I
  ): GenerateCodeResponse {
    const message = createBaseGenerateCodeResponse();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseEventDataRequest(): EventDataRequest {
  return { eventId: 0 };
}

export const EventDataRequest = {
  encode(
    message: EventDataRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventDataRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventDataRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
    };
  },

  toJSON(message: EventDataRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventDataRequest>, I>>(
    base?: I
  ): EventDataRequest {
    return EventDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventDataRequest>, I>>(
    object: I
  ): EventDataRequest {
    const message = createBaseEventDataRequest();
    message.eventId = object.eventId ?? 0;
    return message;
  },
};

function createBaseEventDataResponse(): EventDataResponse {
  return { eventData: undefined };
}

export const EventDataResponse = {
  encode(
    message: EventDataResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventData !== undefined) {
      EventData.encode(message.eventData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventDataResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventData = EventData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventDataResponse {
    return {
      eventData: isSet(object.eventData)
        ? EventData.fromJSON(object.eventData)
        : undefined,
    };
  },

  toJSON(message: EventDataResponse): unknown {
    const obj: any = {};
    if (message.eventData !== undefined) {
      obj.eventData = EventData.toJSON(message.eventData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventDataResponse>, I>>(
    base?: I
  ): EventDataResponse {
    return EventDataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventDataResponse>, I>>(
    object: I
  ): EventDataResponse {
    const message = createBaseEventDataResponse();
    message.eventData =
      object.eventData !== undefined && object.eventData !== null
        ? EventData.fromPartial(object.eventData)
        : undefined;
    return message;
  },
};

function createBaseUpdateCodeRequest(): UpdateCodeRequest {
  return {
    eventId: 0,
    code: "",
    affiliates: [],
    shouldUpdateCode: false,
    newCode: "",
    isTemplate: false,
    discountPer1000: 0,
    maxUses: 0,
  };
}

export const UpdateCodeRequest = {
  encode(
    message: UpdateCodeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    for (const v of message.affiliates) {
      AffiliateDetails.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.shouldUpdateCode === true) {
      writer.uint32(32).bool(message.shouldUpdateCode);
    }
    if (message.newCode !== "") {
      writer.uint32(42).string(message.newCode);
    }
    if (message.isTemplate === true) {
      writer.uint32(48).bool(message.isTemplate);
    }
    if (message.discountPer1000 !== 0) {
      writer.uint32(56).int32(message.discountPer1000);
    }
    if (message.maxUses !== 0) {
      writer.uint32(64).int32(message.maxUses);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.affiliates.push(
            AffiliateDetails.decode(reader, reader.uint32())
          );
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.shouldUpdateCode = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.newCode = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isTemplate = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.discountPer1000 = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.maxUses = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCodeRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      affiliates: globalThis.Array.isArray(object?.affiliates)
        ? object.affiliates.map((e: any) => AffiliateDetails.fromJSON(e))
        : [],
      shouldUpdateCode: isSet(object.shouldUpdateCode)
        ? globalThis.Boolean(object.shouldUpdateCode)
        : false,
      newCode: isSet(object.newCode) ? globalThis.String(object.newCode) : "",
      isTemplate: isSet(object.isTemplate)
        ? globalThis.Boolean(object.isTemplate)
        : false,
      discountPer1000: isSet(object.discountPer1000)
        ? globalThis.Number(object.discountPer1000)
        : 0,
      maxUses: isSet(object.maxUses) ? globalThis.Number(object.maxUses) : 0,
    };
  },

  toJSON(message: UpdateCodeRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.affiliates?.length) {
      obj.affiliates = message.affiliates.map(e => AffiliateDetails.toJSON(e));
    }
    if (message.shouldUpdateCode === true) {
      obj.shouldUpdateCode = message.shouldUpdateCode;
    }
    if (message.newCode !== "") {
      obj.newCode = message.newCode;
    }
    if (message.isTemplate === true) {
      obj.isTemplate = message.isTemplate;
    }
    if (message.discountPer1000 !== 0) {
      obj.discountPer1000 = Math.round(message.discountPer1000);
    }
    if (message.maxUses !== 0) {
      obj.maxUses = Math.round(message.maxUses);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCodeRequest>, I>>(
    base?: I
  ): UpdateCodeRequest {
    return UpdateCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCodeRequest>, I>>(
    object: I
  ): UpdateCodeRequest {
    const message = createBaseUpdateCodeRequest();
    message.eventId = object.eventId ?? 0;
    message.code = object.code ?? "";
    message.affiliates =
      object.affiliates?.map(e => AffiliateDetails.fromPartial(e)) || [];
    message.shouldUpdateCode = object.shouldUpdateCode ?? false;
    message.newCode = object.newCode ?? "";
    message.isTemplate = object.isTemplate ?? false;
    message.discountPer1000 = object.discountPer1000 ?? 0;
    message.maxUses = object.maxUses ?? 0;
    return message;
  },
};

function createBaseDeleteCodeRequest(): DeleteCodeRequest {
  return { eventId: 0, code: "" };
}

export const DeleteCodeRequest = {
  encode(
    message: DeleteCodeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteCodeRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      code: isSet(object.code) ? globalThis.String(object.code) : "",
    };
  },

  toJSON(message: DeleteCodeRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteCodeRequest>, I>>(
    base?: I
  ): DeleteCodeRequest {
    return DeleteCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteCodeRequest>, I>>(
    object: I
  ): DeleteCodeRequest {
    const message = createBaseDeleteCodeRequest();
    message.eventId = object.eventId ?? 0;
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseAddDoormanRequest(): AddDoormanRequest {
  return { eventId: 0, doormanId: 0 };
}

export const AddDoormanRequest = {
  encode(
    message: AddDoormanRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.doormanId !== 0) {
      writer.uint32(16).int32(message.doormanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddDoormanRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddDoormanRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.doormanId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddDoormanRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      doormanId: isSet(object.doormanId)
        ? globalThis.Number(object.doormanId)
        : 0,
    };
  },

  toJSON(message: AddDoormanRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.doormanId !== 0) {
      obj.doormanId = Math.round(message.doormanId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddDoormanRequest>, I>>(
    base?: I
  ): AddDoormanRequest {
    return AddDoormanRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddDoormanRequest>, I>>(
    object: I
  ): AddDoormanRequest {
    const message = createBaseAddDoormanRequest();
    message.eventId = object.eventId ?? 0;
    message.doormanId = object.doormanId ?? 0;
    return message;
  },
};

function createBaseRemoveDoormanRequest(): RemoveDoormanRequest {
  return { eventId: 0, doormanId: 0 };
}

export const RemoveDoormanRequest = {
  encode(
    message: RemoveDoormanRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.doormanId !== 0) {
      writer.uint32(16).int32(message.doormanId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveDoormanRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDoormanRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.doormanId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveDoormanRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      doormanId: isSet(object.doormanId)
        ? globalThis.Number(object.doormanId)
        : 0,
    };
  },

  toJSON(message: RemoveDoormanRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.doormanId !== 0) {
      obj.doormanId = Math.round(message.doormanId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveDoormanRequest>, I>>(
    base?: I
  ): RemoveDoormanRequest {
    return RemoveDoormanRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveDoormanRequest>, I>>(
    object: I
  ): RemoveDoormanRequest {
    const message = createBaseRemoveDoormanRequest();
    message.eventId = object.eventId ?? 0;
    message.doormanId = object.doormanId ?? 0;
    return message;
  },
};

function createBaseCreatEventPaymentIntentRequest(): CreatEventPaymentIntentRequest {
  return { cart: undefined, burnTokenAmount: Long.ZERO, isPreauth: false };
}

export const CreatEventPaymentIntentRequest = {
  encode(
    message: CreatEventPaymentIntentRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.cart !== undefined) {
      EventPurchaseCart.encode(message.cart, writer.uint32(10).fork()).ldelim();
    }
    if (!message.burnTokenAmount.isZero()) {
      writer.uint32(16).int64(message.burnTokenAmount);
    }
    if (message.isPreauth === true) {
      writer.uint32(24).bool(message.isPreauth);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreatEventPaymentIntentRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatEventPaymentIntentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cart = EventPurchaseCart.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.burnTokenAmount = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isPreauth = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreatEventPaymentIntentRequest {
    return {
      cart: isSet(object.cart)
        ? EventPurchaseCart.fromJSON(object.cart)
        : undefined,
      burnTokenAmount: isSet(object.burnTokenAmount)
        ? Long.fromValue(object.burnTokenAmount)
        : Long.ZERO,
      isPreauth: isSet(object.isPreauth)
        ? globalThis.Boolean(object.isPreauth)
        : false,
    };
  },

  toJSON(message: CreatEventPaymentIntentRequest): unknown {
    const obj: any = {};
    if (message.cart !== undefined) {
      obj.cart = EventPurchaseCart.toJSON(message.cart);
    }
    if (!message.burnTokenAmount.isZero()) {
      obj.burnTokenAmount = (message.burnTokenAmount || Long.ZERO).toString();
    }
    if (message.isPreauth === true) {
      obj.isPreauth = message.isPreauth;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreatEventPaymentIntentRequest>, I>>(
    base?: I
  ): CreatEventPaymentIntentRequest {
    return CreatEventPaymentIntentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatEventPaymentIntentRequest>, I>>(
    object: I
  ): CreatEventPaymentIntentRequest {
    const message = createBaseCreatEventPaymentIntentRequest();
    message.cart =
      object.cart !== undefined && object.cart !== null
        ? EventPurchaseCart.fromPartial(object.cart)
        : undefined;
    message.burnTokenAmount =
      object.burnTokenAmount !== undefined && object.burnTokenAmount !== null
        ? Long.fromValue(object.burnTokenAmount)
        : Long.ZERO;
    message.isPreauth = object.isPreauth ?? false;
    return message;
  },
};

function createBaseCreatEventPaymentIntentResponse(): CreatEventPaymentIntentResponse {
  return { clientSecret: "", paymentId: "" };
}

export const CreatEventPaymentIntentResponse = {
  encode(
    message: CreatEventPaymentIntentResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.clientSecret !== "") {
      writer.uint32(10).string(message.clientSecret);
    }
    if (message.paymentId !== "") {
      writer.uint32(18).string(message.paymentId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreatEventPaymentIntentResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatEventPaymentIntentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientSecret = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreatEventPaymentIntentResponse {
    return {
      clientSecret: isSet(object.clientSecret)
        ? globalThis.String(object.clientSecret)
        : "",
      paymentId: isSet(object.paymentId)
        ? globalThis.String(object.paymentId)
        : "",
    };
  },

  toJSON(message: CreatEventPaymentIntentResponse): unknown {
    const obj: any = {};
    if (message.clientSecret !== "") {
      obj.clientSecret = message.clientSecret;
    }
    if (message.paymentId !== "") {
      obj.paymentId = message.paymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreatEventPaymentIntentResponse>, I>>(
    base?: I
  ): CreatEventPaymentIntentResponse {
    return CreatEventPaymentIntentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatEventPaymentIntentResponse>, I>>(
    object: I
  ): CreatEventPaymentIntentResponse {
    const message = createBaseCreatEventPaymentIntentResponse();
    message.clientSecret = object.clientSecret ?? "";
    message.paymentId = object.paymentId ?? "";
    return message;
  },
};

function createBaseJoinEventRequest(): JoinEventRequest {
  return {
    cart: undefined,
    cartProto: new Uint8Array(0),
    txs: [],
    escrowType: "",
    isPayingLater: false,
    processor: "",
    processorPaymentId: "",
    createAutoAffiliate: false,
    sendConfirmationVia: [],
    isSharingInfoWithHost: false,
    eventCodeLegacy: "",
    numBookedHoursLegacy: 0,
  };
}

export const JoinEventRequest = {
  encode(
    message: JoinEventRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.cart !== undefined) {
      EventPurchaseCart.encode(message.cart, writer.uint32(66).fork()).ldelim();
    }
    if (message.cartProto.length !== 0) {
      writer.uint32(90).bytes(message.cartProto);
    }
    for (const v of message.txs) {
      writer.uint32(18).bytes(v!);
    }
    if (message.escrowType !== "") {
      writer.uint32(34).string(message.escrowType);
    }
    if (message.isPayingLater === true) {
      writer.uint32(40).bool(message.isPayingLater);
    }
    if (message.processor !== "") {
      writer.uint32(74).string(message.processor);
    }
    if (message.processorPaymentId !== "") {
      writer.uint32(82).string(message.processorPaymentId);
    }
    if (message.createAutoAffiliate === true) {
      writer.uint32(48).bool(message.createAutoAffiliate);
    }
    for (const v of message.sendConfirmationVia) {
      writer.uint32(58).string(v!);
    }
    if (message.isSharingInfoWithHost === true) {
      writer.uint32(96).bool(message.isSharingInfoWithHost);
    }
    if (message.eventCodeLegacy !== "") {
      writer.uint32(10).string(message.eventCodeLegacy);
    }
    if (message.numBookedHoursLegacy !== 0) {
      writer.uint32(24).uint32(message.numBookedHoursLegacy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinEventRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 8:
          if (tag !== 66) {
            break;
          }

          message.cart = EventPurchaseCart.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.cartProto = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.txs.push(reader.bytes());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.escrowType = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isPayingLater = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.processor = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.processorPaymentId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.createAutoAffiliate = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sendConfirmationVia.push(reader.string());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isSharingInfoWithHost = reader.bool();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventCodeLegacy = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numBookedHoursLegacy = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinEventRequest {
    return {
      cart: isSet(object.cart)
        ? EventPurchaseCart.fromJSON(object.cart)
        : undefined,
      cartProto: isSet(object.cartProto)
        ? bytesFromBase64(object.cartProto)
        : new Uint8Array(0),
      txs: globalThis.Array.isArray(object?.txs)
        ? object.txs.map((e: any) => bytesFromBase64(e))
        : [],
      escrowType: isSet(object.escrowType)
        ? globalThis.String(object.escrowType)
        : "",
      isPayingLater: isSet(object.isPayingLater)
        ? globalThis.Boolean(object.isPayingLater)
        : false,
      processor: isSet(object.processor)
        ? globalThis.String(object.processor)
        : "",
      processorPaymentId: isSet(object.processorPaymentId)
        ? globalThis.String(object.processorPaymentId)
        : "",
      createAutoAffiliate: isSet(object.createAutoAffiliate)
        ? globalThis.Boolean(object.createAutoAffiliate)
        : false,
      sendConfirmationVia: globalThis.Array.isArray(object?.sendConfirmationVia)
        ? object.sendConfirmationVia.map((e: any) => globalThis.String(e))
        : [],
      isSharingInfoWithHost: isSet(object.isSharingInfoWithHost)
        ? globalThis.Boolean(object.isSharingInfoWithHost)
        : false,
      eventCodeLegacy: isSet(object.eventCodeLegacy)
        ? globalThis.String(object.eventCodeLegacy)
        : "",
      numBookedHoursLegacy: isSet(object.numBookedHoursLegacy)
        ? globalThis.Number(object.numBookedHoursLegacy)
        : 0,
    };
  },

  toJSON(message: JoinEventRequest): unknown {
    const obj: any = {};
    if (message.cart !== undefined) {
      obj.cart = EventPurchaseCart.toJSON(message.cart);
    }
    if (message.cartProto.length !== 0) {
      obj.cartProto = base64FromBytes(message.cartProto);
    }
    if (message.txs?.length) {
      obj.txs = message.txs.map(e => base64FromBytes(e));
    }
    if (message.escrowType !== "") {
      obj.escrowType = message.escrowType;
    }
    if (message.isPayingLater === true) {
      obj.isPayingLater = message.isPayingLater;
    }
    if (message.processor !== "") {
      obj.processor = message.processor;
    }
    if (message.processorPaymentId !== "") {
      obj.processorPaymentId = message.processorPaymentId;
    }
    if (message.createAutoAffiliate === true) {
      obj.createAutoAffiliate = message.createAutoAffiliate;
    }
    if (message.sendConfirmationVia?.length) {
      obj.sendConfirmationVia = message.sendConfirmationVia;
    }
    if (message.isSharingInfoWithHost === true) {
      obj.isSharingInfoWithHost = message.isSharingInfoWithHost;
    }
    if (message.eventCodeLegacy !== "") {
      obj.eventCodeLegacy = message.eventCodeLegacy;
    }
    if (message.numBookedHoursLegacy !== 0) {
      obj.numBookedHoursLegacy = Math.round(message.numBookedHoursLegacy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinEventRequest>, I>>(
    base?: I
  ): JoinEventRequest {
    return JoinEventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinEventRequest>, I>>(
    object: I
  ): JoinEventRequest {
    const message = createBaseJoinEventRequest();
    message.cart =
      object.cart !== undefined && object.cart !== null
        ? EventPurchaseCart.fromPartial(object.cart)
        : undefined;
    message.cartProto = object.cartProto ?? new Uint8Array(0);
    message.txs = object.txs?.map(e => e) || [];
    message.escrowType = object.escrowType ?? "";
    message.isPayingLater = object.isPayingLater ?? false;
    message.processor = object.processor ?? "";
    message.processorPaymentId = object.processorPaymentId ?? "";
    message.createAutoAffiliate = object.createAutoAffiliate ?? false;
    message.sendConfirmationVia = object.sendConfirmationVia?.map(e => e) || [];
    message.isSharingInfoWithHost = object.isSharingInfoWithHost ?? false;
    message.eventCodeLegacy = object.eventCodeLegacy ?? "";
    message.numBookedHoursLegacy = object.numBookedHoursLegacy ?? 0;
    return message;
  },
};

function createBaseJoinEventResponse(): JoinEventResponse {
  return { txids: [], autoAffiliateCode: "", paymentId: 0 };
}

export const JoinEventResponse = {
  encode(
    message: JoinEventResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.txids) {
      writer.uint32(10).bytes(v!);
    }
    if (message.autoAffiliateCode !== "") {
      writer.uint32(18).string(message.autoAffiliateCode);
    }
    if (message.paymentId !== 0) {
      writer.uint32(24).int32(message.paymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinEventResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinEventResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.txids.push(reader.bytes());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.autoAffiliateCode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.paymentId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinEventResponse {
    return {
      txids: globalThis.Array.isArray(object?.txids)
        ? object.txids.map((e: any) => bytesFromBase64(e))
        : [],
      autoAffiliateCode: isSet(object.autoAffiliateCode)
        ? globalThis.String(object.autoAffiliateCode)
        : "",
      paymentId: isSet(object.paymentId)
        ? globalThis.Number(object.paymentId)
        : 0,
    };
  },

  toJSON(message: JoinEventResponse): unknown {
    const obj: any = {};
    if (message.txids?.length) {
      obj.txids = message.txids.map(e => base64FromBytes(e));
    }
    if (message.autoAffiliateCode !== "") {
      obj.autoAffiliateCode = message.autoAffiliateCode;
    }
    if (message.paymentId !== 0) {
      obj.paymentId = Math.round(message.paymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinEventResponse>, I>>(
    base?: I
  ): JoinEventResponse {
    return JoinEventResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinEventResponse>, I>>(
    object: I
  ): JoinEventResponse {
    const message = createBaseJoinEventResponse();
    message.txids = object.txids?.map(e => e) || [];
    message.autoAffiliateCode = object.autoAffiliateCode ?? "";
    message.paymentId = object.paymentId ?? 0;
    return message;
  },
};

function createBaseEventPurchaseCart(): EventPurchaseCart {
  return { eventCode: "", numBookedHours: 0, items: [] };
}

export const EventPurchaseCart = {
  encode(
    message: EventPurchaseCart,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventCode !== "") {
      writer.uint32(10).string(message.eventCode);
    }
    if (message.numBookedHours !== 0) {
      writer.uint32(24).uint32(message.numBookedHours);
    }
    for (const v of message.items) {
      EventCartItem.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPurchaseCart {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPurchaseCart();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventCode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numBookedHours = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.items.push(EventCartItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPurchaseCart {
    return {
      eventCode: isSet(object.eventCode)
        ? globalThis.String(object.eventCode)
        : "",
      numBookedHours: isSet(object.numBookedHours)
        ? globalThis.Number(object.numBookedHours)
        : 0,
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => EventCartItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EventPurchaseCart): unknown {
    const obj: any = {};
    if (message.eventCode !== "") {
      obj.eventCode = message.eventCode;
    }
    if (message.numBookedHours !== 0) {
      obj.numBookedHours = Math.round(message.numBookedHours);
    }
    if (message.items?.length) {
      obj.items = message.items.map(e => EventCartItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPurchaseCart>, I>>(
    base?: I
  ): EventPurchaseCart {
    return EventPurchaseCart.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPurchaseCart>, I>>(
    object: I
  ): EventPurchaseCart {
    const message = createBaseEventPurchaseCart();
    message.eventCode = object.eventCode ?? "";
    message.numBookedHours = object.numBookedHours ?? 0;
    message.items = object.items?.map(e => EventCartItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEventCartItem(): EventCartItem {
  return {
    ticketVariantId: Long.ZERO,
    quantity: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    totalPrice: Long.ZERO,
    totalPriceUndiscounted: Long.ZERO,
    priceFlat: Long.ZERO,
    pricePerHour: Long.ZERO,
  };
}

export const EventCartItem = {
  encode(
    message: EventCartItem,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.ticketVariantId.isZero()) {
      writer.uint32(8).int64(message.ticketVariantId);
    }
    if (message.quantity !== 0) {
      writer.uint32(16).int32(message.quantity);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(42).string(message.phoneNumber);
    }
    if (!message.totalPrice.isZero()) {
      writer.uint32(56).int64(message.totalPrice);
    }
    if (!message.totalPriceUndiscounted.isZero()) {
      writer.uint32(80).int64(message.totalPriceUndiscounted);
    }
    if (!message.priceFlat.isZero()) {
      writer.uint32(64).int64(message.priceFlat);
    }
    if (!message.pricePerHour.isZero()) {
      writer.uint32(72).int64(message.pricePerHour);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventCartItem {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventCartItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ticketVariantId = reader.int64() as Long;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.quantity = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.totalPrice = reader.int64() as Long;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.totalPriceUndiscounted = reader.int64() as Long;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.priceFlat = reader.int64() as Long;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.pricePerHour = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventCartItem {
    return {
      ticketVariantId: isSet(object.ticketVariantId)
        ? Long.fromValue(object.ticketVariantId)
        : Long.ZERO,
      quantity: isSet(object.quantity) ? globalThis.Number(object.quantity) : 0,
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
      phoneNumber: isSet(object.phoneNumber)
        ? globalThis.String(object.phoneNumber)
        : "",
      totalPrice: isSet(object.totalPrice)
        ? Long.fromValue(object.totalPrice)
        : Long.ZERO,
      totalPriceUndiscounted: isSet(object.totalPriceUndiscounted)
        ? Long.fromValue(object.totalPriceUndiscounted)
        : Long.ZERO,
      priceFlat: isSet(object.priceFlat)
        ? Long.fromValue(object.priceFlat)
        : Long.ZERO,
      pricePerHour: isSet(object.pricePerHour)
        ? Long.fromValue(object.pricePerHour)
        : Long.ZERO,
    };
  },

  toJSON(message: EventCartItem): unknown {
    const obj: any = {};
    if (!message.ticketVariantId.isZero()) {
      obj.ticketVariantId = (message.ticketVariantId || Long.ZERO).toString();
    }
    if (message.quantity !== 0) {
      obj.quantity = Math.round(message.quantity);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (!message.totalPrice.isZero()) {
      obj.totalPrice = (message.totalPrice || Long.ZERO).toString();
    }
    if (!message.totalPriceUndiscounted.isZero()) {
      obj.totalPriceUndiscounted = (
        message.totalPriceUndiscounted || Long.ZERO
      ).toString();
    }
    if (!message.priceFlat.isZero()) {
      obj.priceFlat = (message.priceFlat || Long.ZERO).toString();
    }
    if (!message.pricePerHour.isZero()) {
      obj.pricePerHour = (message.pricePerHour || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventCartItem>, I>>(
    base?: I
  ): EventCartItem {
    return EventCartItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventCartItem>, I>>(
    object: I
  ): EventCartItem {
    const message = createBaseEventCartItem();
    message.ticketVariantId =
      object.ticketVariantId !== undefined && object.ticketVariantId !== null
        ? Long.fromValue(object.ticketVariantId)
        : Long.ZERO;
    message.quantity = object.quantity ?? 0;
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.totalPrice =
      object.totalPrice !== undefined && object.totalPrice !== null
        ? Long.fromValue(object.totalPrice)
        : Long.ZERO;
    message.totalPriceUndiscounted =
      object.totalPriceUndiscounted !== undefined &&
      object.totalPriceUndiscounted !== null
        ? Long.fromValue(object.totalPriceUndiscounted)
        : Long.ZERO;
    message.priceFlat =
      object.priceFlat !== undefined && object.priceFlat !== null
        ? Long.fromValue(object.priceFlat)
        : Long.ZERO;
    message.pricePerHour =
      object.pricePerHour !== undefined && object.pricePerHour !== null
        ? Long.fromValue(object.pricePerHour)
        : Long.ZERO;
    return message;
  },
};

function createBaseSendEventConfirmationEmailRequest(): SendEventConfirmationEmailRequest {
  return {
    paymentId: 0,
    sendConfirmationVia: [],
    email: "",
    shouldSaveEmail: false,
    checkinUri: "",
  };
}

export const SendEventConfirmationEmailRequest = {
  encode(
    message: SendEventConfirmationEmailRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.paymentId !== 0) {
      writer.uint32(8).int32(message.paymentId);
    }
    for (const v of message.sendConfirmationVia) {
      writer.uint32(18).string(v!);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.shouldSaveEmail === true) {
      writer.uint32(32).bool(message.shouldSaveEmail);
    }
    if (message.checkinUri !== "") {
      writer.uint32(42).string(message.checkinUri);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SendEventConfirmationEmailRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendEventConfirmationEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.paymentId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sendConfirmationVia.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.shouldSaveEmail = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.checkinUri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendEventConfirmationEmailRequest {
    return {
      paymentId: isSet(object.paymentId)
        ? globalThis.Number(object.paymentId)
        : 0,
      sendConfirmationVia: globalThis.Array.isArray(object?.sendConfirmationVia)
        ? object.sendConfirmationVia.map((e: any) => globalThis.String(e))
        : [],
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      shouldSaveEmail: isSet(object.shouldSaveEmail)
        ? globalThis.Boolean(object.shouldSaveEmail)
        : false,
      checkinUri: isSet(object.checkinUri)
        ? globalThis.String(object.checkinUri)
        : "",
    };
  },

  toJSON(message: SendEventConfirmationEmailRequest): unknown {
    const obj: any = {};
    if (message.paymentId !== 0) {
      obj.paymentId = Math.round(message.paymentId);
    }
    if (message.sendConfirmationVia?.length) {
      obj.sendConfirmationVia = message.sendConfirmationVia;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.shouldSaveEmail === true) {
      obj.shouldSaveEmail = message.shouldSaveEmail;
    }
    if (message.checkinUri !== "") {
      obj.checkinUri = message.checkinUri;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendEventConfirmationEmailRequest>, I>>(
    base?: I
  ): SendEventConfirmationEmailRequest {
    return SendEventConfirmationEmailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<SendEventConfirmationEmailRequest>, I>
  >(object: I): SendEventConfirmationEmailRequest {
    const message = createBaseSendEventConfirmationEmailRequest();
    message.paymentId = object.paymentId ?? 0;
    message.sendConfirmationVia = object.sendConfirmationVia?.map(e => e) || [];
    message.email = object.email ?? "";
    message.shouldSaveEmail = object.shouldSaveEmail ?? false;
    message.checkinUri = object.checkinUri ?? "";
    return message;
  },
};

function createBaseEventOverviewRequest(): EventOverviewRequest {
  return { eventId: 0 };
}

export const EventOverviewRequest = {
  encode(
    message: EventOverviewRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): EventOverviewRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventOverviewRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventOverviewRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
    };
  },

  toJSON(message: EventOverviewRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventOverviewRequest>, I>>(
    base?: I
  ): EventOverviewRequest {
    return EventOverviewRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventOverviewRequest>, I>>(
    object: I
  ): EventOverviewRequest {
    const message = createBaseEventOverviewRequest();
    message.eventId = object.eventId ?? 0;
    return message;
  },
};

function createBaseEventOverviewResponse(): EventOverviewResponse {
  return { guests: [] };
}

export const EventOverviewResponse = {
  encode(
    message: EventOverviewResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.guests) {
      OverviewGuest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): EventOverviewResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventOverviewResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.guests.push(OverviewGuest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventOverviewResponse {
    return {
      guests: globalThis.Array.isArray(object?.guests)
        ? object.guests.map((e: any) => OverviewGuest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EventOverviewResponse): unknown {
    const obj: any = {};
    if (message.guests?.length) {
      obj.guests = message.guests.map(e => OverviewGuest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventOverviewResponse>, I>>(
    base?: I
  ): EventOverviewResponse {
    return EventOverviewResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventOverviewResponse>, I>>(
    object: I
  ): EventOverviewResponse {
    const message = createBaseEventOverviewResponse();
    message.guests =
      object.guests?.map(e => OverviewGuest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOverviewGuest(): OverviewGuest {
  return {
    userId: 0,
    firstName: "",
    lastName: "",
    guestState: undefined,
    eventCodes: [],
    items: [],
  };
}

export const OverviewGuest = {
  encode(
    message: OverviewGuest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (message.guestState !== undefined) {
      GuestState.encode(message.guestState, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.eventCodes) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.items) {
      OverviewGuestItem.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverviewGuest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverviewGuest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.guestState = GuestState.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.eventCodes.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.items.push(OverviewGuestItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OverviewGuest {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
      guestState: isSet(object.guestState)
        ? GuestState.fromJSON(object.guestState)
        : undefined,
      eventCodes: globalThis.Array.isArray(object?.eventCodes)
        ? object.eventCodes.map((e: any) => globalThis.String(e))
        : [],
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => OverviewGuestItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OverviewGuest): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.guestState !== undefined) {
      obj.guestState = GuestState.toJSON(message.guestState);
    }
    if (message.eventCodes?.length) {
      obj.eventCodes = message.eventCodes;
    }
    if (message.items?.length) {
      obj.items = message.items.map(e => OverviewGuestItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OverviewGuest>, I>>(
    base?: I
  ): OverviewGuest {
    return OverviewGuest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OverviewGuest>, I>>(
    object: I
  ): OverviewGuest {
    const message = createBaseOverviewGuest();
    message.userId = object.userId ?? 0;
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.guestState =
      object.guestState !== undefined && object.guestState !== null
        ? GuestState.fromPartial(object.guestState)
        : undefined;
    message.eventCodes = object.eventCodes?.map(e => e) || [];
    message.items =
      object.items?.map(e => OverviewGuestItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOverviewGuestItem(): OverviewGuestItem {
  return { firstName: "", lastName: "" };
}

export const OverviewGuestItem = {
  encode(
    message: OverviewGuestItem,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverviewGuestItem {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverviewGuestItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OverviewGuestItem {
    return {
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
    };
  },

  toJSON(message: OverviewGuestItem): unknown {
    const obj: any = {};
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OverviewGuestItem>, I>>(
    base?: I
  ): OverviewGuestItem {
    return OverviewGuestItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OverviewGuestItem>, I>>(
    object: I
  ): OverviewGuestItem {
    const message = createBaseOverviewGuestItem();
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    return message;
  },
};

function createBaseEventPayoutRequest(): EventPayoutRequest {
  return { eventId: 0, isSimulation: false };
}

export const EventPayoutRequest = {
  encode(
    message: EventPayoutRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.isSimulation === true) {
      writer.uint32(16).bool(message.isSimulation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPayoutRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSimulation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayoutRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      isSimulation: isSet(object.isSimulation)
        ? globalThis.Boolean(object.isSimulation)
        : false,
    };
  },

  toJSON(message: EventPayoutRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.isSimulation === true) {
      obj.isSimulation = message.isSimulation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayoutRequest>, I>>(
    base?: I
  ): EventPayoutRequest {
    return EventPayoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayoutRequest>, I>>(
    object: I
  ): EventPayoutRequest {
    const message = createBaseEventPayoutRequest();
    message.eventId = object.eventId ?? 0;
    message.isSimulation = object.isSimulation ?? false;
    return message;
  },
};

function createBaseGrantPrivilegeRequest(): GrantPrivilegeRequest {
  return { grantee: 0, privilege: "" };
}

export const GrantPrivilegeRequest = {
  encode(
    message: GrantPrivilegeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.grantee !== 0) {
      writer.uint32(8).int32(message.grantee);
    }
    if (message.privilege !== "") {
      writer.uint32(18).string(message.privilege);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GrantPrivilegeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGrantPrivilegeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.grantee = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.privilege = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GrantPrivilegeRequest {
    return {
      grantee: isSet(object.grantee) ? globalThis.Number(object.grantee) : 0,
      privilege: isSet(object.privilege)
        ? globalThis.String(object.privilege)
        : "",
    };
  },

  toJSON(message: GrantPrivilegeRequest): unknown {
    const obj: any = {};
    if (message.grantee !== 0) {
      obj.grantee = Math.round(message.grantee);
    }
    if (message.privilege !== "") {
      obj.privilege = message.privilege;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GrantPrivilegeRequest>, I>>(
    base?: I
  ): GrantPrivilegeRequest {
    return GrantPrivilegeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GrantPrivilegeRequest>, I>>(
    object: I
  ): GrantPrivilegeRequest {
    const message = createBaseGrantPrivilegeRequest();
    message.grantee = object.grantee ?? 0;
    message.privilege = object.privilege ?? "";
    return message;
  },
};

function createBasePrivilegesResponse(): PrivilegesResponse {
  return { privileges: [] };
}

export const PrivilegesResponse = {
  encode(
    message: PrivilegesResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.privileges) {
      Privilege.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrivilegesResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivilegesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.privileges.push(Privilege.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrivilegesResponse {
    return {
      privileges: globalThis.Array.isArray(object?.privileges)
        ? object.privileges.map((e: any) => Privilege.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PrivilegesResponse): unknown {
    const obj: any = {};
    if (message.privileges?.length) {
      obj.privileges = message.privileges.map(e => Privilege.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrivilegesResponse>, I>>(
    base?: I
  ): PrivilegesResponse {
    return PrivilegesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrivilegesResponse>, I>>(
    object: I
  ): PrivilegesResponse {
    const message = createBasePrivilegesResponse();
    message.privileges =
      object.privileges?.map(e => Privilege.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEventPayout(): EventPayout {
  return {
    escrowTxid: new Uint8Array(0),
    escrowOutIdx: 0,
    network: "",
    tokenProtocol: 0,
    tokenId: "",
    tokenType: 0,
    escrowOutputSats: Long.ZERO,
    escrowType: "",
    escrowSig: new Uint8Array(0),
    escrowMsg: new Uint8Array(0),
    escrowTokenAmounts: [],
    recipientScripts: [],
    escrowPk: new Uint8Array(0),
    isRedeemed: false,
  };
}

export const EventPayout = {
  encode(
    message: EventPayout,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.escrowTxid.length !== 0) {
      writer.uint32(10).bytes(message.escrowTxid);
    }
    if (message.escrowOutIdx !== 0) {
      writer.uint32(16).uint32(message.escrowOutIdx);
    }
    if (message.network !== "") {
      writer.uint32(90).string(message.network);
    }
    if (message.tokenProtocol !== 0) {
      writer.uint32(96).int32(message.tokenProtocol);
    }
    if (message.tokenId !== "") {
      writer.uint32(106).string(message.tokenId);
    }
    if (message.tokenType !== 0) {
      writer.uint32(112).int32(message.tokenType);
    }
    if (!message.escrowOutputSats.isZero()) {
      writer.uint32(24).int64(message.escrowOutputSats);
    }
    if (message.escrowType !== "") {
      writer.uint32(34).string(message.escrowType);
    }
    if (message.escrowSig.length !== 0) {
      writer.uint32(42).bytes(message.escrowSig);
    }
    if (message.escrowMsg.length !== 0) {
      writer.uint32(50).bytes(message.escrowMsg);
    }
    writer.uint32(58).fork();
    for (const v of message.escrowTokenAmounts) {
      writer.uint64(v);
    }
    writer.ldelim();
    for (const v of message.recipientScripts) {
      writer.uint32(66).bytes(v!);
    }
    if (message.escrowPk.length !== 0) {
      writer.uint32(74).bytes(message.escrowPk);
    }
    if (message.isRedeemed === true) {
      writer.uint32(80).bool(message.isRedeemed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPayout {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.escrowTxid = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.escrowOutIdx = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.network = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.tokenProtocol = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.tokenType = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.escrowOutputSats = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.escrowType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.escrowSig = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.escrowMsg = reader.bytes();
          continue;
        case 7:
          if (tag === 56) {
            message.escrowTokenAmounts.push(reader.uint64() as Long);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.escrowTokenAmounts.push(reader.uint64() as Long);
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.recipientScripts.push(reader.bytes());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.escrowPk = reader.bytes();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isRedeemed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayout {
    return {
      escrowTxid: isSet(object.escrowTxid)
        ? bytesFromBase64(object.escrowTxid)
        : new Uint8Array(0),
      escrowOutIdx: isSet(object.escrowOutIdx)
        ? globalThis.Number(object.escrowOutIdx)
        : 0,
      network: isSet(object.network) ? globalThis.String(object.network) : "",
      tokenProtocol: isSet(object.tokenProtocol)
        ? tokenProtocolFromJSON(object.tokenProtocol)
        : 0,
      tokenId: isSet(object.tokenId) ? globalThis.String(object.tokenId) : "",
      tokenType: isSet(object.tokenType)
        ? globalThis.Number(object.tokenType)
        : 0,
      escrowOutputSats: isSet(object.escrowOutputSats)
        ? Long.fromValue(object.escrowOutputSats)
        : Long.ZERO,
      escrowType: isSet(object.escrowType)
        ? globalThis.String(object.escrowType)
        : "",
      escrowSig: isSet(object.escrowSig)
        ? bytesFromBase64(object.escrowSig)
        : new Uint8Array(0),
      escrowMsg: isSet(object.escrowMsg)
        ? bytesFromBase64(object.escrowMsg)
        : new Uint8Array(0),
      escrowTokenAmounts: globalThis.Array.isArray(object?.escrowTokenAmounts)
        ? object.escrowTokenAmounts.map((e: any) => Long.fromValue(e))
        : [],
      recipientScripts: globalThis.Array.isArray(object?.recipientScripts)
        ? object.recipientScripts.map((e: any) => bytesFromBase64(e))
        : [],
      escrowPk: isSet(object.escrowPk)
        ? bytesFromBase64(object.escrowPk)
        : new Uint8Array(0),
      isRedeemed: isSet(object.isRedeemed)
        ? globalThis.Boolean(object.isRedeemed)
        : false,
    };
  },

  toJSON(message: EventPayout): unknown {
    const obj: any = {};
    if (message.escrowTxid.length !== 0) {
      obj.escrowTxid = base64FromBytes(message.escrowTxid);
    }
    if (message.escrowOutIdx !== 0) {
      obj.escrowOutIdx = Math.round(message.escrowOutIdx);
    }
    if (message.network !== "") {
      obj.network = message.network;
    }
    if (message.tokenProtocol !== 0) {
      obj.tokenProtocol = tokenProtocolToJSON(message.tokenProtocol);
    }
    if (message.tokenId !== "") {
      obj.tokenId = message.tokenId;
    }
    if (message.tokenType !== 0) {
      obj.tokenType = Math.round(message.tokenType);
    }
    if (!message.escrowOutputSats.isZero()) {
      obj.escrowOutputSats = (message.escrowOutputSats || Long.ZERO).toString();
    }
    if (message.escrowType !== "") {
      obj.escrowType = message.escrowType;
    }
    if (message.escrowSig.length !== 0) {
      obj.escrowSig = base64FromBytes(message.escrowSig);
    }
    if (message.escrowMsg.length !== 0) {
      obj.escrowMsg = base64FromBytes(message.escrowMsg);
    }
    if (message.escrowTokenAmounts?.length) {
      obj.escrowTokenAmounts = message.escrowTokenAmounts.map(e =>
        (e || Long.UZERO).toString()
      );
    }
    if (message.recipientScripts?.length) {
      obj.recipientScripts = message.recipientScripts.map(e =>
        base64FromBytes(e)
      );
    }
    if (message.escrowPk.length !== 0) {
      obj.escrowPk = base64FromBytes(message.escrowPk);
    }
    if (message.isRedeemed === true) {
      obj.isRedeemed = message.isRedeemed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayout>, I>>(base?: I): EventPayout {
    return EventPayout.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayout>, I>>(
    object: I
  ): EventPayout {
    const message = createBaseEventPayout();
    message.escrowTxid = object.escrowTxid ?? new Uint8Array(0);
    message.escrowOutIdx = object.escrowOutIdx ?? 0;
    message.network = object.network ?? "";
    message.tokenProtocol = object.tokenProtocol ?? 0;
    message.tokenId = object.tokenId ?? "";
    message.tokenType = object.tokenType ?? 0;
    message.escrowOutputSats =
      object.escrowOutputSats !== undefined && object.escrowOutputSats !== null
        ? Long.fromValue(object.escrowOutputSats)
        : Long.ZERO;
    message.escrowType = object.escrowType ?? "";
    message.escrowSig = object.escrowSig ?? new Uint8Array(0);
    message.escrowMsg = object.escrowMsg ?? new Uint8Array(0);
    message.escrowTokenAmounts =
      object.escrowTokenAmounts?.map(e => Long.fromValue(e)) || [];
    message.recipientScripts = object.recipientScripts?.map(e => e) || [];
    message.escrowPk = object.escrowPk ?? new Uint8Array(0);
    message.isRedeemed = object.isRedeemed ?? false;
    return message;
  },
};

function createBaseEventPayoutWalletless(): EventPayoutWalletless {
  return { userId: 0, tokenAmount: Long.ZERO, payoutId: Long.ZERO };
}

export const EventPayoutWalletless = {
  encode(
    message: EventPayoutWalletless,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (!message.tokenAmount.isZero()) {
      writer.uint32(16).int64(message.tokenAmount);
    }
    if (!message.payoutId.isZero()) {
      writer.uint32(24).int64(message.payoutId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): EventPayoutWalletless {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayoutWalletless();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tokenAmount = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.payoutId = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayoutWalletless {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      tokenAmount: isSet(object.tokenAmount)
        ? Long.fromValue(object.tokenAmount)
        : Long.ZERO,
      payoutId: isSet(object.payoutId)
        ? Long.fromValue(object.payoutId)
        : Long.ZERO,
    };
  },

  toJSON(message: EventPayoutWalletless): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (!message.tokenAmount.isZero()) {
      obj.tokenAmount = (message.tokenAmount || Long.ZERO).toString();
    }
    if (!message.payoutId.isZero()) {
      obj.payoutId = (message.payoutId || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayoutWalletless>, I>>(
    base?: I
  ): EventPayoutWalletless {
    return EventPayoutWalletless.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayoutWalletless>, I>>(
    object: I
  ): EventPayoutWalletless {
    const message = createBaseEventPayoutWalletless();
    message.userId = object.userId ?? 0;
    message.tokenAmount =
      object.tokenAmount !== undefined && object.tokenAmount !== null
        ? Long.fromValue(object.tokenAmount)
        : Long.ZERO;
    message.payoutId =
      object.payoutId !== undefined && object.payoutId !== null
        ? Long.fromValue(object.payoutId)
        : Long.ZERO;
    return message;
  },
};

function createBaseEventPayoutUser(): EventPayoutUser {
  return {
    recipientScript: new Uint8Array(0),
    userId: 0,
    userPk: new Uint8Array(0),
    firstName: "",
    lastName: "",
  };
}

export const EventPayoutUser = {
  encode(
    message: EventPayoutUser,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.recipientScript.length !== 0) {
      writer.uint32(10).bytes(message.recipientScript);
    }
    if (message.userId !== 0) {
      writer.uint32(16).int32(message.userId);
    }
    if (message.userPk.length !== 0) {
      writer.uint32(42).bytes(message.userPk);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPayoutUser {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayoutUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientScript = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userPk = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayoutUser {
    return {
      recipientScript: isSet(object.recipientScript)
        ? bytesFromBase64(object.recipientScript)
        : new Uint8Array(0),
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      userPk: isSet(object.userPk)
        ? bytesFromBase64(object.userPk)
        : new Uint8Array(0),
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
    };
  },

  toJSON(message: EventPayoutUser): unknown {
    const obj: any = {};
    if (message.recipientScript.length !== 0) {
      obj.recipientScript = base64FromBytes(message.recipientScript);
    }
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.userPk.length !== 0) {
      obj.userPk = base64FromBytes(message.userPk);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayoutUser>, I>>(
    base?: I
  ): EventPayoutUser {
    return EventPayoutUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayoutUser>, I>>(
    object: I
  ): EventPayoutUser {
    const message = createBaseEventPayoutUser();
    message.recipientScript = object.recipientScript ?? new Uint8Array(0);
    message.userId = object.userId ?? 0;
    message.userPk = object.userPk ?? new Uint8Array(0);
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    return message;
  },
};

function createBaseEventPayoutResponse(): EventPayoutResponse {
  return { payouts: [], payoutsWalletless: [], payoutUsers: [] };
}

export const EventPayoutResponse = {
  encode(
    message: EventPayoutResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.payouts) {
      EventPayout.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.payoutsWalletless) {
      EventPayoutWalletless.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.payoutUsers) {
      EventPayoutUser.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPayoutResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayoutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payouts.push(EventPayout.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.payoutsWalletless.push(
            EventPayoutWalletless.decode(reader, reader.uint32())
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payoutUsers.push(
            EventPayoutUser.decode(reader, reader.uint32())
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayoutResponse {
    return {
      payouts: globalThis.Array.isArray(object?.payouts)
        ? object.payouts.map((e: any) => EventPayout.fromJSON(e))
        : [],
      payoutsWalletless: globalThis.Array.isArray(object?.payoutsWalletless)
        ? object.payoutsWalletless.map((e: any) =>
            EventPayoutWalletless.fromJSON(e)
          )
        : [],
      payoutUsers: globalThis.Array.isArray(object?.payoutUsers)
        ? object.payoutUsers.map((e: any) => EventPayoutUser.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EventPayoutResponse): unknown {
    const obj: any = {};
    if (message.payouts?.length) {
      obj.payouts = message.payouts.map(e => EventPayout.toJSON(e));
    }
    if (message.payoutsWalletless?.length) {
      obj.payoutsWalletless = message.payoutsWalletless.map(e =>
        EventPayoutWalletless.toJSON(e)
      );
    }
    if (message.payoutUsers?.length) {
      obj.payoutUsers = message.payoutUsers.map(e => EventPayoutUser.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayoutResponse>, I>>(
    base?: I
  ): EventPayoutResponse {
    return EventPayoutResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayoutResponse>, I>>(
    object: I
  ): EventPayoutResponse {
    const message = createBaseEventPayoutResponse();
    message.payouts =
      object.payouts?.map(e => EventPayout.fromPartial(e)) || [];
    message.payoutsWalletless =
      object.payoutsWalletless?.map(e =>
        EventPayoutWalletless.fromPartial(e)
      ) || [];
    message.payoutUsers =
      object.payoutUsers?.map(e => EventPayoutUser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJoinedEventsResponse(): JoinedEventsResponse {
  return { events: [] };
}

export const JoinedEventsResponse = {
  encode(
    message: JoinedEventsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.events) {
      Event.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): JoinedEventsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinedEventsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.events.push(Event.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinedEventsResponse {
    return {
      events: globalThis.Array.isArray(object?.events)
        ? object.events.map((e: any) => Event.fromJSON(e))
        : [],
    };
  },

  toJSON(message: JoinedEventsResponse): unknown {
    const obj: any = {};
    if (message.events?.length) {
      obj.events = message.events.map(e => Event.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinedEventsResponse>, I>>(
    base?: I
  ): JoinedEventsResponse {
    return JoinedEventsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinedEventsResponse>, I>>(
    object: I
  ): JoinedEventsResponse {
    const message = createBaseJoinedEventsResponse();
    message.events = object.events?.map(e => Event.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHostedEventsResponse(): HostedEventsResponse {
  return { events: [] };
}

export const HostedEventsResponse = {
  encode(
    message: HostedEventsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.events) {
      Event.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): HostedEventsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHostedEventsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.events.push(Event.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HostedEventsResponse {
    return {
      events: globalThis.Array.isArray(object?.events)
        ? object.events.map((e: any) => Event.fromJSON(e))
        : [],
    };
  },

  toJSON(message: HostedEventsResponse): unknown {
    const obj: any = {};
    if (message.events?.length) {
      obj.events = message.events.map(e => Event.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HostedEventsResponse>, I>>(
    base?: I
  ): HostedEventsResponse {
    return HostedEventsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HostedEventsResponse>, I>>(
    object: I
  ): HostedEventsResponse {
    const message = createBaseHostedEventsResponse();
    message.events = object.events?.map(e => Event.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAffiliatedEventsResponse(): AffiliatedEventsResponse {
  return { events: [] };
}

export const AffiliatedEventsResponse = {
  encode(
    message: AffiliatedEventsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.events) {
      Event.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): AffiliatedEventsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAffiliatedEventsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.events.push(Event.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AffiliatedEventsResponse {
    return {
      events: globalThis.Array.isArray(object?.events)
        ? object.events.map((e: any) => Event.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AffiliatedEventsResponse): unknown {
    const obj: any = {};
    if (message.events?.length) {
      obj.events = message.events.map(e => Event.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AffiliatedEventsResponse>, I>>(
    base?: I
  ): AffiliatedEventsResponse {
    return AffiliatedEventsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AffiliatedEventsResponse>, I>>(
    object: I
  ): AffiliatedEventsResponse {
    const message = createBaseAffiliatedEventsResponse();
    message.events = object.events?.map(e => Event.fromPartial(e)) || [];
    return message;
  },
};

function createBasePaymentHistoryStatusResponse(): PaymentHistoryStatusResponse {
  return { isSyncing: false };
}

export const PaymentHistoryStatusResponse = {
  encode(
    message: PaymentHistoryStatusResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.isSyncing === true) {
      writer.uint32(8).bool(message.isSyncing);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PaymentHistoryStatusResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentHistoryStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isSyncing = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentHistoryStatusResponse {
    return {
      isSyncing: isSet(object.isSyncing)
        ? globalThis.Boolean(object.isSyncing)
        : false,
    };
  },

  toJSON(message: PaymentHistoryStatusResponse): unknown {
    const obj: any = {};
    if (message.isSyncing === true) {
      obj.isSyncing = message.isSyncing;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentHistoryStatusResponse>, I>>(
    base?: I
  ): PaymentHistoryStatusResponse {
    return PaymentHistoryStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentHistoryStatusResponse>, I>>(
    object: I
  ): PaymentHistoryStatusResponse {
    const message = createBasePaymentHistoryStatusResponse();
    message.isSyncing = object.isSyncing ?? false;
    return message;
  },
};

function createBasePaymentHistoryRequest(): PaymentHistoryRequest {
  return { page: 0 };
}

export const PaymentHistoryRequest = {
  encode(
    message: PaymentHistoryRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.page !== 0) {
      writer.uint32(8).int32(message.page);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PaymentHistoryRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentHistoryRequest {
    return { page: isSet(object.page) ? globalThis.Number(object.page) : 0 };
  },

  toJSON(message: PaymentHistoryRequest): unknown {
    const obj: any = {};
    if (message.page !== 0) {
      obj.page = Math.round(message.page);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentHistoryRequest>, I>>(
    base?: I
  ): PaymentHistoryRequest {
    return PaymentHistoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentHistoryRequest>, I>>(
    object: I
  ): PaymentHistoryRequest {
    const message = createBasePaymentHistoryRequest();
    message.page = object.page ?? 0;
    return message;
  },
};

function createBasePaymentHistoryResponse(): PaymentHistoryResponse {
  return { payments: [] };
}

export const PaymentHistoryResponse = {
  encode(
    message: PaymentHistoryResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.payments) {
      Payment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PaymentHistoryResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payments.push(Payment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentHistoryResponse {
    return {
      payments: globalThis.Array.isArray(object?.payments)
        ? object.payments.map((e: any) => Payment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PaymentHistoryResponse): unknown {
    const obj: any = {};
    if (message.payments?.length) {
      obj.payments = message.payments.map(e => Payment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentHistoryResponse>, I>>(
    base?: I
  ): PaymentHistoryResponse {
    return PaymentHistoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentHistoryResponse>, I>>(
    object: I
  ): PaymentHistoryResponse {
    const message = createBasePaymentHistoryResponse();
    message.payments = object.payments?.map(e => Payment.fromPartial(e)) || [];
    return message;
  },
};

function createBasePayment(): Payment {
  return {
    paymentType: "",
    deltaTokenAmount: Long.ZERO,
    timestamp: Long.ZERO,
    paymentId: 0,
    mint: undefined,
    event: undefined,
    burnRefund: undefined,
    burnPayout: undefined,
    userTransfer: undefined,
  };
}

export const Payment = {
  encode(
    message: Payment,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.paymentType !== "") {
      writer.uint32(10).string(message.paymentType);
    }
    if (!message.deltaTokenAmount.isZero()) {
      writer.uint32(16).int64(message.deltaTokenAmount);
    }
    if (!message.timestamp.isZero()) {
      writer.uint32(24).int64(message.timestamp);
    }
    if (message.paymentId !== 0) {
      writer.uint32(32).int32(message.paymentId);
    }
    if (message.mint !== undefined) {
      PaymentVariantMint.encode(
        message.mint,
        writer.uint32(82).fork()
      ).ldelim();
    }
    if (message.event !== undefined) {
      PaymentVariantEvent.encode(
        message.event,
        writer.uint32(90).fork()
      ).ldelim();
    }
    if (message.burnRefund !== undefined) {
      PaymentVariantBurnRefund.encode(
        message.burnRefund,
        writer.uint32(98).fork()
      ).ldelim();
    }
    if (message.burnPayout !== undefined) {
      PaymentVariantBurnPayout.encode(
        message.burnPayout,
        writer.uint32(106).fork()
      ).ldelim();
    }
    if (message.userTransfer !== undefined) {
      PaymentVariantUserTransfer.encode(
        message.userTransfer,
        writer.uint32(114).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Payment {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deltaTokenAmount = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timestamp = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.paymentId = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.mint = PaymentVariantMint.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.event = PaymentVariantEvent.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.burnRefund = PaymentVariantBurnRefund.decode(
            reader,
            reader.uint32()
          );
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.burnPayout = PaymentVariantBurnPayout.decode(
            reader,
            reader.uint32()
          );
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.userTransfer = PaymentVariantUserTransfer.decode(
            reader,
            reader.uint32()
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Payment {
    return {
      paymentType: isSet(object.paymentType)
        ? globalThis.String(object.paymentType)
        : "",
      deltaTokenAmount: isSet(object.deltaTokenAmount)
        ? Long.fromValue(object.deltaTokenAmount)
        : Long.ZERO,
      timestamp: isSet(object.timestamp)
        ? Long.fromValue(object.timestamp)
        : Long.ZERO,
      paymentId: isSet(object.paymentId)
        ? globalThis.Number(object.paymentId)
        : 0,
      mint: isSet(object.mint)
        ? PaymentVariantMint.fromJSON(object.mint)
        : undefined,
      event: isSet(object.event)
        ? PaymentVariantEvent.fromJSON(object.event)
        : undefined,
      burnRefund: isSet(object.burnRefund)
        ? PaymentVariantBurnRefund.fromJSON(object.burnRefund)
        : undefined,
      burnPayout: isSet(object.burnPayout)
        ? PaymentVariantBurnPayout.fromJSON(object.burnPayout)
        : undefined,
      userTransfer: isSet(object.userTransfer)
        ? PaymentVariantUserTransfer.fromJSON(object.userTransfer)
        : undefined,
    };
  },

  toJSON(message: Payment): unknown {
    const obj: any = {};
    if (message.paymentType !== "") {
      obj.paymentType = message.paymentType;
    }
    if (!message.deltaTokenAmount.isZero()) {
      obj.deltaTokenAmount = (message.deltaTokenAmount || Long.ZERO).toString();
    }
    if (!message.timestamp.isZero()) {
      obj.timestamp = (message.timestamp || Long.ZERO).toString();
    }
    if (message.paymentId !== 0) {
      obj.paymentId = Math.round(message.paymentId);
    }
    if (message.mint !== undefined) {
      obj.mint = PaymentVariantMint.toJSON(message.mint);
    }
    if (message.event !== undefined) {
      obj.event = PaymentVariantEvent.toJSON(message.event);
    }
    if (message.burnRefund !== undefined) {
      obj.burnRefund = PaymentVariantBurnRefund.toJSON(message.burnRefund);
    }
    if (message.burnPayout !== undefined) {
      obj.burnPayout = PaymentVariantBurnPayout.toJSON(message.burnPayout);
    }
    if (message.userTransfer !== undefined) {
      obj.userTransfer = PaymentVariantUserTransfer.toJSON(
        message.userTransfer
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Payment>, I>>(base?: I): Payment {
    return Payment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Payment>, I>>(object: I): Payment {
    const message = createBasePayment();
    message.paymentType = object.paymentType ?? "";
    message.deltaTokenAmount =
      object.deltaTokenAmount !== undefined && object.deltaTokenAmount !== null
        ? Long.fromValue(object.deltaTokenAmount)
        : Long.ZERO;
    message.timestamp =
      object.timestamp !== undefined && object.timestamp !== null
        ? Long.fromValue(object.timestamp)
        : Long.ZERO;
    message.paymentId = object.paymentId ?? 0;
    message.mint =
      object.mint !== undefined && object.mint !== null
        ? PaymentVariantMint.fromPartial(object.mint)
        : undefined;
    message.event =
      object.event !== undefined && object.event !== null
        ? PaymentVariantEvent.fromPartial(object.event)
        : undefined;
    message.burnRefund =
      object.burnRefund !== undefined && object.burnRefund !== null
        ? PaymentVariantBurnRefund.fromPartial(object.burnRefund)
        : undefined;
    message.burnPayout =
      object.burnPayout !== undefined && object.burnPayout !== null
        ? PaymentVariantBurnPayout.fromPartial(object.burnPayout)
        : undefined;
    message.userTransfer =
      object.userTransfer !== undefined && object.userTransfer !== null
        ? PaymentVariantUserTransfer.fromPartial(object.userTransfer)
        : undefined;
    return message;
  },
};

function createBasePaymentVariantMint(): PaymentVariantMint {
  return { mintCode: "", priceCent: Long.ZERO, refundedAmountCent: Long.ZERO };
}

export const PaymentVariantMint = {
  encode(
    message: PaymentVariantMint,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.mintCode !== "") {
      writer.uint32(10).string(message.mintCode);
    }
    if (!message.priceCent.isZero()) {
      writer.uint32(16).int64(message.priceCent);
    }
    if (!message.refundedAmountCent.isZero()) {
      writer.uint32(24).int64(message.refundedAmountCent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentVariantMint {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentVariantMint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mintCode = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.priceCent = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.refundedAmountCent = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentVariantMint {
    return {
      mintCode: isSet(object.mintCode)
        ? globalThis.String(object.mintCode)
        : "",
      priceCent: isSet(object.priceCent)
        ? Long.fromValue(object.priceCent)
        : Long.ZERO,
      refundedAmountCent: isSet(object.refundedAmountCent)
        ? Long.fromValue(object.refundedAmountCent)
        : Long.ZERO,
    };
  },

  toJSON(message: PaymentVariantMint): unknown {
    const obj: any = {};
    if (message.mintCode !== "") {
      obj.mintCode = message.mintCode;
    }
    if (!message.priceCent.isZero()) {
      obj.priceCent = (message.priceCent || Long.ZERO).toString();
    }
    if (!message.refundedAmountCent.isZero()) {
      obj.refundedAmountCent = (
        message.refundedAmountCent || Long.ZERO
      ).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentVariantMint>, I>>(
    base?: I
  ): PaymentVariantMint {
    return PaymentVariantMint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentVariantMint>, I>>(
    object: I
  ): PaymentVariantMint {
    const message = createBasePaymentVariantMint();
    message.mintCode = object.mintCode ?? "";
    message.priceCent =
      object.priceCent !== undefined && object.priceCent !== null
        ? Long.fromValue(object.priceCent)
        : Long.ZERO;
    message.refundedAmountCent =
      object.refundedAmountCent !== undefined &&
      object.refundedAmountCent !== null
        ? Long.fromValue(object.refundedAmountCent)
        : Long.ZERO;
    return message;
  },
};

function createBasePaymentVariantBurnRefund(): PaymentVariantBurnRefund {
  return { amountCent: Long.ZERO };
}

export const PaymentVariantBurnRefund = {
  encode(
    message: PaymentVariantBurnRefund,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.amountCent.isZero()) {
      writer.uint32(8).int64(message.amountCent);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PaymentVariantBurnRefund {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentVariantBurnRefund();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amountCent = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentVariantBurnRefund {
    return {
      amountCent: isSet(object.amountCent)
        ? Long.fromValue(object.amountCent)
        : Long.ZERO,
    };
  },

  toJSON(message: PaymentVariantBurnRefund): unknown {
    const obj: any = {};
    if (!message.amountCent.isZero()) {
      obj.amountCent = (message.amountCent || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentVariantBurnRefund>, I>>(
    base?: I
  ): PaymentVariantBurnRefund {
    return PaymentVariantBurnRefund.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentVariantBurnRefund>, I>>(
    object: I
  ): PaymentVariantBurnRefund {
    const message = createBasePaymentVariantBurnRefund();
    message.amountCent =
      object.amountCent !== undefined && object.amountCent !== null
        ? Long.fromValue(object.amountCent)
        : Long.ZERO;
    return message;
  },
};

function createBasePaymentVariantBurnPayout(): PaymentVariantBurnPayout {
  return {
    amountCent: Long.ZERO,
    status: "",
    payoutMethodId: 0,
    payoutType: "",
    payoutRecipient: "",
  };
}

export const PaymentVariantBurnPayout = {
  encode(
    message: PaymentVariantBurnPayout,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.amountCent.isZero()) {
      writer.uint32(8).int64(message.amountCent);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    if (message.payoutMethodId !== 0) {
      writer.uint32(24).int32(message.payoutMethodId);
    }
    if (message.payoutType !== "") {
      writer.uint32(34).string(message.payoutType);
    }
    if (message.payoutRecipient !== "") {
      writer.uint32(42).string(message.payoutRecipient);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PaymentVariantBurnPayout {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentVariantBurnPayout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amountCent = reader.int64() as Long;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.payoutMethodId = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.payoutType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.payoutRecipient = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentVariantBurnPayout {
    return {
      amountCent: isSet(object.amountCent)
        ? Long.fromValue(object.amountCent)
        : Long.ZERO,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      payoutMethodId: isSet(object.payoutMethodId)
        ? globalThis.Number(object.payoutMethodId)
        : 0,
      payoutType: isSet(object.payoutType)
        ? globalThis.String(object.payoutType)
        : "",
      payoutRecipient: isSet(object.payoutRecipient)
        ? globalThis.String(object.payoutRecipient)
        : "",
    };
  },

  toJSON(message: PaymentVariantBurnPayout): unknown {
    const obj: any = {};
    if (!message.amountCent.isZero()) {
      obj.amountCent = (message.amountCent || Long.ZERO).toString();
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.payoutMethodId !== 0) {
      obj.payoutMethodId = Math.round(message.payoutMethodId);
    }
    if (message.payoutType !== "") {
      obj.payoutType = message.payoutType;
    }
    if (message.payoutRecipient !== "") {
      obj.payoutRecipient = message.payoutRecipient;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentVariantBurnPayout>, I>>(
    base?: I
  ): PaymentVariantBurnPayout {
    return PaymentVariantBurnPayout.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentVariantBurnPayout>, I>>(
    object: I
  ): PaymentVariantBurnPayout {
    const message = createBasePaymentVariantBurnPayout();
    message.amountCent =
      object.amountCent !== undefined && object.amountCent !== null
        ? Long.fromValue(object.amountCent)
        : Long.ZERO;
    message.status = object.status ?? "";
    message.payoutMethodId = object.payoutMethodId ?? 0;
    message.payoutType = object.payoutType ?? "";
    message.payoutRecipient = object.payoutRecipient ?? "";
    return message;
  },
};

function createBasePaymentVariantEvent(): PaymentVariantEvent {
  return {
    eventTitle: "",
    hostFirstName: "",
    hostLastName: "",
    isHost: false,
    isGuest: false,
    eventCode: "",
    eventDateTime: Long.ZERO,
    priceData: undefined,
  };
}

export const PaymentVariantEvent = {
  encode(
    message: PaymentVariantEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventTitle !== "") {
      writer.uint32(10).string(message.eventTitle);
    }
    if (message.hostFirstName !== "") {
      writer.uint32(18).string(message.hostFirstName);
    }
    if (message.hostLastName !== "") {
      writer.uint32(26).string(message.hostLastName);
    }
    if (message.isHost === true) {
      writer.uint32(32).bool(message.isHost);
    }
    if (message.isGuest === true) {
      writer.uint32(40).bool(message.isGuest);
    }
    if (message.eventCode !== "") {
      writer.uint32(50).string(message.eventCode);
    }
    if (!message.eventDateTime.isZero()) {
      writer.uint32(56).int64(message.eventDateTime);
    }
    if (message.priceData !== undefined) {
      EventPrice.encode(message.priceData, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentVariantEvent {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentVariantEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventTitle = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hostFirstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hostLastName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isHost = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isGuest = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.eventCode = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.eventDateTime = reader.int64() as Long;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.priceData = EventPrice.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentVariantEvent {
    return {
      eventTitle: isSet(object.eventTitle)
        ? globalThis.String(object.eventTitle)
        : "",
      hostFirstName: isSet(object.hostFirstName)
        ? globalThis.String(object.hostFirstName)
        : "",
      hostLastName: isSet(object.hostLastName)
        ? globalThis.String(object.hostLastName)
        : "",
      isHost: isSet(object.isHost) ? globalThis.Boolean(object.isHost) : false,
      isGuest: isSet(object.isGuest)
        ? globalThis.Boolean(object.isGuest)
        : false,
      eventCode: isSet(object.eventCode)
        ? globalThis.String(object.eventCode)
        : "",
      eventDateTime: isSet(object.eventDateTime)
        ? Long.fromValue(object.eventDateTime)
        : Long.ZERO,
      priceData: isSet(object.priceData)
        ? EventPrice.fromJSON(object.priceData)
        : undefined,
    };
  },

  toJSON(message: PaymentVariantEvent): unknown {
    const obj: any = {};
    if (message.eventTitle !== "") {
      obj.eventTitle = message.eventTitle;
    }
    if (message.hostFirstName !== "") {
      obj.hostFirstName = message.hostFirstName;
    }
    if (message.hostLastName !== "") {
      obj.hostLastName = message.hostLastName;
    }
    if (message.isHost === true) {
      obj.isHost = message.isHost;
    }
    if (message.isGuest === true) {
      obj.isGuest = message.isGuest;
    }
    if (message.eventCode !== "") {
      obj.eventCode = message.eventCode;
    }
    if (!message.eventDateTime.isZero()) {
      obj.eventDateTime = (message.eventDateTime || Long.ZERO).toString();
    }
    if (message.priceData !== undefined) {
      obj.priceData = EventPrice.toJSON(message.priceData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentVariantEvent>, I>>(
    base?: I
  ): PaymentVariantEvent {
    return PaymentVariantEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentVariantEvent>, I>>(
    object: I
  ): PaymentVariantEvent {
    const message = createBasePaymentVariantEvent();
    message.eventTitle = object.eventTitle ?? "";
    message.hostFirstName = object.hostFirstName ?? "";
    message.hostLastName = object.hostLastName ?? "";
    message.isHost = object.isHost ?? false;
    message.isGuest = object.isGuest ?? false;
    message.eventCode = object.eventCode ?? "";
    message.eventDateTime =
      object.eventDateTime !== undefined && object.eventDateTime !== null
        ? Long.fromValue(object.eventDateTime)
        : Long.ZERO;
    message.priceData =
      object.priceData !== undefined && object.priceData !== null
        ? EventPrice.fromPartial(object.priceData)
        : undefined;
    return message;
  },
};

function createBasePaymentVariantUserTransfer(): PaymentVariantUserTransfer {
  return { participants: [] };
}

export const PaymentVariantUserTransfer = {
  encode(
    message: PaymentVariantUserTransfer,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.participants) {
      UserTransferParticipant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PaymentVariantUserTransfer {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentVariantUserTransfer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.participants.push(
            UserTransferParticipant.decode(reader, reader.uint32())
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentVariantUserTransfer {
    return {
      participants: globalThis.Array.isArray(object?.participants)
        ? object.participants.map((e: any) =>
            UserTransferParticipant.fromJSON(e)
          )
        : [],
    };
  },

  toJSON(message: PaymentVariantUserTransfer): unknown {
    const obj: any = {};
    if (message.participants?.length) {
      obj.participants = message.participants.map(e =>
        UserTransferParticipant.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentVariantUserTransfer>, I>>(
    base?: I
  ): PaymentVariantUserTransfer {
    return PaymentVariantUserTransfer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentVariantUserTransfer>, I>>(
    object: I
  ): PaymentVariantUserTransfer {
    const message = createBasePaymentVariantUserTransfer();
    message.participants =
      object.participants?.map(e => UserTransferParticipant.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseUserTransferParticipant(): UserTransferParticipant {
  return {
    userId: 0,
    firstName: "",
    lastName: "",
    deltaTokenAmount: Long.ZERO,
  };
}

export const UserTransferParticipant = {
  encode(
    message: UserTransferParticipant,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (!message.deltaTokenAmount.isZero()) {
      writer.uint32(32).int64(message.deltaTokenAmount);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UserTransferParticipant {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserTransferParticipant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deltaTokenAmount = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserTransferParticipant {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
      deltaTokenAmount: isSet(object.deltaTokenAmount)
        ? Long.fromValue(object.deltaTokenAmount)
        : Long.ZERO,
    };
  },

  toJSON(message: UserTransferParticipant): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (!message.deltaTokenAmount.isZero()) {
      obj.deltaTokenAmount = (message.deltaTokenAmount || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserTransferParticipant>, I>>(
    base?: I
  ): UserTransferParticipant {
    return UserTransferParticipant.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserTransferParticipant>, I>>(
    object: I
  ): UserTransferParticipant {
    const message = createBaseUserTransferParticipant();
    message.userId = object.userId ?? 0;
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.deltaTokenAmount =
      object.deltaTokenAmount !== undefined && object.deltaTokenAmount !== null
        ? Long.fromValue(object.deltaTokenAmount)
        : Long.ZERO;
    return message;
  },
};

function createBaseGuestCheckinMsg(): GuestCheckinMsg {
  return {
    guestId: 0,
    eventId: 0,
    timestamp: Long.ZERO,
    isCheckin: false,
    isManualByDoorman: false,
  };
}

export const GuestCheckinMsg = {
  encode(
    message: GuestCheckinMsg,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.guestId !== 0) {
      writer.uint32(8).int32(message.guestId);
    }
    if (message.eventId !== 0) {
      writer.uint32(16).int32(message.eventId);
    }
    if (!message.timestamp.isZero()) {
      writer.uint32(24).int64(message.timestamp);
    }
    if (message.isCheckin === true) {
      writer.uint32(32).bool(message.isCheckin);
    }
    if (message.isManualByDoorman === true) {
      writer.uint32(40).bool(message.isManualByDoorman);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GuestCheckinMsg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGuestCheckinMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.guestId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timestamp = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isCheckin = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isManualByDoorman = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GuestCheckinMsg {
    return {
      guestId: isSet(object.guestId) ? globalThis.Number(object.guestId) : 0,
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      timestamp: isSet(object.timestamp)
        ? Long.fromValue(object.timestamp)
        : Long.ZERO,
      isCheckin: isSet(object.isCheckin)
        ? globalThis.Boolean(object.isCheckin)
        : false,
      isManualByDoorman: isSet(object.isManualByDoorman)
        ? globalThis.Boolean(object.isManualByDoorman)
        : false,
    };
  },

  toJSON(message: GuestCheckinMsg): unknown {
    const obj: any = {};
    if (message.guestId !== 0) {
      obj.guestId = Math.round(message.guestId);
    }
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (!message.timestamp.isZero()) {
      obj.timestamp = (message.timestamp || Long.ZERO).toString();
    }
    if (message.isCheckin === true) {
      obj.isCheckin = message.isCheckin;
    }
    if (message.isManualByDoorman === true) {
      obj.isManualByDoorman = message.isManualByDoorman;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GuestCheckinMsg>, I>>(
    base?: I
  ): GuestCheckinMsg {
    return GuestCheckinMsg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GuestCheckinMsg>, I>>(
    object: I
  ): GuestCheckinMsg {
    const message = createBaseGuestCheckinMsg();
    message.guestId = object.guestId ?? 0;
    message.eventId = object.eventId ?? 0;
    message.timestamp =
      object.timestamp !== undefined && object.timestamp !== null
        ? Long.fromValue(object.timestamp)
        : Long.ZERO;
    message.isCheckin = object.isCheckin ?? false;
    message.isManualByDoorman = object.isManualByDoorman ?? false;
    return message;
  },
};

function createBaseCheckinGuest(): CheckinGuest {
  return {
    firstName: "",
    lastName: "",
    birthdayYear: 0,
    birthdayMonth: 0,
    birthdayDay: 0,
    avatarUrl: "",
    quantity: 0,
  };
}

export const CheckinGuest = {
  encode(
    message: CheckinGuest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.firstName !== "") {
      writer.uint32(10).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(18).string(message.lastName);
    }
    if (message.birthdayYear !== 0) {
      writer.uint32(24).int32(message.birthdayYear);
    }
    if (message.birthdayMonth !== 0) {
      writer.uint32(32).uint32(message.birthdayMonth);
    }
    if (message.birthdayDay !== 0) {
      writer.uint32(40).uint32(message.birthdayDay);
    }
    if (message.avatarUrl !== "") {
      writer.uint32(50).string(message.avatarUrl);
    }
    if (message.quantity !== 0) {
      writer.uint32(56).int32(message.quantity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckinGuest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckinGuest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.birthdayYear = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.birthdayMonth = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.birthdayDay = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.quantity = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckinGuest {
    return {
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
      birthdayYear: isSet(object.birthdayYear)
        ? globalThis.Number(object.birthdayYear)
        : 0,
      birthdayMonth: isSet(object.birthdayMonth)
        ? globalThis.Number(object.birthdayMonth)
        : 0,
      birthdayDay: isSet(object.birthdayDay)
        ? globalThis.Number(object.birthdayDay)
        : 0,
      avatarUrl: isSet(object.avatarUrl)
        ? globalThis.String(object.avatarUrl)
        : "",
      quantity: isSet(object.quantity) ? globalThis.Number(object.quantity) : 0,
    };
  },

  toJSON(message: CheckinGuest): unknown {
    const obj: any = {};
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.birthdayYear !== 0) {
      obj.birthdayYear = Math.round(message.birthdayYear);
    }
    if (message.birthdayMonth !== 0) {
      obj.birthdayMonth = Math.round(message.birthdayMonth);
    }
    if (message.birthdayDay !== 0) {
      obj.birthdayDay = Math.round(message.birthdayDay);
    }
    if (message.avatarUrl !== "") {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.quantity !== 0) {
      obj.quantity = Math.round(message.quantity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckinGuest>, I>>(
    base?: I
  ): CheckinGuest {
    return CheckinGuest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckinGuest>, I>>(
    object: I
  ): CheckinGuest {
    const message = createBaseCheckinGuest();
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.birthdayYear = object.birthdayYear ?? 0;
    message.birthdayMonth = object.birthdayMonth ?? 0;
    message.birthdayDay = object.birthdayDay ?? 0;
    message.avatarUrl = object.avatarUrl ?? "";
    message.quantity = object.quantity ?? 0;
    return message;
  },
};

function createBaseCheckinRequest(): CheckinRequest {
  return {
    guestCheckinMsg: new Uint8Array(0),
    guestCheckinSig: new Uint8Array(0),
    performCheckin: false,
    markAsPaid: false,
  };
}

export const CheckinRequest = {
  encode(
    message: CheckinRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.guestCheckinMsg.length !== 0) {
      writer.uint32(10).bytes(message.guestCheckinMsg);
    }
    if (message.guestCheckinSig.length !== 0) {
      writer.uint32(18).bytes(message.guestCheckinSig);
    }
    if (message.performCheckin === true) {
      writer.uint32(24).bool(message.performCheckin);
    }
    if (message.markAsPaid === true) {
      writer.uint32(32).bool(message.markAsPaid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckinRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckinRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.guestCheckinMsg = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.guestCheckinSig = reader.bytes();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.performCheckin = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.markAsPaid = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckinRequest {
    return {
      guestCheckinMsg: isSet(object.guestCheckinMsg)
        ? bytesFromBase64(object.guestCheckinMsg)
        : new Uint8Array(0),
      guestCheckinSig: isSet(object.guestCheckinSig)
        ? bytesFromBase64(object.guestCheckinSig)
        : new Uint8Array(0),
      performCheckin: isSet(object.performCheckin)
        ? globalThis.Boolean(object.performCheckin)
        : false,
      markAsPaid: isSet(object.markAsPaid)
        ? globalThis.Boolean(object.markAsPaid)
        : false,
    };
  },

  toJSON(message: CheckinRequest): unknown {
    const obj: any = {};
    if (message.guestCheckinMsg.length !== 0) {
      obj.guestCheckinMsg = base64FromBytes(message.guestCheckinMsg);
    }
    if (message.guestCheckinSig.length !== 0) {
      obj.guestCheckinSig = base64FromBytes(message.guestCheckinSig);
    }
    if (message.performCheckin === true) {
      obj.performCheckin = message.performCheckin;
    }
    if (message.markAsPaid === true) {
      obj.markAsPaid = message.markAsPaid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckinRequest>, I>>(
    base?: I
  ): CheckinRequest {
    return CheckinRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckinRequest>, I>>(
    object: I
  ): CheckinRequest {
    const message = createBaseCheckinRequest();
    message.guestCheckinMsg = object.guestCheckinMsg ?? new Uint8Array(0);
    message.guestCheckinSig = object.guestCheckinSig ?? new Uint8Array(0);
    message.performCheckin = object.performCheckin ?? false;
    message.markAsPaid = object.markAsPaid ?? false;
    return message;
  },
};

function createBaseCheckinResponse(): CheckinResponse {
  return {
    verifyType: "",
    checkinPerformedSuccessfully: false,
    user: undefined,
    guests: [],
    eventDetails: undefined,
    mustPay: false,
  };
}

export const CheckinResponse = {
  encode(
    message: CheckinResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.verifyType !== "") {
      writer.uint32(10).string(message.verifyType);
    }
    if (message.checkinPerformedSuccessfully === true) {
      writer.uint32(16).bool(message.checkinPerformedSuccessfully);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.guests) {
      CheckinGuest.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.eventDetails !== undefined) {
      EventDetails.encode(
        message.eventDetails,
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.mustPay === true) {
      writer.uint32(40).bool(message.mustPay);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckinResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckinResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.verifyType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.checkinPerformedSuccessfully = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.guests.push(CheckinGuest.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.eventDetails = EventDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mustPay = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckinResponse {
    return {
      verifyType: isSet(object.verifyType)
        ? globalThis.String(object.verifyType)
        : "",
      checkinPerformedSuccessfully: isSet(object.checkinPerformedSuccessfully)
        ? globalThis.Boolean(object.checkinPerformedSuccessfully)
        : false,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      guests: globalThis.Array.isArray(object?.guests)
        ? object.guests.map((e: any) => CheckinGuest.fromJSON(e))
        : [],
      eventDetails: isSet(object.eventDetails)
        ? EventDetails.fromJSON(object.eventDetails)
        : undefined,
      mustPay: isSet(object.mustPay)
        ? globalThis.Boolean(object.mustPay)
        : false,
    };
  },

  toJSON(message: CheckinResponse): unknown {
    const obj: any = {};
    if (message.verifyType !== "") {
      obj.verifyType = message.verifyType;
    }
    if (message.checkinPerformedSuccessfully === true) {
      obj.checkinPerformedSuccessfully = message.checkinPerformedSuccessfully;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.guests?.length) {
      obj.guests = message.guests.map(e => CheckinGuest.toJSON(e));
    }
    if (message.eventDetails !== undefined) {
      obj.eventDetails = EventDetails.toJSON(message.eventDetails);
    }
    if (message.mustPay === true) {
      obj.mustPay = message.mustPay;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckinResponse>, I>>(
    base?: I
  ): CheckinResponse {
    return CheckinResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckinResponse>, I>>(
    object: I
  ): CheckinResponse {
    const message = createBaseCheckinResponse();
    message.verifyType = object.verifyType ?? "";
    message.checkinPerformedSuccessfully =
      object.checkinPerformedSuccessfully ?? false;
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.guests = object.guests?.map(e => CheckinGuest.fromPartial(e)) || [];
    message.eventDetails =
      object.eventDetails !== undefined && object.eventDetails !== null
        ? EventDetails.fromPartial(object.eventDetails)
        : undefined;
    message.mustPay = object.mustPay ?? false;
    return message;
  },
};

function createBaseEventRequest(): EventRequest {
  return { eventCode: "" };
}

export const EventRequest = {
  encode(
    message: EventRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventCode !== "") {
      writer.uint32(10).string(message.eventCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventRequest {
    return {
      eventCode: isSet(object.eventCode)
        ? globalThis.String(object.eventCode)
        : "",
    };
  },

  toJSON(message: EventRequest): unknown {
    const obj: any = {};
    if (message.eventCode !== "") {
      obj.eventCode = message.eventCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventRequest>, I>>(
    base?: I
  ): EventRequest {
    return EventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventRequest>, I>>(
    object: I
  ): EventRequest {
    const message = createBaseEventRequest();
    message.eventCode = object.eventCode ?? "";
    return message;
  },
};

function createBaseStartEventRequest(): StartEventRequest {
  return { eventId: 0 };
}

export const StartEventRequest = {
  encode(
    message: StartEventRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartEventRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartEventRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
    };
  },

  toJSON(message: StartEventRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartEventRequest>, I>>(
    base?: I
  ): StartEventRequest {
    return StartEventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartEventRequest>, I>>(
    object: I
  ): StartEventRequest {
    const message = createBaseStartEventRequest();
    message.eventId = object.eventId ?? 0;
    return message;
  },
};

function createBaseTrashGuestRequest(): TrashGuestRequest {
  return { eventId: 0 };
}

export const TrashGuestRequest = {
  encode(
    message: TrashGuestRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TrashGuestRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrashGuestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrashGuestRequest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
    };
  },

  toJSON(message: TrashGuestRequest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrashGuestRequest>, I>>(
    base?: I
  ): TrashGuestRequest {
    return TrashGuestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrashGuestRequest>, I>>(
    object: I
  ): TrashGuestRequest {
    const message = createBaseTrashGuestRequest();
    message.eventId = object.eventId ?? 0;
    return message;
  },
};

function createBaseCreateEmailTokenResponse(): CreateEmailTokenResponse {
  return { emailTokenId: "", seedPhrasePk: new Uint8Array(0) };
}

export const CreateEmailTokenResponse = {
  encode(
    message: CreateEmailTokenResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.emailTokenId !== "") {
      writer.uint32(10).string(message.emailTokenId);
    }
    if (message.seedPhrasePk.length !== 0) {
      writer.uint32(18).bytes(message.seedPhrasePk);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateEmailTokenResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateEmailTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emailTokenId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.seedPhrasePk = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateEmailTokenResponse {
    return {
      emailTokenId: isSet(object.emailTokenId)
        ? globalThis.String(object.emailTokenId)
        : "",
      seedPhrasePk: isSet(object.seedPhrasePk)
        ? bytesFromBase64(object.seedPhrasePk)
        : new Uint8Array(0),
    };
  },

  toJSON(message: CreateEmailTokenResponse): unknown {
    const obj: any = {};
    if (message.emailTokenId !== "") {
      obj.emailTokenId = message.emailTokenId;
    }
    if (message.seedPhrasePk.length !== 0) {
      obj.seedPhrasePk = base64FromBytes(message.seedPhrasePk);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateEmailTokenResponse>, I>>(
    base?: I
  ): CreateEmailTokenResponse {
    return CreateEmailTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateEmailTokenResponse>, I>>(
    object: I
  ): CreateEmailTokenResponse {
    const message = createBaseCreateEmailTokenResponse();
    message.emailTokenId = object.emailTokenId ?? "";
    message.seedPhrasePk = object.seedPhrasePk ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSendOtpRequest(): SendOtpRequest {
  return { phoneNumber: "", isSignin: false };
}

export const SendOtpRequest = {
  encode(
    message: SendOtpRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.phoneNumber !== "") {
      writer.uint32(10).string(message.phoneNumber);
    }
    if (message.isSignin === true) {
      writer.uint32(16).bool(message.isSignin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendOtpRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOtpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isSignin = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOtpRequest {
    return {
      phoneNumber: isSet(object.phoneNumber)
        ? globalThis.String(object.phoneNumber)
        : "",
      isSignin: isSet(object.isSignin)
        ? globalThis.Boolean(object.isSignin)
        : false,
    };
  },

  toJSON(message: SendOtpRequest): unknown {
    const obj: any = {};
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.isSignin === true) {
      obj.isSignin = message.isSignin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendOtpRequest>, I>>(
    base?: I
  ): SendOtpRequest {
    return SendOtpRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendOtpRequest>, I>>(
    object: I
  ): SendOtpRequest {
    const message = createBaseSendOtpRequest();
    message.phoneNumber = object.phoneNumber ?? "";
    message.isSignin = object.isSignin ?? false;
    return message;
  },
};

function createBaseSendOtpResponse(): SendOtpResponse {
  return { isSuccess: false, resendTimestamp: Long.ZERO };
}

export const SendOtpResponse = {
  encode(
    message: SendOtpResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.isSuccess === true) {
      writer.uint32(8).bool(message.isSuccess);
    }
    if (!message.resendTimestamp.isZero()) {
      writer.uint32(16).int64(message.resendTimestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendOtpResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendOtpResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isSuccess = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resendTimestamp = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendOtpResponse {
    return {
      isSuccess: isSet(object.isSuccess)
        ? globalThis.Boolean(object.isSuccess)
        : false,
      resendTimestamp: isSet(object.resendTimestamp)
        ? Long.fromValue(object.resendTimestamp)
        : Long.ZERO,
    };
  },

  toJSON(message: SendOtpResponse): unknown {
    const obj: any = {};
    if (message.isSuccess === true) {
      obj.isSuccess = message.isSuccess;
    }
    if (!message.resendTimestamp.isZero()) {
      obj.resendTimestamp = (message.resendTimestamp || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendOtpResponse>, I>>(
    base?: I
  ): SendOtpResponse {
    return SendOtpResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendOtpResponse>, I>>(
    object: I
  ): SendOtpResponse {
    const message = createBaseSendOtpResponse();
    message.isSuccess = object.isSuccess ?? false;
    message.resendTimestamp =
      object.resendTimestamp !== undefined && object.resendTimestamp !== null
        ? Long.fromValue(object.resendTimestamp)
        : Long.ZERO;
    return message;
  },
};

function createBaseVerifyOtpRequest(): VerifyOtpRequest {
  return { phoneNumber: "", otpCode: "", consentTypes: [], isSignin: false };
}

export const VerifyOtpRequest = {
  encode(
    message: VerifyOtpRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.phoneNumber !== "") {
      writer.uint32(10).string(message.phoneNumber);
    }
    if (message.otpCode !== "") {
      writer.uint32(18).string(message.otpCode);
    }
    for (const v of message.consentTypes) {
      writer.uint32(26).string(v!);
    }
    if (message.isSignin === true) {
      writer.uint32(32).bool(message.isSignin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyOtpRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyOtpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.otpCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.consentTypes.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isSignin = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyOtpRequest {
    return {
      phoneNumber: isSet(object.phoneNumber)
        ? globalThis.String(object.phoneNumber)
        : "",
      otpCode: isSet(object.otpCode) ? globalThis.String(object.otpCode) : "",
      consentTypes: globalThis.Array.isArray(object?.consentTypes)
        ? object.consentTypes.map((e: any) => globalThis.String(e))
        : [],
      isSignin: isSet(object.isSignin)
        ? globalThis.Boolean(object.isSignin)
        : false,
    };
  },

  toJSON(message: VerifyOtpRequest): unknown {
    const obj: any = {};
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.otpCode !== "") {
      obj.otpCode = message.otpCode;
    }
    if (message.consentTypes?.length) {
      obj.consentTypes = message.consentTypes;
    }
    if (message.isSignin === true) {
      obj.isSignin = message.isSignin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyOtpRequest>, I>>(
    base?: I
  ): VerifyOtpRequest {
    return VerifyOtpRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VerifyOtpRequest>, I>>(
    object: I
  ): VerifyOtpRequest {
    const message = createBaseVerifyOtpRequest();
    message.phoneNumber = object.phoneNumber ?? "";
    message.otpCode = object.otpCode ?? "";
    message.consentTypes = object.consentTypes?.map(e => e) || [];
    message.isSignin = object.isSignin ?? false;
    return message;
  },
};

function createBaseVerifyOtpResponse(): VerifyOtpResponse {
  return {};
}

export const VerifyOtpResponse = {
  encode(
    _: VerifyOtpResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyOtpResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyOtpResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): VerifyOtpResponse {
    return {};
  },

  toJSON(_: VerifyOtpResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyOtpResponse>, I>>(
    base?: I
  ): VerifyOtpResponse {
    return VerifyOtpResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VerifyOtpResponse>, I>>(
    _: I
  ): VerifyOtpResponse {
    const message = createBaseVerifyOtpResponse();
    return message;
  },
};

function createBaseNewsletterSubscriptionStatusResponse(): NewsletterSubscriptionStatusResponse {
  return { isSubscribed: false };
}

export const NewsletterSubscriptionStatusResponse = {
  encode(
    message: NewsletterSubscriptionStatusResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.isSubscribed === true) {
      writer.uint32(8).bool(message.isSubscribed);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): NewsletterSubscriptionStatusResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewsletterSubscriptionStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isSubscribed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewsletterSubscriptionStatusResponse {
    return {
      isSubscribed: isSet(object.isSubscribed)
        ? globalThis.Boolean(object.isSubscribed)
        : false,
    };
  },

  toJSON(message: NewsletterSubscriptionStatusResponse): unknown {
    const obj: any = {};
    if (message.isSubscribed === true) {
      obj.isSubscribed = message.isSubscribed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewsletterSubscriptionStatusResponse>, I>>(
    base?: I
  ): NewsletterSubscriptionStatusResponse {
    return NewsletterSubscriptionStatusResponse.fromPartial(
      base ?? ({} as any)
    );
  },
  fromPartial<
    I extends Exact<DeepPartial<NewsletterSubscriptionStatusResponse>, I>
  >(object: I): NewsletterSubscriptionStatusResponse {
    const message = createBaseNewsletterSubscriptionStatusResponse();
    message.isSubscribed = object.isSubscribed ?? false;
    return message;
  },
};

function createBaseGetBrowserSessionRequest(): GetBrowserSessionRequest {
  return { sessionId: "" };
}

export const GetBrowserSessionRequest = {
  encode(
    message: GetBrowserSessionRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.sessionId !== "") {
      writer.uint32(10).string(message.sessionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetBrowserSessionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBrowserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBrowserSessionRequest {
    return {
      sessionId: isSet(object.sessionId)
        ? globalThis.String(object.sessionId)
        : "",
    };
  },

  toJSON(message: GetBrowserSessionRequest): unknown {
    const obj: any = {};
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBrowserSessionRequest>, I>>(
    base?: I
  ): GetBrowserSessionRequest {
    return GetBrowserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBrowserSessionRequest>, I>>(
    object: I
  ): GetBrowserSessionRequest {
    const message = createBaseGetBrowserSessionRequest();
    message.sessionId = object.sessionId ?? "";
    return message;
  },
};

function createBaseGetBrowserSessionResponse(): GetBrowserSessionResponse {
  return { nextEventCode: "" };
}

export const GetBrowserSessionResponse = {
  encode(
    message: GetBrowserSessionResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.nextEventCode !== "") {
      writer.uint32(10).string(message.nextEventCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetBrowserSessionResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBrowserSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextEventCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBrowserSessionResponse {
    return {
      nextEventCode: isSet(object.nextEventCode)
        ? globalThis.String(object.nextEventCode)
        : "",
    };
  },

  toJSON(message: GetBrowserSessionResponse): unknown {
    const obj: any = {};
    if (message.nextEventCode !== "") {
      obj.nextEventCode = message.nextEventCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBrowserSessionResponse>, I>>(
    base?: I
  ): GetBrowserSessionResponse {
    return GetBrowserSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBrowserSessionResponse>, I>>(
    object: I
  ): GetBrowserSessionResponse {
    const message = createBaseGetBrowserSessionResponse();
    message.nextEventCode = object.nextEventCode ?? "";
    return message;
  },
};

function createBaseResetBrowserSessionRequest(): ResetBrowserSessionRequest {
  return { sessionId: "" };
}

export const ResetBrowserSessionRequest = {
  encode(
    message: ResetBrowserSessionRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.sessionId !== "") {
      writer.uint32(10).string(message.sessionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ResetBrowserSessionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetBrowserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetBrowserSessionRequest {
    return {
      sessionId: isSet(object.sessionId)
        ? globalThis.String(object.sessionId)
        : "",
    };
  },

  toJSON(message: ResetBrowserSessionRequest): unknown {
    const obj: any = {};
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetBrowserSessionRequest>, I>>(
    base?: I
  ): ResetBrowserSessionRequest {
    return ResetBrowserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetBrowserSessionRequest>, I>>(
    object: I
  ): ResetBrowserSessionRequest {
    const message = createBaseResetBrowserSessionRequest();
    message.sessionId = object.sessionId ?? "";
    return message;
  },
};

function createBaseSendVerificationEmailRequest(): SendVerificationEmailRequest {
  return { emailTokenId: "", encryptedSeedPhrase: new Uint8Array(0) };
}

export const SendVerificationEmailRequest = {
  encode(
    message: SendVerificationEmailRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.emailTokenId !== "") {
      writer.uint32(10).string(message.emailTokenId);
    }
    if (message.encryptedSeedPhrase.length !== 0) {
      writer.uint32(18).bytes(message.encryptedSeedPhrase);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SendVerificationEmailRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendVerificationEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emailTokenId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.encryptedSeedPhrase = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendVerificationEmailRequest {
    return {
      emailTokenId: isSet(object.emailTokenId)
        ? globalThis.String(object.emailTokenId)
        : "",
      encryptedSeedPhrase: isSet(object.encryptedSeedPhrase)
        ? bytesFromBase64(object.encryptedSeedPhrase)
        : new Uint8Array(0),
    };
  },

  toJSON(message: SendVerificationEmailRequest): unknown {
    const obj: any = {};
    if (message.emailTokenId !== "") {
      obj.emailTokenId = message.emailTokenId;
    }
    if (message.encryptedSeedPhrase.length !== 0) {
      obj.encryptedSeedPhrase = base64FromBytes(message.encryptedSeedPhrase);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendVerificationEmailRequest>, I>>(
    base?: I
  ): SendVerificationEmailRequest {
    return SendVerificationEmailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendVerificationEmailRequest>, I>>(
    object: I
  ): SendVerificationEmailRequest {
    const message = createBaseSendVerificationEmailRequest();
    message.emailTokenId = object.emailTokenId ?? "";
    message.encryptedSeedPhrase =
      object.encryptedSeedPhrase ?? new Uint8Array(0);
    return message;
  },
};

function createBaseVersionResponse(): VersionResponse {
  return { versionNumber: 0 };
}

export const VersionResponse = {
  encode(
    message: VersionResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.versionNumber !== 0) {
      writer.uint32(8).int32(message.versionNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VersionResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVersionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.versionNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VersionResponse {
    return {
      versionNumber: isSet(object.versionNumber)
        ? globalThis.Number(object.versionNumber)
        : 0,
    };
  },

  toJSON(message: VersionResponse): unknown {
    const obj: any = {};
    if (message.versionNumber !== 0) {
      obj.versionNumber = Math.round(message.versionNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VersionResponse>, I>>(
    base?: I
  ): VersionResponse {
    return VersionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VersionResponse>, I>>(
    object: I
  ): VersionResponse {
    const message = createBaseVersionResponse();
    message.versionNumber = object.versionNumber ?? 0;
    return message;
  },
};

function createBaseCreateMintCodePaymentIntentRequest(): CreateMintCodePaymentIntentRequest {
  return { tokenBaseAmount: Long.ZERO, eventCode: "", hideDetails: false };
}

export const CreateMintCodePaymentIntentRequest = {
  encode(
    message: CreateMintCodePaymentIntentRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.tokenBaseAmount.isZero()) {
      writer.uint32(8).int64(message.tokenBaseAmount);
    }
    if (message.eventCode !== "") {
      writer.uint32(18).string(message.eventCode);
    }
    if (message.hideDetails === true) {
      writer.uint32(32).bool(message.hideDetails);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateMintCodePaymentIntentRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMintCodePaymentIntentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tokenBaseAmount = reader.int64() as Long;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.eventCode = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hideDetails = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMintCodePaymentIntentRequest {
    return {
      tokenBaseAmount: isSet(object.tokenBaseAmount)
        ? Long.fromValue(object.tokenBaseAmount)
        : Long.ZERO,
      eventCode: isSet(object.eventCode)
        ? globalThis.String(object.eventCode)
        : "",
      hideDetails: isSet(object.hideDetails)
        ? globalThis.Boolean(object.hideDetails)
        : false,
    };
  },

  toJSON(message: CreateMintCodePaymentIntentRequest): unknown {
    const obj: any = {};
    if (!message.tokenBaseAmount.isZero()) {
      obj.tokenBaseAmount = (message.tokenBaseAmount || Long.ZERO).toString();
    }
    if (message.eventCode !== "") {
      obj.eventCode = message.eventCode;
    }
    if (message.hideDetails === true) {
      obj.hideDetails = message.hideDetails;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMintCodePaymentIntentRequest>, I>>(
    base?: I
  ): CreateMintCodePaymentIntentRequest {
    return CreateMintCodePaymentIntentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<CreateMintCodePaymentIntentRequest>, I>
  >(object: I): CreateMintCodePaymentIntentRequest {
    const message = createBaseCreateMintCodePaymentIntentRequest();
    message.tokenBaseAmount =
      object.tokenBaseAmount !== undefined && object.tokenBaseAmount !== null
        ? Long.fromValue(object.tokenBaseAmount)
        : Long.ZERO;
    message.eventCode = object.eventCode ?? "";
    message.hideDetails = object.hideDetails ?? false;
    return message;
  },
};

function createBaseCreateMintCodePaymentIntentResponse(): CreateMintCodePaymentIntentResponse {
  return { clientSecret: "", paymentId: "", tokenBaseAmount: Long.ZERO };
}

export const CreateMintCodePaymentIntentResponse = {
  encode(
    message: CreateMintCodePaymentIntentResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.clientSecret !== "") {
      writer.uint32(10).string(message.clientSecret);
    }
    if (message.paymentId !== "") {
      writer.uint32(18).string(message.paymentId);
    }
    if (!message.tokenBaseAmount.isZero()) {
      writer.uint32(24).int64(message.tokenBaseAmount);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateMintCodePaymentIntentResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMintCodePaymentIntentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientSecret = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paymentId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.tokenBaseAmount = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMintCodePaymentIntentResponse {
    return {
      clientSecret: isSet(object.clientSecret)
        ? globalThis.String(object.clientSecret)
        : "",
      paymentId: isSet(object.paymentId)
        ? globalThis.String(object.paymentId)
        : "",
      tokenBaseAmount: isSet(object.tokenBaseAmount)
        ? Long.fromValue(object.tokenBaseAmount)
        : Long.ZERO,
    };
  },

  toJSON(message: CreateMintCodePaymentIntentResponse): unknown {
    const obj: any = {};
    if (message.clientSecret !== "") {
      obj.clientSecret = message.clientSecret;
    }
    if (message.paymentId !== "") {
      obj.paymentId = message.paymentId;
    }
    if (!message.tokenBaseAmount.isZero()) {
      obj.tokenBaseAmount = (message.tokenBaseAmount || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMintCodePaymentIntentResponse>, I>>(
    base?: I
  ): CreateMintCodePaymentIntentResponse {
    return CreateMintCodePaymentIntentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<CreateMintCodePaymentIntentResponse>, I>
  >(object: I): CreateMintCodePaymentIntentResponse {
    const message = createBaseCreateMintCodePaymentIntentResponse();
    message.clientSecret = object.clientSecret ?? "";
    message.paymentId = object.paymentId ?? "";
    message.tokenBaseAmount =
      object.tokenBaseAmount !== undefined && object.tokenBaseAmount !== null
        ? Long.fromValue(object.tokenBaseAmount)
        : Long.ZERO;
    return message;
  },
};

function createBaseStripeLoadPkResponse(): StripeLoadPkResponse {
  return { publishableKey: "" };
}

export const StripeLoadPkResponse = {
  encode(
    message: StripeLoadPkResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.publishableKey !== "") {
      writer.uint32(10).string(message.publishableKey);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StripeLoadPkResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeLoadPkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishableKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeLoadPkResponse {
    return {
      publishableKey: isSet(object.publishableKey)
        ? globalThis.String(object.publishableKey)
        : "",
    };
  },

  toJSON(message: StripeLoadPkResponse): unknown {
    const obj: any = {};
    if (message.publishableKey !== "") {
      obj.publishableKey = message.publishableKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeLoadPkResponse>, I>>(
    base?: I
  ): StripeLoadPkResponse {
    return StripeLoadPkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeLoadPkResponse>, I>>(
    object: I
  ): StripeLoadPkResponse {
    const message = createBaseStripeLoadPkResponse();
    message.publishableKey = object.publishableKey ?? "";
    return message;
  },
};

function createBaseStripeMintCodeRequest(): StripeMintCodeRequest {
  return { paymentId: "" };
}

export const StripeMintCodeRequest = {
  encode(
    message: StripeMintCodeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.paymentId !== "") {
      writer.uint32(10).string(message.paymentId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StripeMintCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeMintCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeMintCodeRequest {
    return {
      paymentId: isSet(object.paymentId)
        ? globalThis.String(object.paymentId)
        : "",
    };
  },

  toJSON(message: StripeMintCodeRequest): unknown {
    const obj: any = {};
    if (message.paymentId !== "") {
      obj.paymentId = message.paymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeMintCodeRequest>, I>>(
    base?: I
  ): StripeMintCodeRequest {
    return StripeMintCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeMintCodeRequest>, I>>(
    object: I
  ): StripeMintCodeRequest {
    const message = createBaseStripeMintCodeRequest();
    message.paymentId = object.paymentId ?? "";
    return message;
  },
};

function createBaseStripeMintCodeResponse(): StripeMintCodeResponse {
  return { mintCode: "" };
}

export const StripeMintCodeResponse = {
  encode(
    message: StripeMintCodeResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.mintCode !== "") {
      writer.uint32(10).string(message.mintCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StripeMintCodeResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeMintCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mintCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeMintCodeResponse {
    return {
      mintCode: isSet(object.mintCode)
        ? globalThis.String(object.mintCode)
        : "",
    };
  },

  toJSON(message: StripeMintCodeResponse): unknown {
    const obj: any = {};
    if (message.mintCode !== "") {
      obj.mintCode = message.mintCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeMintCodeResponse>, I>>(
    base?: I
  ): StripeMintCodeResponse {
    return StripeMintCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeMintCodeResponse>, I>>(
    object: I
  ): StripeMintCodeResponse {
    const message = createBaseStripeMintCodeResponse();
    message.mintCode = object.mintCode ?? "";
    return message;
  },
};

function createBaseEventPayoutMintCodeRequest(): EventPayoutMintCodeRequest {
  return { payoutId: Long.ZERO };
}

export const EventPayoutMintCodeRequest = {
  encode(
    message: EventPayoutMintCodeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.payoutId.isZero()) {
      writer.uint32(8).int64(message.payoutId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): EventPayoutMintCodeRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayoutMintCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.payoutId = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayoutMintCodeRequest {
    return {
      payoutId: isSet(object.payoutId)
        ? Long.fromValue(object.payoutId)
        : Long.ZERO,
    };
  },

  toJSON(message: EventPayoutMintCodeRequest): unknown {
    const obj: any = {};
    if (!message.payoutId.isZero()) {
      obj.payoutId = (message.payoutId || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayoutMintCodeRequest>, I>>(
    base?: I
  ): EventPayoutMintCodeRequest {
    return EventPayoutMintCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayoutMintCodeRequest>, I>>(
    object: I
  ): EventPayoutMintCodeRequest {
    const message = createBaseEventPayoutMintCodeRequest();
    message.payoutId =
      object.payoutId !== undefined && object.payoutId !== null
        ? Long.fromValue(object.payoutId)
        : Long.ZERO;
    return message;
  },
};

function createBaseEventPayoutMintCodeResponse(): EventPayoutMintCodeResponse {
  return { mintCode: "" };
}

export const EventPayoutMintCodeResponse = {
  encode(
    message: EventPayoutMintCodeResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.mintCode !== "") {
      writer.uint32(10).string(message.mintCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): EventPayoutMintCodeResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayoutMintCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mintCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayoutMintCodeResponse {
    return {
      mintCode: isSet(object.mintCode)
        ? globalThis.String(object.mintCode)
        : "",
    };
  },

  toJSON(message: EventPayoutMintCodeResponse): unknown {
    const obj: any = {};
    if (message.mintCode !== "") {
      obj.mintCode = message.mintCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayoutMintCodeResponse>, I>>(
    base?: I
  ): EventPayoutMintCodeResponse {
    return EventPayoutMintCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayoutMintCodeResponse>, I>>(
    object: I
  ): EventPayoutMintCodeResponse {
    const message = createBaseEventPayoutMintCodeResponse();
    message.mintCode = object.mintCode ?? "";
    return message;
  },
};

function createBaseRefundablePurchasesResponse(): RefundablePurchasesResponse {
  return { refundablePurchases: [] };
}

export const RefundablePurchasesResponse = {
  encode(
    message: RefundablePurchasesResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.refundablePurchases) {
      RefundablePurchase.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RefundablePurchasesResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefundablePurchasesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refundablePurchases.push(
            RefundablePurchase.decode(reader, reader.uint32())
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefundablePurchasesResponse {
    return {
      refundablePurchases: globalThis.Array.isArray(object?.refundablePurchases)
        ? object.refundablePurchases.map((e: any) =>
            RefundablePurchase.fromJSON(e)
          )
        : [],
    };
  },

  toJSON(message: RefundablePurchasesResponse): unknown {
    const obj: any = {};
    if (message.refundablePurchases?.length) {
      obj.refundablePurchases = message.refundablePurchases.map(e =>
        RefundablePurchase.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefundablePurchasesResponse>, I>>(
    base?: I
  ): RefundablePurchasesResponse {
    return RefundablePurchasesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefundablePurchasesResponse>, I>>(
    object: I
  ): RefundablePurchasesResponse {
    const message = createBaseRefundablePurchasesResponse();
    message.refundablePurchases =
      object.refundablePurchases?.map(e => RefundablePurchase.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseRefundablePurchase(): RefundablePurchase {
  return {
    purchaseId: 0,
    refundableAmountCent: Long.ZERO,
    timestampCreated: Long.ZERO,
    processor: "",
  };
}

export const RefundablePurchase = {
  encode(
    message: RefundablePurchase,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.purchaseId !== 0) {
      writer.uint32(8).int32(message.purchaseId);
    }
    if (!message.refundableAmountCent.isZero()) {
      writer.uint32(16).int64(message.refundableAmountCent);
    }
    if (!message.timestampCreated.isZero()) {
      writer.uint32(24).int64(message.timestampCreated);
    }
    if (message.processor !== "") {
      writer.uint32(34).string(message.processor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefundablePurchase {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefundablePurchase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.purchaseId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.refundableAmountCent = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timestampCreated = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.processor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefundablePurchase {
    return {
      purchaseId: isSet(object.purchaseId)
        ? globalThis.Number(object.purchaseId)
        : 0,
      refundableAmountCent: isSet(object.refundableAmountCent)
        ? Long.fromValue(object.refundableAmountCent)
        : Long.ZERO,
      timestampCreated: isSet(object.timestampCreated)
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO,
      processor: isSet(object.processor)
        ? globalThis.String(object.processor)
        : "",
    };
  },

  toJSON(message: RefundablePurchase): unknown {
    const obj: any = {};
    if (message.purchaseId !== 0) {
      obj.purchaseId = Math.round(message.purchaseId);
    }
    if (!message.refundableAmountCent.isZero()) {
      obj.refundableAmountCent = (
        message.refundableAmountCent || Long.ZERO
      ).toString();
    }
    if (!message.timestampCreated.isZero()) {
      obj.timestampCreated = (message.timestampCreated || Long.ZERO).toString();
    }
    if (message.processor !== "") {
      obj.processor = message.processor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefundablePurchase>, I>>(
    base?: I
  ): RefundablePurchase {
    return RefundablePurchase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefundablePurchase>, I>>(
    object: I
  ): RefundablePurchase {
    const message = createBaseRefundablePurchase();
    message.purchaseId = object.purchaseId ?? 0;
    message.refundableAmountCent =
      object.refundableAmountCent !== undefined &&
      object.refundableAmountCent !== null
        ? Long.fromValue(object.refundableAmountCent)
        : Long.ZERO;
    message.timestampCreated =
      object.timestampCreated !== undefined && object.timestampCreated !== null
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO;
    message.processor = object.processor ?? "";
    return message;
  },
};

function createBasePayoutMethod(): PayoutMethod {
  return {
    payoutMethodId: 0,
    payoutData: undefined,
    timestampCreated: Long.ZERO,
  };
}

export const PayoutMethod = {
  encode(
    message: PayoutMethod,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.payoutMethodId !== 0) {
      writer.uint32(8).int32(message.payoutMethodId);
    }
    if (message.payoutData !== undefined) {
      PayoutData.encode(message.payoutData, writer.uint32(18).fork()).ldelim();
    }
    if (!message.timestampCreated.isZero()) {
      writer.uint32(24).int64(message.timestampCreated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutMethod {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.payoutMethodId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payoutData = PayoutData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timestampCreated = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutMethod {
    return {
      payoutMethodId: isSet(object.payoutMethodId)
        ? globalThis.Number(object.payoutMethodId)
        : 0,
      payoutData: isSet(object.payoutData)
        ? PayoutData.fromJSON(object.payoutData)
        : undefined,
      timestampCreated: isSet(object.timestampCreated)
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO,
    };
  },

  toJSON(message: PayoutMethod): unknown {
    const obj: any = {};
    if (message.payoutMethodId !== 0) {
      obj.payoutMethodId = Math.round(message.payoutMethodId);
    }
    if (message.payoutData !== undefined) {
      obj.payoutData = PayoutData.toJSON(message.payoutData);
    }
    if (!message.timestampCreated.isZero()) {
      obj.timestampCreated = (message.timestampCreated || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutMethod>, I>>(
    base?: I
  ): PayoutMethod {
    return PayoutMethod.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PayoutMethod>, I>>(
    object: I
  ): PayoutMethod {
    const message = createBasePayoutMethod();
    message.payoutMethodId = object.payoutMethodId ?? 0;
    message.payoutData =
      object.payoutData !== undefined && object.payoutData !== null
        ? PayoutData.fromPartial(object.payoutData)
        : undefined;
    message.timestampCreated =
      object.timestampCreated !== undefined && object.timestampCreated !== null
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO;
    return message;
  },
};

function createBasePayoutData(): PayoutData {
  return { recipientId: "", paypal: undefined, usBank: undefined };
}

export const PayoutData = {
  encode(
    message: PayoutData,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.recipientId !== "") {
      writer.uint32(26).string(message.recipientId);
    }
    if (message.paypal !== undefined) {
      PayoutDataPayPal.encode(
        message.paypal,
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.usBank !== undefined) {
      PayoutDataUsBank.encode(
        message.usBank,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipientId = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paypal = PayoutDataPayPal.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.usBank = PayoutDataUsBank.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutData {
    return {
      recipientId: isSet(object.recipientId)
        ? globalThis.String(object.recipientId)
        : "",
      paypal: isSet(object.paypal)
        ? PayoutDataPayPal.fromJSON(object.paypal)
        : undefined,
      usBank: isSet(object.usBank)
        ? PayoutDataUsBank.fromJSON(object.usBank)
        : undefined,
    };
  },

  toJSON(message: PayoutData): unknown {
    const obj: any = {};
    if (message.recipientId !== "") {
      obj.recipientId = message.recipientId;
    }
    if (message.paypal !== undefined) {
      obj.paypal = PayoutDataPayPal.toJSON(message.paypal);
    }
    if (message.usBank !== undefined) {
      obj.usBank = PayoutDataUsBank.toJSON(message.usBank);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutData>, I>>(base?: I): PayoutData {
    return PayoutData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PayoutData>, I>>(
    object: I
  ): PayoutData {
    const message = createBasePayoutData();
    message.recipientId = object.recipientId ?? "";
    message.paypal =
      object.paypal !== undefined && object.paypal !== null
        ? PayoutDataPayPal.fromPartial(object.paypal)
        : undefined;
    message.usBank =
      object.usBank !== undefined && object.usBank !== null
        ? PayoutDataUsBank.fromPartial(object.usBank)
        : undefined;
    return message;
  },
};

function createBasePayoutDataPayPal(): PayoutDataPayPal {
  return { paypalEmail: "" };
}

export const PayoutDataPayPal = {
  encode(
    message: PayoutDataPayPal,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.paypalEmail !== "") {
      writer.uint32(10).string(message.paypalEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutDataPayPal {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutDataPayPal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paypalEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutDataPayPal {
    return {
      paypalEmail: isSet(object.paypalEmail)
        ? globalThis.String(object.paypalEmail)
        : "",
    };
  },

  toJSON(message: PayoutDataPayPal): unknown {
    const obj: any = {};
    if (message.paypalEmail !== "") {
      obj.paypalEmail = message.paypalEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutDataPayPal>, I>>(
    base?: I
  ): PayoutDataPayPal {
    return PayoutDataPayPal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PayoutDataPayPal>, I>>(
    object: I
  ): PayoutDataPayPal {
    const message = createBasePayoutDataPayPal();
    message.paypalEmail = object.paypalEmail ?? "";
    return message;
  },
};

function createBasePayoutDataUsBank(): PayoutDataUsBank {
  return {
    accountHolderType: "",
    accountType: "",
    accountHolderName: "",
    routingNumber: "",
    accountNumber: "",
    address: undefined,
    bankName: "",
  };
}

export const PayoutDataUsBank = {
  encode(
    message: PayoutDataUsBank,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.accountHolderType !== "") {
      writer.uint32(10).string(message.accountHolderType);
    }
    if (message.accountType !== "") {
      writer.uint32(18).string(message.accountType);
    }
    if (message.accountHolderName !== "") {
      writer.uint32(26).string(message.accountHolderName);
    }
    if (message.routingNumber !== "") {
      writer.uint32(34).string(message.routingNumber);
    }
    if (message.accountNumber !== "") {
      writer.uint32(42).string(message.accountNumber);
    }
    if (message.address !== undefined) {
      AccountAddress.encode(message.address, writer.uint32(50).fork()).ldelim();
    }
    if (message.bankName !== "") {
      writer.uint32(82).string(message.bankName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutDataUsBank {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutDataUsBank();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountHolderType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountHolderName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.routingNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address = AccountAddress.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bankName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutDataUsBank {
    return {
      accountHolderType: isSet(object.accountHolderType)
        ? globalThis.String(object.accountHolderType)
        : "",
      accountType: isSet(object.accountType)
        ? globalThis.String(object.accountType)
        : "",
      accountHolderName: isSet(object.accountHolderName)
        ? globalThis.String(object.accountHolderName)
        : "",
      routingNumber: isSet(object.routingNumber)
        ? globalThis.String(object.routingNumber)
        : "",
      accountNumber: isSet(object.accountNumber)
        ? globalThis.String(object.accountNumber)
        : "",
      address: isSet(object.address)
        ? AccountAddress.fromJSON(object.address)
        : undefined,
      bankName: isSet(object.bankName)
        ? globalThis.String(object.bankName)
        : "",
    };
  },

  toJSON(message: PayoutDataUsBank): unknown {
    const obj: any = {};
    if (message.accountHolderType !== "") {
      obj.accountHolderType = message.accountHolderType;
    }
    if (message.accountType !== "") {
      obj.accountType = message.accountType;
    }
    if (message.accountHolderName !== "") {
      obj.accountHolderName = message.accountHolderName;
    }
    if (message.routingNumber !== "") {
      obj.routingNumber = message.routingNumber;
    }
    if (message.accountNumber !== "") {
      obj.accountNumber = message.accountNumber;
    }
    if (message.address !== undefined) {
      obj.address = AccountAddress.toJSON(message.address);
    }
    if (message.bankName !== "") {
      obj.bankName = message.bankName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutDataUsBank>, I>>(
    base?: I
  ): PayoutDataUsBank {
    return PayoutDataUsBank.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PayoutDataUsBank>, I>>(
    object: I
  ): PayoutDataUsBank {
    const message = createBasePayoutDataUsBank();
    message.accountHolderType = object.accountHolderType ?? "";
    message.accountType = object.accountType ?? "";
    message.accountHolderName = object.accountHolderName ?? "";
    message.routingNumber = object.routingNumber ?? "";
    message.accountNumber = object.accountNumber ?? "";
    message.address =
      object.address !== undefined && object.address !== null
        ? AccountAddress.fromPartial(object.address)
        : undefined;
    message.bankName = object.bankName ?? "";
    return message;
  },
};

function createBaseAccountAddress(): AccountAddress {
  return {
    address1: "",
    address2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
  };
}

export const AccountAddress = {
  encode(
    message: AccountAddress,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.address1 !== "") {
      writer.uint32(10).string(message.address1);
    }
    if (message.address2 !== "") {
      writer.uint32(18).string(message.address2);
    }
    if (message.city !== "") {
      writer.uint32(26).string(message.city);
    }
    if (message.region !== "") {
      writer.uint32(34).string(message.region);
    }
    if (message.postalCode !== "") {
      writer.uint32(42).string(message.postalCode);
    }
    if (message.country !== "") {
      writer.uint32(50).string(message.country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAddress {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address1 = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address2 = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.city = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.region = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.postalCode = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.country = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAddress {
    return {
      address1: isSet(object.address1)
        ? globalThis.String(object.address1)
        : "",
      address2: isSet(object.address2)
        ? globalThis.String(object.address2)
        : "",
      city: isSet(object.city) ? globalThis.String(object.city) : "",
      region: isSet(object.region) ? globalThis.String(object.region) : "",
      postalCode: isSet(object.postalCode)
        ? globalThis.String(object.postalCode)
        : "",
      country: isSet(object.country) ? globalThis.String(object.country) : "",
    };
  },

  toJSON(message: AccountAddress): unknown {
    const obj: any = {};
    if (message.address1 !== "") {
      obj.address1 = message.address1;
    }
    if (message.address2 !== "") {
      obj.address2 = message.address2;
    }
    if (message.city !== "") {
      obj.city = message.city;
    }
    if (message.region !== "") {
      obj.region = message.region;
    }
    if (message.postalCode !== "") {
      obj.postalCode = message.postalCode;
    }
    if (message.country !== "") {
      obj.country = message.country;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAddress>, I>>(
    base?: I
  ): AccountAddress {
    return AccountAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAddress>, I>>(
    object: I
  ): AccountAddress {
    const message = createBaseAccountAddress();
    message.address1 = object.address1 ?? "";
    message.address2 = object.address2 ?? "";
    message.city = object.city ?? "";
    message.region = object.region ?? "";
    message.postalCode = object.postalCode ?? "";
    message.country = object.country ?? "";
    return message;
  },
};

function createBaseCreatePayoutMethodRequest(): CreatePayoutMethodRequest {
  return { payoutData: undefined };
}

export const CreatePayoutMethodRequest = {
  encode(
    message: CreatePayoutMethodRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.payoutData !== undefined) {
      PayoutData.encode(message.payoutData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreatePayoutMethodRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePayoutMethodRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payoutData = PayoutData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreatePayoutMethodRequest {
    return {
      payoutData: isSet(object.payoutData)
        ? PayoutData.fromJSON(object.payoutData)
        : undefined,
    };
  },

  toJSON(message: CreatePayoutMethodRequest): unknown {
    const obj: any = {};
    if (message.payoutData !== undefined) {
      obj.payoutData = PayoutData.toJSON(message.payoutData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreatePayoutMethodRequest>, I>>(
    base?: I
  ): CreatePayoutMethodRequest {
    return CreatePayoutMethodRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatePayoutMethodRequest>, I>>(
    object: I
  ): CreatePayoutMethodRequest {
    const message = createBaseCreatePayoutMethodRequest();
    message.payoutData =
      object.payoutData !== undefined && object.payoutData !== null
        ? PayoutData.fromPartial(object.payoutData)
        : undefined;
    return message;
  },
};

function createBasePayoutMethodsResponse(): PayoutMethodsResponse {
  return { payoutMethods: [] };
}

export const PayoutMethodsResponse = {
  encode(
    message: PayoutMethodsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.payoutMethods) {
      PayoutMethod.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): PayoutMethodsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutMethodsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payoutMethods.push(
            PayoutMethod.decode(reader, reader.uint32())
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutMethodsResponse {
    return {
      payoutMethods: globalThis.Array.isArray(object?.payoutMethods)
        ? object.payoutMethods.map((e: any) => PayoutMethod.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PayoutMethodsResponse): unknown {
    const obj: any = {};
    if (message.payoutMethods?.length) {
      obj.payoutMethods = message.payoutMethods.map(e =>
        PayoutMethod.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutMethodsResponse>, I>>(
    base?: I
  ): PayoutMethodsResponse {
    return PayoutMethodsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PayoutMethodsResponse>, I>>(
    object: I
  ): PayoutMethodsResponse {
    const message = createBasePayoutMethodsResponse();
    message.payoutMethods =
      object.payoutMethods?.map(e => PayoutMethod.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdatePayoutMethodRequest(): UpdatePayoutMethodRequest {
  return { payoutMethodId: 0, payoutData: undefined };
}

export const UpdatePayoutMethodRequest = {
  encode(
    message: UpdatePayoutMethodRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.payoutMethodId !== 0) {
      writer.uint32(8).int32(message.payoutMethodId);
    }
    if (message.payoutData !== undefined) {
      PayoutData.encode(message.payoutData, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdatePayoutMethodRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePayoutMethodRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.payoutMethodId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payoutData = PayoutData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePayoutMethodRequest {
    return {
      payoutMethodId: isSet(object.payoutMethodId)
        ? globalThis.Number(object.payoutMethodId)
        : 0,
      payoutData: isSet(object.payoutData)
        ? PayoutData.fromJSON(object.payoutData)
        : undefined,
    };
  },

  toJSON(message: UpdatePayoutMethodRequest): unknown {
    const obj: any = {};
    if (message.payoutMethodId !== 0) {
      obj.payoutMethodId = Math.round(message.payoutMethodId);
    }
    if (message.payoutData !== undefined) {
      obj.payoutData = PayoutData.toJSON(message.payoutData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePayoutMethodRequest>, I>>(
    base?: I
  ): UpdatePayoutMethodRequest {
    return UpdatePayoutMethodRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePayoutMethodRequest>, I>>(
    object: I
  ): UpdatePayoutMethodRequest {
    const message = createBaseUpdatePayoutMethodRequest();
    message.payoutMethodId = object.payoutMethodId ?? 0;
    message.payoutData =
      object.payoutData !== undefined && object.payoutData !== null
        ? PayoutData.fromPartial(object.payoutData)
        : undefined;
    return message;
  },
};

function createBaseDeletePayoutMethodRequest(): DeletePayoutMethodRequest {
  return { payoutMethodId: 0 };
}

export const DeletePayoutMethodRequest = {
  encode(
    message: DeletePayoutMethodRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.payoutMethodId !== 0) {
      writer.uint32(8).int32(message.payoutMethodId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DeletePayoutMethodRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePayoutMethodRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.payoutMethodId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeletePayoutMethodRequest {
    return {
      payoutMethodId: isSet(object.payoutMethodId)
        ? globalThis.Number(object.payoutMethodId)
        : 0,
    };
  },

  toJSON(message: DeletePayoutMethodRequest): unknown {
    const obj: any = {};
    if (message.payoutMethodId !== 0) {
      obj.payoutMethodId = Math.round(message.payoutMethodId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeletePayoutMethodRequest>, I>>(
    base?: I
  ): DeletePayoutMethodRequest {
    return DeletePayoutMethodRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeletePayoutMethodRequest>, I>>(
    object: I
  ): DeletePayoutMethodRequest {
    const message = createBaseDeletePayoutMethodRequest();
    message.payoutMethodId = object.payoutMethodId ?? 0;
    return message;
  },
};

function createBaseSubmitProofOfBurnRequest(): SubmitProofOfBurnRequest {
  return {
    burnRawTx: new Uint8Array(0),
    signature: "",
    refundPurchaseIds: [],
    payoutMethodId: 0,
    isSimulation: false,
  };
}

export const SubmitProofOfBurnRequest = {
  encode(
    message: SubmitProofOfBurnRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.burnRawTx.length !== 0) {
      writer.uint32(10).bytes(message.burnRawTx);
    }
    if (message.signature !== "") {
      writer.uint32(18).string(message.signature);
    }
    writer.uint32(26).fork();
    for (const v of message.refundPurchaseIds) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.payoutMethodId !== 0) {
      writer.uint32(32).int32(message.payoutMethodId);
    }
    if (message.isSimulation === true) {
      writer.uint32(48).bool(message.isSimulation);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubmitProofOfBurnRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitProofOfBurnRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.burnRawTx = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signature = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.refundPurchaseIds.push(reader.int32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.refundPurchaseIds.push(reader.int32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.payoutMethodId = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isSimulation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitProofOfBurnRequest {
    return {
      burnRawTx: isSet(object.burnRawTx)
        ? bytesFromBase64(object.burnRawTx)
        : new Uint8Array(0),
      signature: isSet(object.signature)
        ? globalThis.String(object.signature)
        : "",
      refundPurchaseIds: globalThis.Array.isArray(object?.refundPurchaseIds)
        ? object.refundPurchaseIds.map((e: any) => globalThis.Number(e))
        : [],
      payoutMethodId: isSet(object.payoutMethodId)
        ? globalThis.Number(object.payoutMethodId)
        : 0,
      isSimulation: isSet(object.isSimulation)
        ? globalThis.Boolean(object.isSimulation)
        : false,
    };
  },

  toJSON(message: SubmitProofOfBurnRequest): unknown {
    const obj: any = {};
    if (message.burnRawTx.length !== 0) {
      obj.burnRawTx = base64FromBytes(message.burnRawTx);
    }
    if (message.signature !== "") {
      obj.signature = message.signature;
    }
    if (message.refundPurchaseIds?.length) {
      obj.refundPurchaseIds = message.refundPurchaseIds.map(e => Math.round(e));
    }
    if (message.payoutMethodId !== 0) {
      obj.payoutMethodId = Math.round(message.payoutMethodId);
    }
    if (message.isSimulation === true) {
      obj.isSimulation = message.isSimulation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitProofOfBurnRequest>, I>>(
    base?: I
  ): SubmitProofOfBurnRequest {
    return SubmitProofOfBurnRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitProofOfBurnRequest>, I>>(
    object: I
  ): SubmitProofOfBurnRequest {
    const message = createBaseSubmitProofOfBurnRequest();
    message.burnRawTx = object.burnRawTx ?? new Uint8Array(0);
    message.signature = object.signature ?? "";
    message.refundPurchaseIds = object.refundPurchaseIds?.map(e => e) || [];
    message.payoutMethodId = object.payoutMethodId ?? 0;
    message.isSimulation = object.isSimulation ?? false;
    return message;
  },
};

function createBaseSubmitProofOfBurnResponse(): SubmitProofOfBurnResponse {
  return { failedRefunds: [], refundIds: [], payoutIds: [], failedPayouts: [] };
}

export const SubmitProofOfBurnResponse = {
  encode(
    message: SubmitProofOfBurnResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.failedRefunds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.refundIds) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.payoutIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.failedPayouts) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubmitProofOfBurnResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitProofOfBurnResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.failedRefunds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refundIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.payoutIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.failedPayouts.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitProofOfBurnResponse {
    return {
      failedRefunds: globalThis.Array.isArray(object?.failedRefunds)
        ? object.failedRefunds.map((e: any) => globalThis.String(e))
        : [],
      refundIds: globalThis.Array.isArray(object?.refundIds)
        ? object.refundIds.map((e: any) => globalThis.String(e))
        : [],
      payoutIds: globalThis.Array.isArray(object?.payoutIds)
        ? object.payoutIds.map((e: any) => globalThis.String(e))
        : [],
      failedPayouts: globalThis.Array.isArray(object?.failedPayouts)
        ? object.failedPayouts.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SubmitProofOfBurnResponse): unknown {
    const obj: any = {};
    if (message.failedRefunds?.length) {
      obj.failedRefunds = message.failedRefunds;
    }
    if (message.refundIds?.length) {
      obj.refundIds = message.refundIds;
    }
    if (message.payoutIds?.length) {
      obj.payoutIds = message.payoutIds;
    }
    if (message.failedPayouts?.length) {
      obj.failedPayouts = message.failedPayouts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitProofOfBurnResponse>, I>>(
    base?: I
  ): SubmitProofOfBurnResponse {
    return SubmitProofOfBurnResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubmitProofOfBurnResponse>, I>>(
    object: I
  ): SubmitProofOfBurnResponse {
    const message = createBaseSubmitProofOfBurnResponse();
    message.failedRefunds = object.failedRefunds?.map(e => e) || [];
    message.refundIds = object.refundIds?.map(e => e) || [];
    message.payoutIds = object.payoutIds?.map(e => e) || [];
    message.failedPayouts = object.failedPayouts?.map(e => e) || [];
    return message;
  },
};

function createBaseProofOfBurnPayoutHistoryRequest(): ProofOfBurnPayoutHistoryRequest {
  return { page: Long.ZERO };
}

export const ProofOfBurnPayoutHistoryRequest = {
  encode(
    message: ProofOfBurnPayoutHistoryRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.page.isZero()) {
      writer.uint32(8).int64(message.page);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ProofOfBurnPayoutHistoryRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProofOfBurnPayoutHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProofOfBurnPayoutHistoryRequest {
    return {
      page: isSet(object.page) ? Long.fromValue(object.page) : Long.ZERO,
    };
  },

  toJSON(message: ProofOfBurnPayoutHistoryRequest): unknown {
    const obj: any = {};
    if (!message.page.isZero()) {
      obj.page = (message.page || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProofOfBurnPayoutHistoryRequest>, I>>(
    base?: I
  ): ProofOfBurnPayoutHistoryRequest {
    return ProofOfBurnPayoutHistoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProofOfBurnPayoutHistoryRequest>, I>>(
    object: I
  ): ProofOfBurnPayoutHistoryRequest {
    const message = createBaseProofOfBurnPayoutHistoryRequest();
    message.page =
      object.page !== undefined && object.page !== null
        ? Long.fromValue(object.page)
        : Long.ZERO;
    return message;
  },
};

function createBaseProofOfBurnPayoutHistoryResponse(): ProofOfBurnPayoutHistoryResponse {
  return { payouts: [], numPages: Long.ZERO };
}

export const ProofOfBurnPayoutHistoryResponse = {
  encode(
    message: ProofOfBurnPayoutHistoryResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.payouts) {
      ProofOfBurnPayout.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (!message.numPages.isZero()) {
      writer.uint32(16).int64(message.numPages);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ProofOfBurnPayoutHistoryResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProofOfBurnPayoutHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payouts.push(
            ProofOfBurnPayout.decode(reader, reader.uint32())
          );
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numPages = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProofOfBurnPayoutHistoryResponse {
    return {
      payouts: globalThis.Array.isArray(object?.payouts)
        ? object.payouts.map((e: any) => ProofOfBurnPayout.fromJSON(e))
        : [],
      numPages: isSet(object.numPages)
        ? Long.fromValue(object.numPages)
        : Long.ZERO,
    };
  },

  toJSON(message: ProofOfBurnPayoutHistoryResponse): unknown {
    const obj: any = {};
    if (message.payouts?.length) {
      obj.payouts = message.payouts.map(e => ProofOfBurnPayout.toJSON(e));
    }
    if (!message.numPages.isZero()) {
      obj.numPages = (message.numPages || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProofOfBurnPayoutHistoryResponse>, I>>(
    base?: I
  ): ProofOfBurnPayoutHistoryResponse {
    return ProofOfBurnPayoutHistoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<ProofOfBurnPayoutHistoryResponse>, I>
  >(object: I): ProofOfBurnPayoutHistoryResponse {
    const message = createBaseProofOfBurnPayoutHistoryResponse();
    message.payouts =
      object.payouts?.map(e => ProofOfBurnPayout.fromPartial(e)) || [];
    message.numPages =
      object.numPages !== undefined && object.numPages !== null
        ? Long.fromValue(object.numPages)
        : Long.ZERO;
    return message;
  },
};

function createBaseProofOfBurnPayout(): ProofOfBurnPayout {
  return {
    txid: new Uint8Array(0),
    tokenAmount: Long.ZERO,
    amountCent: Long.ZERO,
    timestampSubmitted: Long.ZERO,
    status: "",
    payoutMethodId: 0,
    payoutType: "",
    payoutRecipient: "",
  };
}

export const ProofOfBurnPayout = {
  encode(
    message: ProofOfBurnPayout,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.txid.length !== 0) {
      writer.uint32(10).bytes(message.txid);
    }
    if (!message.tokenAmount.isZero()) {
      writer.uint32(16).int64(message.tokenAmount);
    }
    if (!message.amountCent.isZero()) {
      writer.uint32(24).int64(message.amountCent);
    }
    if (!message.timestampSubmitted.isZero()) {
      writer.uint32(32).int64(message.timestampSubmitted);
    }
    if (message.status !== "") {
      writer.uint32(42).string(message.status);
    }
    if (message.payoutMethodId !== 0) {
      writer.uint32(48).int32(message.payoutMethodId);
    }
    if (message.payoutType !== "") {
      writer.uint32(58).string(message.payoutType);
    }
    if (message.payoutRecipient !== "") {
      writer.uint32(66).string(message.payoutRecipient);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProofOfBurnPayout {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProofOfBurnPayout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.txid = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tokenAmount = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.amountCent = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampSubmitted = reader.int64() as Long;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.status = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.payoutMethodId = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.payoutType = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.payoutRecipient = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProofOfBurnPayout {
    return {
      txid: isSet(object.txid)
        ? bytesFromBase64(object.txid)
        : new Uint8Array(0),
      tokenAmount: isSet(object.tokenAmount)
        ? Long.fromValue(object.tokenAmount)
        : Long.ZERO,
      amountCent: isSet(object.amountCent)
        ? Long.fromValue(object.amountCent)
        : Long.ZERO,
      timestampSubmitted: isSet(object.timestampSubmitted)
        ? Long.fromValue(object.timestampSubmitted)
        : Long.ZERO,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      payoutMethodId: isSet(object.payoutMethodId)
        ? globalThis.Number(object.payoutMethodId)
        : 0,
      payoutType: isSet(object.payoutType)
        ? globalThis.String(object.payoutType)
        : "",
      payoutRecipient: isSet(object.payoutRecipient)
        ? globalThis.String(object.payoutRecipient)
        : "",
    };
  },

  toJSON(message: ProofOfBurnPayout): unknown {
    const obj: any = {};
    if (message.txid.length !== 0) {
      obj.txid = base64FromBytes(message.txid);
    }
    if (!message.tokenAmount.isZero()) {
      obj.tokenAmount = (message.tokenAmount || Long.ZERO).toString();
    }
    if (!message.amountCent.isZero()) {
      obj.amountCent = (message.amountCent || Long.ZERO).toString();
    }
    if (!message.timestampSubmitted.isZero()) {
      obj.timestampSubmitted = (
        message.timestampSubmitted || Long.ZERO
      ).toString();
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.payoutMethodId !== 0) {
      obj.payoutMethodId = Math.round(message.payoutMethodId);
    }
    if (message.payoutType !== "") {
      obj.payoutType = message.payoutType;
    }
    if (message.payoutRecipient !== "") {
      obj.payoutRecipient = message.payoutRecipient;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProofOfBurnPayout>, I>>(
    base?: I
  ): ProofOfBurnPayout {
    return ProofOfBurnPayout.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProofOfBurnPayout>, I>>(
    object: I
  ): ProofOfBurnPayout {
    const message = createBaseProofOfBurnPayout();
    message.txid = object.txid ?? new Uint8Array(0);
    message.tokenAmount =
      object.tokenAmount !== undefined && object.tokenAmount !== null
        ? Long.fromValue(object.tokenAmount)
        : Long.ZERO;
    message.amountCent =
      object.amountCent !== undefined && object.amountCent !== null
        ? Long.fromValue(object.amountCent)
        : Long.ZERO;
    message.timestampSubmitted =
      object.timestampSubmitted !== undefined &&
      object.timestampSubmitted !== null
        ? Long.fromValue(object.timestampSubmitted)
        : Long.ZERO;
    message.status = object.status ?? "";
    message.payoutMethodId = object.payoutMethodId ?? 0;
    message.payoutType = object.payoutType ?? "";
    message.payoutRecipient = object.payoutRecipient ?? "";
    return message;
  },
};

function createBaseParsedMintCode(): ParsedMintCode {
  return {
    tokenBaseAmount: Long.ZERO,
    authSig: new Uint8Array(0),
    mintBatonTxid: new Uint8Array(0),
    mintBatonOutIdx: 0,
  };
}

export const ParsedMintCode = {
  encode(
    message: ParsedMintCode,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.tokenBaseAmount.isZero()) {
      writer.uint32(8).int64(message.tokenBaseAmount);
    }
    if (message.authSig.length !== 0) {
      writer.uint32(18).bytes(message.authSig);
    }
    if (message.mintBatonTxid.length !== 0) {
      writer.uint32(26).bytes(message.mintBatonTxid);
    }
    if (message.mintBatonOutIdx !== 0) {
      writer.uint32(32).uint32(message.mintBatonOutIdx);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ParsedMintCode {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParsedMintCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tokenBaseAmount = reader.int64() as Long;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authSig = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mintBatonTxid = reader.bytes();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.mintBatonOutIdx = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ParsedMintCode {
    return {
      tokenBaseAmount: isSet(object.tokenBaseAmount)
        ? Long.fromValue(object.tokenBaseAmount)
        : Long.ZERO,
      authSig: isSet(object.authSig)
        ? bytesFromBase64(object.authSig)
        : new Uint8Array(0),
      mintBatonTxid: isSet(object.mintBatonTxid)
        ? bytesFromBase64(object.mintBatonTxid)
        : new Uint8Array(0),
      mintBatonOutIdx: isSet(object.mintBatonOutIdx)
        ? globalThis.Number(object.mintBatonOutIdx)
        : 0,
    };
  },

  toJSON(message: ParsedMintCode): unknown {
    const obj: any = {};
    if (!message.tokenBaseAmount.isZero()) {
      obj.tokenBaseAmount = (message.tokenBaseAmount || Long.ZERO).toString();
    }
    if (message.authSig.length !== 0) {
      obj.authSig = base64FromBytes(message.authSig);
    }
    if (message.mintBatonTxid.length !== 0) {
      obj.mintBatonTxid = base64FromBytes(message.mintBatonTxid);
    }
    if (message.mintBatonOutIdx !== 0) {
      obj.mintBatonOutIdx = Math.round(message.mintBatonOutIdx);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ParsedMintCode>, I>>(
    base?: I
  ): ParsedMintCode {
    return ParsedMintCode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ParsedMintCode>, I>>(
    object: I
  ): ParsedMintCode {
    const message = createBaseParsedMintCode();
    message.tokenBaseAmount =
      object.tokenBaseAmount !== undefined && object.tokenBaseAmount !== null
        ? Long.fromValue(object.tokenBaseAmount)
        : Long.ZERO;
    message.authSig = object.authSig ?? new Uint8Array(0);
    message.mintBatonTxid = object.mintBatonTxid ?? new Uint8Array(0);
    message.mintBatonOutIdx = object.mintBatonOutIdx ?? 0;
    return message;
  },
};

function createBaseUser(): User {
  return {
    userId: 0,
    userPk: new Uint8Array(0),
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    birthdayYear: 0,
    birthdayMonth: 0,
    birthdayDay: 0,
    timestampCreated: Long.ZERO,
    hasCreatedWallet: false,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.userPk.length !== 0) {
      writer.uint32(18).bytes(message.userPk);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.email !== "") {
      writer.uint32(42).string(message.email);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(82).string(message.phoneNumber);
    }
    if (message.birthdayYear !== 0) {
      writer.uint32(48).int32(message.birthdayYear);
    }
    if (message.birthdayMonth !== 0) {
      writer.uint32(56).uint32(message.birthdayMonth);
    }
    if (message.birthdayDay !== 0) {
      writer.uint32(64).uint32(message.birthdayDay);
    }
    if (!message.timestampCreated.isZero()) {
      writer.uint32(72).int64(message.timestampCreated);
    }
    if (message.hasCreatedWallet === true) {
      writer.uint32(88).bool(message.hasCreatedWallet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userPk = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.birthdayYear = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.birthdayMonth = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.birthdayDay = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.timestampCreated = reader.int64() as Long;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.hasCreatedWallet = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      userPk: isSet(object.userPk)
        ? bytesFromBase64(object.userPk)
        : new Uint8Array(0),
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      phoneNumber: isSet(object.phoneNumber)
        ? globalThis.String(object.phoneNumber)
        : "",
      birthdayYear: isSet(object.birthdayYear)
        ? globalThis.Number(object.birthdayYear)
        : 0,
      birthdayMonth: isSet(object.birthdayMonth)
        ? globalThis.Number(object.birthdayMonth)
        : 0,
      birthdayDay: isSet(object.birthdayDay)
        ? globalThis.Number(object.birthdayDay)
        : 0,
      timestampCreated: isSet(object.timestampCreated)
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO,
      hasCreatedWallet: isSet(object.hasCreatedWallet)
        ? globalThis.Boolean(object.hasCreatedWallet)
        : false,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.userPk.length !== 0) {
      obj.userPk = base64FromBytes(message.userPk);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.birthdayYear !== 0) {
      obj.birthdayYear = Math.round(message.birthdayYear);
    }
    if (message.birthdayMonth !== 0) {
      obj.birthdayMonth = Math.round(message.birthdayMonth);
    }
    if (message.birthdayDay !== 0) {
      obj.birthdayDay = Math.round(message.birthdayDay);
    }
    if (!message.timestampCreated.isZero()) {
      obj.timestampCreated = (message.timestampCreated || Long.ZERO).toString();
    }
    if (message.hasCreatedWallet === true) {
      obj.hasCreatedWallet = message.hasCreatedWallet;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.userId = object.userId ?? 0;
    message.userPk = object.userPk ?? new Uint8Array(0);
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.birthdayYear = object.birthdayYear ?? 0;
    message.birthdayMonth = object.birthdayMonth ?? 0;
    message.birthdayDay = object.birthdayDay ?? 0;
    message.timestampCreated =
      object.timestampCreated !== undefined && object.timestampCreated !== null
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO;
    message.hasCreatedWallet = object.hasCreatedWallet ?? false;
    return message;
  },
};

function createBaseUserMetadata(): UserMetadata {
  return { avatarUrl: "" };
}

export const UserMetadata = {
  encode(
    message: UserMetadata,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.avatarUrl !== "") {
      writer.uint32(10).string(message.avatarUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMetadata {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMetadata {
    return {
      avatarUrl: isSet(object.avatarUrl)
        ? globalThis.String(object.avatarUrl)
        : "",
    };
  },

  toJSON(message: UserMetadata): unknown {
    const obj: any = {};
    if (message.avatarUrl !== "") {
      obj.avatarUrl = message.avatarUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMetadata>, I>>(
    base?: I
  ): UserMetadata {
    return UserMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMetadata>, I>>(
    object: I
  ): UserMetadata {
    const message = createBaseUserMetadata();
    message.avatarUrl = object.avatarUrl ?? "";
    return message;
  },
};

function createBasePrivilege(): Privilege {
  return { privilege: "" };
}

export const Privilege = {
  encode(
    message: Privilege,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.privilege !== "") {
      writer.uint32(10).string(message.privilege);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Privilege {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivilege();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.privilege = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Privilege {
    return {
      privilege: isSet(object.privilege)
        ? globalThis.String(object.privilege)
        : "",
    };
  },

  toJSON(message: Privilege): unknown {
    const obj: any = {};
    if (message.privilege !== "") {
      obj.privilege = message.privilege;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Privilege>, I>>(base?: I): Privilege {
    return Privilege.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Privilege>, I>>(
    object: I
  ): Privilege {
    const message = createBasePrivilege();
    message.privilege = object.privilege ?? "";
    return message;
  },
};

function createBaseEvent(): Event {
  return {
    eventId: 0,
    hostPk: new Uint8Array(0),
    hostId: 0,
    hostFirstName: "",
    hostLastName: "",
    hostAvatarUrl: "",
    bannerUrl: "",
    title: "",
    locationAddress: "",
    dateTime: Long.ZERO,
    duration: 0,
    description: "",
    priceData: undefined,
    basePriceData: undefined,
    discountPer1000: 0,
    minHoursUpfront: 0,
    isCashAllowed: false,
    isPreauthAllowed: false,
    isAppRequired: false,
    autoGuestAffiliatePer1000: 0,
    guestState: undefined,
    currency: "",
    affiliates: [],
    escrowPk: new Uint8Array(0),
    eventStats: undefined,
    eventCodes: [],
    affiliateStats: [],
    eventStatus: "",
    payments: [],
    pendingPayoutTokens: Long.ZERO,
    tags: [],
  };
}

export const Event = {
  encode(message: Event, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.hostPk.length !== 0) {
      writer.uint32(18).bytes(message.hostPk);
    }
    if (message.hostId !== 0) {
      writer.uint32(168).int32(message.hostId);
    }
    if (message.hostFirstName !== "") {
      writer.uint32(26).string(message.hostFirstName);
    }
    if (message.hostLastName !== "") {
      writer.uint32(34).string(message.hostLastName);
    }
    if (message.hostAvatarUrl !== "") {
      writer.uint32(194).string(message.hostAvatarUrl);
    }
    if (message.bannerUrl !== "") {
      writer.uint32(202).string(message.bannerUrl);
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.locationAddress !== "") {
      writer.uint32(50).string(message.locationAddress);
    }
    if (!message.dateTime.isZero()) {
      writer.uint32(56).int64(message.dateTime);
    }
    if (message.duration !== 0) {
      writer.uint32(64).int32(message.duration);
    }
    if (message.description !== "") {
      writer.uint32(74).string(message.description);
    }
    if (message.priceData !== undefined) {
      EventPrice.encode(message.priceData, writer.uint32(90).fork()).ldelim();
    }
    if (message.basePriceData !== undefined) {
      EventPrice.encode(
        message.basePriceData,
        writer.uint32(210).fork()
      ).ldelim();
    }
    if (message.discountPer1000 !== 0) {
      writer.uint32(216).int32(message.discountPer1000);
    }
    if (message.minHoursUpfront !== 0) {
      writer.uint32(104).int32(message.minHoursUpfront);
    }
    if (message.isCashAllowed === true) {
      writer.uint32(176).bool(message.isCashAllowed);
    }
    if (message.isPreauthAllowed === true) {
      writer.uint32(232).bool(message.isPreauthAllowed);
    }
    if (message.isAppRequired === true) {
      writer.uint32(256).bool(message.isAppRequired);
    }
    if (message.autoGuestAffiliatePer1000 !== 0) {
      writer.uint32(184).int32(message.autoGuestAffiliatePer1000);
    }
    if (message.guestState !== undefined) {
      GuestState.encode(message.guestState, writer.uint32(114).fork()).ldelim();
    }
    if (message.currency !== "") {
      writer.uint32(98).string(message.currency);
    }
    for (const v of message.affiliates) {
      Affiliate.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.escrowPk.length !== 0) {
      writer.uint32(130).bytes(message.escrowPk);
    }
    if (message.eventStats !== undefined) {
      EventStats.encode(message.eventStats, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.eventCodes) {
      writer.uint32(146).string(v!);
    }
    for (const v of message.affiliateStats) {
      AffiliateStats.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.eventStatus !== "") {
      writer.uint32(162).string(message.eventStatus);
    }
    for (const v of message.payments) {
      EventPayment.encode(v!, writer.uint32(226).fork()).ldelim();
    }
    if (!message.pendingPayoutTokens.isZero()) {
      writer.uint32(248).int64(message.pendingPayoutTokens);
    }
    for (const v of message.tags) {
      writer.uint32(242).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hostPk = reader.bytes();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.hostId = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hostFirstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.hostLastName = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.hostAvatarUrl = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.bannerUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.locationAddress = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.dateTime = reader.int64() as Long;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.description = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.priceData = EventPrice.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.basePriceData = EventPrice.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.discountPer1000 = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.minHoursUpfront = reader.int32();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.isCashAllowed = reader.bool();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.isPreauthAllowed = reader.bool();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.isAppRequired = reader.bool();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.autoGuestAffiliatePer1000 = reader.int32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.guestState = GuestState.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.affiliates.push(Affiliate.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.escrowPk = reader.bytes();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.eventStats = EventStats.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.eventCodes.push(reader.string());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.affiliateStats.push(
            AffiliateStats.decode(reader, reader.uint32())
          );
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.eventStatus = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.payments.push(EventPayment.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.pendingPayoutTokens = reader.int64() as Long;
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Event {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      hostPk: isSet(object.hostPk)
        ? bytesFromBase64(object.hostPk)
        : new Uint8Array(0),
      hostId: isSet(object.hostId) ? globalThis.Number(object.hostId) : 0,
      hostFirstName: isSet(object.hostFirstName)
        ? globalThis.String(object.hostFirstName)
        : "",
      hostLastName: isSet(object.hostLastName)
        ? globalThis.String(object.hostLastName)
        : "",
      hostAvatarUrl: isSet(object.hostAvatarUrl)
        ? globalThis.String(object.hostAvatarUrl)
        : "",
      bannerUrl: isSet(object.bannerUrl)
        ? globalThis.String(object.bannerUrl)
        : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      locationAddress: isSet(object.locationAddress)
        ? globalThis.String(object.locationAddress)
        : "",
      dateTime: isSet(object.dateTime)
        ? Long.fromValue(object.dateTime)
        : Long.ZERO,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : "",
      priceData: isSet(object.priceData)
        ? EventPrice.fromJSON(object.priceData)
        : undefined,
      basePriceData: isSet(object.basePriceData)
        ? EventPrice.fromJSON(object.basePriceData)
        : undefined,
      discountPer1000: isSet(object.discountPer1000)
        ? globalThis.Number(object.discountPer1000)
        : 0,
      minHoursUpfront: isSet(object.minHoursUpfront)
        ? globalThis.Number(object.minHoursUpfront)
        : 0,
      isCashAllowed: isSet(object.isCashAllowed)
        ? globalThis.Boolean(object.isCashAllowed)
        : false,
      isPreauthAllowed: isSet(object.isPreauthAllowed)
        ? globalThis.Boolean(object.isPreauthAllowed)
        : false,
      isAppRequired: isSet(object.isAppRequired)
        ? globalThis.Boolean(object.isAppRequired)
        : false,
      autoGuestAffiliatePer1000: isSet(object.autoGuestAffiliatePer1000)
        ? globalThis.Number(object.autoGuestAffiliatePer1000)
        : 0,
      guestState: isSet(object.guestState)
        ? GuestState.fromJSON(object.guestState)
        : undefined,
      currency: isSet(object.currency)
        ? globalThis.String(object.currency)
        : "",
      affiliates: globalThis.Array.isArray(object?.affiliates)
        ? object.affiliates.map((e: any) => Affiliate.fromJSON(e))
        : [],
      escrowPk: isSet(object.escrowPk)
        ? bytesFromBase64(object.escrowPk)
        : new Uint8Array(0),
      eventStats: isSet(object.eventStats)
        ? EventStats.fromJSON(object.eventStats)
        : undefined,
      eventCodes: globalThis.Array.isArray(object?.eventCodes)
        ? object.eventCodes.map((e: any) => globalThis.String(e))
        : [],
      affiliateStats: globalThis.Array.isArray(object?.affiliateStats)
        ? object.affiliateStats.map((e: any) => AffiliateStats.fromJSON(e))
        : [],
      eventStatus: isSet(object.eventStatus)
        ? globalThis.String(object.eventStatus)
        : "",
      payments: globalThis.Array.isArray(object?.payments)
        ? object.payments.map((e: any) => EventPayment.fromJSON(e))
        : [],
      pendingPayoutTokens: isSet(object.pendingPayoutTokens)
        ? Long.fromValue(object.pendingPayoutTokens)
        : Long.ZERO,
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Event): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.hostPk.length !== 0) {
      obj.hostPk = base64FromBytes(message.hostPk);
    }
    if (message.hostId !== 0) {
      obj.hostId = Math.round(message.hostId);
    }
    if (message.hostFirstName !== "") {
      obj.hostFirstName = message.hostFirstName;
    }
    if (message.hostLastName !== "") {
      obj.hostLastName = message.hostLastName;
    }
    if (message.hostAvatarUrl !== "") {
      obj.hostAvatarUrl = message.hostAvatarUrl;
    }
    if (message.bannerUrl !== "") {
      obj.bannerUrl = message.bannerUrl;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.locationAddress !== "") {
      obj.locationAddress = message.locationAddress;
    }
    if (!message.dateTime.isZero()) {
      obj.dateTime = (message.dateTime || Long.ZERO).toString();
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.priceData !== undefined) {
      obj.priceData = EventPrice.toJSON(message.priceData);
    }
    if (message.basePriceData !== undefined) {
      obj.basePriceData = EventPrice.toJSON(message.basePriceData);
    }
    if (message.discountPer1000 !== 0) {
      obj.discountPer1000 = Math.round(message.discountPer1000);
    }
    if (message.minHoursUpfront !== 0) {
      obj.minHoursUpfront = Math.round(message.minHoursUpfront);
    }
    if (message.isCashAllowed === true) {
      obj.isCashAllowed = message.isCashAllowed;
    }
    if (message.isPreauthAllowed === true) {
      obj.isPreauthAllowed = message.isPreauthAllowed;
    }
    if (message.isAppRequired === true) {
      obj.isAppRequired = message.isAppRequired;
    }
    if (message.autoGuestAffiliatePer1000 !== 0) {
      obj.autoGuestAffiliatePer1000 = Math.round(
        message.autoGuestAffiliatePer1000
      );
    }
    if (message.guestState !== undefined) {
      obj.guestState = GuestState.toJSON(message.guestState);
    }
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.affiliates?.length) {
      obj.affiliates = message.affiliates.map(e => Affiliate.toJSON(e));
    }
    if (message.escrowPk.length !== 0) {
      obj.escrowPk = base64FromBytes(message.escrowPk);
    }
    if (message.eventStats !== undefined) {
      obj.eventStats = EventStats.toJSON(message.eventStats);
    }
    if (message.eventCodes?.length) {
      obj.eventCodes = message.eventCodes;
    }
    if (message.affiliateStats?.length) {
      obj.affiliateStats = message.affiliateStats.map(e =>
        AffiliateStats.toJSON(e)
      );
    }
    if (message.eventStatus !== "") {
      obj.eventStatus = message.eventStatus;
    }
    if (message.payments?.length) {
      obj.payments = message.payments.map(e => EventPayment.toJSON(e));
    }
    if (!message.pendingPayoutTokens.isZero()) {
      obj.pendingPayoutTokens = (
        message.pendingPayoutTokens || Long.ZERO
      ).toString();
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Event>, I>>(base?: I): Event {
    return Event.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Event>, I>>(object: I): Event {
    const message = createBaseEvent();
    message.eventId = object.eventId ?? 0;
    message.hostPk = object.hostPk ?? new Uint8Array(0);
    message.hostId = object.hostId ?? 0;
    message.hostFirstName = object.hostFirstName ?? "";
    message.hostLastName = object.hostLastName ?? "";
    message.hostAvatarUrl = object.hostAvatarUrl ?? "";
    message.bannerUrl = object.bannerUrl ?? "";
    message.title = object.title ?? "";
    message.locationAddress = object.locationAddress ?? "";
    message.dateTime =
      object.dateTime !== undefined && object.dateTime !== null
        ? Long.fromValue(object.dateTime)
        : Long.ZERO;
    message.duration = object.duration ?? 0;
    message.description = object.description ?? "";
    message.priceData =
      object.priceData !== undefined && object.priceData !== null
        ? EventPrice.fromPartial(object.priceData)
        : undefined;
    message.basePriceData =
      object.basePriceData !== undefined && object.basePriceData !== null
        ? EventPrice.fromPartial(object.basePriceData)
        : undefined;
    message.discountPer1000 = object.discountPer1000 ?? 0;
    message.minHoursUpfront = object.minHoursUpfront ?? 0;
    message.isCashAllowed = object.isCashAllowed ?? false;
    message.isPreauthAllowed = object.isPreauthAllowed ?? false;
    message.isAppRequired = object.isAppRequired ?? false;
    message.autoGuestAffiliatePer1000 = object.autoGuestAffiliatePer1000 ?? 0;
    message.guestState =
      object.guestState !== undefined && object.guestState !== null
        ? GuestState.fromPartial(object.guestState)
        : undefined;
    message.currency = object.currency ?? "";
    message.affiliates =
      object.affiliates?.map(e => Affiliate.fromPartial(e)) || [];
    message.escrowPk = object.escrowPk ?? new Uint8Array(0);
    message.eventStats =
      object.eventStats !== undefined && object.eventStats !== null
        ? EventStats.fromPartial(object.eventStats)
        : undefined;
    message.eventCodes = object.eventCodes?.map(e => e) || [];
    message.affiliateStats =
      object.affiliateStats?.map(e => AffiliateStats.fromPartial(e)) || [];
    message.eventStatus = object.eventStatus ?? "";
    message.payments =
      object.payments?.map(e => EventPayment.fromPartial(e)) || [];
    message.pendingPayoutTokens =
      object.pendingPayoutTokens !== undefined &&
      object.pendingPayoutTokens !== null
        ? Long.fromValue(object.pendingPayoutTokens)
        : Long.ZERO;
    message.tags = object.tags?.map(e => e) || [];
    return message;
  },
};

function createBaseEventData(): EventData {
  return { eventId: 0, details: undefined, codes: [], teamMembers: [] };
}

export const EventData = {
  encode(
    message: EventData,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.details !== undefined) {
      EventDetails.encode(message.details, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.codes) {
      CodeDetails.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.teamMembers) {
      TeamMember.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details = EventDetails.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.codes.push(CodeDetails.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.teamMembers.push(TeamMember.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventData {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      details: isSet(object.details)
        ? EventDetails.fromJSON(object.details)
        : undefined,
      codes: globalThis.Array.isArray(object?.codes)
        ? object.codes.map((e: any) => CodeDetails.fromJSON(e))
        : [],
      teamMembers: globalThis.Array.isArray(object?.teamMembers)
        ? object.teamMembers.map((e: any) => TeamMember.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EventData): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.details !== undefined) {
      obj.details = EventDetails.toJSON(message.details);
    }
    if (message.codes?.length) {
      obj.codes = message.codes.map(e => CodeDetails.toJSON(e));
    }
    if (message.teamMembers?.length) {
      obj.teamMembers = message.teamMembers.map(e => TeamMember.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventData>, I>>(base?: I): EventData {
    return EventData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventData>, I>>(
    object: I
  ): EventData {
    const message = createBaseEventData();
    message.eventId = object.eventId ?? 0;
    message.details =
      object.details !== undefined && object.details !== null
        ? EventDetails.fromPartial(object.details)
        : undefined;
    message.codes = object.codes?.map(e => CodeDetails.fromPartial(e)) || [];
    message.teamMembers =
      object.teamMembers?.map(e => TeamMember.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEventDetails(): EventDetails {
  return {
    title: "",
    locationAddress: "",
    dateTime: Long.ZERO,
    duration: 0,
    description: "",
    bannerUrl: "",
    priceData: undefined,
    minHoursUpfront: 0,
    isCashAllowed: false,
    isPreauthAllowed: false,
    isAppRequired: false,
    autoGuestAffiliatePer1000: 0,
    currency: "",
    tags: [],
  };
}

export const EventDetails = {
  encode(
    message: EventDetails,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.locationAddress !== "") {
      writer.uint32(18).string(message.locationAddress);
    }
    if (!message.dateTime.isZero()) {
      writer.uint32(24).int64(message.dateTime);
    }
    if (message.duration !== 0) {
      writer.uint32(32).int32(message.duration);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.bannerUrl !== "") {
      writer.uint32(106).string(message.bannerUrl);
    }
    if (message.priceData !== undefined) {
      EventPrice.encode(message.priceData, writer.uint32(58).fork()).ldelim();
    }
    if (message.minHoursUpfront !== 0) {
      writer.uint32(64).int32(message.minHoursUpfront);
    }
    if (message.isCashAllowed === true) {
      writer.uint32(80).bool(message.isCashAllowed);
    }
    if (message.isPreauthAllowed === true) {
      writer.uint32(112).bool(message.isPreauthAllowed);
    }
    if (message.isAppRequired === true) {
      writer.uint32(120).bool(message.isAppRequired);
    }
    if (message.autoGuestAffiliatePer1000 !== 0) {
      writer.uint32(88).int32(message.autoGuestAffiliatePer1000);
    }
    if (message.currency !== "") {
      writer.uint32(74).string(message.currency);
    }
    for (const v of message.tags) {
      writer.uint32(98).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationAddress = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.dateTime = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.bannerUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.priceData = EventPrice.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.minHoursUpfront = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isCashAllowed = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.isPreauthAllowed = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.isAppRequired = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.autoGuestAffiliatePer1000 = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventDetails {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      locationAddress: isSet(object.locationAddress)
        ? globalThis.String(object.locationAddress)
        : "",
      dateTime: isSet(object.dateTime)
        ? Long.fromValue(object.dateTime)
        : Long.ZERO,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : "",
      bannerUrl: isSet(object.bannerUrl)
        ? globalThis.String(object.bannerUrl)
        : "",
      priceData: isSet(object.priceData)
        ? EventPrice.fromJSON(object.priceData)
        : undefined,
      minHoursUpfront: isSet(object.minHoursUpfront)
        ? globalThis.Number(object.minHoursUpfront)
        : 0,
      isCashAllowed: isSet(object.isCashAllowed)
        ? globalThis.Boolean(object.isCashAllowed)
        : false,
      isPreauthAllowed: isSet(object.isPreauthAllowed)
        ? globalThis.Boolean(object.isPreauthAllowed)
        : false,
      isAppRequired: isSet(object.isAppRequired)
        ? globalThis.Boolean(object.isAppRequired)
        : false,
      autoGuestAffiliatePer1000: isSet(object.autoGuestAffiliatePer1000)
        ? globalThis.Number(object.autoGuestAffiliatePer1000)
        : 0,
      currency: isSet(object.currency)
        ? globalThis.String(object.currency)
        : "",
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: EventDetails): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.locationAddress !== "") {
      obj.locationAddress = message.locationAddress;
    }
    if (!message.dateTime.isZero()) {
      obj.dateTime = (message.dateTime || Long.ZERO).toString();
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.bannerUrl !== "") {
      obj.bannerUrl = message.bannerUrl;
    }
    if (message.priceData !== undefined) {
      obj.priceData = EventPrice.toJSON(message.priceData);
    }
    if (message.minHoursUpfront !== 0) {
      obj.minHoursUpfront = Math.round(message.minHoursUpfront);
    }
    if (message.isCashAllowed === true) {
      obj.isCashAllowed = message.isCashAllowed;
    }
    if (message.isPreauthAllowed === true) {
      obj.isPreauthAllowed = message.isPreauthAllowed;
    }
    if (message.isAppRequired === true) {
      obj.isAppRequired = message.isAppRequired;
    }
    if (message.autoGuestAffiliatePer1000 !== 0) {
      obj.autoGuestAffiliatePer1000 = Math.round(
        message.autoGuestAffiliatePer1000
      );
    }
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventDetails>, I>>(
    base?: I
  ): EventDetails {
    return EventDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventDetails>, I>>(
    object: I
  ): EventDetails {
    const message = createBaseEventDetails();
    message.title = object.title ?? "";
    message.locationAddress = object.locationAddress ?? "";
    message.dateTime =
      object.dateTime !== undefined && object.dateTime !== null
        ? Long.fromValue(object.dateTime)
        : Long.ZERO;
    message.duration = object.duration ?? 0;
    message.description = object.description ?? "";
    message.bannerUrl = object.bannerUrl ?? "";
    message.priceData =
      object.priceData !== undefined && object.priceData !== null
        ? EventPrice.fromPartial(object.priceData)
        : undefined;
    message.minHoursUpfront = object.minHoursUpfront ?? 0;
    message.isCashAllowed = object.isCashAllowed ?? false;
    message.isPreauthAllowed = object.isPreauthAllowed ?? false;
    message.isAppRequired = object.isAppRequired ?? false;
    message.autoGuestAffiliatePer1000 = object.autoGuestAffiliatePer1000 ?? 0;
    message.currency = object.currency ?? "";
    message.tags = object.tags?.map(e => e) || [];
    return message;
  },
};

function createBaseEventPrice(): EventPrice {
  return {
    pricePerHour: Long.ZERO,
    priceFlat: Long.ZERO,
    priceUpchargeCash: Long.ZERO,
  };
}

export const EventPrice = {
  encode(
    message: EventPrice,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.pricePerHour.isZero()) {
      writer.uint32(8).int64(message.pricePerHour);
    }
    if (!message.priceFlat.isZero()) {
      writer.uint32(16).int64(message.priceFlat);
    }
    if (!message.priceUpchargeCash.isZero()) {
      writer.uint32(24).int64(message.priceUpchargeCash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPrice {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pricePerHour = reader.int64() as Long;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.priceFlat = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.priceUpchargeCash = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPrice {
    return {
      pricePerHour: isSet(object.pricePerHour)
        ? Long.fromValue(object.pricePerHour)
        : Long.ZERO,
      priceFlat: isSet(object.priceFlat)
        ? Long.fromValue(object.priceFlat)
        : Long.ZERO,
      priceUpchargeCash: isSet(object.priceUpchargeCash)
        ? Long.fromValue(object.priceUpchargeCash)
        : Long.ZERO,
    };
  },

  toJSON(message: EventPrice): unknown {
    const obj: any = {};
    if (!message.pricePerHour.isZero()) {
      obj.pricePerHour = (message.pricePerHour || Long.ZERO).toString();
    }
    if (!message.priceFlat.isZero()) {
      obj.priceFlat = (message.priceFlat || Long.ZERO).toString();
    }
    if (!message.priceUpchargeCash.isZero()) {
      obj.priceUpchargeCash = (
        message.priceUpchargeCash || Long.ZERO
      ).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPrice>, I>>(base?: I): EventPrice {
    return EventPrice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPrice>, I>>(
    object: I
  ): EventPrice {
    const message = createBaseEventPrice();
    message.pricePerHour =
      object.pricePerHour !== undefined && object.pricePerHour !== null
        ? Long.fromValue(object.pricePerHour)
        : Long.ZERO;
    message.priceFlat =
      object.priceFlat !== undefined && object.priceFlat !== null
        ? Long.fromValue(object.priceFlat)
        : Long.ZERO;
    message.priceUpchargeCash =
      object.priceUpchargeCash !== undefined &&
      object.priceUpchargeCash !== null
        ? Long.fromValue(object.priceUpchargeCash)
        : Long.ZERO;
    return message;
  },
};

function createBaseEventPayment(): EventPayment {
  return { tokenAmount: Long.ZERO };
}

export const EventPayment = {
  encode(
    message: EventPayment,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.tokenAmount.isZero()) {
      writer.uint32(8).int64(message.tokenAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventPayment {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventPayment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tokenAmount = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventPayment {
    return {
      tokenAmount: isSet(object.tokenAmount)
        ? Long.fromValue(object.tokenAmount)
        : Long.ZERO,
    };
  },

  toJSON(message: EventPayment): unknown {
    const obj: any = {};
    if (!message.tokenAmount.isZero()) {
      obj.tokenAmount = (message.tokenAmount || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventPayment>, I>>(
    base?: I
  ): EventPayment {
    return EventPayment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventPayment>, I>>(
    object: I
  ): EventPayment {
    const message = createBaseEventPayment();
    message.tokenAmount =
      object.tokenAmount !== undefined && object.tokenAmount !== null
        ? Long.fromValue(object.tokenAmount)
        : Long.ZERO;
    return message;
  },
};

function createBaseCodeDetails(): CodeDetails {
  return {
    code: "",
    affiliates: [],
    numPayments: Long.ZERO,
    timestampCreated: Long.ZERO,
    isTemplate: false,
    template: "",
    maxUses: 0,
    discountPer1000: 0,
  };
}

export const CodeDetails = {
  encode(
    message: CodeDetails,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    for (const v of message.affiliates) {
      AffiliateDetails.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (!message.numPayments.isZero()) {
      writer.uint32(24).int64(message.numPayments);
    }
    if (!message.timestampCreated.isZero()) {
      writer.uint32(32).int64(message.timestampCreated);
    }
    if (message.isTemplate === true) {
      writer.uint32(40).bool(message.isTemplate);
    }
    if (message.template !== "") {
      writer.uint32(50).string(message.template);
    }
    if (message.maxUses !== 0) {
      writer.uint32(56).int32(message.maxUses);
    }
    if (message.discountPer1000 !== 0) {
      writer.uint32(64).int32(message.discountPer1000);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CodeDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCodeDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.affiliates.push(
            AffiliateDetails.decode(reader, reader.uint32())
          );
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numPayments = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampCreated = reader.int64() as Long;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isTemplate = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.template = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.maxUses = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.discountPer1000 = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CodeDetails {
    return {
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      affiliates: globalThis.Array.isArray(object?.affiliates)
        ? object.affiliates.map((e: any) => AffiliateDetails.fromJSON(e))
        : [],
      numPayments: isSet(object.numPayments)
        ? Long.fromValue(object.numPayments)
        : Long.ZERO,
      timestampCreated: isSet(object.timestampCreated)
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO,
      isTemplate: isSet(object.isTemplate)
        ? globalThis.Boolean(object.isTemplate)
        : false,
      template: isSet(object.template)
        ? globalThis.String(object.template)
        : "",
      maxUses: isSet(object.maxUses) ? globalThis.Number(object.maxUses) : 0,
      discountPer1000: isSet(object.discountPer1000)
        ? globalThis.Number(object.discountPer1000)
        : 0,
    };
  },

  toJSON(message: CodeDetails): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.affiliates?.length) {
      obj.affiliates = message.affiliates.map(e => AffiliateDetails.toJSON(e));
    }
    if (!message.numPayments.isZero()) {
      obj.numPayments = (message.numPayments || Long.ZERO).toString();
    }
    if (!message.timestampCreated.isZero()) {
      obj.timestampCreated = (message.timestampCreated || Long.ZERO).toString();
    }
    if (message.isTemplate === true) {
      obj.isTemplate = message.isTemplate;
    }
    if (message.template !== "") {
      obj.template = message.template;
    }
    if (message.maxUses !== 0) {
      obj.maxUses = Math.round(message.maxUses);
    }
    if (message.discountPer1000 !== 0) {
      obj.discountPer1000 = Math.round(message.discountPer1000);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CodeDetails>, I>>(base?: I): CodeDetails {
    return CodeDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CodeDetails>, I>>(
    object: I
  ): CodeDetails {
    const message = createBaseCodeDetails();
    message.code = object.code ?? "";
    message.affiliates =
      object.affiliates?.map(e => AffiliateDetails.fromPartial(e)) || [];
    message.numPayments =
      object.numPayments !== undefined && object.numPayments !== null
        ? Long.fromValue(object.numPayments)
        : Long.ZERO;
    message.timestampCreated =
      object.timestampCreated !== undefined && object.timestampCreated !== null
        ? Long.fromValue(object.timestampCreated)
        : Long.ZERO;
    message.isTemplate = object.isTemplate ?? false;
    message.template = object.template ?? "";
    message.maxUses = object.maxUses ?? 0;
    message.discountPer1000 = object.discountPer1000 ?? 0;
    return message;
  },
};

function createBaseAffiliateDetails(): AffiliateDetails {
  return {
    affiliateId: 0,
    pk: new Uint8Array(0),
    mode: "",
    per1000: 0,
    firstName: "",
    lastName: "",
  };
}

export const AffiliateDetails = {
  encode(
    message: AffiliateDetails,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.affiliateId !== 0) {
      writer.uint32(8).int32(message.affiliateId);
    }
    if (message.pk.length !== 0) {
      writer.uint32(18).bytes(message.pk);
    }
    if (message.mode !== "") {
      writer.uint32(26).string(message.mode);
    }
    if (message.per1000 !== 0) {
      writer.uint32(32).int32(message.per1000);
    }
    if (message.firstName !== "") {
      writer.uint32(42).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(50).string(message.lastName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AffiliateDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAffiliateDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.affiliateId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pk = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mode = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.per1000 = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AffiliateDetails {
    return {
      affiliateId: isSet(object.affiliateId)
        ? globalThis.Number(object.affiliateId)
        : 0,
      pk: isSet(object.pk) ? bytesFromBase64(object.pk) : new Uint8Array(0),
      mode: isSet(object.mode) ? globalThis.String(object.mode) : "",
      per1000: isSet(object.per1000) ? globalThis.Number(object.per1000) : 0,
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
    };
  },

  toJSON(message: AffiliateDetails): unknown {
    const obj: any = {};
    if (message.affiliateId !== 0) {
      obj.affiliateId = Math.round(message.affiliateId);
    }
    if (message.pk.length !== 0) {
      obj.pk = base64FromBytes(message.pk);
    }
    if (message.mode !== "") {
      obj.mode = message.mode;
    }
    if (message.per1000 !== 0) {
      obj.per1000 = Math.round(message.per1000);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AffiliateDetails>, I>>(
    base?: I
  ): AffiliateDetails {
    return AffiliateDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AffiliateDetails>, I>>(
    object: I
  ): AffiliateDetails {
    const message = createBaseAffiliateDetails();
    message.affiliateId = object.affiliateId ?? 0;
    message.pk = object.pk ?? new Uint8Array(0);
    message.mode = object.mode ?? "";
    message.per1000 = object.per1000 ?? 0;
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    return message;
  },
};

function createBaseTeamMember(): TeamMember {
  return {
    memberId: 0,
    pk: new Uint8Array(0),
    firstName: "",
    lastName: "",
    timestampAdded: Long.ZERO,
    roles: [],
  };
}

export const TeamMember = {
  encode(
    message: TeamMember,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.memberId !== 0) {
      writer.uint32(8).int32(message.memberId);
    }
    if (message.pk.length !== 0) {
      writer.uint32(18).bytes(message.pk);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (!message.timestampAdded.isZero()) {
      writer.uint32(40).int64(message.timestampAdded);
    }
    for (const v of message.roles) {
      TeamMemberRole.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TeamMember {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTeamMember();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.memberId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pk = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timestampAdded = reader.int64() as Long;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.roles.push(TeamMemberRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TeamMember {
    return {
      memberId: isSet(object.memberId) ? globalThis.Number(object.memberId) : 0,
      pk: isSet(object.pk) ? bytesFromBase64(object.pk) : new Uint8Array(0),
      firstName: isSet(object.firstName)
        ? globalThis.String(object.firstName)
        : "",
      lastName: isSet(object.lastName)
        ? globalThis.String(object.lastName)
        : "",
      timestampAdded: isSet(object.timestampAdded)
        ? Long.fromValue(object.timestampAdded)
        : Long.ZERO,
      roles: globalThis.Array.isArray(object?.roles)
        ? object.roles.map((e: any) => TeamMemberRole.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TeamMember): unknown {
    const obj: any = {};
    if (message.memberId !== 0) {
      obj.memberId = Math.round(message.memberId);
    }
    if (message.pk.length !== 0) {
      obj.pk = base64FromBytes(message.pk);
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (!message.timestampAdded.isZero()) {
      obj.timestampAdded = (message.timestampAdded || Long.ZERO).toString();
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map(e => TeamMemberRole.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TeamMember>, I>>(base?: I): TeamMember {
    return TeamMember.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TeamMember>, I>>(
    object: I
  ): TeamMember {
    const message = createBaseTeamMember();
    message.memberId = object.memberId ?? 0;
    message.pk = object.pk ?? new Uint8Array(0);
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.timestampAdded =
      object.timestampAdded !== undefined && object.timestampAdded !== null
        ? Long.fromValue(object.timestampAdded)
        : Long.ZERO;
    message.roles = object.roles?.map(e => TeamMemberRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTeamMemberRole(): TeamMemberRole {
  return { role: "", timestampGranted: Long.ZERO };
}

export const TeamMemberRole = {
  encode(
    message: TeamMemberRole,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.role !== "") {
      writer.uint32(10).string(message.role);
    }
    if (!message.timestampGranted.isZero()) {
      writer.uint32(16).int64(message.timestampGranted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TeamMemberRole {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTeamMemberRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.role = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestampGranted = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TeamMemberRole {
    return {
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      timestampGranted: isSet(object.timestampGranted)
        ? Long.fromValue(object.timestampGranted)
        : Long.ZERO,
    };
  },

  toJSON(message: TeamMemberRole): unknown {
    const obj: any = {};
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (!message.timestampGranted.isZero()) {
      obj.timestampGranted = (message.timestampGranted || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TeamMemberRole>, I>>(
    base?: I
  ): TeamMemberRole {
    return TeamMemberRole.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TeamMemberRole>, I>>(
    object: I
  ): TeamMemberRole {
    const message = createBaseTeamMemberRole();
    message.role = object.role ?? "";
    message.timestampGranted =
      object.timestampGranted !== undefined && object.timestampGranted !== null
        ? Long.fromValue(object.timestampGranted)
        : Long.ZERO;
    return message;
  },
};

function createBaseAffiliate(): Affiliate {
  return { outputScript: new Uint8Array(0) };
}

export const Affiliate = {
  encode(
    message: Affiliate,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.outputScript.length !== 0) {
      writer.uint32(10).bytes(message.outputScript);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Affiliate {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAffiliate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.outputScript = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Affiliate {
    return {
      outputScript: isSet(object.outputScript)
        ? bytesFromBase64(object.outputScript)
        : new Uint8Array(0),
    };
  },

  toJSON(message: Affiliate): unknown {
    const obj: any = {};
    if (message.outputScript.length !== 0) {
      obj.outputScript = base64FromBytes(message.outputScript);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Affiliate>, I>>(base?: I): Affiliate {
    return Affiliate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Affiliate>, I>>(
    object: I
  ): Affiliate {
    const message = createBaseAffiliate();
    message.outputScript = object.outputScript ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGuestState(): GuestState {
  return {
    status: "",
    totalTimeAvailable: Long.ZERO,
    totalFundsAvailable: Long.ZERO,
    timeCheckedIn: Long.ZERO,
    timestampLastCheckin: Long.ZERO,
    tokensUsed: Long.ZERO,
    tokensUsedPerHour: Long.ZERO,
    timestampLastFundsUpdated: Long.ZERO,
  };
}

export const GuestState = {
  encode(
    message: GuestState,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    if (!message.totalTimeAvailable.isZero()) {
      writer.uint32(16).int64(message.totalTimeAvailable);
    }
    if (!message.totalFundsAvailable.isZero()) {
      writer.uint32(40).int64(message.totalFundsAvailable);
    }
    if (!message.timeCheckedIn.isZero()) {
      writer.uint32(24).int64(message.timeCheckedIn);
    }
    if (!message.timestampLastCheckin.isZero()) {
      writer.uint32(32).int64(message.timestampLastCheckin);
    }
    if (!message.tokensUsed.isZero()) {
      writer.uint32(48).int64(message.tokensUsed);
    }
    if (!message.tokensUsedPerHour.isZero()) {
      writer.uint32(56).int64(message.tokensUsedPerHour);
    }
    if (!message.timestampLastFundsUpdated.isZero()) {
      writer.uint32(64).int64(message.timestampLastFundsUpdated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GuestState {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGuestState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalTimeAvailable = reader.int64() as Long;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalFundsAvailable = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.timeCheckedIn = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampLastCheckin = reader.int64() as Long;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tokensUsed = reader.int64() as Long;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.tokensUsedPerHour = reader.int64() as Long;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.timestampLastFundsUpdated = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GuestState {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      totalTimeAvailable: isSet(object.totalTimeAvailable)
        ? Long.fromValue(object.totalTimeAvailable)
        : Long.ZERO,
      totalFundsAvailable: isSet(object.totalFundsAvailable)
        ? Long.fromValue(object.totalFundsAvailable)
        : Long.ZERO,
      timeCheckedIn: isSet(object.timeCheckedIn)
        ? Long.fromValue(object.timeCheckedIn)
        : Long.ZERO,
      timestampLastCheckin: isSet(object.timestampLastCheckin)
        ? Long.fromValue(object.timestampLastCheckin)
        : Long.ZERO,
      tokensUsed: isSet(object.tokensUsed)
        ? Long.fromValue(object.tokensUsed)
        : Long.ZERO,
      tokensUsedPerHour: isSet(object.tokensUsedPerHour)
        ? Long.fromValue(object.tokensUsedPerHour)
        : Long.ZERO,
      timestampLastFundsUpdated: isSet(object.timestampLastFundsUpdated)
        ? Long.fromValue(object.timestampLastFundsUpdated)
        : Long.ZERO,
    };
  },

  toJSON(message: GuestState): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (!message.totalTimeAvailable.isZero()) {
      obj.totalTimeAvailable = (
        message.totalTimeAvailable || Long.ZERO
      ).toString();
    }
    if (!message.totalFundsAvailable.isZero()) {
      obj.totalFundsAvailable = (
        message.totalFundsAvailable || Long.ZERO
      ).toString();
    }
    if (!message.timeCheckedIn.isZero()) {
      obj.timeCheckedIn = (message.timeCheckedIn || Long.ZERO).toString();
    }
    if (!message.timestampLastCheckin.isZero()) {
      obj.timestampLastCheckin = (
        message.timestampLastCheckin || Long.ZERO
      ).toString();
    }
    if (!message.tokensUsed.isZero()) {
      obj.tokensUsed = (message.tokensUsed || Long.ZERO).toString();
    }
    if (!message.tokensUsedPerHour.isZero()) {
      obj.tokensUsedPerHour = (
        message.tokensUsedPerHour || Long.ZERO
      ).toString();
    }
    if (!message.timestampLastFundsUpdated.isZero()) {
      obj.timestampLastFundsUpdated = (
        message.timestampLastFundsUpdated || Long.ZERO
      ).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GuestState>, I>>(base?: I): GuestState {
    return GuestState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GuestState>, I>>(
    object: I
  ): GuestState {
    const message = createBaseGuestState();
    message.status = object.status ?? "";
    message.totalTimeAvailable =
      object.totalTimeAvailable !== undefined &&
      object.totalTimeAvailable !== null
        ? Long.fromValue(object.totalTimeAvailable)
        : Long.ZERO;
    message.totalFundsAvailable =
      object.totalFundsAvailable !== undefined &&
      object.totalFundsAvailable !== null
        ? Long.fromValue(object.totalFundsAvailable)
        : Long.ZERO;
    message.timeCheckedIn =
      object.timeCheckedIn !== undefined && object.timeCheckedIn !== null
        ? Long.fromValue(object.timeCheckedIn)
        : Long.ZERO;
    message.timestampLastCheckin =
      object.timestampLastCheckin !== undefined &&
      object.timestampLastCheckin !== null
        ? Long.fromValue(object.timestampLastCheckin)
        : Long.ZERO;
    message.tokensUsed =
      object.tokensUsed !== undefined && object.tokensUsed !== null
        ? Long.fromValue(object.tokensUsed)
        : Long.ZERO;
    message.tokensUsedPerHour =
      object.tokensUsedPerHour !== undefined &&
      object.tokensUsedPerHour !== null
        ? Long.fromValue(object.tokensUsedPerHour)
        : Long.ZERO;
    message.timestampLastFundsUpdated =
      object.timestampLastFundsUpdated !== undefined &&
      object.timestampLastFundsUpdated !== null
        ? Long.fromValue(object.timestampLastFundsUpdated)
        : Long.ZERO;
    return message;
  },
};

function createBaseEventStats(): EventStats {
  return {
    numGuestsJoined: 0,
    numGuestsCheckedIn: 0,
    totalTokensCollected: Long.ZERO,
    tokensCollectedPerHour: Long.ZERO,
    timestampLastFundsUpdated: Long.ZERO,
    timestampNextInvalidation: Long.ZERO,
  };
}

export const EventStats = {
  encode(
    message: EventStats,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.numGuestsJoined !== 0) {
      writer.uint32(8).int32(message.numGuestsJoined);
    }
    if (message.numGuestsCheckedIn !== 0) {
      writer.uint32(16).int32(message.numGuestsCheckedIn);
    }
    if (!message.totalTokensCollected.isZero()) {
      writer.uint32(24).int64(message.totalTokensCollected);
    }
    if (!message.tokensCollectedPerHour.isZero()) {
      writer.uint32(48).int64(message.tokensCollectedPerHour);
    }
    if (!message.timestampLastFundsUpdated.isZero()) {
      writer.uint32(32).int64(message.timestampLastFundsUpdated);
    }
    if (!message.timestampNextInvalidation.isZero()) {
      writer.uint32(40).int64(message.timestampNextInvalidation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventStats {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numGuestsJoined = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numGuestsCheckedIn = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalTokensCollected = reader.int64() as Long;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tokensCollectedPerHour = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestampLastFundsUpdated = reader.int64() as Long;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timestampNextInvalidation = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventStats {
    return {
      numGuestsJoined: isSet(object.numGuestsJoined)
        ? globalThis.Number(object.numGuestsJoined)
        : 0,
      numGuestsCheckedIn: isSet(object.numGuestsCheckedIn)
        ? globalThis.Number(object.numGuestsCheckedIn)
        : 0,
      totalTokensCollected: isSet(object.totalTokensCollected)
        ? Long.fromValue(object.totalTokensCollected)
        : Long.ZERO,
      tokensCollectedPerHour: isSet(object.tokensCollectedPerHour)
        ? Long.fromValue(object.tokensCollectedPerHour)
        : Long.ZERO,
      timestampLastFundsUpdated: isSet(object.timestampLastFundsUpdated)
        ? Long.fromValue(object.timestampLastFundsUpdated)
        : Long.ZERO,
      timestampNextInvalidation: isSet(object.timestampNextInvalidation)
        ? Long.fromValue(object.timestampNextInvalidation)
        : Long.ZERO,
    };
  },

  toJSON(message: EventStats): unknown {
    const obj: any = {};
    if (message.numGuestsJoined !== 0) {
      obj.numGuestsJoined = Math.round(message.numGuestsJoined);
    }
    if (message.numGuestsCheckedIn !== 0) {
      obj.numGuestsCheckedIn = Math.round(message.numGuestsCheckedIn);
    }
    if (!message.totalTokensCollected.isZero()) {
      obj.totalTokensCollected = (
        message.totalTokensCollected || Long.ZERO
      ).toString();
    }
    if (!message.tokensCollectedPerHour.isZero()) {
      obj.tokensCollectedPerHour = (
        message.tokensCollectedPerHour || Long.ZERO
      ).toString();
    }
    if (!message.timestampLastFundsUpdated.isZero()) {
      obj.timestampLastFundsUpdated = (
        message.timestampLastFundsUpdated || Long.ZERO
      ).toString();
    }
    if (!message.timestampNextInvalidation.isZero()) {
      obj.timestampNextInvalidation = (
        message.timestampNextInvalidation || Long.ZERO
      ).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventStats>, I>>(base?: I): EventStats {
    return EventStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventStats>, I>>(
    object: I
  ): EventStats {
    const message = createBaseEventStats();
    message.numGuestsJoined = object.numGuestsJoined ?? 0;
    message.numGuestsCheckedIn = object.numGuestsCheckedIn ?? 0;
    message.totalTokensCollected =
      object.totalTokensCollected !== undefined &&
      object.totalTokensCollected !== null
        ? Long.fromValue(object.totalTokensCollected)
        : Long.ZERO;
    message.tokensCollectedPerHour =
      object.tokensCollectedPerHour !== undefined &&
      object.tokensCollectedPerHour !== null
        ? Long.fromValue(object.tokensCollectedPerHour)
        : Long.ZERO;
    message.timestampLastFundsUpdated =
      object.timestampLastFundsUpdated !== undefined &&
      object.timestampLastFundsUpdated !== null
        ? Long.fromValue(object.timestampLastFundsUpdated)
        : Long.ZERO;
    message.timestampNextInvalidation =
      object.timestampNextInvalidation !== undefined &&
      object.timestampNextInvalidation !== null
        ? Long.fromValue(object.timestampNextInvalidation)
        : Long.ZERO;
    return message;
  },
};

function createBaseAffiliateStats(): AffiliateStats {
  return {
    eventCode: "",
    numPayments: 0,
    numCheckedIn: 0,
    tokensCollected: Long.ZERO,
    tokensPerHour: Long.ZERO,
    timestampLastFundsUpdated: Long.ZERO,
    timestampNextInvalidation: Long.ZERO,
  };
}

export const AffiliateStats = {
  encode(
    message: AffiliateStats,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventCode !== "") {
      writer.uint32(10).string(message.eventCode);
    }
    if (message.numPayments !== 0) {
      writer.uint32(16).int32(message.numPayments);
    }
    if (message.numCheckedIn !== 0) {
      writer.uint32(24).int32(message.numCheckedIn);
    }
    if (!message.tokensCollected.isZero()) {
      writer.uint32(32).int64(message.tokensCollected);
    }
    if (!message.tokensPerHour.isZero()) {
      writer.uint32(40).int64(message.tokensPerHour);
    }
    if (!message.timestampLastFundsUpdated.isZero()) {
      writer.uint32(48).int64(message.timestampLastFundsUpdated);
    }
    if (!message.timestampNextInvalidation.isZero()) {
      writer.uint32(56).int64(message.timestampNextInvalidation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AffiliateStats {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAffiliateStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventCode = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numPayments = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numCheckedIn = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tokensCollected = reader.int64() as Long;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.tokensPerHour = reader.int64() as Long;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.timestampLastFundsUpdated = reader.int64() as Long;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.timestampNextInvalidation = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AffiliateStats {
    return {
      eventCode: isSet(object.eventCode)
        ? globalThis.String(object.eventCode)
        : "",
      numPayments: isSet(object.numPayments)
        ? globalThis.Number(object.numPayments)
        : 0,
      numCheckedIn: isSet(object.numCheckedIn)
        ? globalThis.Number(object.numCheckedIn)
        : 0,
      tokensCollected: isSet(object.tokensCollected)
        ? Long.fromValue(object.tokensCollected)
        : Long.ZERO,
      tokensPerHour: isSet(object.tokensPerHour)
        ? Long.fromValue(object.tokensPerHour)
        : Long.ZERO,
      timestampLastFundsUpdated: isSet(object.timestampLastFundsUpdated)
        ? Long.fromValue(object.timestampLastFundsUpdated)
        : Long.ZERO,
      timestampNextInvalidation: isSet(object.timestampNextInvalidation)
        ? Long.fromValue(object.timestampNextInvalidation)
        : Long.ZERO,
    };
  },

  toJSON(message: AffiliateStats): unknown {
    const obj: any = {};
    if (message.eventCode !== "") {
      obj.eventCode = message.eventCode;
    }
    if (message.numPayments !== 0) {
      obj.numPayments = Math.round(message.numPayments);
    }
    if (message.numCheckedIn !== 0) {
      obj.numCheckedIn = Math.round(message.numCheckedIn);
    }
    if (!message.tokensCollected.isZero()) {
      obj.tokensCollected = (message.tokensCollected || Long.ZERO).toString();
    }
    if (!message.tokensPerHour.isZero()) {
      obj.tokensPerHour = (message.tokensPerHour || Long.ZERO).toString();
    }
    if (!message.timestampLastFundsUpdated.isZero()) {
      obj.timestampLastFundsUpdated = (
        message.timestampLastFundsUpdated || Long.ZERO
      ).toString();
    }
    if (!message.timestampNextInvalidation.isZero()) {
      obj.timestampNextInvalidation = (
        message.timestampNextInvalidation || Long.ZERO
      ).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AffiliateStats>, I>>(
    base?: I
  ): AffiliateStats {
    return AffiliateStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AffiliateStats>, I>>(
    object: I
  ): AffiliateStats {
    const message = createBaseAffiliateStats();
    message.eventCode = object.eventCode ?? "";
    message.numPayments = object.numPayments ?? 0;
    message.numCheckedIn = object.numCheckedIn ?? 0;
    message.tokensCollected =
      object.tokensCollected !== undefined && object.tokensCollected !== null
        ? Long.fromValue(object.tokensCollected)
        : Long.ZERO;
    message.tokensPerHour =
      object.tokensPerHour !== undefined && object.tokensPerHour !== null
        ? Long.fromValue(object.tokensPerHour)
        : Long.ZERO;
    message.timestampLastFundsUpdated =
      object.timestampLastFundsUpdated !== undefined &&
      object.timestampLastFundsUpdated !== null
        ? Long.fromValue(object.timestampLastFundsUpdated)
        : Long.ZERO;
    message.timestampNextInvalidation =
      object.timestampNextInvalidation !== undefined &&
      object.timestampNextInvalidation !== null
        ? Long.fromValue(object.timestampNextInvalidation)
        : Long.ZERO;
    return message;
  },
};

function createBaseBurn(): Burn {
  return {
    paymentId: 0,
    txid: new Uint8Array(0),
    burnTokenAmount: Long.ZERO,
    timestamp: Long.ZERO,
  };
}

export const Burn = {
  encode(message: Burn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentId !== 0) {
      writer.uint32(8).int32(message.paymentId);
    }
    if (message.txid.length !== 0) {
      writer.uint32(18).bytes(message.txid);
    }
    if (!message.burnTokenAmount.isZero()) {
      writer.uint32(24).int64(message.burnTokenAmount);
    }
    if (!message.timestamp.isZero()) {
      writer.uint32(32).int64(message.timestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Burn {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.paymentId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.txid = reader.bytes();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.burnTokenAmount = reader.int64() as Long;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timestamp = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Burn {
    return {
      paymentId: isSet(object.paymentId)
        ? globalThis.Number(object.paymentId)
        : 0,
      txid: isSet(object.txid)
        ? bytesFromBase64(object.txid)
        : new Uint8Array(0),
      burnTokenAmount: isSet(object.burnTokenAmount)
        ? Long.fromValue(object.burnTokenAmount)
        : Long.ZERO,
      timestamp: isSet(object.timestamp)
        ? Long.fromValue(object.timestamp)
        : Long.ZERO,
    };
  },

  toJSON(message: Burn): unknown {
    const obj: any = {};
    if (message.paymentId !== 0) {
      obj.paymentId = Math.round(message.paymentId);
    }
    if (message.txid.length !== 0) {
      obj.txid = base64FromBytes(message.txid);
    }
    if (!message.burnTokenAmount.isZero()) {
      obj.burnTokenAmount = (message.burnTokenAmount || Long.ZERO).toString();
    }
    if (!message.timestamp.isZero()) {
      obj.timestamp = (message.timestamp || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Burn>, I>>(base?: I): Burn {
    return Burn.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Burn>, I>>(object: I): Burn {
    const message = createBaseBurn();
    message.paymentId = object.paymentId ?? 0;
    message.txid = object.txid ?? new Uint8Array(0);
    message.burnTokenAmount =
      object.burnTokenAmount !== undefined && object.burnTokenAmount !== null
        ? Long.fromValue(object.burnTokenAmount)
        : Long.ZERO;
    message.timestamp =
      object.timestamp !== undefined && object.timestamp !== null
        ? Long.fromValue(object.timestamp)
        : Long.ZERO;
    return message;
  },
};

function createBaseTokenAmounts(): TokenAmounts {
  return { amounts: [] };
}

export const TokenAmounts = {
  encode(
    message: TokenAmounts,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.amounts) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TokenAmounts {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTokenAmounts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.amounts.push(reader.int64() as Long);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.amounts.push(reader.int64() as Long);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TokenAmounts {
    return {
      amounts: globalThis.Array.isArray(object?.amounts)
        ? object.amounts.map((e: any) => Long.fromValue(e))
        : [],
    };
  },

  toJSON(message: TokenAmounts): unknown {
    const obj: any = {};
    if (message.amounts?.length) {
      obj.amounts = message.amounts.map(e => (e || Long.ZERO).toString());
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TokenAmounts>, I>>(
    base?: I
  ): TokenAmounts {
    return TokenAmounts.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TokenAmounts>, I>>(
    object: I
  ): TokenAmounts {
    const message = createBaseTokenAmounts();
    message.amounts = object.amounts?.map(e => Long.fromValue(e)) || [];
    return message;
  },
};

function createBaseBalance(): Balance {
  return { usd: 0, cent: Long.ZERO, baseToken: Long.ZERO };
}

export const Balance = {
  encode(
    message: Balance,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.usd !== 0) {
      writer.uint32(8).int32(message.usd);
    }
    if (!message.cent.isZero()) {
      writer.uint32(16).int64(message.cent);
    }
    if (!message.baseToken.isZero()) {
      writer.uint32(24).int64(message.baseToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Balance {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.usd = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cent = reader.int64() as Long;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.baseToken = reader.int64() as Long;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Balance {
    return {
      usd: isSet(object.usd) ? globalThis.Number(object.usd) : 0,
      cent: isSet(object.cent) ? Long.fromValue(object.cent) : Long.ZERO,
      baseToken: isSet(object.baseToken)
        ? Long.fromValue(object.baseToken)
        : Long.ZERO,
    };
  },

  toJSON(message: Balance): unknown {
    const obj: any = {};
    if (message.usd !== 0) {
      obj.usd = Math.round(message.usd);
    }
    if (!message.cent.isZero()) {
      obj.cent = (message.cent || Long.ZERO).toString();
    }
    if (!message.baseToken.isZero()) {
      obj.baseToken = (message.baseToken || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Balance>, I>>(base?: I): Balance {
    return Balance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Balance>, I>>(object: I): Balance {
    const message = createBaseBalance();
    message.usd = object.usd ?? 0;
    message.cent =
      object.cent !== undefined && object.cent !== null
        ? Long.fromValue(object.cent)
        : Long.ZERO;
    message.baseToken =
      object.baseToken !== undefined && object.baseToken !== null
        ? Long.fromValue(object.baseToken)
        : Long.ZERO;
    return message;
  },
};

function createBaseWsSub(): WsSub {
  return { isUnsub: false, user: undefined };
}

export const WsSub = {
  encode(message: WsSub, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isUnsub === true) {
      writer.uint32(8).bool(message.isUnsub);
    }
    if (message.user !== undefined) {
      WsSubUser.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WsSub {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWsSub();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isUnsub = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = WsSubUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WsSub {
    return {
      isUnsub: isSet(object.isUnsub)
        ? globalThis.Boolean(object.isUnsub)
        : false,
      user: isSet(object.user) ? WsSubUser.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: WsSub): unknown {
    const obj: any = {};
    if (message.isUnsub === true) {
      obj.isUnsub = message.isUnsub;
    }
    if (message.user !== undefined) {
      obj.user = WsSubUser.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WsSub>, I>>(base?: I): WsSub {
    return WsSub.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WsSub>, I>>(object: I): WsSub {
    const message = createBaseWsSub();
    message.isUnsub = object.isUnsub ?? false;
    message.user =
      object.user !== undefined && object.user !== null
        ? WsSubUser.fromPartial(object.user)
        : undefined;
    return message;
  },
};

function createBaseWsSubUser(): WsSubUser {
  return {};
}

export const WsSubUser = {
  encode(_: WsSubUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WsSubUser {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWsSubUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): WsSubUser {
    return {};
  },

  toJSON(_: WsSubUser): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<WsSubUser>, I>>(base?: I): WsSubUser {
    return WsSubUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WsSubUser>, I>>(_: I): WsSubUser {
    const message = createBaseWsSubUser();
    return message;
  },
};

function createBaseWsMsg(): WsMsg {
  return { error: undefined, guest: undefined };
}

export const WsMsg = {
  encode(message: WsMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(10).fork()).ldelim();
    }
    if (message.guest !== undefined) {
      MsgGuest.encode(message.guest, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WsMsg {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWsMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.guest = MsgGuest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WsMsg {
    return {
      error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
      guest: isSet(object.guest) ? MsgGuest.fromJSON(object.guest) : undefined,
    };
  },

  toJSON(message: WsMsg): unknown {
    const obj: any = {};
    if (message.error !== undefined) {
      obj.error = Error.toJSON(message.error);
    }
    if (message.guest !== undefined) {
      obj.guest = MsgGuest.toJSON(message.guest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WsMsg>, I>>(base?: I): WsMsg {
    return WsMsg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WsMsg>, I>>(object: I): WsMsg {
    const message = createBaseWsMsg();
    message.error =
      object.error !== undefined && object.error !== null
        ? Error.fromPartial(object.error)
        : undefined;
    message.guest =
      object.guest !== undefined && object.guest !== null
        ? MsgGuest.fromPartial(object.guest)
        : undefined;
    return message;
  },
};

function createBaseMsgGuest(): MsgGuest {
  return { eventId: 0, guestId: 0, checkinStatus: "" };
}

export const MsgGuest = {
  encode(
    message: MsgGuest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int32(message.eventId);
    }
    if (message.guestId !== 0) {
      writer.uint32(16).int32(message.guestId);
    }
    if (message.checkinStatus !== "") {
      writer.uint32(26).string(message.checkinStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgGuest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgGuest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.guestId = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.checkinStatus = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgGuest {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      guestId: isSet(object.guestId) ? globalThis.Number(object.guestId) : 0,
      checkinStatus: isSet(object.checkinStatus)
        ? globalThis.String(object.checkinStatus)
        : "",
    };
  },

  toJSON(message: MsgGuest): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.guestId !== 0) {
      obj.guestId = Math.round(message.guestId);
    }
    if (message.checkinStatus !== "") {
      obj.checkinStatus = message.checkinStatus;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgGuest>, I>>(base?: I): MsgGuest {
    return MsgGuest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgGuest>, I>>(object: I): MsgGuest {
    const message = createBaseMsgGuest();
    message.eventId = object.eventId ?? 0;
    message.guestId = object.guestId ?? 0;
    message.checkinStatus = object.checkinStatus ?? "";
    return message;
  },
};

function createBaseError(): Error {
  return { msg: "" };
}

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.msg !== "") {
      writer.uint32(10).string(message.msg);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.msg = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Error {
    return { msg: isSet(object.msg) ? globalThis.String(object.msg) : "" };
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    if (message.msg !== "") {
      obj.msg = message.msg;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Error>, I>>(base?: I): Error {
    return Error.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Error>, I>>(object: I): Error {
    const message = createBaseError();
    message.msg = object.msg ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach(byte => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
