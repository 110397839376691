// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React from "react";

import { PIN_CODE_LENGTH } from "../../store/pin";

import "./PinDots.scss";

interface Props {
  pinLength: number;
}

const PinDot: React.FC<Props> = ({ pinLength }) => {
  const dots = new Array(PIN_CODE_LENGTH).fill("");
  return (
    <div className="pin-dots">
      {dots.map((_, index) => (
        <div
          key={index}
          className={`circle ${index < pinLength ? "filled" : ""}`}
        ></div>
      ))}
    </div>
  );
};

export default PinDot;
