// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { useUserAgent } from "./useUserAgent";
import { useStore } from "../store";

export const useSupportsInstallPrompt = () => {
  const userAgent = useUserAgent().userAgent;
  const installPromptEvent = useStore(store => store.installPrompt.event);

  // Blink is the rendering engine used by Chromium, and `beforeinstallprompt`
  // is only supported in Chromium-based browsers.
  const supportsBeforeInstallPrompt = userAgent.engine.name === "Blink";

  return installPromptEvent && supportsBeforeInstallPrompt;
};
