// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { errorToString } from "../store/error";

export const ERR_PROTOBUF_DECODE = "ERR_PROTOBUF_DECODE";

export interface ProtoDecoder<T> {
  decode(input: Uint8Array): T;
}

export function protoDecode<T>(decoder: ProtoDecoder<T>, data: Uint8Array): T {
  try {
    return decoder.decode(data);
  } catch (e) {
    throw new Error(errorToString(e), {
      cause: {
        code: ERR_PROTOBUF_DECODE,
      },
    });
  }
}
