// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import InApp from "detect-inapp";
import { useState } from "react";
import { UAParser } from "ua-parser-js";

export const useUserAgent = () => {
  const [userAgentParser] = useState(new UAParser(navigator.userAgent));
  const inAppType = new InApp(navigator.userAgent);
  return { userAgent: userAgentParser.getResult(), inAppType };
};
