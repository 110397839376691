// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import * as proto from "../rpc/p2s";
import { signedRequest, signedRequestRaw } from "../rpc/request";

export interface EmailToken {
  emailTokenId: string;
  seedPhrasePk: Uint8Array;
}

export interface VerifyEmailState {
  hasVerifiedEmail: boolean;
  emailToken: EmailToken | undefined;
}

export interface VerifyEmailActions {
  createEmailToken: () => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  checkHasVerifiedEmail: () => Promise<void>;
}

export const initialVerifyEmail: VerifyEmailState = {
  hasVerifiedEmail: false,
  emailToken: undefined,
};

export function verifyEmailActions(
  set: StoreSet,
  get: StoreGet
): VerifyEmailActions {
  return {
    checkHasVerifiedEmail: catchError("Email Verification", set, async () => {
      set(state => {
        state.verifyEmail.hasVerifiedEmail = false;
      });
      const walletKeys = get().wallet.keys!;
      const response = await signedRequest({
        proto: proto.LoginResponse,
        path: "/login",
        method: "POST",
        ecc: get().wallet.ecc!,
        seckey: walletKeys.seckey,
        pubkey: walletKeys.pubkey,
        payload: new Uint8Array(),
      });
      if (!response.exists) {
        throw "User doesn't exist";
      }
      if (!response.hasVerifiedEmail) {
        return;
      }
      await localforage.setItem("p2s:hasVerifiedEmail", true);
      set(state => {
        state.verifyEmail.hasVerifiedEmail = true;
      });
    }),
    sendVerificationEmail: catchError(
      "Send Verification Email",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const emailToken = get().verifyEmail.emailToken;
        if (keys === undefined || emailToken === undefined) {
          return;
        }
        await signedRequestRaw({
          path: "/email/send-verification",
          method: "POST",
          ecc: ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.SendVerificationEmailRequest.encode({
            emailTokenId: emailToken.emailTokenId,
            encryptedSeedPhrase: new Uint8Array(),
          }).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    createEmailToken: catchError(
      "Create Email Token",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(store => {
          store.loading.isLoading = true;
          store.verifyEmail.emailToken = undefined;
        });
        const request = proto.Empty.encode({}).finish();
        const response = await signedRequest({
          proto: proto.CreateEmailTokenResponse,
          path: "/email/create-token",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });
        set(store => {
          store.verifyEmail.emailToken = response;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
