// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

export const CLIENT_ID = {
  PRODUCTION:
    "ATPjCoOQT8kYOAzUUwehyvrA7D4nyvkfyZgmSMiR5_YOe9G2UomchTEQJzdzj2QGiUXOxfYCpK17izz7",
  SANDBOX:
    "ASeC_-LaVBM9hl052Tz_BAE01e9hFjNLbQvUwVk1x2YYNNyhL6dg7931DbOJqpA8SD6eVU1BKF8USWKk",
};

export const AUTH_CODE_URL = "https://bux.digital/v1/success";
