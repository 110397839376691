// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { useStore } from "../../store";
import { buildUrlParams, getUrlParam } from "../../util";
import { useUpdateRegularly } from "../../hook/useUpdateRegularly";
import { EVENT_CODE_PARAM } from "../../store/eventDetail";

const SESSION_ID_PARAM = "id";

export const BrowserSession: React.FC = () => {
  const history = useHistory();
  const sessionId = useStore(store => store.browserSession.sessionId);
  const initBrowserSession = useStore(store => store.initBrowserSession);
  const fetchBrowserSession = useStore(store => store.fetchBrowserSession);
  const resetBrowserSession = useStore(store => store.resetBrowserSession);
  const eventDetailFetchByCode = useStore(
    store => store.eventDetailFetchByCode
  );
  const nextEventCode = useStore(store => store.browserSession.nextEventCode);

  useEffect(() => {
    const paramsSessionId = getUrlParam(SESSION_ID_PARAM, history.location);
    initBrowserSession(paramsSessionId);
  }, []);

  // Update on app switch and every 5 minutes
  useUpdateRegularly(() => fetchBrowserSession(), [sessionId], 5 * 60 * 1000);

  useEffect(() => {
    if (nextEventCode !== undefined) {
      resetBrowserSession();
      eventDetailFetchByCode(nextEventCode).then(() => {
        history.push(
          buildUrlParams("/event/detail", { [EVENT_CODE_PARAM]: nextEventCode })
        );
      });
    }
  }, [nextEventCode]);

  return null;
};
