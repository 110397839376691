// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { P2S_WS_URL } from "../rpc/settings";
import { signRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { protoDecode } from "../rpc/util";

export type WsHandler = (wsMsg: proto.WsMsg) => void;

export interface WsState {
  conn: WebSocket | undefined;
  handler: WsHandler | undefined;
  backoffMs: number;
}

export interface WsActions {
  wsInit: () => Promise<void>;
  wsSetHandler: (handler: WsHandler | undefined) => void;
}

export const initialWs: WsState = {
  conn: undefined,
  handler: undefined,
  backoffMs: 0,
};

export function wsActions(set: StoreSet, get: StoreGet): WsActions {
  return {
    wsInit: catchError(
      "WebSocket",
      set,
      async () => {
        function connectWs() {
          if (
            get().wallet.ecc === undefined ||
            get().wallet.keys === undefined
          ) {
            return;
          }
          const wsUrl = `${P2S_WS_URL}/ws`;
          console.log("Attempting to connect to WS", wsUrl);
          const ws = new WebSocket(wsUrl);
          set(state => {
            state.ws.conn = ws;
          });
          ws.onopen = msg => {
            console.log("WS connection successful", msg);
            const ecc = get().wallet.ecc!;
            const keys = get().wallet.keys!;
            ws.send(
              signRequest({
                ecc,
                seckey: keys.seckey,
                pubkey: keys.pubkey,
                payload: proto.WsSub.encode({
                  isUnsub: false,
                  user: {},
                }).finish(),
              })
            );
            set(state => {
              state.ws.backoffMs = 0;
            });
          };
          ws.onmessage = e => handleMsg(e);
          ws.onclose = e => {
            const backoffMs = get().ws.backoffMs;
            console.log(backoffMs, "ms before retrying to connect to WS");
            setTimeout(connectWs, backoffMs);
            set(state => {
              state.ws.backoffMs = state.ws.backoffMs * 1.4;
              if (state.ws.backoffMs < 1) {
                state.ws.backoffMs = 50;
              }
            });
          };
        }

        async function handleMsg(msg: MessageEvent) {
          if (!(msg.data instanceof Blob)) {
            console.warn("Non-blob msg from WS:", msg.data);
            return;
          }
          const data = new Uint8Array(await msg.data.arrayBuffer());
          const wsMsg = protoDecode(proto.WsMsg, data);
          const handler = get().ws.handler;
          if (handler !== undefined) {
            handler(wsMsg);
          }
        }

        connectWs();
      },
      undefined,
      false
    ),
    wsSetHandler: handler => {
      set(state => {
        state.ws.handler = handler;
      });
    },
  };
}
