// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect } from "react";
import { useStore } from "../../store";

interface Props {
  progress?: number;
}

const Loading: React.FC<Props> = ({ progress }) => {
  const setFullPageLoading = useStore(store => store.setFullPageLoading);

  useEffect(() => {
    setFullPageLoading(true);
    return () => {
      setFullPageLoading(false);
    };
  }, []);

  return (
    <div className="initial-loading">
      <div className="initial-loading-wrap">
        {progress && (
          <div
            aria-label="Loading"
            aria-valuemax={100}
            aria-valuemin={0}
            aria-valuenow={progress}
            aria-valuetext={`${progress}%`}
            className="initial-loading-progress-bar"
            style={
              {
                "--progress": `${progress}%`,
              } as React.CSSProperties
            }
          ></div>
        )}
      </div>
    </div>
  );
};

export default Loading;
