// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import { chronik } from "../rpc/request";
import { fromHexRev, toHex } from "./hex";
import { SlpUtxo } from "./utxo";

export async function requestUtxos(
  tokenIdHex: string,
  pkh: Uint8Array
): Promise<SlpUtxo[]> {
  const scriptUtxos = await chronik().script("p2pkh", toHex(pkh)).utxos();
  const utxos: SlpUtxo[] = [];
  for (const utxo of scriptUtxos.utxos) {
    if (utxo.token === undefined) {
      continue;
    }
    if (utxo.token.tokenId != tokenIdHex) {
      continue;
    }
    const amount = Long.fromString(utxo.token.amount);
    if (amount.isZero()) {
      continue;
    }
    utxos.push({
      outpoint: {
        txid: fromHexRev(utxo.outpoint.txid),
        outIdx: utxo.outpoint.outIdx,
      },
      blockHeight: utxo.blockHeight,
      isCoinbase: utxo.isCoinbase,
      value: Long.fromNumber(utxo.value),
      tokenId: utxo.token.tokenId,
      slpAmount: Long.fromString(utxo.token.amount),
      isMintBaton: utxo.token.isMintBaton,
    });
  }
  return utxos;
}
