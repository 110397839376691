// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import * as proto from "../rpc/p2s";
import Long from "long";
import { Ecc, encode_base58, sha256 } from "./ffi";
import { buildUrlParams } from "../util";

export const CHECKIN_MSG_PARAM = "m";
export const CHECKIN_SIG_PARAM = "s";
export const CHECKIN_SUCCESS_PARAM = "success";
export const CHECKIN_TYPE_PARAM = "checkin_type";

export interface ParsedCheckinUri {
  msgBase58: string;
  sigBase58: string;
}

export function buildCheckinUri(params: {
  ecc: Ecc;
  guestSk: Uint8Array;
  guestId: number;
  eventId: number;
  isCheckin: boolean;
  isManualByDoorman: boolean;
}): string {
  const timestampNumber = new Date().getTime() / 1000;
  const msg = proto.GuestCheckinMsg.encode({
    guestId: params.guestId,
    eventId: params.eventId,
    timestamp: Long.fromInt(Math.round(timestampNumber)),
    isCheckin: params.isCheckin,
    isManualByDoorman: params.isManualByDoorman,
  }).finish();
  const msgHash = sha256(msg);
  const sig = params.ecc.schnorr_sign(params.guestSk, msgHash);
  return buildUrlParams("p2s:", {
    [CHECKIN_MSG_PARAM]: encode_base58(msg),
    [CHECKIN_SIG_PARAM]: encode_base58(sig),
  });
}

export function parseCheckinUri(uriPayload: string): ParsedCheckinUri {
  const idxSearch = uriPayload.indexOf("?");
  if (idxSearch === -1) {
    throw "missing ?";
  }
  const search = uriPayload.substring(idxSearch);
  const params = new URLSearchParams(search);
  const checkinMsgBase58 = params.getAll(CHECKIN_MSG_PARAM).at(0);
  const checkinSigBase58 = params.getAll(CHECKIN_SIG_PARAM).at(0);
  if (checkinMsgBase58 === undefined) {
    throw "missing checkin message";
  }
  if (checkinSigBase58 === undefined) {
    throw "missing checkin signature";
  }
  return {
    msgBase58: checkinMsgBase58,
    sigBase58: checkinSigBase58,
  };
}

export function buildVerifyCheckinRoute(parsedUri: ParsedCheckinUri): string {
  return buildUrlParams("/scan/verify-guest", {
    [CHECKIN_MSG_PARAM]: parsedUri.msgBase58,
    [CHECKIN_SIG_PARAM]: parsedUri.sigBase58,
  });
}

export function formatCheckinVerifyType(
  verifyType: string | undefined
): string {
  switch (verifyType) {
    case "SigExpired":
    case "TimestampNotIncreasing":
      return `Check-in QR code expired: Scan again or update system clock`;
    case "SigTooEarly":
      return `Check-in QR code from the future: Update system clock`;
    case "SigInvalid":
      return `Signature invalid, please contact support`;
    case "WrongEvent":
      return `Check-in QR code is for a different event`;
    case "NotAGuest":
      return `User is not a guest of this event`;
    case "NotYetCheckedIn":
      return `User tried checking-out, but user is not yet checked in`;
    case "AlreadyCheckedIn":
      return `User tried checking-in, but user already checked-in`;
    case "AlreadyCheckedOut":
      return `User tried checking-out, but user is already checked-out`;
    case "Valid":
      return `Guest verified`;
    default:
      return `Check-in QR code invalid: ${verifyType}`;
  }
}
