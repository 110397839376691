// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonIcon, IonItem, IonLabel, IonModal } from "@ionic/react";
import React, { ReactNode, useRef } from "react";
import { globeOutline } from "ionicons/icons";

import { logoColorIcon } from "../../../icon/icon";
import { setCookie, USE_BROWSER_COOKIE } from "../../../cookieUtil";
import { usePlatforms } from "../../../hook/usePlatforms";

import "./InstallAppPopup.scss";

interface Props {
  isOpen: boolean;
  eventCode: string | undefined;
  onBackdropDismiss: () => void;
  onClickInstall: () => void;
  installCustom?: ReactNode;
}

const InstallAppPopup: React.FC<Props> = props => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { isBrowserIos, isBrowserAndroid } = usePlatforms();

  const handledUseBrowser = () => {
    if (isBrowserIos || isBrowserAndroid) {
      const days = 14 * 3600 * 24; // 14 day ;
      setCookie(USE_BROWSER_COOKIE, days.toString(), days);
      window.location.reload();
    }
  };

  return (
    <IonModal
      ref={modal}
      backdropDismiss={true}
      isOpen={props.isOpen}
      onDidDismiss={() => props.onBackdropDismiss()}
      className="install-app-modal"
    >
      <div className="install-popup-content">
        {props.eventCode && <h3 className="title-modal">RSVP via...</h3>}
        <div className="card-item">
          <IonItem lines="none" button onClick={props.onClickInstall}>
            <img src={logoColorIcon} />
            <IonLabel> Pay2Stay app</IonLabel>
            {props.installCustom ? (
              props.installCustom
            ) : (
              <div className="btn solid">Install</div>
            )}
          </IonItem>
          <IonItem lines="none" button onClick={handledUseBrowser}>
            <IonIcon icon={globeOutline} slot="start"></IonIcon>
            <IonLabel> Browser</IonLabel>
            <div className="btn outline">Continue</div>
          </IonItem>
        </div>
      </div>
    </IonModal>
  );
};

export default InstallAppPopup;
