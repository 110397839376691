// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { signedRequest, signedRequestRaw } from "../rpc/request";
import * as proto from "../rpc/p2s";

export interface BrowserSessionState {
  sessionId: string | undefined;
  nextEventCode: string | undefined;
}

export interface BrowserSessionActions {
  initBrowserSession: (sessionId: string | undefined) => Promise<void>;
  fetchBrowserSession: () => Promise<void>;
  resetBrowserSession: () => Promise<void>;
}

export const initialBrowserSession: BrowserSessionState = {
  sessionId: undefined,
  nextEventCode: undefined,
};

export function browserSessionActions(
  set: StoreSet,
  get: StoreGet
): BrowserSessionActions {
  return {
    initBrowserSession: async (sessionId: string | undefined) => {
      if (get().browserSession.sessionId !== undefined) {
        return;
      }
      const existingSessionId = await localforage.getItem<string>(
        "p2s:sessionId"
      );
      if (existingSessionId === null && sessionId !== undefined) {
        await localforage.setItem<string>("p2s:sessionId", sessionId);
      }
      set(state => {
        state.browserSession.sessionId = existingSessionId ?? sessionId;
      });
    },
    fetchBrowserSession: catchError("Browser session", set, async () => {
      const sessionId = get().browserSession.sessionId;
      const keys = get().wallet.keys;
      const hasVerifiedEmail = get().verifyEmail.hasVerifiedEmail;
      if (sessionId === undefined || keys === undefined || !hasVerifiedEmail) {
        return;
      }
      const ecc = get().wallet.ecc!;
      const response = await signedRequest({
        proto: proto.GetBrowserSessionResponse,
        path: "/browser-session",
        method: "POST",
        ecc,
        seckey: keys.seckey,
        pubkey: keys.pubkey,
        payload: proto.GetBrowserSessionRequest.encode({
          sessionId,
        }).finish(),
      });
      set(state => {
        state.browserSession.nextEventCode =
          response.nextEventCode !== "" ? response.nextEventCode : undefined;
      });
    }),
    resetBrowserSession: catchError("Browser session", set, async () => {
      set(state => {
        state.browserSession.nextEventCode = undefined;
      });
      const sessionId = get().browserSession.sessionId;
      if (sessionId === undefined) {
        return;
      }
      const ecc = get().wallet.ecc!;
      const keys = get().wallet.keys!;
      await signedRequestRaw({
        path: "/browser-session/reset",
        method: "POST",
        ecc,
        seckey: keys.seckey,
        pubkey: keys.pubkey,
        payload: proto.ResetBrowserSessionRequest.encode({
          sessionId,
        }).finish(),
      });
    }),
  };
}
