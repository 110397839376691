// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { lazy } from "react";

export interface AppRoute {
  component: React.LazyExoticComponent<React.FC<{}>>;
  path: string;
}

export const appRoutes: AppRoute[] = [
  {
    component: lazy(() => import("../../pages/burn-perform/BurnPerformPage")),
    path: "/burn/perform",
  },
  {
    component: lazy(() => import("../../pages/confirm-refund/ConfirmRefund")),
    path: "/refund/confirm",
  },
  {
    component: lazy(() => import("../../pages/check-in/EventCheckInPage")),
    path: "/event/check-in",
  },
  {
    component: lazy(
      () => import("../../pages/pay-confirmation/PayConfirmationPage")
    ),
    path: "/event/confirm-pay",
  },
  {
    component: lazy(() => import("../../pages/event-detail/EventDetailPage")),
    path: "/event/detail",
  },
  {
    component: lazy(() => import("../../pages/join-event/JoinEventPage")),
    path: "/event/join",
  },
  {
    component: lazy(
      () => import("../../pages/event-ticket-intro/EventTicketIntroPage")
    ),
    path: "/event/ticket/intro",
  },
  {
    component: lazy(
      () => import("../../pages/event-ticket-guests/EventTicketGuestsPage")
    ),
    path: "/event/ticket/guests",
  },
  {
    component: lazy(
      () => import("../../pages/event-ticket-pay/EventTicketPayPage")
    ),
    path: "/event/ticket/pay",
  },
  {
    component: lazy(
      () => import("../../pages/event-ticket-pay-stripe/PayWithStripePage")
    ),
    path: "/event/ticket/pay/stripe",
  },
  {
    component: lazy(
      () => import("../../pages/event-overview/EventOverviewPage")
    ),
    path: "/event/overview",
  },
  {
    component: lazy(
      () => import("../../pages/ended-events/EndedHostedEventsPage")
    ),
    path: "/event/ended-hosted",
  },
  {
    component: lazy(
      () => import("../../pages/ended-events/EndedJoinedEventsPage")
    ),
    path: "/event/ended-joined",
  },
  {
    component: lazy(() => import("../../pages/manage-event/ManageEventPage")),
    path: "/event/manage",
  },
  {
    component: lazy(
      () => import("../../pages/event-code-list/EventCodeListPage")
    ),
    path: "/event/codes/list",
  },
  {
    component: lazy(
      () => import("../../pages/event-member-list/EventMemberListPage")
    ),
    path: "/event/members/list",
  },
  {
    component: lazy(
      () => import("../../pages/manage-event-code/ManageEventCodePage")
    ),
    path: "/event/manage/code",
  },
  {
    component: lazy(() => import("../../pages/pay-with-card/PayWithCard")),
    path: "/event/pay-card",
  },
  {
    component: lazy(() => import("../../pages/pay-with-cash/PayWithCashPage")),
    path: "/event/pay-with-cash",
  },
  {
    component: lazy(() => import("../../pages/event-payout/EventPayoutPage")),
    path: "/event/payout",
  },
  {
    component: lazy(
      () =>
        import("../../pages/affiliate-event-payout/AffiliateEventPayoutPage")
    ),
    path: "/event/affiliate-payout",
  },
  {
    component: lazy(() => import("../../pages/home/HomePage")),
    path: "/home",
  },
  {
    component: lazy(() => import("../../pages/install/InstallPage")),
    path: "/install",
  },
  {
    component: lazy(() => import("../../pages/login/LoginPage")),
    path: "/login",
  },
  {
    component: lazy(() => import("../../pages/payout-host/PayoutHostPage")),
    path: "/host-payout",
  },
  {
    component: lazy(() => import("../../pages/profile/ProfilePage")),
    path: "/profile",
  },
  {
    component: lazy(() => import("../../pages/log-out/LogoutPage")),
    path: "/profile/logout",
  },
  {
    component: lazy(
      () => import("../../pages/profile-detail/ProfileDetailPage")
    ),
    path: "/profile/detail",
  },
  {
    component: lazy(
      () => import("../../pages/view-seed-phrase/ViewSeedPhrasePage")
    ),
    path: "/profile/seed-phrase",
  },
  {
    component: lazy(() => import("../../pages/edit-profile/EditProfilePage")),
    path: "/profile/edit",
  },
  {
    component: lazy(
      () => import("../../pages/update-profile/UpdateProfilePage")
    ),
    path: "/init/profile",
  },
  {
    component: lazy(() => import("../../pages/scan-qr/ScanQrPage")),
    path: "/scan",
  },
  {
    component: lazy(() => import("../../pages/verify-guest/VerifyUserGuest")),
    path: "/scan/verify-guest",
  },
  {
    component: lazy(
      () => import("../../pages/self-mint-form/SelfMintFormPage")
    ),
    path: "/self-mint/form",
  },
  {
    component: lazy(
      () => import("../../pages/self-mint-stripe/SelfMintStripePage")
    ),
    path: "/self-mint/stripe",
  },
  {
    component: lazy(
      () => import("../../pages/self-mint-send/SelfMintSendPage")
    ),
    path: "/self-mint/send",
  },
  {
    component: lazy(() => import("../../pages/send-amount/SendAmountPage")),
    path: "/send",
  },
  {
    component: lazy(() => import("../../pages/send-success/SendSuccessPage")),
    path: "/send/success",
  },
  {
    component: lazy(() => import("../../pages/signup/SignUpPage")),
    path: "/signup",
  },
  {
    component: lazy(() => import("../../pages/signin/SignInPage")),
    path: "/signin",
  },
  {
    component: lazy(
      () =>
        import(
          "../../pages/sign-in-existing-seed-phrase/SignInExistingSeedPhrasePage"
        )
    ),
    path: "/signin/existing-seed-phrase",
  },
  {
    component: lazy(
      () => import("../../pages/sign-in-existing-jwt/SignInExistingJwtPage")
    ),
    path: "/signin/existing-jwt",
  },
  {
    component: lazy(() => import("../../pages/reset-account/ResetAccountPage")),
    path: "/signin/reset-account",
  },
  {
    component: lazy(
      () => import("../../pages/terms-and-conditions/TermsAndConditionsPage")
    ),
    path: "/terms-and-conditions",
  },
  {
    component: lazy(() => import("../../pages/verify-email/VerifyEmailPage")),
    path: "/verify-email",
  },
  {
    component: lazy(
      () => import("../../pages/update-phone-number/UpdatePhoneNumberPage")
    ),
    path: "/phone-number/update",
  },
  {
    component: lazy(
      () => import("../../pages/verify-phone-number/VerifyPhoneNumberPage")
    ),
    path: "/phone-number/verify",
  },
  {
    component: lazy(
      () => import("../../pages/payment-history/PaymentHistoryPage")
    ),
    path: "/payment-history",
  },
  {
    component: lazy(() => import("../../pages/wallet/WalletPage")),
    path: "/wallet",
  },
  {
    component: lazy(() => import("../../pages/payout/PayoutPage")),
    path: "/payout",
  },
  {
    component: lazy(
      () => import("../../pages/payout-information/PayoutInfoPage")
    ),
    path: "/payout/info",
  },
  {
    component: lazy(() => import("../../pages/payout-method/PayoutMethodPage")),
    path: "/payout/method",
  },
  {
    component: lazy(
      () => import("../../pages/payout-history/PayoutHistoryPage")
    ),
    path: "/payout/history",
  },
  {
    component: lazy(
      () => import("../../pages/payout-confirm/PayoutConfirmPage")
    ),
    path: "/payout/confirm",
  },
  {
    component: lazy(
      () => import("../../pages/grant-privileges/GrantPrivileges")
    ),
    path: "/grant-privileges",
  },
  {
    component: lazy(
      () => import("../../pages/event-ticket-sucess/EventTicketSuccessPage")
    ),
    path: "/event/ticket/success",
  },
];
