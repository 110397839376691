// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

import { useStore } from "../../store";

export const AppUrlOpener: React.FC<any> = () => {
  let history = useHistory();
  const eventDetailFetchByCode = useStore(
    store => store.eventDetailFetchByCode
  );

  useEffect(() => {
    App.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);
      const eventCode =
        url.searchParams.get("event") || url.searchParams.get("code");
      console.log("appUrlOpen", event.url, eventCode);
      if (eventCode !== null) {
        await eventDetailFetchByCode(eventCode);
        history.replace(`/event/detail?code=${eventCode}`);
      }
    });
  }, []);

  return null;
};
