// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import { IonModal } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useStore } from "../../store";
import { PIN_IDLE_TIME_UNTIL_LOCK } from "../../store/pin";
import PinModal from "../pin/PinModal";

export const PinLockOverlay: React.FC = () => {
  const pinCode = useStore(store => store.pin.pinCode);
  const modalPin = useRef<HTMLIonModalElement>(null);

  const openPinModal = () => {
    if (pinCode !== undefined) {
      modalPin.current?.present();
    }
  };

  useEffect(() => {
    // Lock app when initially loading the app
    openPinModal();
  }, []);

  useIdleTimer({
    onIdle: openPinModal,
    timeout: PIN_IDLE_TIME_UNTIL_LOCK * 1000,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
  });
  return (
    <IonModal ref={modalPin} id="fullscreen-modal" backdropDismiss={false}>
      <PinModal
        action="CheckPin"
        dismiss={() => {
          modalPin.current?.dismiss();
        }}
      />
    </IonModal>
  );
};
