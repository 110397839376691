// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import * as proto from "../rpc/p2s";
import { canJoinAgain } from "./format";

export function priceToUsd(price: Long): number {
  return price.toNumber() / 100;
}

export function totalPriceEvent(
  priceData: proto.EventPrice | undefined,
  guestState: proto.GuestState | undefined,
  numBookedHours: number | undefined,
  addCashUpcharge?: boolean,
  numberGuests = 1
): Long {
  if (priceData === undefined) {
    return Long.ZERO;
  }

  const timePrice = timePriceEvent(priceData, numBookedHours, numberGuests);
  const upfrontPrice = upfrontPriceEvent(priceData, numberGuests);

  return timePrice
    .add(!canJoinAgain(guestState) ? upfrontPrice : Long.ZERO)
    .add(addCashUpcharge ? priceData.priceUpchargeCash : Long.ZERO);
}

/**
 * Calculates the time-based maximum price of the event for the given number of guests.
 */
export function timePriceEvent(
  priceData: proto.EventPrice,
  numBookedHours: number | undefined,
  numberGuests = 1
): Long {
  return priceData.pricePerHour
    .mul(numBookedHours ?? Long.ZERO)
    .mul(numberGuests);
}

/**
 * Calculates the upfront price of the event for the given number of guests.
 */
export function upfrontPriceEvent(
  priceData: proto.EventPrice,
  numberGuests = 1
): Long {
  return priceData.priceFlat.mul(numberGuests);
}
