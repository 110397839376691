// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import { decodeEtokenAddress } from "../wallet/address";
import { script_p2pkh } from "../wallet/ffi";
import { toHexRev } from "../wallet/hex";
import { buildSendToTxs, tokenProtocolProto } from "../wallet/send";
import {
  FEE_PER_KB,
  P2S_ADDRESS,
  POSTAGE_FEE,
  TOKEN_DATA,
  TOKEN_FACTOR_CENT,
} from "../wallet/settings";
import { catchError } from "./error";
import Long from "long";
import { signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";

export interface SendSuccessInfo {
  sentAmountCent: Long;
  sentAddress: string;
}
export interface SendStore {
  successInfo: SendSuccessInfo | undefined;
}

export const SEND_AMOUNT_PARAM = "amount";
export const SEND_ADDRESS_PARAM = "address";

export interface SendActions {
  sendRequest: (
    address: string,
    amountCent: Long,
    tokenId: string
  ) => Promise<void>;
  sendReset: () => void;
}

export const initialSend: SendStore = {
  successInfo: undefined,
};

export function sendActions(set: StoreSet, get: StoreGet): SendActions {
  return {
    sendReset: () =>
      set(state => {
        state.send = initialSend;
      }),
    sendRequest: catchError(
      "Send Request",
      set,
      async (address: string, amountCent: Long, tokenId: string) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const tokenData = TOKEN_DATA.get(tokenId)!;
        const postageFee = Long.fromNumber(POSTAGE_FEE);
        const amountToken = amountCent.mul(TOKEN_FACTOR_CENT);
        const recipientScript = decodeEtokenAddress(address);
        const p2sScript = decodeEtokenAddress(P2S_ADDRESS);
        const leftoverScript = script_p2pkh(keys.pkh);
        set(store => {
          store.loading.isLoading = true;
          store.send.successInfo = undefined;
        });
        const txs = await buildSendToTxs({
          ecc,
          keys,
          tokenId,
          tokenProtocol: tokenData.tokenProtocol,
          amount: amountToken,
          recipientScript,
          leftoverScript,
          feePerKb: FEE_PER_KB,
          postage: {
            totalFee: postageFee,
            script: p2sScript,
          },
        });
        const response = await signedRequest({
          proto: proto.SendTxsResponse,
          path: "/tx/send",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.SendTxsRequest.encode({
            txs,
            tokenProtocol: tokenProtocolProto(tokenData.tokenProtocol),
          }).finish(),
        });
        console.log("Sent txids:", response.txids.map(toHexRev));
        set(store => {
          store.send.successInfo = {
            sentAmountCent: amountCent,
            sentAddress: address,
          };
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
