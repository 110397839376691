// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";
import { WALLET_ADAPTERS, CHAIN_NAMESPACES } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { Browser } from "@capacitor/browser";
import { isPlatform } from "@ionic/react";

import { Ecc, sha256 } from "../wallet/ffi";
import type { StoreGet, StoreSet } from "../store";
import { catchError } from "./error";
import { signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { fromHex } from "../wallet/hex";
import { mnemonicFromEntropy } from "../wallet/mnemonic";
import {
  deleteWalletKeys,
  deriveWalletKeys,
  storeWalletKeys,
} from "../wallet/key";
import { Pay2Stay } from "../plugin";
import { calculateBalance } from "../wallet/balance";
import { hash160 } from "../wallet/ffi";
import { requestUtxos } from "../wallet/request";
import { MAIN_TOKEN_ID } from "../wallet/settings";
import { browserStorage } from "../storage/browser";
import { WalletKeys } from "./wallet";

export const EXISTING_LOGIN_METHOD_PARAM = "existing_login_method";

export type ExistingLoginMethod = "SeedPhrase" | "JwtTestnet";

export interface Web3AuthState {
  instance: Web3AuthNoModal | undefined;
  existingPubkey: Uint8Array | undefined;
  existingLoginMethod: ExistingLoginMethod | undefined;
  existingBalance: proto.Balance | undefined;
  newJwt: string | undefined;
  newSecretHex: string | undefined;
}

export interface Web3AuthActions {
  web3authInit: (isExistingUser: boolean) => Promise<void>;
  web3authLogin: (
    loginProvider: string,
    isExistingUser: boolean,
    isSetUpWallet?: boolean
  ) => Promise<void>;
  resetExistingLoginMethod: () => void;
  resetAccount: () => Promise<void>;
  resetWeb3authState: () => void;
}

export const initialWeb3Auth: Web3AuthState = {
  instance: undefined,
  existingPubkey: undefined,
  existingLoginMethod: undefined,
  existingBalance: undefined,
  newJwt: undefined,
  newSecretHex: undefined,
};

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.OTHER,
  chainId: "0x1",
  rpcTarget: "https://rpc.ankr.com/eth",
  displayName: "eCash",
  blockExplorer: "https://explorer.e.cash",
  ticker: "XEC",
  tickerName: "XEC",
};

// Create a Web3Auth instance using our old Web3Auth config.
// This is so people can recover their old accounts.
// Note: Creating accounts with the same email but different providers
// (e.g. Google vs Apple) will result in different private keys.
function createWeb3AuthInstanceLegacy(): Web3AuthNoModal {
  const web3auth = new Web3AuthNoModal({
    clientId:
      "BJgWypJLABeYtnepSExoTq8r_zliV9z3CyZcOfxf2u4kHKTgx7DXyfuyjdNVzvTw1wHXjGf8010OmCdsMHxh_FM",
    chainConfig,
    web3AuthNetwork: "mainnet",
  });

  const privateKeyProvider = new CommonPrivateKeyProvider({
    config: { chainConfig },
  });

  const openloginAdapter = new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      redirectUrl: "http://localhost:3000",
    },
    loginSettings: {
      mfaLevel: "none",
    },
  });

  web3auth.configureAdapter(openloginAdapter);
  return web3auth;
}

// Create a Web3Auth instance using the "Saphire Mainnet", and using a aggregate verifier.
// The mainnet is a bit faster and more scalable.
// Aggregate verifier ensures that the same email address always results in the same private key.
function createWeb3AuthInstance(): Web3AuthNoModal {
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.OTHER,
    chainId: "0x1",
    rpcTarget: "https://rpc.ankr.com/eth",
    displayName: "eCash",
    blockExplorer: "https://explorer.e.cash",
    ticker: "XEC",
    tickerName: "XEC",
  };

  const web3auth = new Web3AuthNoModal({
    clientId:
      "BPRyAqoZIOycpiv7gBxERvh7cyI5vknv5C4ilC18lySt698w72ygrn-CtMkjZP4tNFQpirrcE_jg_F_6iviVQR4",
    chainConfig,
    web3AuthNetwork: "sapphire_mainnet",
  });

  const privateKeyProvider = new CommonPrivateKeyProvider({
    config: { chainConfig },
  });

  const openloginAdapter = new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      loginConfig: {
        google: {
          verifier: "p2s-aggregate",
          verifierSubIdentifier: "p2s-main-google-auth-web",
          typeOfLogin: "google",
          clientId:
            "59936508368-tgoheba28me7kk8rm1ct0c2125dpa108.apps.googleusercontent.com",
        },
        apple: {
          verifier: "p2s-aggregate",
          verifierSubIdentifier: "p2s-main-apple-auth",
          typeOfLogin: "apple",
          clientId: "7Woyy4iy55fmpFUw52MlX9R32CqPINtJ",
        },
      },
    },
    loginSettings: {
      mfaLevel: "none",
    },
  });

  web3auth.configureAdapter(openloginAdapter);
  return web3auth;
}

function getProvisionalSig(
  isSetUpWallet: boolean | undefined,
  ecc: Ecc,
  web3authPk: Uint8Array,
  walletKey: WalletKeys
) {
  const stringToUtf8 = (str: string) => {
    return new TextEncoder().encode(str);
  };

  if (isSetUpWallet) {
    const textKey = stringToUtf8("LOGIN_PROVISIONAL_PK");
    const msg = new Uint8Array(textKey.length + web3authPk.length);
    msg.set(textKey, 0);
    msg.set(web3authPk, textKey.length);
    const msgHash = sha256(sha256(msg));
    return ecc.schnorr_sign(walletKey.seckey, msgHash);
  } else {
    return new Uint8Array();
  }
}

async function connectWeb3Auth(
  web3auth: Web3AuthNoModal,
  loginProvider: string
) {
  if (web3auth.coreOptions.web3AuthNetwork != "sapphire_mainnet") {
    // Legacy doesn't have any config
    return await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider,
    });
  }
  switch (loginProvider) {
    case "google":
      return await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider,
      });
    case "apple":
      // Auth0 requires specifying extra options
      return await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider,
        extraLoginOptions: {
          domain: "https://dev-tqgmus24dp1vqfne.us.auth0.com",
          verifierIdField: "email",
          isVerifierIdCaseSensitive: false,
        },
      });
    default:
      throw `${loginProvider} login provider is not implemented yet`;
  }
}

export function web3authActions(set: StoreSet, get: StoreGet): Web3AuthActions {
  return {
    resetWeb3authState: () => {
      set(state => {
        state.web3auth = initialWeb3Auth;
      });
    },
    resetExistingLoginMethod: () => {
      set(state => {
        state.web3auth.existingLoginMethod = undefined;
      });
    },
    web3authInit: catchError(
      "Web3Auth Init",
      set,
      async (isExistingUser: boolean) => {
        const web3auth = isExistingUser
          ? createWeb3AuthInstanceLegacy()
          : createWeb3AuthInstance();
        web3auth.init();
        set(state => {
          state.web3auth.instance = web3auth;
        });
        if (isPlatform("hybrid") && isPlatform("ios")) {
          // In-app, we need to monkey-patch a window.open method that uses Capacitor's in-app
          // browser. Note that this will create an infinite loop if called outside of an app.
          window.open = function (
            url?: string | URL,
            target?: any,
            features?: any
          ): Window | null {
            console.log("called window.open", url, target, features);
            if (!(typeof url == "string")) {
              return null;
            }
            Browser.open({
              url,
            });
            return {
              closed: false,
              close: () => Browser.close(),
            } as any;
          };
        }
      },
      undefined,
      false
    ),
    web3authLogin: catchError(
      "Web3Auth Login",
      set,
      async (
        loginProvider: string,
        isExistingUser: boolean,
        isSetUpWallet?: boolean
      ) => {
        set(state => {
          state.loading.isLoading = true;
        });
        let web3auth = get().web3auth.instance;
        if (web3auth === undefined || web3auth.status == "connecting") {
          if (web3auth !== undefined) {
            console.log("already connecting, resetting");
            web3auth.clearCache();
          }
          web3auth = isExistingUser
            ? createWeb3AuthInstanceLegacy()
            : createWeb3AuthInstance();
          await web3auth.init();
        }
        if (web3auth.connected) {
          await web3auth.logout();
        }
        const conn = await connectWeb3Auth(web3auth, loginProvider);
        if (conn === null) {
          throw "Couldn't sign up using Web3Auth";
        }
        const user = await web3auth.getUserInfo();
        if (user.idToken === undefined) {
          throw "Couldn't get JWT using Web3Auth";
        }
        const secretHex = await conn.request<{}, string>({
          method: "private_key",
        });
        if (!secretHex) {
          throw "Couldn't get private key using Web3Auth";
        }
        const secret = fromHex(secretHex);
        const mnemonic = mnemonicFromEntropy(secret.slice(0, 16));
        const ecc = get().wallet.ecc!;
        const walletKeys = deriveWalletKeys(
          mnemonic.seed,
          mnemonic.phrase,
          ecc
        );
        const request = proto.SignUpJwtRequest.encode({
          jwt: user.idToken,
          sessionId: get().browserSession.sessionId ?? "",
          retireUserPk: new Uint8Array(),
          loginMethod: isExistingUser ? "JwtTestnet" : "JwtSaphireMainnet",
          provisionalPk: isSetUpWallet
            ? get().wallet.keys!.pubkey
            : proto.Empty.encode({}).finish(),
          provisionalSig: getProvisionalSig(
            isSetUpWallet,
            ecc,
            walletKeys.pubkey,
            get().wallet.keys!
          ),
        }).finish();
        const response = await signedRequest({
          proto: proto.SignUpJwtResponse,
          path: "/signup-jwt",
          method: "PUT",
          ecc: ecc,
          seckey: walletKeys.seckey,
          pubkey: walletKeys.pubkey,
          payload: request,
        });
        if (!response.isSuccess) {
          const utxos = await requestUtxos(
            MAIN_TOKEN_ID,
            hash160(response.existingPubkey)
          );
          set(state => {
            state.web3auth.existingPubkey = response.existingPubkey;
            state.web3auth.existingBalance = calculateBalance(utxos);
            state.web3auth.newJwt = user.idToken;
            state.web3auth.newSecretHex = secretHex;
            state.web3auth.existingLoginMethod =
              response.existingLoginMethod == "SeedPhrase"
                ? "SeedPhrase"
                : "JwtTestnet";
          });
          return;
        }
        await deleteWalletKeys();
        await storeWalletKeys(walletKeys);
        await browserStorage.balance.clear();
        walletKeys.isProvisionalPk = false;
        await localforage.setItem("p2s:hasVerifiedEmail", true);
        set(state => {
          state.wallet.keys = walletKeys;
          state.verifyEmail.hasVerifiedEmail = true;
        });
        if (isPlatform("hybrid") && isPlatform("android")) {
          // On Android, we have to open the app again via an Intent
          await Pay2Stay.openApp();
        }
      },
      state => {
        state.loading.isLoading = false;
      },
      false
    ),
    resetAccount: catchError(
      "Reset Account",
      set,
      async () => {
        set(state => {
          state.loading.isLoading = true;
        });
        const secretHex = get().web3auth.newSecretHex;
        const newJwt = get().web3auth.newJwt;
        const existingPubkey = get().web3auth.existingPubkey;
        if (secretHex === undefined) {
          throw "Couldn't get JWT to reset account";
        }
        if (newJwt === undefined) {
          throw "Couldn't get private key to reset account";
        }
        if (existingPubkey === undefined) {
          throw "Couldn't get existing Pubkey to reset account";
        }
        const secret = fromHex(secretHex);
        const mnemonic = mnemonicFromEntropy(secret.slice(0, 16));
        const ecc = get().wallet.ecc!;
        const walletKeys = deriveWalletKeys(
          mnemonic.seed,
          mnemonic.phrase,
          ecc
        );
        const request = proto.SignUpJwtRequest.encode({
          jwt: newJwt,
          sessionId: get().browserSession.sessionId ?? "",
          retireUserPk: existingPubkey,
          loginMethod: "JwtSaphireMainnet",
          provisionalPk: proto.Empty.encode({}).finish(),
          provisionalSig: proto.Empty.encode({}).finish(),
        }).finish();
        const response = await signedRequest({
          proto: proto.SignUpJwtResponse,
          path: "/signup-jwt",
          method: "PUT",
          ecc: ecc,
          seckey: walletKeys.seckey,
          pubkey: walletKeys.pubkey,
          payload: request,
        });
        if (!response.isSuccess) {
          throw "Account reset failed.";
        }
        await storeWalletKeys(walletKeys);
        await localforage.setItem("p2s:hasVerifiedEmail", true);
        set(state => {
          state.wallet.keys = walletKeys;
          state.verifyEmail.hasVerifiedEmail = true;
        });
      },
      state => {
        state.loading.isLoading = false;
      },
      false
    ),
  };
}
