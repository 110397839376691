// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import { Ecc, sha256 } from "./ffi";

const MESSAGE_PREFIX = "eCash Signed Message:\n";

export function signMessage(
  ecc: Ecc,
  seckey: Uint8Array,
  msg: string
): Uint8Array {
  const magicMsg = calcMagicMsg(msg);
  const magicHash = sha256(sha256(magicMsg));
  return ecc.sign_recoverable(seckey, magicHash);
}

function encodeCompactSize(size: Long): number[] {
  const bytesLE = size.toBytesLE();
  if (size.lessThan(Long.fromNumber(0xfd))) {
    return [bytesLE[0]];
  } else if (size.lessThanOrEqual(Long.fromNumber(0xffff))) {
    return [0xfd].concat(bytesLE.slice(0, 2));
  } else if (size.lessThanOrEqual(Long.fromNumber(0xffff_ffff))) {
    return [0xfe].concat(bytesLE.slice(0, 4));
  } else {
    return [0xff].concat(bytesLE);
  }
}

function calcMagicMsg(msg: string): Uint8Array {
  const prefixBytes = new TextEncoder().encode(MESSAGE_PREFIX);
  const prefixBytesSerSize = encodeCompactSize(
    Long.fromNumber(prefixBytes.length)
  );
  const msgBytes = new TextEncoder().encode(msg);
  const msgBytesSerSize = encodeCompactSize(Long.fromNumber(msgBytes.length));

  const magicMsgSize =
    prefixBytesSerSize.length +
    prefixBytes.length +
    msgBytes.length +
    msgBytesSerSize.length;

  const magicMsg = new Uint8Array(magicMsgSize);
  magicMsg.set(prefixBytesSerSize);
  magicMsg.set(prefixBytes, prefixBytesSerSize.length);
  magicMsg.set(msgBytesSerSize, prefixBytesSerSize.length + prefixBytes.length);
  magicMsg.set(
    msgBytes,
    prefixBytesSerSize.length + prefixBytes.length + msgBytesSerSize.length
  );

  return magicMsg;
}
