// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import { chronik } from "../rpc/request";
import * as proto from "../rpc/p2s";
import {
  script_p2pkh,
  sign_tx,
  Ecc,
  decode_base58,
  slpv2_opreturn_section1,
  slpv2_mint_section,
  self_mint_v2_script,
} from "./ffi";
import { fromHexRev, toHexRev } from "./hex";
import { DUST_AMOUNT, MINT_AUTH_V2_PUBKEY, TOKEN_FACTOR_USD } from "./settings";
import * as tx from "./tx";
import { amountsToHighLow } from "./send";
import { protoDecode } from "../rpc/util";

interface ParsedAuthCode {
  mintAmount: Long;
  mintBatonOutpoint: tx.OutPoint;
  authSig: Uint8Array;
}

export interface AuthCodeData {
  mintAmountUsd: number;
  mintAmount: Long;
  mintBatonOutpoint: tx.OutPoint;
  authSig: Uint8Array;
}

export function parseAuthCode(authCodeBase58: string): ParsedAuthCode {
  if (!authCodeBase58.startsWith("mint_")) {
    throw new Error("Mint code should start with 'mint_'");
  }
  authCodeBase58 = authCodeBase58.substring("mint_".length);
  const authCodeBytes = decode_base58(authCodeBase58);
  const mintCode = protoDecode(proto.ParsedMintCode, authCodeBytes);
  return {
    mintAmount: mintCode.tokenBaseAmount,
    mintBatonOutpoint: {
      txid: mintCode.mintBatonTxid,
      outIdx: mintCode.mintBatonOutIdx,
    },
    authSig: mintCode.authSig,
  };
}

export function processAuthCode(authCodeBase58: string): AuthCodeData {
  try {
    const parseData = parseAuthCode(authCodeBase58.trim());
    const mintAmountUsd = parseData.mintAmount.toNumber() / TOKEN_FACTOR_USD;
    return {
      mintAmountUsd,
      mintAmount: parseData.mintAmount,
      mintBatonOutpoint: parseData.mintBatonOutpoint,
      authSig: parseData.authSig,
    };
  } catch (error) {
    console.error("Mint auth error:", error);
    const errorMsg =
      typeof error === "string"
        ? `Invalid authorization code: ${error}`
        : "Invalid authorization code: Authcode is for unsupported self-mint token";
    throw new Error(errorMsg);
  }
}

async function fetchMintBatonUtxo(outpoint: tx.OutPoint): Promise<{
  value: Long;
  tokenId: string;
}> {
  const mintTx = await chronik().tx(toHexRev(outpoint.txid));
  const mintBatonUtxo = mintTx.outputs.at(outpoint.outIdx);
  if (mintBatonUtxo === undefined) {
    throw "Mint baton UTXO doesn't exist";
  }
  if (mintBatonUtxo.token === undefined) {
    throw "Mint baton UTXO is not SLPv2";
  }
  return {
    value: Long.fromNumber(mintBatonUtxo.value),
    tokenId: mintBatonUtxo.token.tokenId,
  };
}

export async function buildSelfMintTx(params: {
  ecc: Ecc;
  seckey: Uint8Array;
  pubkey: Uint8Array;
  pkh: Uint8Array;
  authCodeData: AuthCodeData;
}): Promise<Uint8Array> {
  const authCodeData = params.authCodeData;
  const mintBatonScript = self_mint_v2_script(MINT_AUTH_V2_PUBKEY);
  const mintBatonUtxo = await fetchMintBatonUtxo(
    authCodeData.mintBatonOutpoint
  );
  const makeInput = (
    prevOut: tx.OutPoint,
    redeemScript: Uint8Array,
    value: Long
  ) => ({
    prevOut,
    script: new Uint8Array(),
    sequence: 0xffff_ffff,
    signData: <tx.SignData>{
      fields: [
        <tx.SignField>{
          redeemScript,
        },
        <tx.SignField>{
          value,
        },
      ],
      selfMintV2: {
        mintSk: params.seckey,
        mintPk: params.pubkey,
        authSig: authCodeData.authSig,
      },
    },
  });
  const txRequest: tx.Tx = {
    version: 1,
    inputs: [
      makeInput(
        authCodeData.mintBatonOutpoint,
        mintBatonScript,
        mintBatonUtxo.value
      ),
    ],
    outputs: [
      {
        value: Long.ZERO,
        script: mintOpreturn(mintBatonUtxo.tokenId, authCodeData.mintAmount),
      },
      {
        value: Long.fromNumber(DUST_AMOUNT),
        script: script_p2pkh(params.pkh),
      },
    ],
    lockTime: 0,
  };
  const txRequestEncoded = tx.Tx.encode(txRequest).finish();
  return sign_tx(txRequestEncoded, params.ecc, 0, 0);
}

function mintOpreturn(tokenId: string, mintAmount: Long): Uint8Array {
  return slpv2_opreturn_section1(
    slpv2_mint_section(fromHexRev(tokenId), amountsToHighLow([mintAmount]), 0)
  );
}
