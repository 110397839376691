// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import * as proto from "../rpc/p2s";
import { signedRequest, signedRequestRaw } from "../rpc/request";
import { catchError } from "./error";
import { AffiliateData } from "../pages/manage-event-code/ManageEventCodePage";

export interface ManageEventCodeState {
  details: proto.CodeDetails | undefined;
}

export interface ManageEventCodeActions {
  updateCodeRequest: (
    eventId: number,
    code: string,
    newCode: string,
    isTemplate: boolean,
    discountPer1000: number,
    maxUses: number,
    affiliates: AffiliateData[]
  ) => Promise<void>;
  fetchAffiliates: (eventId: number, code: string) => Promise<void>;
  manageEventCodeReset: () => void;
}

export const initialManageEventCode: ManageEventCodeState = {
  details: undefined,
};

export function manageEventCodeActions(
  set: StoreSet,
  get: StoreGet
): ManageEventCodeActions {
  return {
    manageEventCodeReset: () => {
      set(state => {
        state.manageEventCode = initialManageEventCode;
      });
    },
    fetchAffiliates: catchError(
      "Fetch Affiliates ",
      set,
      async (eventId: number, code: string) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.EventDataRequest = {
          eventId,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventDataResponse,
          path: "/event/manage/read",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventDataRequest.encode(request).finish(),
        });
        const foundCodeDetails = response.eventData?.codes.find(
          item => item.code === code
        );
        set(store => {
          store.manageEventCode.details = foundCodeDetails;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    updateCodeRequest: catchError(
      "Update Code Request",
      set,
      async (
        eventId: number,
        code: string,
        newCode: string = "",
        isTemplate: boolean = false,
        discountPer1000: number = 0,
        maxUses: number = 0,
        affiliateFormData: AffiliateData[]
      ) => {
        const affiliates = affiliateFormData.map<proto.AffiliateDetails>(
          item => ({
            affiliateId: Number(item.affiliateId),
            pk: new Uint8Array(),
            mode: item.isHost ? "Leftover" : "Per1000",
            per1000: item.isHost ? 0 : Number(item.percent) * 10,
            firstName: "",
            lastName: "",
          })
        );
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.UpdateCodeRequest = {
          eventId,
          code,
          affiliates,
          shouldUpdateCode: true,
          newCode,
          isTemplate,
          discountPer1000,
          maxUses,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        await signedRequestRaw({
          path: "/event/manage/code/update",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.UpdateCodeRequest.encode(request).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
