// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import * as proto from "../rpc/p2s";
import { signedRequest, signedRequestRaw } from "../rpc/request";
import { catchError } from "./error";
import { groupBy } from "../util";
import { browserStorage } from "../storage/browser";

export const EVENT_ID_PARAM = "event_id";
export const EVENT_TYPE_PARAM = "type";

export interface EventsState {
  hasLoadedFromStorage: boolean;
  storageLoadingPromise: Promise<void> | undefined;
  joinedEvents: proto.Event[] | undefined;
  hostedEvents: proto.Event[] | undefined;
  affiliatedEvents: proto.Event[] | undefined;
  overviewEvents: proto.OverviewGuest[] | undefined;
  endedJoinedEvents: proto.Event[] | undefined;
  endedHostedEvents: proto.Event[] | undefined;
}

export interface EventsActions {
  eventsLoad: () => Promise<void>;
  joinedEventsFetch: () => Promise<void>;
  hostedEventsFetch: () => Promise<void>;
  affiliatedEventsFetch: () => Promise<void>;
  overviewEventsFetch: (eventId: number) => Promise<void>;
  startEvent: (eventId: number) => Promise<void>;
  endedJoinedEventsFetch: () => Promise<void>;
  endedHostedEventsFetch: () => Promise<void>;
  trashEventRequest: (eventId: number) => Promise<void>;
}

export const initialEvents: EventsState = {
  hasLoadedFromStorage: false,
  storageLoadingPromise: undefined,
  joinedEvents: undefined,
  hostedEvents: undefined,
  affiliatedEvents: undefined,
  overviewEvents: undefined,
  endedJoinedEvents: undefined,
  endedHostedEvents: undefined,
};

export function eventsActions(set: StoreSet, get: StoreGet): EventsActions {
  return {
    /**
     * Waits for events to load from storage if they haven't been loaded yet.
     */
    eventsLoad: catchError(
      "Load Events From Storage",
      set,
      async () => {
        const eventsState = get().events;

        if (eventsState.hasLoadedFromStorage) {
          return;
        }

        if (eventsState.storageLoadingPromise) {
          return eventsState.storageLoadingPromise;
        }

        set(state => {
          state.events.storageLoadingPromise = (async () => {
            const [
              joinedEvents,
              hostedEvents,
              affiliatedEvents,
              overviewEvents,
              endedJoinedEvents,
              endedHostedEvents,
            ] = await Promise.allSettled([
              browserStorage.joinedEvents.get(),
              browserStorage.hostedEvents.get(),
              browserStorage.affiliatedEvents.get(),
              browserStorage.overviewEvents.get(),
              browserStorage.endedJoinedEvents.get(),
              browserStorage.endedHostedEvents.get(),
            ]);

            set(state => {
              state.events.hasLoadedFromStorage = true;
              if (joinedEvents.status === "fulfilled") {
                state.events.joinedEvents = joinedEvents.value;
              }
              if (hostedEvents.status === "fulfilled") {
                state.events.hostedEvents = hostedEvents.value;
              }
              if (affiliatedEvents.status === "fulfilled") {
                state.events.affiliatedEvents = affiliatedEvents.value;
              }
              if (overviewEvents.status === "fulfilled") {
                state.events.overviewEvents = overviewEvents.value;
              }
              if (endedJoinedEvents.status === "fulfilled") {
                state.events.endedJoinedEvents = endedJoinedEvents.value;
              }
              if (endedHostedEvents.status === "fulfilled") {
                state.events.endedHostedEvents = endedHostedEvents.value;
              }
              state.events.hasLoadedFromStorage = true;
            });
          })();
        });

        await get().events.storageLoadingPromise;
      },
      undefined,
      false
    ),
    joinedEventsFetch: catchError(
      "Joined Events",
      set,
      async () => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys;
        if (keys === undefined) {
          return;
        }
        const request = proto.Empty.encode({}).finish();
        const responsePromise = signedRequest({
          proto: proto.JoinedEventsResponse,
          path: "/user/joined-events",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });

        try {
          await get().eventsLoad();
        } catch (_) {} // Error is already handled

        const response = await responsePromise;
        const sortedEvents = sortEvents(response.events);
        set(state => {
          state.events.joinedEvents = sortedEvents;
        });

        browserStorage.joinedEvents.set(sortedEvents);
      },
      undefined,
      false
    ),
    hostedEventsFetch: catchError(
      "Hosted Events",
      set,
      async () => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const responsePromise = signedRequest({
          proto: proto.HostedEventsResponse,
          path: "/user/hosted-events",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });

        try {
          await get().eventsLoad();
        } catch (_) {} // Error is already handled

        const response = await responsePromise;
        const sortedEvents = response.events.sort(
          (a, z) => z.dateTime.toNumber() - a.dateTime.toNumber()
        );
        set(state => {
          state.events.hostedEvents = sortedEvents;
        });

        browserStorage.hostedEvents.set(sortedEvents);
      },
      undefined,
      false
    ),
    endedJoinedEventsFetch: catchError(
      "Ended Joined Events ",
      set,
      async () => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const responsePromise = signedRequest({
          proto: proto.JoinedEventsResponse,
          path: "/user/ended-events/joined",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });

        try {
          await get().eventsLoad();
        } catch (_) {} // Error is already handled

        const response = await responsePromise;
        const sortedEvents = response.events.sort(
          (a, z) => z.dateTime.toNumber() - a.dateTime.toNumber()
        );

        set(state => {
          state.events.endedJoinedEvents = sortedEvents;
        });

        browserStorage.endedJoinedEvents.set(sortedEvents);
      },
      undefined,
      false
    ),
    endedHostedEventsFetch: catchError(
      "Ended Hosted Events ",
      set,
      async () => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const responsePromise = signedRequest({
          proto: proto.HostedEventsResponse,
          path: "/user/ended-events/hosted",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });

        try {
          await get().eventsLoad();
        } catch (_) {} // Error is already handled

        const response = await responsePromise;
        const sortedEvents = response.events.sort(
          (a, z) => z.dateTime.toNumber() - a.dateTime.toNumber()
        );
        set(state => {
          state.events.endedHostedEvents = sortedEvents;
        });

        browserStorage.endedHostedEvents.set(sortedEvents);
      },
      undefined,
      false
    ),
    affiliatedEventsFetch: catchError(
      "Affiliated Events",
      set,
      async () => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.Empty.encode({}).finish();
        const responsePromise = signedRequest({
          proto: proto.AffiliatedEventsResponse,
          path: "/user/affiliated-events",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: request,
        });

        try {
          await get().eventsLoad();
        } catch (_) {} // Error is already handled

        const response = await responsePromise;
        const sortedEvents = response.events.sort(
          (a, z) => z.dateTime.toNumber() - a.dateTime.toNumber()
        );
        set(state => {
          state.events.affiliatedEvents = sortedEvents;
        });

        browserStorage.affiliatedEvents.set(sortedEvents);
      },
      undefined,
      false
    ),
    overviewEventsFetch: catchError(
      "Events",
      set,
      async (eventId: number) => {
        set(state => {
          state.loading.isLoading = true;
        });
        set(state => {
          state.events.overviewEvents = undefined;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.EventOverviewRequest = {
          eventId,
        };
        const responsePromise = signedRequest({
          proto: proto.EventOverviewResponse,
          path: "/event/overview",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventOverviewRequest.encode(request).finish(),
        });

        try {
          await get().eventsLoad();
        } catch (_) {} // Error is already handled

        const response = await responsePromise;
        set(state => {
          state.events.overviewEvents = response.guests;
        });

        browserStorage.overviewEvents.set(response.guests);
      },
      state => {
        state.loading.isLoading = false;
      },
      false
    ),
    startEvent: catchError(
      "Start Event",
      set,
      async (eventId: number) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.StartEventRequest = {
          eventId,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        await signedRequestRaw({
          path: "/event/start",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.StartEventRequest.encode(request).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;

        browserStorage.hostedEvents.set(state.events.hostedEvents!);
      }
    ),
    trashEventRequest: catchError(
      "Trash Event",
      set,
      async (eventId: number) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request: proto.TrashGuestRequest = {
          eventId,
        };
        set(store => {
          store.loading.isLoading = true;
        });
        await signedRequestRaw({
          path: "/event/trash",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.TrashGuestRequest.encode(request).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;

        if (state.events.joinedEvents) {
          browserStorage.joinedEvents.set(state.events.joinedEvents);
        }
      }
    ),
  };
}

const sortEvents = (events: proto.Event[]) => {
  const sortByDate = (events: proto.Event[]) => {
    return events.sort((a, z) => a.dateTime.toNumber() - z.dateTime.toNumber());
  };

  // remove guestState == undefined;
  const groupEventsByStatus = groupBy(events, item => item.guestState?.status);

  // remove status != ( CheckIn, CheckOut, "");
  return [
    ...sortByDate(groupEventsByStatus["CheckIn"] ?? []),
    ...sortByDate(groupEventsByStatus["CheckOut"] ?? []),
    ...sortByDate(groupEventsByStatus["Going"] ?? []),
    ...sortByDate(groupEventsByStatus["PaidOut"] ?? []),
  ];
};
