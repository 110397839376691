// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import * as tx from "./tx";
import * as proto from "../rpc/p2s";
import {
  Ecc,
  script_p2pkh,
  sign_tx,
  slpv2_burn_section,
  slpv2_opreturn_section1,
  slpv2_opreturn_section2,
  slpv2_opreturn_section3,
  slpv2_send_section_vec,
} from "./ffi";
import { WalletKeys } from "../store/wallet";
import {
  PostageData,
  TokenProtocol,
  amountsToHighLow,
  selectUtxos,
  sendOpreturn,
} from "./send";
import { requestUtxos } from "./request";
import { fromHexRev, toHex, toHexRev } from "./hex";
import { DUST_AMOUNT, P2S_ADDRESS, POSTAGE_FEE } from "./settings";
import { SlpUtxo } from "./utxo";
import { signedRequest } from "../rpc/request";
import { decodeEtokenAddress } from "./address";

export interface BurnParams {
  ecc: Ecc;
  keys: WalletKeys;
  tokenId: string;
  tokenProtocol: TokenProtocol;
  amount: Long;
  leftoverScript: Uint8Array;
  feePerKb: number;
  postageAllowed?: boolean;
  extraSection?: Uint8Array;
}

export async function buildBurnTx(params: BurnParams): Promise<Uint8Array> {
  const { keys, tokenId } = params;
  const walletUtxos = await requestUtxos(tokenId, keys.pkh);
  console.log("walletUtxos", walletUtxos);
  let [utxos, selectedAmount] = selectUtxos(walletUtxos, params.amount);
  if (selectedAmount.lt(params.amount)) {
    throw "Insufficient balance";
  }
  const leftoverAmount = selectedAmount.sub(params.amount);
  const outputScript = script_p2pkh(keys.pkh);
  let outputs: tx.TxOutput[] = [];
  let requiresPostage = false;
  const tokenIdBytes = fromHexRev(tokenId);
  const burnSection = slpv2_burn_section(
    tokenIdBytes,
    params.amount.low,
    params.amount.high
  );
  console.log(
    "extra section",
    params.extraSection && toHex(params.extraSection)
  );
  if (leftoverAmount.isZero()) {
    outputs = [
      {
        value: Long.ZERO,
        script: params.extraSection
          ? slpv2_opreturn_section2(burnSection, params.extraSection)
          : slpv2_opreturn_section1(burnSection),
      },
    ];
  } else {
    const sendSection = slpv2_send_section_vec(
      tokenIdBytes,
      amountsToHighLow([leftoverAmount])
    );
    outputs = [
      {
        value: Long.ZERO,
        script: params.extraSection
          ? slpv2_opreturn_section3(
              sendSection,
              burnSection,
              params.extraSection
            )
          : slpv2_opreturn_section2(sendSection, burnSection),
      },
      {
        value: Long.fromInt(DUST_AMOUNT),
        script: outputScript,
      },
    ];
    if (utxos.length == 1) {
      if (params.postageAllowed) {
        requiresPostage = true;
      } else {
        // Case where there's exactly 1 UTXO but there's a leftover,
        // in which case we have to do a "pre burn" (not enough sats otherwise).
        const burnUtxo = await sendPreburnTx({
          ecc: params.ecc,
          keys: params.keys,
          tokenId: params.tokenId,
          selectedUtxos: utxos,
          selectedAmount: selectedAmount,
          walletScript: outputScript,
          burnAmount: params.amount,
          feePerKb: params.feePerKb,
          postage: {
            totalFee: Long.fromNumber(POSTAGE_FEE),
            script: decodeEtokenAddress(P2S_ADDRESS),
          },
        });
        utxos = [burnUtxo];
        selectedAmount = burnUtxo.slpAmount;
        outputs = [
          {
            value: Long.ZERO,
            script: params.extraSection
              ? slpv2_opreturn_section2(burnSection, params.extraSection)
              : slpv2_opreturn_section1(burnSection),
          },
        ];
      }
    }
  }
  const txRequest: tx.Tx = {
    version: 1,
    inputs: utxos.map(utxo => ({
      prevOut: utxo.outpoint,
      script: new Uint8Array(),
      sequence: 0xffff_ffff,
      signData: <tx.SignData>{
        fields: [
          <tx.SignField>{
            outputScript,
          },
          <tx.SignField>{
            value: utxo.value,
          },
        ],
        p2pkh: {
          seckey: params.keys.seckey,
          pubkey: params.keys.pubkey,
          sigHashType: requiresPostage ? 0xc1 : 0x41,
        },
      },
    })),
    outputs,
    lockTime: 0,
  };
  const txRequestEncoded = tx.Tx.encode(txRequest).finish();
  const signedTx = sign_tx(
    txRequestEncoded,
    params.ecc,
    params.feePerKb,
    DUST_AMOUNT
  );
  return signedTx;
}

export function buildP2sBurnCommitment(params: {
  guestPkh: Uint8Array;
  eventId: number;
  paymentIdx: number;
  cartProtoHash: Uint8Array;
}): Uint8Array {
  const data = new Uint8Array(20 + 8 + 4 + 32);
  const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
  data.set(params.guestPkh, 0);
  view.setInt32(20, params.eventId, true);
  view.setInt32(20 + 8, params.paymentIdx, true);
  data.set(params.cartProtoHash, 20 + 8 + 4);
  return data;
}

export function buildP2sBurnCommitmentSection(
  burnHash: Uint8Array
): Uint8Array {
  const data = new Uint8Array(4 + 1 + 32);
  data.set(new TextEncoder().encode("P2S0"), 0);
  data[4] = 0; // version
  data.set(burnHash, 5);
  return data;
}

interface PreburnParams {
  ecc: Ecc;
  keys: WalletKeys;
  tokenId: string;
  selectedUtxos: SlpUtxo[];
  selectedAmount: Long;
  walletScript: Uint8Array;
  burnAmount: Long;
  feePerKb: number;
  postage: PostageData;
}

async function sendPreburnTx(params: PreburnParams): Promise<SlpUtxo> {
  const tx = buildPreburnTx(params);
  const response = await signedRequest({
    proto: proto.SendTxsResponse,
    path: "/tx/send",
    method: "POST",
    ecc: params.ecc,
    seckey: params.keys.seckey,
    pubkey: params.keys.pubkey,
    payload: proto.SendTxsRequest.encode({
      txs: [tx],
      tokenProtocol: proto.TokenProtocol.Slpv2,
    }).finish(),
  });
  console.log("Preburn txids:", response.txids.map(toHexRev));
  const txid = response.txids[0];
  return {
    outpoint: {
      txid,
      outIdx: 1,
    },
    blockHeight: -1,
    isCoinbase: false,
    value: Long.fromNumber(DUST_AMOUNT),
    tokenId: params.tokenId,
    slpAmount: params.burnAmount,
    isMintBaton: false,
  };
}

function buildPreburnTx(params: PreburnParams): Uint8Array {
  const leftoverAmount = params.selectedAmount
    .sub(params.burnAmount)
    .sub(params.postage.totalFee);
  const txRequest: tx.Tx = {
    version: 1,
    inputs: params.selectedUtxos.map(utxo => ({
      prevOut: utxo.outpoint,
      script: new Uint8Array(),
      sequence: 0xffff_ffff,
      signData: <tx.SignData>{
        fields: [
          <tx.SignField>{
            outputScript: params.walletScript,
          },
          <tx.SignField>{
            value: utxo.value,
          },
        ],
        p2pkh: {
          seckey: params.keys.seckey,
          pubkey: params.keys.pubkey,
          sigHashType: 0xc1,
        },
      },
    })),
    outputs: [
      {
        value: Long.ZERO,
        script: sendOpreturn(params.tokenId, "Slpv2", [
          params.burnAmount,
          leftoverAmount,
          params.postage.totalFee,
        ]),
      },
      {
        value: Long.fromNumber(DUST_AMOUNT),
        script: params.walletScript,
      },
      {
        value: Long.fromNumber(DUST_AMOUNT),
        script: params.walletScript,
      },
      {
        value: Long.fromNumber(DUST_AMOUNT),
        script: params.postage.script,
      },
    ],
    lockTime: 0,
  };
  const txRequestEncoded = tx.Tx.encode(txRequest).finish();
  const signedTx = sign_tx(
    txRequestEncoded,
    params.ecc,
    params.feePerKb,
    DUST_AMOUNT
  );
  return signedTx;
}
