/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tx";

export interface Tx {
  version: number;
  inputs: TxInput[];
  outputs: TxOutput[];
  lockTime: number;
}

export interface OutPoint {
  txid: Uint8Array;
  outIdx: number;
}

export interface TxInput {
  prevOut: OutPoint | undefined;
  script: Uint8Array;
  sequence: number;
  signData: SignData | undefined;
}

export interface TxOutput {
  value: Long;
  script: Uint8Array;
}

export interface TxOutputs {
  outputs: TxOutput[];
}

export interface SignData {
  fields: SignField[];
  p2pkh: P2PKHSignatory | undefined;
  selfMint: SelfMintSignatory | undefined;
  selfMintV2: SelfMintV2Signatory | undefined;
  slpEscrow: SlpEscrowSignatory | undefined;
}

export interface SignField {
  outputScript: Uint8Array | undefined;
  redeemScript: Uint8Array | undefined;
  value: Long | undefined;
}

export interface P2PKHSignatory {
  seckey: Uint8Array;
  pubkey: Uint8Array;
  sigHashType: number;
}

export interface SelfMintSignatory {
  seckey: Uint8Array;
  pubkey: Uint8Array;
  authSig: Uint8Array;
}

export interface SelfMintV2Signatory {
  mintSk: Uint8Array;
  mintPk: Uint8Array;
  authSig: Uint8Array;
}

export interface SlpEscrowSignatory {
  /** Which sighash type to use for signatures; encoded in the contract. Usually ALL_BIP143 */
  sigHashType: number;
  /** Message signed by the escrow determining who gets how many amounts. */
  escrowMsg: Uint8Array;
  /** Signagure for `escrow_msg` from the escrow. */
  escrowSig: Uint8Array;
  /** Whether the redeemer is `alice_pk` or `bob_pk`. */
  isAlice: boolean;
  /** Secret key from `alice_pk` or `bob_pk`. */
  covenantSk: Uint8Array;
}

function createBaseTx(): Tx {
  return { version: 0, inputs: [], outputs: [], lockTime: 0 };
}

export const Tx = {
  encode(message: Tx, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== 0) {
      writer.uint32(8).int32(message.version);
    }
    for (const v of message.inputs) {
      TxInput.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.outputs) {
      TxOutput.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.lockTime !== 0) {
      writer.uint32(32).uint32(message.lockTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tx {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTx();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.version = reader.int32();
          break;
        case 2:
          message.inputs.push(TxInput.decode(reader, reader.uint32()));
          break;
        case 3:
          message.outputs.push(TxOutput.decode(reader, reader.uint32()));
          break;
        case 4:
          message.lockTime = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Tx {
    return {
      version: isSet(object.version) ? Number(object.version) : 0,
      inputs: Array.isArray(object?.inputs)
        ? object.inputs.map((e: any) => TxInput.fromJSON(e))
        : [],
      outputs: Array.isArray(object?.outputs)
        ? object.outputs.map((e: any) => TxOutput.fromJSON(e))
        : [],
      lockTime: isSet(object.lockTime) ? Number(object.lockTime) : 0,
    };
  },

  toJSON(message: Tx): unknown {
    const obj: any = {};
    message.version !== undefined &&
      (obj.version = Math.round(message.version));
    if (message.inputs) {
      obj.inputs = message.inputs.map(e => (e ? TxInput.toJSON(e) : undefined));
    } else {
      obj.inputs = [];
    }
    if (message.outputs) {
      obj.outputs = message.outputs.map(e =>
        e ? TxOutput.toJSON(e) : undefined
      );
    } else {
      obj.outputs = [];
    }
    message.lockTime !== undefined &&
      (obj.lockTime = Math.round(message.lockTime));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Tx>, I>>(object: I): Tx {
    const message = createBaseTx();
    message.version = object.version ?? 0;
    message.inputs = object.inputs?.map(e => TxInput.fromPartial(e)) || [];
    message.outputs = object.outputs?.map(e => TxOutput.fromPartial(e)) || [];
    message.lockTime = object.lockTime ?? 0;
    return message;
  },
};

function createBaseOutPoint(): OutPoint {
  return { txid: new Uint8Array(), outIdx: 0 };
}

export const OutPoint = {
  encode(
    message: OutPoint,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.txid.length !== 0) {
      writer.uint32(10).bytes(message.txid);
    }
    if (message.outIdx !== 0) {
      writer.uint32(16).uint32(message.outIdx);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OutPoint {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOutPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.txid = reader.bytes();
          break;
        case 2:
          message.outIdx = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OutPoint {
    return {
      txid: isSet(object.txid)
        ? bytesFromBase64(object.txid)
        : new Uint8Array(),
      outIdx: isSet(object.outIdx) ? Number(object.outIdx) : 0,
    };
  },

  toJSON(message: OutPoint): unknown {
    const obj: any = {};
    message.txid !== undefined &&
      (obj.txid = base64FromBytes(
        message.txid !== undefined ? message.txid : new Uint8Array()
      ));
    message.outIdx !== undefined && (obj.outIdx = Math.round(message.outIdx));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OutPoint>, I>>(object: I): OutPoint {
    const message = createBaseOutPoint();
    message.txid = object.txid ?? new Uint8Array();
    message.outIdx = object.outIdx ?? 0;
    return message;
  },
};

function createBaseTxInput(): TxInput {
  return {
    prevOut: undefined,
    script: new Uint8Array(),
    sequence: 0,
    signData: undefined,
  };
}

export const TxInput = {
  encode(
    message: TxInput,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.prevOut !== undefined) {
      OutPoint.encode(message.prevOut, writer.uint32(10).fork()).ldelim();
    }
    if (message.script.length !== 0) {
      writer.uint32(18).bytes(message.script);
    }
    if (message.sequence !== 0) {
      writer.uint32(24).uint32(message.sequence);
    }
    if (message.signData !== undefined) {
      SignData.encode(message.signData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TxInput {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTxInput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.prevOut = OutPoint.decode(reader, reader.uint32());
          break;
        case 2:
          message.script = reader.bytes();
          break;
        case 3:
          message.sequence = reader.uint32();
          break;
        case 4:
          message.signData = SignData.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TxInput {
    return {
      prevOut: isSet(object.prevOut)
        ? OutPoint.fromJSON(object.prevOut)
        : undefined,
      script: isSet(object.script)
        ? bytesFromBase64(object.script)
        : new Uint8Array(),
      sequence: isSet(object.sequence) ? Number(object.sequence) : 0,
      signData: isSet(object.signData)
        ? SignData.fromJSON(object.signData)
        : undefined,
    };
  },

  toJSON(message: TxInput): unknown {
    const obj: any = {};
    message.prevOut !== undefined &&
      (obj.prevOut = message.prevOut
        ? OutPoint.toJSON(message.prevOut)
        : undefined);
    message.script !== undefined &&
      (obj.script = base64FromBytes(
        message.script !== undefined ? message.script : new Uint8Array()
      ));
    message.sequence !== undefined &&
      (obj.sequence = Math.round(message.sequence));
    message.signData !== undefined &&
      (obj.signData = message.signData
        ? SignData.toJSON(message.signData)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TxInput>, I>>(object: I): TxInput {
    const message = createBaseTxInput();
    message.prevOut =
      object.prevOut !== undefined && object.prevOut !== null
        ? OutPoint.fromPartial(object.prevOut)
        : undefined;
    message.script = object.script ?? new Uint8Array();
    message.sequence = object.sequence ?? 0;
    message.signData =
      object.signData !== undefined && object.signData !== null
        ? SignData.fromPartial(object.signData)
        : undefined;
    return message;
  },
};

function createBaseTxOutput(): TxOutput {
  return { value: Long.ZERO, script: new Uint8Array() };
}

export const TxOutput = {
  encode(
    message: TxOutput,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.value.isZero()) {
      writer.uint32(8).int64(message.value);
    }
    if (message.script.length !== 0) {
      writer.uint32(18).bytes(message.script);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TxOutput {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTxOutput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.value = reader.int64() as Long;
          break;
        case 2:
          message.script = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TxOutput {
    return {
      value: isSet(object.value) ? Long.fromValue(object.value) : Long.ZERO,
      script: isSet(object.script)
        ? bytesFromBase64(object.script)
        : new Uint8Array(),
    };
  },

  toJSON(message: TxOutput): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = (message.value || Long.ZERO).toString());
    message.script !== undefined &&
      (obj.script = base64FromBytes(
        message.script !== undefined ? message.script : new Uint8Array()
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TxOutput>, I>>(object: I): TxOutput {
    const message = createBaseTxOutput();
    message.value =
      object.value !== undefined && object.value !== null
        ? Long.fromValue(object.value)
        : Long.ZERO;
    message.script = object.script ?? new Uint8Array();
    return message;
  },
};

function createBaseTxOutputs(): TxOutputs {
  return { outputs: [] };
}

export const TxOutputs = {
  encode(
    message: TxOutputs,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.outputs) {
      TxOutput.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TxOutputs {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTxOutputs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.outputs.push(TxOutput.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TxOutputs {
    return {
      outputs: Array.isArray(object?.outputs)
        ? object.outputs.map((e: any) => TxOutput.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TxOutputs): unknown {
    const obj: any = {};
    if (message.outputs) {
      obj.outputs = message.outputs.map(e =>
        e ? TxOutput.toJSON(e) : undefined
      );
    } else {
      obj.outputs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TxOutputs>, I>>(
    object: I
  ): TxOutputs {
    const message = createBaseTxOutputs();
    message.outputs = object.outputs?.map(e => TxOutput.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSignData(): SignData {
  return {
    fields: [],
    p2pkh: undefined,
    selfMint: undefined,
    selfMintV2: undefined,
    slpEscrow: undefined,
  };
}

export const SignData = {
  encode(
    message: SignData,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.fields) {
      SignField.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.p2pkh !== undefined) {
      P2PKHSignatory.encode(message.p2pkh, writer.uint32(18).fork()).ldelim();
    }
    if (message.selfMint !== undefined) {
      SelfMintSignatory.encode(
        message.selfMint,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.selfMintV2 !== undefined) {
      SelfMintV2Signatory.encode(
        message.selfMintV2,
        writer.uint32(42).fork()
      ).ldelim();
    }
    if (message.slpEscrow !== undefined) {
      SlpEscrowSignatory.encode(
        message.slpEscrow,
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fields.push(SignField.decode(reader, reader.uint32()));
          break;
        case 2:
          message.p2pkh = P2PKHSignatory.decode(reader, reader.uint32());
          break;
        case 3:
          message.selfMint = SelfMintSignatory.decode(reader, reader.uint32());
          break;
        case 5:
          message.selfMintV2 = SelfMintV2Signatory.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.slpEscrow = SlpEscrowSignatory.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignData {
    return {
      fields: Array.isArray(object?.fields)
        ? object.fields.map((e: any) => SignField.fromJSON(e))
        : [],
      p2pkh: isSet(object.p2pkh)
        ? P2PKHSignatory.fromJSON(object.p2pkh)
        : undefined,
      selfMint: isSet(object.selfMint)
        ? SelfMintSignatory.fromJSON(object.selfMint)
        : undefined,
      selfMintV2: isSet(object.selfMintV2)
        ? SelfMintV2Signatory.fromJSON(object.selfMintV2)
        : undefined,
      slpEscrow: isSet(object.slpEscrow)
        ? SlpEscrowSignatory.fromJSON(object.slpEscrow)
        : undefined,
    };
  },

  toJSON(message: SignData): unknown {
    const obj: any = {};
    if (message.fields) {
      obj.fields = message.fields.map(e =>
        e ? SignField.toJSON(e) : undefined
      );
    } else {
      obj.fields = [];
    }
    message.p2pkh !== undefined &&
      (obj.p2pkh = message.p2pkh
        ? P2PKHSignatory.toJSON(message.p2pkh)
        : undefined);
    message.selfMint !== undefined &&
      (obj.selfMint = message.selfMint
        ? SelfMintSignatory.toJSON(message.selfMint)
        : undefined);
    message.selfMintV2 !== undefined &&
      (obj.selfMintV2 = message.selfMintV2
        ? SelfMintV2Signatory.toJSON(message.selfMintV2)
        : undefined);
    message.slpEscrow !== undefined &&
      (obj.slpEscrow = message.slpEscrow
        ? SlpEscrowSignatory.toJSON(message.slpEscrow)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignData>, I>>(object: I): SignData {
    const message = createBaseSignData();
    message.fields = object.fields?.map(e => SignField.fromPartial(e)) || [];
    message.p2pkh =
      object.p2pkh !== undefined && object.p2pkh !== null
        ? P2PKHSignatory.fromPartial(object.p2pkh)
        : undefined;
    message.selfMint =
      object.selfMint !== undefined && object.selfMint !== null
        ? SelfMintSignatory.fromPartial(object.selfMint)
        : undefined;
    message.selfMintV2 =
      object.selfMintV2 !== undefined && object.selfMintV2 !== null
        ? SelfMintV2Signatory.fromPartial(object.selfMintV2)
        : undefined;
    message.slpEscrow =
      object.slpEscrow !== undefined && object.slpEscrow !== null
        ? SlpEscrowSignatory.fromPartial(object.slpEscrow)
        : undefined;
    return message;
  },
};

function createBaseSignField(): SignField {
  return { outputScript: undefined, redeemScript: undefined, value: undefined };
}

export const SignField = {
  encode(
    message: SignField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.outputScript !== undefined) {
      writer.uint32(10).bytes(message.outputScript);
    }
    if (message.redeemScript !== undefined) {
      writer.uint32(18).bytes(message.redeemScript);
    }
    if (message.value !== undefined) {
      writer.uint32(24).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignField();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.outputScript = reader.bytes();
          break;
        case 2:
          message.redeemScript = reader.bytes();
          break;
        case 3:
          message.value = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignField {
    return {
      outputScript: isSet(object.outputScript)
        ? bytesFromBase64(object.outputScript)
        : undefined,
      redeemScript: isSet(object.redeemScript)
        ? bytesFromBase64(object.redeemScript)
        : undefined,
      value: isSet(object.value) ? Long.fromValue(object.value) : undefined,
    };
  },

  toJSON(message: SignField): unknown {
    const obj: any = {};
    message.outputScript !== undefined &&
      (obj.outputScript =
        message.outputScript !== undefined
          ? base64FromBytes(message.outputScript)
          : undefined);
    message.redeemScript !== undefined &&
      (obj.redeemScript =
        message.redeemScript !== undefined
          ? base64FromBytes(message.redeemScript)
          : undefined);
    message.value !== undefined &&
      (obj.value = (message.value || undefined).toString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignField>, I>>(
    object: I
  ): SignField {
    const message = createBaseSignField();
    message.outputScript = object.outputScript ?? undefined;
    message.redeemScript = object.redeemScript ?? undefined;
    message.value =
      object.value !== undefined && object.value !== null
        ? Long.fromValue(object.value)
        : undefined;
    return message;
  },
};

function createBaseP2PKHSignatory(): P2PKHSignatory {
  return { seckey: new Uint8Array(), pubkey: new Uint8Array(), sigHashType: 0 };
}

export const P2PKHSignatory = {
  encode(
    message: P2PKHSignatory,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.seckey.length !== 0) {
      writer.uint32(10).bytes(message.seckey);
    }
    if (message.pubkey.length !== 0) {
      writer.uint32(18).bytes(message.pubkey);
    }
    if (message.sigHashType !== 0) {
      writer.uint32(24).uint32(message.sigHashType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): P2PKHSignatory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PKHSignatory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.seckey = reader.bytes();
          break;
        case 2:
          message.pubkey = reader.bytes();
          break;
        case 3:
          message.sigHashType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): P2PKHSignatory {
    return {
      seckey: isSet(object.seckey)
        ? bytesFromBase64(object.seckey)
        : new Uint8Array(),
      pubkey: isSet(object.pubkey)
        ? bytesFromBase64(object.pubkey)
        : new Uint8Array(),
      sigHashType: isSet(object.sigHashType) ? Number(object.sigHashType) : 0,
    };
  },

  toJSON(message: P2PKHSignatory): unknown {
    const obj: any = {};
    message.seckey !== undefined &&
      (obj.seckey = base64FromBytes(
        message.seckey !== undefined ? message.seckey : new Uint8Array()
      ));
    message.pubkey !== undefined &&
      (obj.pubkey = base64FromBytes(
        message.pubkey !== undefined ? message.pubkey : new Uint8Array()
      ));
    message.sigHashType !== undefined &&
      (obj.sigHashType = Math.round(message.sigHashType));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<P2PKHSignatory>, I>>(
    object: I
  ): P2PKHSignatory {
    const message = createBaseP2PKHSignatory();
    message.seckey = object.seckey ?? new Uint8Array();
    message.pubkey = object.pubkey ?? new Uint8Array();
    message.sigHashType = object.sigHashType ?? 0;
    return message;
  },
};

function createBaseSelfMintSignatory(): SelfMintSignatory {
  return {
    seckey: new Uint8Array(),
    pubkey: new Uint8Array(),
    authSig: new Uint8Array(),
  };
}

export const SelfMintSignatory = {
  encode(
    message: SelfMintSignatory,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.seckey.length !== 0) {
      writer.uint32(10).bytes(message.seckey);
    }
    if (message.pubkey.length !== 0) {
      writer.uint32(18).bytes(message.pubkey);
    }
    if (message.authSig.length !== 0) {
      writer.uint32(26).bytes(message.authSig);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfMintSignatory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfMintSignatory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.seckey = reader.bytes();
          break;
        case 2:
          message.pubkey = reader.bytes();
          break;
        case 3:
          message.authSig = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SelfMintSignatory {
    return {
      seckey: isSet(object.seckey)
        ? bytesFromBase64(object.seckey)
        : new Uint8Array(),
      pubkey: isSet(object.pubkey)
        ? bytesFromBase64(object.pubkey)
        : new Uint8Array(),
      authSig: isSet(object.authSig)
        ? bytesFromBase64(object.authSig)
        : new Uint8Array(),
    };
  },

  toJSON(message: SelfMintSignatory): unknown {
    const obj: any = {};
    message.seckey !== undefined &&
      (obj.seckey = base64FromBytes(
        message.seckey !== undefined ? message.seckey : new Uint8Array()
      ));
    message.pubkey !== undefined &&
      (obj.pubkey = base64FromBytes(
        message.pubkey !== undefined ? message.pubkey : new Uint8Array()
      ));
    message.authSig !== undefined &&
      (obj.authSig = base64FromBytes(
        message.authSig !== undefined ? message.authSig : new Uint8Array()
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SelfMintSignatory>, I>>(
    object: I
  ): SelfMintSignatory {
    const message = createBaseSelfMintSignatory();
    message.seckey = object.seckey ?? new Uint8Array();
    message.pubkey = object.pubkey ?? new Uint8Array();
    message.authSig = object.authSig ?? new Uint8Array();
    return message;
  },
};

function createBaseSelfMintV2Signatory(): SelfMintV2Signatory {
  return {
    mintSk: new Uint8Array(),
    mintPk: new Uint8Array(),
    authSig: new Uint8Array(),
  };
}

export const SelfMintV2Signatory = {
  encode(
    message: SelfMintV2Signatory,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.mintSk.length !== 0) {
      writer.uint32(10).bytes(message.mintSk);
    }
    if (message.mintPk.length !== 0) {
      writer.uint32(18).bytes(message.mintPk);
    }
    if (message.authSig.length !== 0) {
      writer.uint32(26).bytes(message.authSig);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfMintV2Signatory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfMintV2Signatory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mintSk = reader.bytes();
          break;
        case 2:
          message.mintPk = reader.bytes();
          break;
        case 3:
          message.authSig = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SelfMintV2Signatory {
    return {
      mintSk: isSet(object.mintSk)
        ? bytesFromBase64(object.mintSk)
        : new Uint8Array(),
      mintPk: isSet(object.mintPk)
        ? bytesFromBase64(object.mintPk)
        : new Uint8Array(),
      authSig: isSet(object.authSig)
        ? bytesFromBase64(object.authSig)
        : new Uint8Array(),
    };
  },

  toJSON(message: SelfMintV2Signatory): unknown {
    const obj: any = {};
    message.mintSk !== undefined &&
      (obj.mintSk = base64FromBytes(
        message.mintSk !== undefined ? message.mintSk : new Uint8Array()
      ));
    message.mintPk !== undefined &&
      (obj.mintPk = base64FromBytes(
        message.mintPk !== undefined ? message.mintPk : new Uint8Array()
      ));
    message.authSig !== undefined &&
      (obj.authSig = base64FromBytes(
        message.authSig !== undefined ? message.authSig : new Uint8Array()
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SelfMintV2Signatory>, I>>(
    object: I
  ): SelfMintV2Signatory {
    const message = createBaseSelfMintV2Signatory();
    message.mintSk = object.mintSk ?? new Uint8Array();
    message.mintPk = object.mintPk ?? new Uint8Array();
    message.authSig = object.authSig ?? new Uint8Array();
    return message;
  },
};

function createBaseSlpEscrowSignatory(): SlpEscrowSignatory {
  return {
    sigHashType: 0,
    escrowMsg: new Uint8Array(),
    escrowSig: new Uint8Array(),
    isAlice: false,
    covenantSk: new Uint8Array(),
  };
}

export const SlpEscrowSignatory = {
  encode(
    message: SlpEscrowSignatory,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.sigHashType !== 0) {
      writer.uint32(8).uint32(message.sigHashType);
    }
    if (message.escrowMsg.length !== 0) {
      writer.uint32(18).bytes(message.escrowMsg);
    }
    if (message.escrowSig.length !== 0) {
      writer.uint32(26).bytes(message.escrowSig);
    }
    if (message.isAlice === true) {
      writer.uint32(32).bool(message.isAlice);
    }
    if (message.covenantSk.length !== 0) {
      writer.uint32(42).bytes(message.covenantSk);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlpEscrowSignatory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlpEscrowSignatory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sigHashType = reader.uint32();
          break;
        case 2:
          message.escrowMsg = reader.bytes();
          break;
        case 3:
          message.escrowSig = reader.bytes();
          break;
        case 4:
          message.isAlice = reader.bool();
          break;
        case 5:
          message.covenantSk = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SlpEscrowSignatory {
    return {
      sigHashType: isSet(object.sigHashType) ? Number(object.sigHashType) : 0,
      escrowMsg: isSet(object.escrowMsg)
        ? bytesFromBase64(object.escrowMsg)
        : new Uint8Array(),
      escrowSig: isSet(object.escrowSig)
        ? bytesFromBase64(object.escrowSig)
        : new Uint8Array(),
      isAlice: isSet(object.isAlice) ? Boolean(object.isAlice) : false,
      covenantSk: isSet(object.covenantSk)
        ? bytesFromBase64(object.covenantSk)
        : new Uint8Array(),
    };
  },

  toJSON(message: SlpEscrowSignatory): unknown {
    const obj: any = {};
    message.sigHashType !== undefined &&
      (obj.sigHashType = Math.round(message.sigHashType));
    message.escrowMsg !== undefined &&
      (obj.escrowMsg = base64FromBytes(
        message.escrowMsg !== undefined ? message.escrowMsg : new Uint8Array()
      ));
    message.escrowSig !== undefined &&
      (obj.escrowSig = base64FromBytes(
        message.escrowSig !== undefined ? message.escrowSig : new Uint8Array()
      ));
    message.isAlice !== undefined && (obj.isAlice = message.isAlice);
    message.covenantSk !== undefined &&
      (obj.covenantSk = base64FromBytes(
        message.covenantSk !== undefined ? message.covenantSk : new Uint8Array()
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SlpEscrowSignatory>, I>>(
    object: I
  ): SlpEscrowSignatory {
    const message = createBaseSlpEscrowSignatory();
    message.sigHashType = object.sigHashType ?? 0;
    message.escrowMsg = object.escrowMsg ?? new Uint8Array();
    message.escrowSig = object.escrowSig ?? new Uint8Array();
    message.isAlice = object.isAlice ?? false;
    message.covenantSk = object.covenantSk ?? new Uint8Array();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach(byte => {
      bin.push(String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
