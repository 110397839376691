// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import Long from "long";

import type { StoreGet, StoreSet } from "../store";
import * as proto from "../rpc/p2s";
import * as tx from "../wallet/tx";
import { signedRequest } from "../rpc/request";
import { buildSendToOneTx } from "../wallet/send";
import { toHex, toHexRev } from "../wallet/hex";
import {
  DUST_AMOUNT,
  FEE_PER_KB,
  P2S_ADDRESS,
  P2S_FEE_PER1000,
  TOKEN_FACTOR_CENT,
  TOKEN_DATA,
} from "../wallet/settings";
import { decodeEtokenAddress } from "../wallet/address";
import {
  hash160,
  script_p2pkh,
  script_p2sh,
  sha256,
  slp_escrow_estimate_spend_size,
  slp_escrow_script,
} from "../wallet/ffi";
import { catchError } from "./error";
import { totalPriceEvent } from "../wallet/price";
import { minRequiredHours, nowSeconds } from "../wallet/format";
import {
  buildBurnTx,
  buildP2sBurnCommitment,
  buildP2sBurnCommitmentSection,
} from "../wallet/burn";
import { buildCheckinUri } from "../wallet/checkin";

export const EVENT_CODE_PARAM = "code";
export const PAY_SUCCESS = "success";
export const EVENT_PRICE = "event_price";
export const EVENT_UPCHARGE_CASH = "upcharge_cash";
export const EVENT_CREATE_AUTO_AFFILIATE = "create_auto_affiliate";
export const EVENT_SEND_CONFIRMATION_VIA = "confirmation_via";
export const EVENT_AUTO_AFFILIATE_CODE = "auto_affiliate_code";

export const MIN_REQUIRED_MINUTES = 60;

export type MessageProtocol = "Email";

export interface EventDetailState {
  eventCode: string | undefined;
  event: proto.Event | undefined;
  createAutoAffiliate: boolean;
  sendConfirmationVia: MessageProtocol[];
  paymentId: number | undefined;
  burnTokenAmount: Long | undefined;
  shouldBurnTokens: boolean;
  isSharingInfoWithHost: boolean;
  /**
   * Event after a successful payment has been made.
   * We need this as `event` gets re-fetched after success, which overrides e.g. the guestState.
   **/
  eventSuccess: proto.Event | undefined;
}

export interface EventDetailActions {
  eventDetailFetchByCode: (
    code: string,
    shouldShowLoading?: boolean
  ) => Promise<void>;
  eventDetailJoin: (sendConfirmationVia: MessageProtocol[]) => Promise<string>;
  eventDetailSet: (event: proto.Event | undefined) => void;
  eventDetailJoinWalletless: (
    sendConfirmationVia: MessageProtocol[]
  ) => Promise<string>;
  eventDetailJoinBurn: (
    sendConfirmationVia: MessageProtocol[]
  ) => Promise<string>;
  eventDetailJoinWithCash: (
    event: proto.Event,
    eventCode: string,
    sendConfirmationVia: MessageProtocol[]
  ) => Promise<string>;
  eventDetailJoinFree: (
    sendConfirmationVia: MessageProtocol[]
  ) => Promise<string>;
  sendEventConfirmation: (
    email: string,
    shouldSaveEmail: boolean
  ) => Promise<void>;
  setCreateAutoAffiliate: (isCreate: boolean) => void;
  setSendConfirmationVia: (sendConfirmationVia: MessageProtocol[]) => void;
  setBurnTokenAmount: (tokenAmount: Long | undefined) => void;
  setShouldBurnTokens: (shouldBurnTokens: boolean) => void;
  setIsSharingInfoWithHost: (isSharingInfoWithHost: boolean) => void;
}

export const initialEventDetail: EventDetailState = {
  eventCode: undefined,
  event: undefined,
  createAutoAffiliate: false,
  sendConfirmationVia: [],
  paymentId: undefined,
  burnTokenAmount: undefined,
  isSharingInfoWithHost: false,
  eventSuccess: undefined,
  shouldBurnTokens: false,
};

export function eventDetailActions(
  set: StoreSet,
  get: StoreGet
): EventDetailActions {
  return {
    setCreateAutoAffiliate: (isCreate: boolean) => {
      set(state => {
        state.eventDetail.createAutoAffiliate = isCreate;
      });
    },
    setSendConfirmationVia: (sendConfirmationVia: MessageProtocol[]) => {
      set(state => {
        state.eventDetail.sendConfirmationVia = sendConfirmationVia;
      });
    },
    setBurnTokenAmount: (tokenAmount: Long | undefined) => {
      set(state => {
        state.eventDetail.burnTokenAmount = tokenAmount;
      });
    },
    setShouldBurnTokens: (shouldBurnTokens: boolean) => {
      set(state => {
        state.eventDetail.shouldBurnTokens = shouldBurnTokens;
      });
    },
    setIsSharingInfoWithHost: (isSharingInfoWithHost: boolean) => {
      set(state => {
        state.eventDetail.isSharingInfoWithHost = isSharingInfoWithHost;
      });
    },
    eventDetailFetchByCode: catchError(
      "Event",
      set,
      async (eventCode: string, shouldShowLoading: boolean = true) => {
        set(state => {
          state.loading.isLoading = shouldShowLoading;
        });
        const ecc = get().wallet.ecc!;
        const keys =
          get().wallet.keys && get().verifyEmail.hasVerifiedEmail
            ? get().wallet.keys!
            : undefined;
        const request: proto.EventRequest = {
          eventCode,
        };
        const event = await signedRequest({
          proto: proto.Event,
          path: "/event",
          method: "POST",
          ecc,
          seckey: keys?.seckey,
          pubkey: keys?.pubkey,
          payload: proto.EventRequest.encode(request).finish(),
        });
        set(state => {
          state.eventDetail.eventCode = eventCode;
          state.eventDetail.event = event;
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDetailJoinWithCash: catchError(
      "Book Event Request",
      set,
      async (
        event: proto.Event,
        eventCode: string,
        sendConfirmationVia: MessageProtocol[]
      ) => {
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const request = proto.JoinEventRequest.fromPartial({
          cart: {
            eventCode,
            numBookedHours: !event.priceData?.pricePerHour.isZero()
              ? minRequiredHours(event, nowSeconds())
              : 0,
            items: get().cart.cart?.items ?? [],
          },
          isPayingLater: true,
          createAutoAffiliate:
            event.autoGuestAffiliatePer1000 > 0 &&
            get().eventDetail.createAutoAffiliate,
          sendConfirmationVia,
          isSharingInfoWithHost: get().eventDetail.isSharingInfoWithHost,
        });
        const response = await signedRequest({
          proto: proto.JoinEventResponse,
          path: "/event/join",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.JoinEventRequest.encode(request).finish(),
        });
        set(state => {
          state.eventDetail.paymentId = response.paymentId;
          state.eventDetail.eventSuccess = event;
        });
        return response.autoAffiliateCode;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDetailJoin: catchError(
      "Join Event",
      set,
      async (sendConfirmationVia: MessageProtocol[]) => {
        const eventCode =
          get().eventDetail.eventCode || get().cart.cart?.eventCode;
        if (eventCode === undefined) {
          throw new Error("Event code is undefined");
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const tokenId = get().wallet.tokenId;
        const tokenData = TOKEN_DATA.get(tokenId)!;
        const event = await signedRequest({
          proto: proto.Event,
          path: "/event",
          method: "POST",
          ecc,
          seckey: keys?.seckey,
          pubkey: keys?.pubkey,
          payload: proto.EventRequest.encode({ eventCode }).finish(),
        });

        const cart = get().cart.cart;
        const price = totalPriceEvent(
          event.priceData,
          event.guestState,
          !event.priceData?.pricePerHour.isZero()
            ? minRequiredHours(event, nowSeconds())
            : 0,
          false,
          cart?.items.length ?? 1
        );
        const totalAmount = price.mul(TOKEN_FACTOR_CENT);
        const p2sFeeAmount = totalAmount.mul(P2S_FEE_PER1000).div(1000);
        const escrowAmount = totalAmount.sub(p2sFeeAmount);
        const guestScript = script_p2pkh(keys.pkh);
        const dustAmount = Long.fromNumber(DUST_AMOUNT);
        const recipientOutputs: tx.TxOutput[] = [
          { value: dustAmount, script: guestScript },
        ].concat(
          event.affiliates.map(affiliate => ({
            value: dustAmount,
            script: affiliate.outputScript,
          }))
        );
        const recipientOutputsProto = tx.TxOutputs.encode({
          outputs: recipientOutputs,
        }).finish();
        const escrowRedeemScript = slp_escrow_script(
          event.escrowPk,
          keys.pubkey,
          event.hostPk,
          tokenId,
          tokenData.tokenProtocol,
          recipientOutputsProto,
          0x41
        );
        const escrowScriptHash = hash160(escrowRedeemScript);
        const escrowP2sh = script_p2sh(escrowScriptHash);
        const escrowSats = slp_escrow_estimate_spend_size(
          recipientOutputsProto,
          FEE_PER_KB,
          tokenData.tokenProtocol
        );
        const p2sScript = decodeEtokenAddress(P2S_ADDRESS);
        const payTx = await buildSendToOneTx({
          ecc,
          keys,
          tokenId,
          tokenProtocol: tokenData.tokenProtocol,
          outputs: [
            { amount: p2sFeeAmount, script: p2sScript },
            {
              amount: escrowAmount,
              script: escrowP2sh,
              sats: Long.fromNumber(escrowSats),
            },
          ],
          leftoverScript: script_p2pkh(keys.pkh),
          feePerKb: FEE_PER_KB,
        });
        const request = proto.JoinEventRequest.fromPartial({
          cart: {
            eventCode,
            numBookedHours: !event.basePriceData?.pricePerHour.isZero()
              ? cart?.numBookedHours ?? minRequiredHours(event, nowSeconds())
              : 0,
            items: cart?.items ?? [],
          },
          escrowType: "",
          txs: [payTx],
          isPayingLater: false,
          createAutoAffiliate:
            event.autoGuestAffiliatePer1000 > 0 &&
            get().eventDetail.createAutoAffiliate,
          sendConfirmationVia,
          isSharingInfoWithHost: get().eventDetail.isSharingInfoWithHost,
        });
        const response = await signedRequest({
          proto: proto.JoinEventResponse,
          path: "/event/join",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.JoinEventRequest.encode(request).finish(),
        });
        set(state => {
          state.eventDetail.paymentId = response.paymentId;
          state.eventDetail.eventSuccess = event;
        });
        console.log("response txids:", response.txids.map(toHexRev));
        return response.autoAffiliateCode;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDetailJoinWalletless: catchError(
      "Join Event",
      set,
      async (sendConfirmationVia: MessageProtocol[]) => {
        const eventCode =
          get().eventDetail.eventCode || get().cart.cart?.eventCode;
        if (eventCode === undefined) {
          throw new Error("Event code is undefined");
        }
        const processorPaymentId = get().stripe.paymentId;
        if (processorPaymentId === undefined) {
          throw new Error("Processor Payment ID is undefined");
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const autoAffiliatePer1000 =
          get().eventDetail.event?.autoGuestAffiliatePer1000;
        const request = proto.JoinEventRequest.fromPartial({
          cart: {
            eventCode,
            numBookedHours: get().cart.cart?.numBookedHours ?? 0,
            items: get().cart.cart?.items ?? [],
          },
          escrowType: "Walletless",
          processor: "Stripe",
          processorPaymentId,
          isPayingLater: false,
          createAutoAffiliate:
            autoAffiliatePer1000 !== undefined &&
            autoAffiliatePer1000 > 0 &&
            get().eventDetail.createAutoAffiliate,
          sendConfirmationVia,
          isSharingInfoWithHost: get().eventDetail.isSharingInfoWithHost,
        });
        const response = await signedRequest({
          proto: proto.JoinEventResponse,
          path: "/event/join",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.JoinEventRequest.encode(request).finish(),
        });
        set(state => {
          state.eventDetail.paymentId = response.paymentId;
          state.eventDetail.eventSuccess = get().eventDetail.event;
        });
        return response.autoAffiliateCode;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDetailJoinBurn: catchError(
      "Join Event",
      set,
      async (sendConfirmationVia: MessageProtocol[]) => {
        const eventCode =
          get().eventDetail.eventCode || get().cart.cart?.eventCode;
        if (eventCode === undefined) {
          throw new Error("Event code is undefined");
        }
        const event = get().eventDetail.event;
        if (event === undefined) {
          throw new Error("Event is undefined");
        }
        const processorPaymentId = get().stripe.paymentId;
        const burnTokenAmount = get().eventDetail.burnTokenAmount;
        if (burnTokenAmount === undefined) {
          throw new Error("Burn token amount is undefined");
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const tokenId = get().wallet.tokenId;
        const tokenData = TOKEN_DATA.get(tokenId)!;
        const autoAffiliatePer1000 =
          get().eventDetail.event?.autoGuestAffiliatePer1000;
        const cartProto = proto.EventPurchaseCart.encode({
          eventCode,
          numBookedHours: get().cart.cart?.numBookedHours ?? 0,
          items: get().cart.cart?.items ?? [],
        }).finish();
        const burnCommitment = buildP2sBurnCommitment({
          guestPkh: keys.pkh,
          eventId: event.eventId,
          paymentIdx: event.payments.length,
          cartProtoHash: sha256(cartProto),
        });
        const burnTx = await buildBurnTx({
          ecc,
          keys,
          tokenId,
          tokenProtocol: tokenData.tokenProtocol,
          amount: burnTokenAmount,
          leftoverScript: script_p2pkh(keys.pkh),
          feePerKb: FEE_PER_KB,
          postageAllowed: true,
          extraSection: buildP2sBurnCommitmentSection(sha256(burnCommitment)),
        });
        console.log("burn token amount", burnTokenAmount.toNumber());
        console.log("burn tx", toHex(burnTx));
        const request = proto.JoinEventRequest.fromPartial({
          cartProto,
          escrowType: "Burn",
          processor: processorPaymentId !== undefined ? "Stripe" : undefined,
          processorPaymentId,
          isPayingLater: false,
          createAutoAffiliate:
            autoAffiliatePer1000 !== undefined &&
            autoAffiliatePer1000 > 0 &&
            get().eventDetail.createAutoAffiliate,
          sendConfirmationVia,
          isSharingInfoWithHost: get().eventDetail.isSharingInfoWithHost,
          txs: [burnTx],
        });
        const response = await signedRequest({
          proto: proto.JoinEventResponse,
          path: "/event/join",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.JoinEventRequest.encode(request).finish(),
        });
        console.log("burn txids:", response.txids.map(toHexRev));
        set(state => {
          state.eventDetail.paymentId = response.paymentId;
          state.eventDetail.eventSuccess = event;
        });
        return response.autoAffiliateCode;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDetailJoinFree: catchError(
      "Join Event",
      set,
      async (sendConfirmationVia: MessageProtocol[]) => {
        const eventCode =
          get().eventDetail.eventCode || get().cart.cart?.eventCode;
        if (eventCode === undefined) {
          throw new Error("Event code is undefined");
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const autoAffiliatePer1000 =
          get().eventDetail.event?.autoGuestAffiliatePer1000;
        const request = proto.JoinEventRequest.fromPartial({
          cart: {
            eventCode,
            numBookedHours: get().cart.cart?.numBookedHours ?? 0,
            items: get().cart.cart?.items ?? [],
          },
          isPayingLater: false,
          createAutoAffiliate:
            autoAffiliatePer1000 !== undefined &&
            autoAffiliatePer1000 > 0 &&
            get().eventDetail.createAutoAffiliate,
          sendConfirmationVia,
          isSharingInfoWithHost: get().eventDetail.isSharingInfoWithHost,
        });
        const response = await signedRequest({
          proto: proto.JoinEventResponse,
          path: "/event/join",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.JoinEventRequest.encode(request).finish(),
        });
        set(state => {
          state.eventDetail.paymentId = response.paymentId;
          state.eventDetail.eventSuccess = get().eventDetail.event;
        });
        return response.autoAffiliateCode;
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    sendEventConfirmation: catchError(
      "Send Event Confirmation",
      set,
      async (email: string, shouldSaveEmail: boolean) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const paymentId = get().eventDetail.paymentId;
        if (paymentId === undefined) {
          throw "Missing payment ID";
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const checkinUri = get().eventDetail.event?.isAppRequired
          ? ""
          : buildCheckinUri({
              ecc,
              guestSk: keys.seckey,
              guestId: get().profile.user?.userId ?? 0,
              eventId: get().eventDetail.event?.eventId ?? 0,
              isCheckin: true,
              isManualByDoorman: false,
            });
        await signedRequest({
          proto: proto.Empty,
          path: "/event/confirmation/send",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.SendEventConfirmationEmailRequest.encode({
            paymentId,
            sendConfirmationVia: ["Email"],
            email,
            shouldSaveEmail,
            checkinUri,
          }).finish(),
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    eventDetailSet: (event: proto.Event | undefined) => {
      set(state => {
        state.eventDetail.event = event;
      });
    },
  };
}
