// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";
import protobuf from "protobufjs";

import * as proto from "../rpc/p2s";
import { UserPrivileges, UserProfile } from "../store/profile";
import { SignInPhoneInfo } from "../store/verifyPhoneNumber";

const STORAGE_PREFIX = "p2s:";

/**
 * Interface for an object that can be encoded and decoded using protobuf.
 */
interface Encodable<T> {
  encode(message: T, writer?: protobuf.Writer): protobuf.Writer;
  decode(input: Uint8Array | protobuf.Reader, length?: number): T;
}

interface AsyncStorage<T> {
  get: () => Promise<T | undefined>;
  set: (item: T) => Promise<void>;
  clear: () => Promise<void>;
}

/**
 * Creates a storage utility for a single, unencoded item using LocalForage.
 *
 * @param key The storage key under which the item will be stored.
 * @returns An object with asynchronous get and set methods.
 */
function createSimpleLocalforageStorage<T>(
  key: string
): AsyncStorage<T | undefined> {
  return {
    get: async () => {
      return (await localforage.getItem<T>(STORAGE_PREFIX + key)) || undefined;
    },
    set: async item => {
      await localforage.setItem(STORAGE_PREFIX + key, item);
    },
    clear: async () => {
      await localforage.removeItem(STORAGE_PREFIX + key);
    },
  };
}

/**
 * Creates a storage utility for a single, encoded item using LocalForage.
 *
 * @param key The storage key under which the item will be stored.
 * @param decoder A function to decode the stored Uint8Array back into the item.
 * @param encoder A function to encode the item into a Uint8Array for storage.
 * @returns An object with asynchronous get and set methods.
 */
function createEncodedLocalforageStorage<T>(
  key: string,
  decoder: (buffer: Uint8Array) => T,
  encoder: (item: T) => Uint8Array
): AsyncStorage<T> {
  return {
    get: async () => {
      const buffer = await localforage.getItem<Uint8Array>(
        STORAGE_PREFIX + key
      );
      if (buffer === null) {
        return undefined;
      }
      return decoder(buffer);
    },
    set: async item => {
      await localforage.setItem(STORAGE_PREFIX + key, encoder(item));
    },
    clear: async () => {
      await localforage.removeItem(STORAGE_PREFIX + key);
    },
  };
}

/**
 * Creates a storage utility for an array of encoded items using LocalForage.
 *
 * @param key The storage key under which the array of items will be stored.
 * @param decoder A function to decode each Uint8Array in the stored array back into items.
 * @param encoder A function to encode items into Uint8Array for storage.
 * @returns An object with asynchronous get and set methods for arrays of items.
 */
function createEncodedLocalforageArrayStorage<T>(
  key: string,
  decoder: (buffer: Uint8Array) => T,
  encoder: (item: T) => Uint8Array
): AsyncStorage<T[]> {
  return {
    get: async () => {
      const buffers = await localforage.getItem<Uint8Array[]>(
        STORAGE_PREFIX + key
      );
      if (buffers === null) {
        return [];
      }
      return buffers.map(buffer => decoder(buffer));
    },
    set: async items => {
      await localforage.setItem(STORAGE_PREFIX + key, items.map(encoder));
    },
    clear: async () => {
      await localforage.removeItem(STORAGE_PREFIX + key);
    },
  };
}

/**
 * Creates a protobuf-based storage utility for a single item using LocalForage.
 *
 * @param key The storage key for the item.
 * @param message The Protobuf message class for encoding and decoding.
 * @returns An object that provides asynchronous get and set methods.
 */
function createLocalforageProtoBufStorage<T>(
  key: string,
  message: Encodable<T>
): AsyncStorage<T> {
  return createEncodedLocalforageStorage<T>(
    key,
    buffer => message.decode(buffer),
    item => message.encode(item).finish()
  );
}

/**
 * Creates a protobuf-based storage utility for an array of items using LocalForage.
 *
 * @param key The storage key for the array of items.
 * @param message The Protobuf message class used for encoding and decoding the items.
 * @returns An object that provides asynchronous get and set methods for arrays of items.
 */
function createLocalforageProtoBufArrayStorage<T>(
  key: string,
  message: Encodable<T>
): AsyncStorage<T[]> {
  return createEncodedLocalforageArrayStorage<T>(
    key,
    buffer => message.decode(buffer),
    item => message.encode(item).finish()
  );
}

export const browserStorage = {
  // Events
  joinedEvents: createLocalforageProtoBufArrayStorage<proto.Event>(
    "joinedEvents",
    proto.Event
  ),
  hostedEvents: createLocalforageProtoBufArrayStorage<proto.Event>(
    "hostedEvents",
    proto.Event
  ),
  affiliatedEvents: createLocalforageProtoBufArrayStorage<proto.Event>(
    "affiliatedEvents",
    proto.Event
  ),
  overviewEvents: createLocalforageProtoBufArrayStorage<proto.OverviewGuest>(
    "overviewEvents",
    proto.OverviewGuest
  ),
  endedJoinedEvents: createLocalforageProtoBufArrayStorage<proto.Event>(
    "endedJoinedEvents",
    proto.Event
  ),
  endedHostedEvents: createLocalforageProtoBufArrayStorage<proto.Event>(
    "endedHostedEvents",
    proto.Event
  ),

  // Profile
  profile: createSimpleLocalforageStorage<UserProfile>("profile"),
  privileges: createSimpleLocalforageStorage<UserPrivileges>("privileges"),

  // SignInPhoneInfo
  signInPhoneInfo:
    createSimpleLocalforageStorage<SignInPhoneInfo>("signInPhoneInfo"),

  // Wallet
  balance: createLocalforageProtoBufStorage<proto.Balance>(
    "balance",
    proto.Balance
  ),

  // canInstallPwa
  canInstallPwa: createSimpleLocalforageStorage<boolean>("canInstallPwa"),
};
