// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import axios from "axios";
import { buildUrlParams } from "../util";
import { AUTH_CODE_URL } from "./settings";

export interface AuthCodeResponse {
  authcode: string;
  description?: string;
  mintauth?: string;
}

export async function requestAuthCode(
  orderId: string,
  method: string
): Promise<AuthCodeResponse> {
  const response = await axios.request({
    url: buildUrlParams(AUTH_CODE_URL, { paymentId: orderId }),
    method: method,
    validateStatus: undefined,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status != 200) {
    throw new Error(response.data);
  }
  if (!response.data || !response.data.authcode) {
    throw new Error("No authorization code");
  }
  return response.data;
}
