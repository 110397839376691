// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import type { StoreGet, StoreSet } from "../store";
import { chronik, signedRequest } from "../rpc/request";
import * as proto from "../rpc/p2s";
import { toHex } from "../wallet/hex";
import { catchError } from "./error";
import {
  buildSlpPayoutTx,
  calcEventPayoutSummary,
  EventPayoutSummary,
} from "../wallet/eventPayout";
import { buildSelfMintTx, processAuthCode } from "../wallet/selfMint";

export interface EventPayoutState {
  payoutSummary: EventPayoutSummary | undefined;
}

export const initialEventPayout: EventPayoutState = {
  payoutSummary: undefined,
};

export interface EventPayoutActions {
  eventPayoutRequest: (event: proto.Event) => Promise<void>;
  payoutGuestFetch: (event: proto.Event) => Promise<void>;
  payoutHostFetch: (event: proto.Event) => Promise<void>;
  payoutHostRequest: (event: proto.Event) => Promise<void>;
  payoutAffiliateFetch: (event: proto.Event) => Promise<void>;
  payoutAffiliateRequest: (event: proto.Event) => Promise<void>;
}

export function eventPayoutActions(
  set: StoreSet,
  get: StoreGet
): EventPayoutActions {
  return {
    eventPayoutRequest: catchError(
      "Event Payout Request",
      set,
      async (event: proto.Event) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const tokenId = get().wallet.tokenId;
        const userId = get().profile.user?.userId;
        if (userId === undefined) {
          throw "No userId set";
        }
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventPayoutResponse,
          path: "/event/payout",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventPayoutRequest.encode({
            eventId: event.eventId,
            isSimulation: false,
          }).finish(),
        });
        const txs = response.payouts
          .filter(payout => !payout.isRedeemed)
          .map(payout =>
            buildSlpPayoutTx({
              ecc,
              keys,
              tokenId,
              guestPk: keys.pubkey,
              hostPk: event.hostPk,
              isGuestRedeeming: true,
              payout,
            })
          );
        const broadcastResponse = await chronik().broadcastTxs(txs);
        console.log("broadcast txs: ", broadcastResponse.txids);
        for (const payout of response.payoutsWalletless) {
          if (payout.userId !== userId) {
            console.log("skipping payout, not for us:", payout);
            continue;
          }
          const response = await signedRequest({
            proto: proto.EventPayoutMintCodeResponse,
            path: "/event/payout/mint-code",
            method: "POST",
            ecc,
            seckey: keys.seckey,
            pubkey: keys.pubkey,
            payload: proto.EventPayoutMintCodeRequest.encode({
              payoutId: payout.payoutId,
            }).finish(),
          });
          console.log("mint code response:", response);
          const authCodeData = processAuthCode(response.mintCode);
          console.log("authCodeData", authCodeData);
          const tx = await buildSelfMintTx({
            ecc,
            seckey: keys.seckey,
            pubkey: keys.pubkey,
            pkh: keys.pkh,
            authCodeData,
          });
          const broadcastResponse = await chronik().broadcastTx(tx);
          console.log("mint broadcast txid:", broadcastResponse.txid);
        }
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    payoutHostFetch: catchError(
      "Payout Host",
      set,
      async (event: proto.Event) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventPayoutResponse,
          path: "/event/host-payout",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventPayoutRequest.encode({
            eventId: event.eventId,
            isSimulation: true,
          }).finish(),
        });
        console.log("payout response", response);
        set(state => {
          state.eventPayout.payoutSummary = calcEventPayoutSummary(
            response.payouts,
            response.payoutsWalletless,
            response.payoutUsers,
            toHex(event.hostPk),
            event.hostId
          );
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    payoutAffiliateFetch: catchError(
      "Payout Affiliate",
      set,
      async (event: proto.Event) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventPayoutResponse,
          path: "/event/affiliate-payout",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventPayoutRequest.encode({
            eventId: event.eventId,
            isSimulation: true,
          }).finish(),
        });
        console.log("payout response", response);
        set(state => {
          state.eventPayout.payoutSummary = calcEventPayoutSummary(
            response.payouts,
            response.payoutsWalletless,
            response.payoutUsers,
            toHex(keys.pubkey),
            get().profile.user?.userId!
          );
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    payoutGuestFetch: catchError(
      "Payout Guest",
      set,
      async (event: proto.Event) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventPayoutResponse,
          path: "/event/payout",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventPayoutRequest.encode({
            eventId: event.eventId,
            isSimulation: true,
          }).finish(),
        });
        console.log("payout response", response);
        set(state => {
          state.eventPayout.payoutSummary = calcEventPayoutSummary(
            response.payouts,
            response.payoutsWalletless,
            response.payoutUsers,
            toHex(keys.pubkey),
            get().profile.user?.userId!
          );
        });
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    payoutHostRequest: catchError(
      "Payout Host",
      set,
      async (event: proto.Event) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventPayoutResponse,
          path: "/event/host-payout",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventPayoutRequest.encode({
            eventId: event.eventId,
            isSimulation: false,
          }).finish(),
        });
        console.log("payout response", response);
        const scriptToUserMap = new Map(
          response.payoutUsers.map(user => [toHex(user.recipientScript), user])
        );
        const txs = response.payouts
          .filter(payout => !payout.isRedeemed)
          .map(payout => {
            const guestScript = payout.recipientScripts[0];
            if (guestScript === undefined) {
              throw `No recipients in payout ${JSON.stringify(payout)}`;
            }
            const guestScriptHex = toHex(guestScript);
            const guestUser = scriptToUserMap.get(guestScriptHex);
            if (guestUser === undefined) {
              throw `No payout user for script ${guestScriptHex}`;
            }
            return buildSlpPayoutTx({
              ecc,
              keys,
              tokenId: payout.tokenId,
              guestPk: guestUser?.userPk,
              hostPk: event.hostPk,
              isGuestRedeeming: false,
              payout,
            });
          });
        const broadcastResponse = await chronik().broadcastTxs(txs);
        console.log("broadcast txs: ", broadcastResponse.txids);
        for (const payout of response.payoutsWalletless) {
          if (payout.userId !== event.hostId) {
            console.log("skipping payout, not for us:", payout);
            continue;
          }
          const response = await signedRequest({
            proto: proto.EventPayoutMintCodeResponse,
            path: "/event/payout/mint-code",
            method: "POST",
            ecc,
            seckey: keys.seckey,
            pubkey: keys.pubkey,
            payload: proto.EventPayoutMintCodeRequest.encode({
              payoutId: payout.payoutId,
            }).finish(),
          });
          console.log("mint code response:", response);
          const authCodeData = processAuthCode(response.mintCode);
          const tx = await buildSelfMintTx({
            ecc,
            seckey: keys.seckey,
            pubkey: keys.pubkey,
            pkh: keys.pkh,
            authCodeData,
          });
          const broadcastResponse = await chronik().broadcastTx(tx);
          console.log("mint broadcast txid:", broadcastResponse.txid);
        }
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
    payoutAffiliateRequest: catchError(
      "Payout Affiliate",
      set,
      async (event: proto.Event) => {
        const ecc = get().wallet.ecc!;
        const keys = get().wallet.keys!;
        const userId = get().profile.user?.userId;
        set(state => {
          state.loading.isLoading = true;
        });
        const response = await signedRequest({
          proto: proto.EventPayoutResponse,
          path: "/event/affiliate-payout",
          method: "POST",
          ecc,
          seckey: keys.seckey,
          pubkey: keys.pubkey,
          payload: proto.EventPayoutRequest.encode({
            eventId: event.eventId,
            isSimulation: false,
          }).finish(),
        });
        console.log("payout response", response);
        for (const payout of response.payoutsWalletless) {
          if (payout.userId !== userId) {
            console.log("skipping payout, not for us:", payout);
            continue;
          }
          const response = await signedRequest({
            proto: proto.EventPayoutMintCodeResponse,
            path: "/event/payout/mint-code",
            method: "POST",
            ecc,
            seckey: keys.seckey,
            pubkey: keys.pubkey,
            payload: proto.EventPayoutMintCodeRequest.encode({
              payoutId: payout.payoutId,
            }).finish(),
          });
          console.log("mint code response:", response);
          const authCodeData = processAuthCode(response.mintCode);
          const tx = await buildSelfMintTx({
            ecc,
            seckey: keys.seckey,
            pubkey: keys.pubkey,
            pkh: keys.pkh,
            authCodeData,
          });
          const broadcastResponse = await chronik().broadcastTx(tx);
          console.log("mint broadcast txid:", broadcastResponse.txid);
        }
      },
      state => {
        state.loading.isLoading = false;
      }
    ),
  };
}
