// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

import localforage from "localforage";

import type { StoreGet, StoreSet } from "../store";
import { initWalletWasm, Ecc } from "../wallet/ffi";
import * as proto from "../rpc/p2s";
import { calculateBalance } from "../wallet/balance";
import { requestUtxos } from "../wallet/request";
import { catchError } from "./error";
import { encodeECashAddress, encodeEtokenAddress } from "../wallet/address";
import { TOKEN_PREFIX, MAIN_TOKEN_ID } from "../wallet/settings";
import { browserStorage } from "../storage/browser";
import { deleteWalletKeys } from "../wallet/key";

export interface WalletStore {
  isReady: boolean;
  isLoading: boolean;
  ecc: Ecc | undefined;
  keys: WalletKeys | undefined;
  balance: proto.Balance | undefined;
  tokenId: string;
}

export interface WalletKeys {
  isProvisionalPk?: boolean;
  seedPhrase: string;
  seckey: Uint8Array;
  pubkey: Uint8Array;
  pkh: Uint8Array;
  address: string;
  eCashAddress: string;
}

export interface WalletActions {
  walletLoad: () => Promise<void>;
  walletFetchBalance: () => void;
  walletDelete: () => Promise<void>;
}

export const initialWallet: WalletStore = {
  isReady: false,
  isLoading: false,
  ecc: undefined,
  keys: undefined,
  balance: undefined,
  tokenId: MAIN_TOKEN_ID,
};

export function walletActions(set: StoreSet, get: StoreGet): WalletActions {
  return {
    walletLoad: catchError("Initialization Error", set, async () => {
      const wallet = get().wallet;
      if (wallet.isLoading || wallet.isReady) {
        return;
      }
      set(store => {
        store.wallet.isLoading = true;
      });

      await initWalletWasm();
      const ecc = new Ecc();

      let [
        seedPhrase,
        seckey,
        pubkey,
        pkh,
        address,
        eCashAddress,
        hasVerifiedEmail,
        hasDecidedAboutNewsletter,
        isProvisionalPk,
      ] = await Promise.all([
        localforage.getItem<string>("p2s:seedPhrase"),
        localforage.getItem<Uint8Array>("p2s:seckey"),
        localforage.getItem<Uint8Array>("p2s:pubkey"),
        localforage.getItem<Uint8Array>("p2s:pkh"),
        localforage.getItem<string>("p2s:address"),
        localforage.getItem<string>("p2s:eCashAddress"),
        localforage.getItem<boolean>("p2s:hasVerifiedEmail"),
        localforage.getItem<boolean>("p2s:hasDecidedAboutNewsletter"),
        localforage.getItem<boolean>("p2s:isProvisionalPk"),
      ]);

      const [balance] = await Promise.allSettled([
        browserStorage.balance.get(),
      ]);

      set(store => {
        store.wallet.isLoading = false;
        store.wallet.isReady = true;
        store.wallet.ecc = ecc;
        if (balance.status === "fulfilled") {
          store.wallet.balance = balance.value;
        }
        if (
          seedPhrase !== null &&
          seckey !== null &&
          pkh !== null &&
          pubkey !== null &&
          address !== null
        ) {
          // Upgrade token address
          if (!address.startsWith(TOKEN_PREFIX + ":")) {
            address = encodeEtokenAddress("P2PKH", pkh);
            localforage.setItem("p2s:address", address);
          }
          if (eCashAddress === null) {
            eCashAddress = encodeECashAddress("P2PKH", pkh);
            localforage.setItem("p2s:eCashAddress", eCashAddress);
          }
          store.wallet.keys = {
            seedPhrase,
            seckey,
            pubkey,
            pkh,
            address,
            eCashAddress,
            isProvisionalPk: !!isProvisionalPk,
          };
          store.verifyEmail.hasVerifiedEmail = !!hasVerifiedEmail;
          store.newsletter.hasDecided = !!hasDecidedAboutNewsletter;
        }
      });
    }),
    walletFetchBalance: catchError("Balance", set, async () => {
      const keys = get().wallet.keys!;
      const tokenId = get().wallet.tokenId;
      const utxos = await requestUtxos(tokenId, keys.pkh);
      const balance = calculateBalance(utxos);
      set(state => {
        state.wallet.balance = balance;
      });

      browserStorage.balance.set(balance);
    }),
    walletDelete: catchError("Delete Wallet", set, async () => {
      await deleteWalletKeys();
      await browserStorage.balance.clear(),
        set(state => {
          state.wallet.balance = undefined;
          state.wallet.keys = undefined;
          state.verifyEmail.hasVerifiedEmail = false;
          state.newsletter.hasDecided = false;
        });
    }),
  };
}
